import React, { useContext,useState } from 'react';
import { Link } from 'react-router-dom';

import { AuthContext } from '../../contexts/Auth';
import { TENANT_ADMIN } from '../../constants/roles';
import { isAuthorized } from '../../helpers/authorization';

import Breadcrumb from '../../components/Breadcrumb';
import Button from '../../components/Button';
import CollectionListing from '../../components/CollectionListing';
import FrameHeader from '../../components/FrameHeader';
import RowItem from '../../components/RowItem';
import SingleColumnPage from '../../components/SingleColumnPage';

// import styles from './Tenants.module.scss';

export default function Customers() {
  const { state } = useContext(AuthContext);
  const breadcrumbs = () => <Breadcrumb title="Customers" />;
  

  return (
    <SingleColumnPage breadcrumbs={breadcrumbs}>
      <FrameHeader title="Customers">
        {isAuthorized(state.user.roles, [TENANT_ADMIN]) && (
          <Link to="/customers/new">
            <Button icon="add" theme="primary">
              New customer
            </Button>
          </Link>
        )}
      </FrameHeader>

      
      
      <CollectionListing
        collection="customers"
        item={c => (
          <RowItem
            key={c.id}
            title={c.name}
            subtitle={`${c.tenant.name} • ${c.city}, ${c.state}`}
            to={`/customers/${c.id}`}
          />
        )}
       /*sortOptions={
          <>
           
            <option value="name">Name</option>
          </>
        }*/
      />
    </SingleColumnPage>
  );
}
