export const initialAuthState = {
  isCheckingAuthState: true,
  isAuthenticating: false,
  isAuthenticated: false,
  bearerToken: null,
  refreshToken: null,
  intent: null,
  error: {},
  user: {} // all stored user data comes directly from the JWT.
};

export const AuthReducer = (state, action) => {
  switch (action.type) {
    case "AUTH_WITH_CREDENTIALS_REQUEST":
      return {
        ...state,
        error: null,
        isAuthenticating: true
      };

    case "AUTH_SUCCESS":
      const { user, bearerToken, refreshToken } = action.payload;

      return {
        ...state,
        isCheckingAuthState: false,
        isAuthenticating: false,
        isAuthenticated: true,
        bearerToken,
        refreshToken,
        user
      };

    case "AUTH_ERROR":
      const { code, message } = action.payload;

      return {
        ...state,
        isCheckingAuthState: false,
        isAuthenticating: false,
        isAuthenticated: false,
        error: {
          code,
          message
        }
      };

    case "ADD_INTENT":
      const { url } = action.payload;

      return {
        ...state,
        intent: url
      };

    case "CLEAR_INTENT":
      return {
        ...state,
        intent: null
      };

    case "LOG_OUT":
      return {
        ...state,
        isCheckingAuthState: false,
        isAuthenticated: false,
        user: {},
        intent: null
      };

    case "AUTH_STATE_CHECKED":
      return {
        ...state,
        isCheckingAuthState: false
      };

    default:
      return state;
  }
};
