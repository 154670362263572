import React from 'react';
import ModalImage from "react-modal-image";
import styles from "./Image.module.scss";

export default function Image({ url, filename, token = true,zoom=false,max=true, ...rest }) {
  const bearerToken = window.localStorage.getItem('bearerToken');

  return (
    <div className={max===true ? styles.max : styles.small}>
    {zoom ? (<ModalImage className={max===true ? styles.maxSize: styles.smallSize} hideZoom={true} hideDownload={true}
      small={`${url}${token ? `?ss-tok=${bearerToken}` : ''}`}
      large={`${url}${token ? `?ss-tok=${bearerToken}` : ''}`}
      alt={filename}
      />):(<img
      src={`${url}${token ? `?ss-tok=${bearerToken}` : ''}`}
      alt={filename}
      {...rest}
    />)}
    </div>
  );
}
