import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import LoadingFrame from '../../components/LoadingFrame';
import SingleColumnPage from '../../components/SingleColumnPage';
import GroupContext from '../../contexts/Group';
import UserGroups from '../UserGroups';
import NewUserGroup from '../NewUserGroup';
import UserGroupDetail from '../UserGroupDetail';

export default function Groups({ history, match: { params } }) {
  const [isLoadng, setIsLoading] = useState(false);
  const breadcrumbs = () => (
    <>
      <Breadcrumb title="Groups" to="/groups" />
    </>
  );

  return (
    <SingleColumnPage breadcrumbs={breadcrumbs}>
      {isLoadng ? (
        <LoadingFrame />
      ) : (
        <GroupContext.Provider>
          <Switch>
            <Redirect from="/groups" to="/groups/list" exact />
            <Route path="/groups/list" component={UserGroups} />
            <Route path="/groups/new" component={NewUserGroup} exact />
            <Route path="/groups/:groupId" component={UserGroupDetail} exact />
          </Switch>
        </GroupContext.Provider>
      )}
    </SingleColumnPage>
  );
}
