import React, { useState, useContext } from 'react';
import Button from "../../components/Button";
import { Link } from 'react-router-dom';
import { uploadQR } from '../../api';
import FileUploadProgress from '../../components/FileUploadProgress';
import { AuthContext } from "../../contexts/Auth";
import styles from "./QrCodeScanner.module.scss";
import { useConfirm } from 'material-ui-confirm';
export default function QrCodeScanner({
  onResult,
  setCheckingQR,
  active = false
}) {

  const [attachments, setAttachments] = useState([]);

  async function checking(attachment) {
    const formData = new FormData();
    formData.set('type', 'QR');
    formData.set('active',active);
    formData.append('file', attachment[0]);
    const res = await uploadQR(formData, onResult);

  }
  function _chooseQR(e) {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
    const files = (e.dataTransfer && e.dataTransfer.files) || (e.target && e.target.files);
    const valid = Array.from(files).every(f => validTypes.includes(f.type));

    if (valid) {
      setCheckingQR(true)
      saveFile(files)
    } else {
      e.target.value = '';
    }
  }

  async function saveFile(attachment) {
    if (attachment != undefined) {
      const isArray = Array.isArray(attachment);
      if (isArray) {
        var firstElement = attachment[0];
        if (firstElement !== undefined && firstElement.name !== undefined) {
          checking(attachment)
        } else {
          checking(firstElement)
        }
      } else {
        checking(attachment)
      }
    } else {

    }
  }
  function renderQRScanning() {

    return (<>

   
      <Button icon="qr_code" size={'xl'}>Scan QR</Button>
      <input
        className={styles.input}
        onChange={_chooseQR}
        onClick={e => e.target.value = ''}
        type="file" name="qrImage" accept="image/*"
        id="uploadFile"
      />
      <div className={styles.donotdisplay}><FileUploadProgress
        preview={false}
        files={attachments}
        uploading={checking}
      />
      </div>
    </>);
  }
  return (<div className={styles.wrap}>
    {renderQRScanning()}
  </div>);
}


