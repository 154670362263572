import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Users from '../Users';
import New from '../NewUser';
import User from '../User';
// import styles from './UsersIndex.module.scss';

export default function UsersIndex() {
  return (
    <>
      <Switch>
        <Route path="/users" component={Users} exact />
        <Route path="/users/new" component={New} exact />
        <Route path="/users/:id" component={User} />
      </Switch>
    </>
  );
}
