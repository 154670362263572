import React from 'react';

import Icon from '../Icon';
import styles from './HealthIndicator.module.scss';

export default function HealthIndicator({ icon, value, title }) {
  return (
    <div title={title} className={styles.wrap}>
      <Icon name={icon} />
      <p className={styles.value}>{value}</p>
    </div>
  );
}
