import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';

import { post } from '../../api';

import Button from '../../components/Button';
import Label from '../../components/Label';
import Space from '../../components/Space';
import TextInput from '../../components/TextInput';
import LoginFrame from '../../components/LoginFrame';

import styles from './ResetPassword.module.scss';

export default function ResetPassword({ history, location: { search } }) {
  const queryParams = queryString.parse(search);

  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState('');

  useEffect(() => {
    if (queryParams.tokenExpiry) {
      const decodedExpiration = atob(queryParams.tokenExpiry);
      const expiration = moment
        .utc(decodedExpiration, 'MM/DD/YYYY h:mm:ss a')
        .local();

      if (moment().isAfter(expiration))
        setRedirect(`/expired?token=${queryParams.token}&type=reset`);
    }

    if (!queryParams.token) {
      setRedirect('/');
    }
  }, []);

  function _validatePasswords() {
    const blank = !password1 && !password2;
    const equal = password1 === password2;

    return blank ? true : equal ? false : true;
  }

  async function _resetPassword(e) {
    e.preventDefault();
    setLoading(true);

    const res = await post('/users/resetPassword', {
      token: queryParams.token,
      password: password1
    });

    res && res.ok && setRedirect(`/log-in?reset=true`);
  }

  function _renderForm() {
    return (
      <LoginFrame
        heading="Reset your password"
        subHeading="Create a new password using the form below."
      >
        <form onSubmit={_resetPassword}>
          <Space size="xl">
            <div className={styles.inputWrap}>
              <Space size="s">
                <Label text="Password">
                  <TextInput
                    type="password"
                    value={password1}
                    onChange={e => setPassword1(e.target.value)}
                  />
                </Label>
              </Space>
              <Label text="Confirm password">
                <TextInput
                  type="password"
                  value={password2}
                  onChange={e => setPassword2(e.target.value)}
                />
              </Label>
            </div>
          </Space>

          <Space size="l">
            <Button
              type="submit"
              theme="primary"
              isLoading={loading}
              disabled={_validatePasswords()}
            >
              Reset password
            </Button>
          </Space>
        </form>
      </LoginFrame>
    );
  }

  function _renderRedirect() {
    return <Redirect from="register" to={redirect} nothrow />;
  }

  return redirect ? _renderRedirect() : _renderForm();
}
