import React from 'react';
import styles from './Select.module.scss';

export default function Select({ children, ...selectProps }) {
  return (
    <div className={styles.wrap}>
      <select className={styles.select} {...selectProps}>
        {children}
      </select>
      <div className={styles.carret} />
    </div>
  );
}
