import React from 'react';
import { Link } from 'react-router-dom';
import styles from './RowItemAlarmActionMobile.module.scss';
import LoadingFrame from '../LoadingFrame/LoadingFrame';
export default function RowItemAlarmActionMobile({
  title,
  subtitle,
  to,
  left,
  center,
  right=false,
  loading = false
}) {

  if(loading===true) return <LoadingFrame/>
  return to && (<div className={styles.outerDiv}>
    <div className={styles.leftDiv}>
      <a href={to} className={styles.row}>
        {left && <div className={styles.left}>{left}</div>}
       
        <div className={styles.fullWidth}>
          {title && <div className={styles.title}>{title}</div>}
          {subtitle && <div className={styles.subtitle}>{subtitle}</div>}

        </div>
       {/** <div className={styles.center}>
          {center && <div className={styles.title}>Peak G-force</div>}
          {center && <div className={styles.subtitle}>{center}</div>}
        </div>*/} 
      </a>
    </div>
    {right && <div className={styles.rightDiv}>
      <div className={styles.rightMobile}>{right}</div>
    </div>}

  </div>)

}
