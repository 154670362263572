import React, { useRef, useState, useEffect } from 'react';
import styles from './Popover.module.scss';
import Loading from '../Loading';
import Frame from '../Frame';
import Icon from '../Icon';

export default function Popover({
  children,
  padding_bottom = false,
  carret = true,
  icon,
  assetDashboard,
  text,
  flag,
  padding = 24,
  align = 'left',
  spacing = -4,
  fullwidth = false,
  width = '100%',
  theme = 'default',
  alt = text,
  open = false,
  isLoading = false,
  ...buttonProps
}) {
  const popoverRef = useRef(null);
  const [isOpen, setIsOpen] = useState(open);

  const popoverClasses = [
    styles.popover,
    isOpen ? styles['popover--open'] : ``
  ].join(' ');

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);
    document.addEventListener('touchend', handleClick, false);

    return () => {
      document.removeEventListener('mousedown', handleClick, false);
      document.removeEventListener('touchend', handleClick, false);
    };
  }, []);

  useEffect(() => {
    isOpen && popoverRef.current.focus();
  }, [isOpen]);

  function handleClick(e) {
    if (popoverRef.current.contains(e.target)) return;
    setIsOpen(false);
  }

  const _loadingSpinner = () => (
    <div className={styles.loadingSpinner}>
      <Loading size={24} color={theme === 'primary' ? 'light' : 'dark'} />
    </div>
  );
  const targetClasses = [styles.target, assetDashboard ? styles.assetDashboard : '', styles[theme]].join(' ');

  return (isLoading ? _loadingSpinner() : (<div className={fullwidth == false ? (padding_bottom == false ? styles.wrap : styles.wrappadding) : styles.wrapfull} >
    <button
      className={targetClasses}
      onClick={() => setIsOpen(!isOpen)}
      alt={alt}
      {...buttonProps}
    >
      {icon && (
        <span className={styles.icon}>
          <Icon name={icon} size={20} />
          {flag && <div className={styles.flag} />}
        </span>
      )}
      {text && <div className={styles.text}>{text}</div>}
      {carret && <div className={styles.carret} />}
    </button>

    <div
      ref={popoverRef}
      className={popoverClasses}
      tabIndex="-1"
      style={{
        [align]: spacing,
        width
      }}
    >
      <Frame padding={padding}>{children}</Frame>
    </div>
  </div>)
  );
}
