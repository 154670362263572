export const INACTIVE = 'INACTIVE';
export const OK = 'OK';
export const SIGNALSTRENGTH_LOW = 'SIGNALSTRENGTH_LOW';
export const SIGNALSTRENGTH_CRITICAL = 'SIGNALSTRENGTH_CRITICAL';
export const TEMPERATURE_LOW = 'TEMPERATURE_LOW';
export const TEMPERATURE_HIGH = 'TEMPERATURE_HIGH';
export const BATTERY_LOW = 'BATTERY_LOW';
export const BATTERY_CRITICAL = 'BATTERY_CRITICAL';
export const COMMUNICATION_ERROR = 'COMMUNICATION_ERROR';
export const IMPACT = 'IMPACT';
export const FALSE_ALERT = 'FALSE_ALERT';
export const DEVICE_REPAIRED = 'DEVICE_REPAIRED';
export const MAINTENANCE = 'MAINTENANCE';
export const WORK_IN_PROGRESS = 'WORK_IN_PROGRESS';
export const WORK_COMPLETE = 'WORK_COMPLETE';
export const READY = 'READY';
export const INSPECTION = 'INSPECTION';
export const NOTE = 'NOTE';
export const ASSET = 'ASSET';
export const EVENT = 'EVENT';
export const ORIENTATION_CHANGE = 'ORIENTATION_CHANGE';
export const CONNECTIVITY_ISSUE = 'CONNECTIVITY_ISSUE';
export const INACTIVE_EXPIRED_LICENSE = 'INACTIVE_EXPIRED_LICENSE';
export const LICENSE_EXPIRED = 'LICENSE_EXPIRED';
export const ACTIVE_DEVICE_NO_ASSET = 'ACTIVE_DEVICE_NO_ASSET';

//export const NO_ISSUES = 'NO_ISSUES';
export const MINOR_WITH_NO_REPAIR = 'MINOR_WITH_NO_REPAIR';
export const MINOR_REPAIR = 'MINOR_REPAIR';
export const MAJOR = 'MAJOR';
