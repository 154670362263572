import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useFormState } from "react-use-form-state";
import { post, get, destroy } from "../../api";
import validate from "../../helpers/validate";
import history from "../../helpers/history";
import { states, provinces, israel, australia } from "../../helpers/forms";
import Button from "../../components/Button";
import Label from "../../components/Label";
import Modal from "../../components/Modal";
import InputRow from "../../components/InputRow";
import TextInput from "../../components/TextInput";
import Select from "../../components/Select";
import Space from "../../components/Space";
import LoadingFrame from "../../components/LoadingFrame";
import Frame from "../../components/Frame";
import { AuthContext } from "../../contexts/Auth";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import styles from "./NewPendingCustomer.module.scss";
import { useConfirm } from "material-ui-confirm";
export default function NewPendingCustomer({ match: { params } }) {
  const { state } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [pendingCustomer, setPendingCustomer] = useState({});
  const [saving, setSaving] = useState(false);
  const [formState, { text, email, select }] = useFormState({});
  const valid = validate(formState, {
    required: ["name", "email", "country", "address", "city", "zip"],
  });
  const [address, setAddress] = useState(getAddress());
  const confirm = useConfirm();

  function MapResults(result) {
    setAddress(result.formatted_address);
    result.address_components[0] &&
      formState.setField(
        "address",
        result.address_components[0].long_name +
          " " +
          result.address_components[1].long_name
      );
    result.address_components[2] &&
      formState.setField("city", result.address_components[2].long_name);

    result.address_components[4] &&
      formState.setField("state", result.address_components[4].long_name);
    result.address_components[5] &&
      formState.setField("country", result.address_components[5].long_name);

    if (result.address_components[6]) {
      //check if address_components[6] is a number
      if (isNaN(result.address_components[6].long_name)) {
        //check if address_components[7] is a number
        if (result.address_components[7]) {
          if (!isNaN(result.address_components[7].long_name)) {
            formState.setField("zip", result.address_components[7].long_name);
          } else {
            formState.setField("zip", "no-zip-code");
          }
        } else {
          formState.setField("zip", "no-zip-code");
        }
      } else {
        formState.setField("zip", result.address_components[6].long_name);
      }
    }
  }

  function getAddress() {
    var address = "";

    if (formState.values.address !== undefined) {
      address += formState.values.address + ", ";
    }
    if (formState.values.city !== undefined) {
      address += formState.values.city + ", ";
    }
    if (formState.values.zip !== undefined) {
      address += formState.values.zip + ", ";
    }
    if (formState.values.state !== undefined) {
      address += formState.values.state + ", ";
    }
    if (formState.values.country !== undefined) {
      address += formState.values.country;
    }
    return address;
  }
  function handleChange(address) {
    setAddress(address);
  }

  function handleSelect(address) {
    geocodeByAddress(address).then((results) => MapResults(results[0]));
  }
  useEffect(() => {
    async function getCustomerPending() {
      const res = await get(`/customers/pending/${params.id}`);
      res && setPendingCustomer(res);
      formState.setField("name", res.company);
      formState.setField("email", res.email);
      formState.setField("address", res.shipping_address);
      formState.setField("city", res.shipping_city);
      formState.setField("zip", res.shipping_zip);
      formState.setField("state", res.shipping_state);
      formState.setField("country", res.shipping_country);
      var address = "";
      if (res.shipping_address !== undefined) {
        address += res.shipping_address + ", ";
      }
      if (res.shipping_city !== undefined) {
        address += res.shipping_city + ", ";
      }
      if (res.shipping_zip !== undefined) {
        address += res.shipping_zip + ", ";
      }
      if (res.shipping_state !== undefined) {
        address += res.shipping_state + ", ";
      }
      if (res.shipping_country !== undefined) {
        address += res.shipping_country;
      }
      setAddress(address);
      setLoading(false);
    }

    getCustomerPending();
  }, []);

  async function _create() {
    setSaving(true);
    const res = await post("/customers", {
      ...formState.values,
      tenant_id: state.user.tenant_id,
    });
    res && (await post(`/customers/pending/${params.id}/created`));
    res && history.push(`/customers/${res.id}`);
  }

  function displayOptons() {
    switch (formState.values.country) {
      case "USA":
        return (
          <Label text="State">
            <Select {...select("state")}>{states()}</Select>
          </Label>
        );
      case "CAN":
        return (
          <Label text="Province">
            <Select {...select("state")}>{provinces()}</Select>
          </Label>
        );
      case "AU":
        return (
          <Label text="Province">
            <Select {...select("state")}>{australia()}</Select>
          </Label>
        );
      case "IL":
        return (
          <Label text="Province">
            <Select {...select("state")}>{israel()}</Select>
          </Label>
        );
      default:
        return "";
    }
  }

  function deletePendingConfirmation() {
    confirm({
      description: "Are you sure you want to remove selected pending customer?",
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true },
    })
      .then(() => {
        deletePending();
      })
      .catch(() => {
        /* ... */
      });
  }

  async function deletePending() {
    await destroy(`/customers/pending/${params.id}`);
    history.push(`/admin_portal`);
  }

  function _renderFooter() {
    return (
      <>
        <Link to={"/admin_portal"}>
          <Button disabled={saving}>Cancel</Button>
        </Link>

        <Button
          theme="danger"
          disabled={saving}
          onClick={() => deletePendingConfirmation()}
        >
          Delete
        </Button>

        <Button
          theme="primary"
          onClick={_create}
          isLoading={saving}
          disabled={!valid}
        >
          Create
        </Button>
      </>
    );
  }

  return (
    <Modal footer={_renderFooter()}>
      {loading ? (
        <LoadingFrame />
      ) : (
        <>
          <Space size="m">
            <h2>New pending customer</h2>
          </Space>
          <Frame>
            <form>
              <InputRow>
                <Label text="Name">
                  <TextInput
                    {...text("name")}
                    error={formState.touched.name && formState.errors.name}
                    required
                  />
                </Label>

                <Label text="Email">
                  <TextInput
                    {...email("email")}
                    error={formState.touched.email && formState.errors.email}
                    required
                  />
                </Label>
              </InputRow>

              <InputRow>
                <Space size="s">
                  <div>
                    <span style={{ display: "block", paddingBottom: 14 }}>
                      Address
                    </span>
                  </div>

                  <PlacesAutocomplete
                    value={address}
                    onChange={handleChange}
                    onSelect={handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          {...getInputProps({
                            //placeholder: 'Enter address',
                            className: styles.autocomplete,
                          })}
                        />
                        <div className={styles.autocompleteDiv}>
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? styles.autocompleteSuggestionActive
                              : styles.autocompleteSuggestion;

                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#eeeeee",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };

                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </Space>
              </InputRow>
              {/*<InputRow>
                <Label text="Serial numbers">
                  {pendingCustomer && pendingCustomer.devices_serials && pendingCustomer.devices_serials.replace('[', '').replace(']', '').split(',').map(serial => (<Space>{serial}</Space>))}
                </Label>
                    </InputRow>*/}
            </form>
          </Frame>
        </>
      )}
    </Modal>
  );
}
