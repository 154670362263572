import React from 'react';

import { humanUserRole } from '../../helpers/user';

import Avatar from '../../components/Avatar';
import Badge from '../../components/Badge';
import CollectionListing from '../../components/CollectionListing';
import FrameHeader from '../../components/FrameHeader';
import RowItem from '../../components/RowItem';

// import styles from './TenantAdmins.module.scss';

export default function TenantUsers({ match: { params } }) {
  const userRowItem = u => (
    <RowItem
      key={u.id}
      left={<Avatar name={`${u.first_name} ${u.last_name}`} />}
      right={<Badge text={humanUserRole(u.authority)} />}
      title={`${u.first_name} ${u.last_name}`}
      subtitle={u.customer && u.customer.name}
      to={`/users/${u.id}`}
    />
  );

  return (
    <>
      <FrameHeader title="Users" />

      <CollectionListing
        collection="users"
        queryKey="email"
        params={{
          tenant_id: params.id,
          'authority!': 'TENANT_ADMIN'
        }}
        item={userRowItem}
        sortOptions={
          <>
           
            <option value="first_name">First name</option>
            <option value="last_name">Last name</option>
            <option value="email">Email</option>
          </>
        }
      />
    </>
  );
}
