import { get } from '../api';

export const fetchAlarms = id => async dispatch => {
  dispatch({ type: 'UPDATE_LOADING', payload: { loading: true } });

  const res = await get('/groupalarms', {
    status: 'ACTIVE_UNACK',
    include: 'COUNT(*)'
  });
  if (res != undefined) {
    dispatch({ type: 'UPDATE_ALARMS', payload: { alarms: res } });
  }
  dispatch({ type: 'UPDATE_LOADING', payload: { loading: false } });
};
