import React from "react";

import getStatus from "../../helpers/statuses";
import {
  OK,
  INACTIVE,
  INACTIVE_EXPIRED_LICENSE,
} from "../../constants/statuses";

import Icon from "../Icon";

import styles from "./StatusIndicator.module.scss";
import { LICENSE_UPDATED, RESUMED_CHECK_IN } from "../../constants/events";

export default function StatusIndicator({ status }) {
  const icon = getStatus(status) && getStatus(status).icon;

  const statusEl = () => {
    switch (status) {
      case "OK":
        return <div className={styles[OK]} />;
      case "ACTIVE":
        return <div className={styles[OK]} />;
      case "INACTIVE":
        return <div className={styles[INACTIVE]} />;

      case undefined:
        return <div className={styles[INACTIVE]} />;

      case true:
        return <div className={styles[OK]} />;
      case false:
        return <div className={styles[INACTIVE]} />;
      case "WORKING_CONDITION":
        return <div className={styles["OKREPORT"]} />;
      case "NONE":
        return <div className={styles["OKREPORT"]} />;
      case "DEPLOYED":
        return <div className={styles[OK]} />;
      case "NOT_DEPLOYED":
        return <div className={styles[INACTIVE]} />;
      case "RESUMED_CHECK_IN":
        return (
          <div className={styles[RESUMED_CHECK_IN]}>
            <div className={styles[status]}>
              <i
                className={`material-icons`}
                style={{ fontSize: 30, color: "#4a90e2" }}
                aria-hidden="true"
              >
                info_outline
              </i>
            </div>
          </div>
        );
      case "LICENSE_UPDATED":
        return (
          <div className={styles[LICENSE_UPDATED]}>
            <div className={styles[status]}>
              <i
                className={`material-icons`}
                style={{ fontSize: 30, color: "#4a90e2" }}
                aria-hidden="true"
              >
                info_outline
              </i>
            </div>
          </div>
        );
      case "NEEDS_REPAIRING":
        return (
          <div className={styles[status]}>
            <i
              className={`material-icons`}
              style={{ fontSize: 30, color: "#ee0e0e" }}
              aria-hidden="true"
            >
              error_outline
            </i>
          </div>
        );
      case "NEEDS_REPLACING":
        return (
          <div className={styles[status]}>
            <i
              className={`material-icons`}
              style={{ fontSize: 30, color: "#ee0e0e" }}
              aria-hidden="true"
            >
              error_outline
            </i>
          </div>
        );
      default:
        return (
          icon && (
            <div className={styles[status]}>
              <Icon name={getStatus(status).icon} size="24" />
            </div>
          )
        );
    }
  };

  return <div className={styles.wrap}>{statusEl()}</div>;
}
