import React from 'react';
import moment from 'moment';
import getStatus from '../../helpers/statuses';
import Label from '../Label/Label';
import {COMMUNICATION_ERROR} from '../../constants/statuses'; 
import StatusIndicator from '../StatusIndicator';
import HealthIndicator from '../HealthIndicator';
import {SENTINEL_TMA,SENTINEL_MOBILE_TRACKER } from '../../constants/sentinel_types.js'
import styles from './DeviceStatus.module.scss';
export default function DeviceStatus({
  status,
  signal,
  body,
  device_type,
  deployed,
  battery,
  firmware,
  temp,
  updated
}) {
  return (
    <div className={styles.wrap}>
      <StatusIndicator status={status} />

      <div className={styles.text}>
        <h3 className={styles.status}>{getStatus(status).name}</h3>

        {/*updated && (
          <p
            className={styles.subtitle}
            title={moment.utc(updated).format('dddd, MMMM Do YYYY, h:mm:ss a')}
          >
            Updated {moment.utc(updated).fromNow()}
          </p>
        )*/}
      </div>
      {/*body && <>
      <Label theme="light" text="Peak G-force">
        {body}
      </Label>
      
      </>*/}
      {device_type === SENTINEL_MOBILE_TRACKER || device_type===SENTINEL_TMA && (<>

        {status !== COMMUNICATION_ERROR ? (<><StatusIndicator status={deployed ? ("DEPLOYED") : ("NOT_DEPLOYED")} /></>)
          :
          (<><StatusIndicator status={COMMUNICATION_ERROR} /></>)}


        <div className={styles.text}>
          <h2 className={styles.status}>{deployed ? ("Deployed") : ("Not deployed")}</h2>
        </div>
      </>)}
      <div className={styles.meta}>
        {signal ? (
          <HealthIndicator
            title="Signal strength"
            icon="signal_cellular_alt"
            value={`${signal}%`}
          />
        ) : null}
        {battery ? (
          <HealthIndicator
            title="Battery level"
            icon="battery_full"
            value={`${battery}%`}
          />
        ) : null}
        {temp ? (
          <HealthIndicator
            title="Temperature"
            icon="whatshot"
            value={`${temp}°F`}
          />
        ) : null}
        {firmware ? (
          <HealthIndicator
            title="Firmware version"
            icon="sd_card"
            value={`v${firmware}`}
          />
        ) : null}
      </div>
    </div>
  );
}
