import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment';
import { AuthContext } from '../../contexts/Auth';
import { logOut } from '../../actions/Auth';
import { get, put, post, destroy } from '../../api';
import Breadcrumb from '../../components/Breadcrumb';
import Space from '../../components/Space';
import Button from '../../components/Button';
import FrameHeader from '../../components/FrameHeader';
import LoadingFrame from '../../components/LoadingFrame';
import UserInfoForm from '../../components/UserInfoForm';
import SingleColumnPage from '../../components/SingleColumnPage';
import ReleaseInfo from '../../components/ReleaseInfo';
import Frame from '../../components/Frame';
import styles from './Account.module.scss';

export default function Account() {
  const { state, dispatch } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [user, setUser] = useState({});
  const [groups, setGroups] = useState([]);

  async function fetchUser() {
    const res = await get(`/users/${state.user.user_id}`);
    const { customer, tenant, ...user } = res;
    const available_groups = await get(`/groups`, {
      orderBy: '-created_time',
      customer_id: state.user.customer_id,
    });
    available_groups && available_groups.results && setGroups(available_groups.results);
    user.groups = await get(`/users/${state.user.user_id}/groups`);
    setUser(user);
    setLoading(false);
  }

  useEffect(() => {
    var isApiSubscribed = true;

    fetchUser();
    return () => {
      isApiSubscribed = false;
    };
  }, []);

  async function saveUser(user) {
    setLoading(true);
    setSaving(true);
    const res = await put(`/users/${state.user.user_id}`, user);
    res.groups = await get(`/users/${state.user.user_id}/groups`);
    setUser(res);
    setSaving(false);
    setLoading(false);
  }

  const updateUserGroup = async (userId, groupId) => {
    setLoading(true);
    const [userGroups] = await Promise.all([
      post('/relations', {
        from_id: groupId,
        from_type: 'GROUP',
        to_id: userId,
        to_type: 'USER',
        relation_type_group: 'COMMON'
      })
    ]);
  };

  async function removeUserFromGroup(userId, groupId) {
    setLoading(true);
    const relation = await get('/relations', {
      from_id: groupId,
      from_type: 'GROUP',
      to_id: userId,
      to_type: 'USER'
    });

    if (relation && relation.results && relation.results.length) {
      const toDelete = relation.results[0].id;
      await destroy(`/relations/${toDelete}`);
    }
  }
  const breadcrumbs = () => <Breadcrumb title="Account" />;

  return loading ? (
    <LoadingFrame />
  ) : (
    <SingleColumnPage breadcrumbs={breadcrumbs}>
      <Space size="l">
        <FrameHeader title="Session" />
        <Frame>
          <div className={styles.session}>
            <p>
              You will automatically be logged out on{' '}
              <b>
                {moment
                  .utc(user.token_expiration)
                  .format('MMMM Do, YYYY [at] h:mma')}
              </b>
            </p>
            <Button theme="danger" onClick={() => dispatch(logOut())}>
              Log out
            </Button>
          </div>
        </Frame>
      </Space>

      <FrameHeader title="Account info" />
      <UserInfoForm
        state={user}
        groups={groups}
        saving={saving}
        onSave={user => saveUser(user)}
        onUpdateUserGroup={updateUserGroup}
        onRemoveUserGroup={removeUserFromGroup}
      />

      <ReleaseInfo />
    </SingleColumnPage>
  );
}
