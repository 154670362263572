import React from 'react';
import SingleColumnPage from '../../components/SingleColumnPage';
import Breadcrumb from '../../components/Breadcrumb';
import { Redirect, Route, Switch } from 'react-router';
import AdminPortalPendingCustomers from '../AdminPortalPendingCustomers';
import SubNavigation from '../../components/SubNavigation';
import AdminPortalLogs from '../AdminPortalLogs';
import AdminPortalFunctions from '../AdminPortalFunctions';
import AdminPortalQueue from '../AdminPortalQueue';
export default function AdminPortal({ history }) {
  const breadcrumbs = () => (<Breadcrumb title="Admin portal" />);
  const subNav = [
    { to: `/admin_portal/pending`, name: 'Pending customers' },
    { to: `/admin_portal/logs`, name: 'Logs' },
    { to: `/admin_portal/functions`, name: 'Functions' },
    { to: `/admin_portal/queue`, name: 'Queue' },

  ];
  return (<SingleColumnPage breadcrumbs={breadcrumbs} >
    <SubNavigation items={subNav} history={history} />
    <Switch>
      <Redirect from="/admin_portal" to="/admin_portal/pending" exact />
      <Route path="/admin_portal/pending" component={AdminPortalPendingCustomers} />
      <Route path="/admin_portal/logs" component={AdminPortalLogs} />
      <Route path="/admin_portal/functions" component={AdminPortalFunctions} />
      <Route path="/admin_portal/queue" component={AdminPortalQueue} />
    </Switch>
  </SingleColumnPage>
  );
}
