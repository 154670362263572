import React from 'react';
import styles from './ActionLabel.module.scss';
import Icon from '../Icon';

export default function ActionLabel({ text, children, theme,action, style }) {
  const classes = [styles.label, styles[theme]].join(' ');

  return (
    <label style={{ ...style }}>
      <div className={styles.row}>
      <div className={styles.left}>{text}</div>
      <div className={styles.right}>{action}</div>
        </div>
      <div className={styles.value}>
       
        {children}
      </div>
    </label>
  );
}
