export const ALARM = "ALARM";
export const ALARM_ACKNOWLEDGED = "ALARM_ACKNOWLEDGED";
export const IMPACT = "IMPACT";
/** Device */
export const DEVICE_CREATED = "DEVICE_CREATED";
export const DEVICE_UPDATED = "DEVICE_UPDATED";
export const DEVICE_ASSIGNED = "DEVICE_ASSIGNED";
export const DEVICE_UNASSIGNED = "DEVICE_UNASSIGNED";
export const DEVICE_NOTE = "DEVICE_NOTE";
export const DEVICE_FILE_ADD = "DEVICE_FILE_ADD";
export const DEVICE_FILE_DELETE = "DEVICE_FILE_DELETE";

/** Asset */
export const ASSET_NOTE = "ASSET_NOTE";
export const ASSET_INSPECTION = "ASSET_INSPECTION";
export const ASSET_CREATED = "ASSET_CREATED";
export const ASSET_DELETED = "ASSET_DELETED";
export const ASSET_UPDATED = "ASSET_UPDATED";
export const ASSET_FILE_ADD = "ASSET_FILE_ADD";
export const ASSET_FILE_DELETE = "ASSET_FILE_DELETE";
export const INSPECTION_CREATED = "INSPECTION_CREATED";
export const INSPECTION_FILE_ADD = "INSPECTION_FILE_ADD";
export const ASSET_MAINTENACE_ENABLE = "ASSET_MAINTENACE_ENABLE";
export const ASSET_MAINTENACE_DISABLE = "ASSET_MAINTENACE_DISABLE";
export const FALSE_ALERT = "FALSE_ALERT";
export const DEVICE_REPAIRED = "DEVICE_REPAIRED";

export const WORK_IN_PROGRESS = "WORK_IN_PROGRESS";
export const WORK_COMPLETE = "WORK_COMPLETE";
export const WARNING = "WARNING";
export const RESUMED_CHECK_IN = "RESUMED_CHECK_IN";

/** Licenses */
export const LICENSE_CREATED = "LICENSE_CREATED";
export const LICENSE_UPDATED = "LICENSE_UPDATED";
export const LICENSE_DEACTIVATED = "LICENSE_DEACTIVATED";
export const LICENSE_TRASFERRED = "LICENSE_TRASFERRED";
export const LICENSE_EXPIRED = "LICENSE_EXPIRED";
