import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './TableItem.module.scss';
import moment from 'moment';
import Icon from '../../components/Icon';
import Space from '../../components/Space';
import Label from '../../components/Label';
import LoadingFrame from '../LoadingFrame/LoadingFrame';
import Frame from '../../components/Frame';
import RowItemAlarmActionMobile from '../../components/RowItemAlarmActionMobile';
import { hasPermission } from "../../helpers/authorization";
import { AuthContext } from "../../contexts/Auth";
import InputRow from '../../components/InputRow';
import Button from '../../components/Button';
import Popover from '../../components/Popover';
import { post } from '../../api';
import getStatus from '../../helpers/statuses';
import history from '../../helpers/history';
import {
  IMPACT,
  CONNECTIVITY_ISSUE,
  LICENSE_EXPIRED
} from '../../constants/statuses';
export default function TableItem({ children, header = false, asset = false, right = false, extraDiv, refreshId}) {
  const { state: currentUser } = useContext(AuthContext);
  const [acknowledgingId, setAcknowledgingId] = useState(-1);
  const [loading, setLoading] = useState(false);
  async function actionTaken(e, id, type, assetId) {
    e.stopPropagation();
    e.preventDefault();
    setAcknowledgingId(id);
    const res = await post(`/alarms/${id}/action/${type}`);
    setLoading(true);
    if (type == 3 || type == 4) {
      return history.push(`/assets/${assetId}`);
    } else {
      refreshId && await refreshId(assetId);
      setAcknowledgingId(null);
      setLoading(false);
    }
  }
  var actionButtonDisplayed = false;
  function icon(type, severity) {
    var color = severity === 'CRITICAL' ? '#ee0e0e' : '#ffb217';
    if (type === CONNECTIVITY_ISSUE || type === LICENSE_EXPIRED) {
      color = '#ff7b00';
    }
    return (
      <div style={{ color }}>
        <Icon name={getStatus(type).icon} size={24} />
      </div>
    );
  }
  function icon_small(type) {
    var color = '#ffb217';
    const green = '#03b106';
    const orange = '#ffb217';
    const red = '#ee0e0e';
    const gray = '#777777';
    var selectedName = "done";
    switch (type) {
      case 0:
        selectedName = "done";
        color = green;
        break;
      case 1:
        selectedName = "error"
        color = red;
        break;
      case 2:
        selectedName = "refresh"
        color = orange;
        break;
      case 3:
        selectedName = "build"
        color = gray;
        break;
      case 4:
        selectedName = "done"
        color = green;
        break;
      case 5:
        selectedName = "error"
        color = red;
        break;
      default:

        break;
    }
    return (
      <div style={{ color }}>
        <Icon name={selectedName} size={20} />
      </div>
    );
  }

  function title(type, severity, assetName) {
    const critical = severity === 'CRITICAL';

    switch (type) {
      case 'IMPACT':
        return `Impact detected`;// on ${assetName}`;

      case 'CONNECTIVITY_ISSUE':
        return `Low signal strength detected`;

      case 'BATTERY':
        return `${critical ? `Critically l` : `L`
          }ow battery detected`;// on ${assetName}`;

      case 'COMMUNICATION_ERROR':
        return "Missed check-in";

      case 'TEMPERATURE':
        return `${critical ? `Critically h` : `H`
          }igh temperature detected`;// on ${assetName}`;

      case 'SIGNALSTRENGTH':
        return `${critical ? `Critically l` : `L`
          }ow signal detected on`;// ${assetName}`;
      case 'ORIENTATION_CHANGE':
        return `Orientation change detected`;
      case 'LICENSE_EXPIRED':
        return `Expired subscription`;
      default:
        return `There is an issue with this asset`;
    }
  }

  function time(timestamp) {
    return moment.utc(timestamp).local().format('MMMM Do, YYYY [at] h:mma');
  }

  function getAlarmOptions(a, maintenance = false, assetId) {
    return (
      a.type == IMPACT ? (<Popover
        text={"Action"}
        align="left"
        spacing={-230}
        width={240}
      >
        <Link to={`/assets/${assetId}/inspection?alarm=${a.id}&asset=${assetId}`} title="Click to add new report">
           <Button fixedWidth={190} alt={"New report"} theme='primary' icon={"add"}> New report</Button>
           </Link>
           <Space />
        <p className={styles.text}>Change to</p>
        <div className={styles.wrap}><Button
          icon={icon_small(1)}
          onClick={e => actionTaken(e, a.id, 1, assetId)}
          isLoading={acknowledgingId === a.id}
          disabled={acknowledgingId === a.id}
          size="l"
        >
          False alert
        </Button> </div>

        {(maintenance == false ? (<div className={styles.wrap}> <Button
          icon={icon_small(3)}
          onClick={e => actionTaken(e, a.id, 3, assetId)}
          isLoading={acknowledgingId === a.id}
          disabled={acknowledgingId === a.id}
          size="l"
        >
          Work in progress
        </Button> </div>) : (<div className={styles.wrap}> <Button
          icon={icon_small(4)}
          onClick={e => actionTaken(e, a.id, 4, assetId)}
          isLoading={acknowledgingId === a.id}
          disabled={acknowledgingId === a.id}
          size="l"
        >
          Repair complete
        </Button> </div>))}

      </Popover>) : (null)
    );
  }

  function returnAlarmActions(alarm, index) {
    if (actionButtonDisplayed) return;
    if (alarm.type == IMPACT && !actionButtonDisplayed) {
      actionButtonDisplayed = true;
      return getAlarmOptions(alarm, asset.maintenance, asset.id)
    }
   }

  return loading ? <LoadingFrame/> :

  (
    <>
      <div className={header == true ? styles.inputRow : styles.header}>
        {children}
      </div>
      {extraDiv && extraDiv.alarms && <div className={styles.inputRowExtra}>
        <Frame padding={0}>
          {extraDiv.alarms && extraDiv.alarms.slice(0, 5).map((alarm, index) => (

            alarm && (<InputRow key={index} noPadding={true}><RowItemAlarmActionMobile
              key={asset.id}
              size="l"
              to={asset && asset.id ? `/assets/${asset.id}` : null}
              rightspace={true}
              title={title(alarm.type, alarm.severity, asset.name)}
              subtitle={time(alarm.start_time)}
              center ={alarm.type==IMPACT && alarm.body}
              left={icon(alarm.type, alarm.severity)}
              right={(hasPermission(currentUser.user.perms, "ADMIN") || hasPermission(currentUser.user.perms, "ASSET_U")) && returnAlarmActions(alarm,index)}
            /></InputRow>
            )
          ))
          }
          {extraDiv.alarms && extraDiv.alarms.length>=5 &&(
            <div className={styles.center}><Link to={asset && asset.id ? `/assets/${asset.id}` : null}><Button>Show more</Button></Link></div>
          )}
        </Frame>
      </div>}
    </>
  );
}
