import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { useFetch } from '../../hooks/fetch';
import TenantContext from '../../contexts/Tenant';

import Admins from '../TenantAdmins';
import Customers from '../TenantCustomers';
import Devices from '../TenantDevices';
import Info from '../TenantInfo';
import NewAdmin from '../NewTenantAdmin';
import NewCustomer from '../NewTenantCustomer';
import Settings from '../TenantSettings';
import Users from '../TenantUsers';

import Breadcrumb from '../../components/Breadcrumb';
import LoadingFrame from '../../components/LoadingFrame';
import SingleColumnPage from '../../components/SingleColumnPage';
import SubNavigation from '../../components/SubNavigation';

// import styles from './Tenant.module.scss';

export default function Tenant({ history, match: { params } }) {
  const [data, loading /*, error */] = useFetch(`/tenants/${params.id}`);
  const [tenant, setTenant] = useState(null);

  useEffect(() => {
    setTenant(data);
  }, [data]);

  const breadcrumbs = () => (
    <>
      <Breadcrumb title="Tenants" to="/tenants" />
      <Breadcrumb title={tenant && tenant.name} />
    </>
  );

  const subNav = [
    { to: `/tenants/${params.id}/info`, name: 'Info' },
    { to: `/tenants/${params.id}/customers`, name: 'Customers' },
    { to: `/tenants/${params.id}/devices`, name: 'Devices' },
    { to: `/tenants/${params.id}/admins`, name: 'Admins' },
    { to: `/tenants/${params.id}/users`, name: 'Users' },
    { to: `/tenants/${params.id}/settings`, name: 'Settings' }
  ];

  return (
    <SingleColumnPage breadcrumbs={breadcrumbs}>
      {loading ? (
        <LoadingFrame />
      ) : (
        <>
          <SubNavigation items={subNav} history={history} />

          <TenantContext.Provider value={{ tenant, setTenant }}>
            <Switch>
              <Redirect from="/tenants/:id" to="/tenants/:id/info" exact />

              <Route path="/tenants/:id/info" component={Info} />

              <Route
                path="/tenants/:id/customers"
                component={Customers}
                exact
              />

              <Route path="/tenants/:id/devices" component={Devices} exact />

              <Route
                path="/tenants/:id/customers/new"
                component={NewCustomer}
              />

              <Route path="/tenants/:id/admins" component={Admins} exact />

              <Route
                path="/tenants/:id/admins/new"
                component={NewAdmin}
                exact
              />

              <Route path="/tenants/:id/users" component={Users} />

              <Route path="/tenants/:id/settings" component={Settings} />
            </Switch>
          </TenantContext.Provider>
        </>
      )}
    </SingleColumnPage>
  );
}
