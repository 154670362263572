import React, { useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';

import { post } from '../../api';
import { AuthContext } from '../../contexts/Auth';
import CustomerModel from '../../models/customer';

import Breadcrumb from '../../components/Breadcrumb';
import CustomerInfoForm from '../../components/CustomerInfoForm';
import FrameHeader from '../../components/FrameHeader';
import SingleColumnPage from '../../components/SingleColumnPage';

// import styles from './NewCustomer.module.scss';

export default function NewCustomer({ location }) {
  const { state } = useContext(AuthContext);

  const [saving, setSaving] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const breadcrumbs = () => (
    <>
      <Breadcrumb title="Customers" to="/customers" />
      <Breadcrumb title="New customer" />
    </>
  );

  const createCustomer = async customer => {
    setSaving(true);

    const res = await post('/customers', {
      ...customer,
      tenant_id: state.user.tenant_id
    });

    setRedirect(`/customers/${res.id}`);
  };

  const newCustomer = () => (
    <SingleColumnPage breadcrumbs={breadcrumbs}>
      <FrameHeader title="New customer" />
      <CustomerInfoForm
        state={CustomerModel}
        saving={saving}
        onSave={customer => createCustomer(customer)}
        isNew
      />
    </SingleColumnPage>
  );

  return redirect ? <Redirect to={redirect} /> : newCustomer();
}
