import React from 'react';
import { Link } from 'react-router-dom';
import styles from './RowItemActions.module.scss';

export default function RowItemActions({
  title,
  subtitle,
  to,
  left,
  right,
  wideActionPanel = false,
}) {
  return to && (<div className={styles.outerDiv}>
    <div className={wideActionPanel == false ? styles.leftDiv : styles.leftDivWider}>
      <a href={to} className={styles.row}>
        {left && <div className={styles.left}>{left}</div>}
        <div className={styles.titleContainer}>
          {title && <div className={styles.title}>{title}</div>}
          {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        </div>
      </a>
    </div>
    <div className={wideActionPanel == false ? styles.rightDiv : styles.rightDivWider}>
      {right && <div className={styles.rightMobile}>{right}</div>}
    </div>
  </div>)
}
