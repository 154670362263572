import React, { useContext, useState } from 'react';

import { put } from '../../api';
import TenantContext from '../../contexts/Tenant';

import TenantInfoForm from '../../components/TenantInfoForm';
import FrameHeader from '../../components/FrameHeader';

// import styles from './TenantInfo.module.scss';

export default function TenantInfo() {
  const { tenant, setTenant } = useContext(TenantContext);
  const [saving, setSaving] = useState(false);

  const updateTenant = async tenant => {
    setSaving(true);
    const res = await put(`/tenants/${tenant.id}`, { ...tenant });
    setTenant(res);
    setSaving(false);
  };

  return (
    <>
      <FrameHeader title="Info" />

      <TenantInfoForm
        state={tenant}
        saving={saving}
        onSave={tenant => updateTenant(tenant)}
      />
    </>
  );
}
