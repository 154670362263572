import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import TypesContext from '../../contexts/Types';
import Button from '../Button';
import Frame from '../Frame';
import Icon from '../Icon';
import Label from '../Label';
import Space from '../Space';
import styles from './AssociatedAsset.module.scss';
import { useConfirm } from 'material-ui-confirm';
export default function AssociatedAsset({
  deviceId,
  asset,
  relation,
  onRemove
}) {
  const types = useContext(TypesContext);
  const type = types.assets.find(t => t.id === (asset && asset.assettype_id));
  const confirm = useConfirm();
  function _removeAsset() {
    confirm({ 
      description: 'Are you sure you want to remove the device from this asset?', 
      title: null, 
      dialogProps: { fullScreen: false }, 
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true }
     })
      .then(() => {
        onRemove(relation.id);
      })
      .catch(() => { /* ... */ });
  }

  function _renderAsset() {
    return (
      <>
        <Space size="m">
          <Label text="Name" theme="light">
            <Link to={`/assets/${asset.id}`}>
              <h3>{asset.name}</h3>
            </Link>
          </Label>
        </Space>

        <div className={styles.details}>
          <Label text="Asset type" icon="local_shipping" theme="light">
            {type && type.name}
          </Label>

          <Label text="Asset serial number" icon="more_horiz" theme="light">
            {asset.serial ? asset.serial : 'N/A'}
          </Label>

          <div className={styles.removeAsset}>
            <Button
              size="s"
              theme="danger"
              icon="delete_forever"
              onClick={_removeAsset}
            >
              Remove asset
            </Button>
          </div>
        </div>
      </>
    );
  }

  function _renderEmpty() {
    return (
      <div className={styles.emptyWrap}>
        <div className={styles.icon}>
          <Icon name="sync_disabled" size={36} />
        </div>

        <div className={styles.emptyContent}>
          <div className={styles.emptyInstructions}>
            <h3>There is no asset associated with this device.</h3>
            <p>To enable impact monitoring, you must complete the following:</p>
            <ul>
              <li>Install the device on the asset.</li>
              <li>Upload a photo of the device installed on the asset.</li>
            </ul>
          </div>

          <Link to={`/devices/${deviceId}/install`}>
            <Button theme="primary">Install device on asset</Button>
          </Link>
        </div>
      </div>
    );
  }

  return (
    <Frame>{asset.id && relation.id ? _renderAsset() : _renderEmpty()}</Frame>
  );
}
