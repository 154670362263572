import React from 'react';
import styles from './FileUploadProgress.module.scss';
import Button from '../Button';
import Image from "../Image";
export default function FileUploadProgress({ files, uploading, onRemove, preview = false,smallPreview=false }) {
  const hasFiles = !!files.length;
  function getUrl(url) {
    return URL.createObjectURL(url);
  }
  return (
    hasFiles && (
      <div className={styles.wrap}>
        {files.map((file, i) => (
          <li key={i} className={styles.file}>
            {preview && <img className={styles.img} src={getUrl(file)} />}

            {smallPreview && <div className={styles.smallPreview}> <Image url={getUrl(file)} token={false} zoom={true} /></div>}
           
            {file.name}
            <Button
              size="s"
              theme="danger"
              disabled={uploading}
              onClick={() => onRemove(i)}
            >
              Remove
            </Button>
          </li>
        ))}
      </div>
    )
  );
}
