import React, { useState, useContext, useEffect } from 'react';
import { get } from '../../api';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/Auth';
import Button from '../../components/Button';
import FrameHeader from '../../components/FrameHeader';
import RowItem from '../../components/RowItem';
import Frame from '../../components/Frame';
import LoadingFrame from '../../components/LoadingFrame';
import QueryBar from '../../components/QueryBar';
import TextInput from '../../components/TextInput';
import PageWrap from '../../components/PageWrap';
import { isAuthorized, hasPermission } from "../../helpers/authorization";

export default function UserGroups({ match: { params } }) {
  const { state } = useContext(AuthContext);
  const [groups, setGroups] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchGroups = async () => {
    const data = await get(`/users/${state.user.user_id}/groups`);
    data && setGroups(data);
    data && setAllGroups(data);
    setIsLoading(false);
  }

  const fetchCustomerAdminGroups = async () => {
    const data = await get(`/groups`, {
      customer_id: state.user.customer_id,
      orderBy: '-created_time',

    });
    data && data.results && setGroups(data.results);
    data && data.results && setAllGroups(data.results);
    setIsLoading(false);
  }

  const filterGroups = (groupName) => {
    setGroups(allGroups.filter(o =>
      Object.keys(o).some(k => o.name.toLowerCase().includes(groupName.toLowerCase()))));
  }
  useEffect(() => {

    hasPermission(state.user.perms, 'ADMIN') ? fetchCustomerAdminGroups() : fetchGroups();

  }, []);

  function _renderContent() {
    if (isLoading && !groups) {
      return <LoadingFrame />;
    } else {
      return (
        <>
          {groups.length
            ? groups.map(g => (
              <RowItem
                key={g.id}
                title={g.name}
                to={`/groups/${g.id}`}
              />
            ))
            : null}
        </>
      );
    }
  }

  return (isLoading ? <LoadingFrame /> :
    <>
      <PageWrap>
        <FrameHeader title={"Groups"}>
          {hasPermission(state.user.perms, 'ADMIN') && <Link to={`/groups/new`}>
            <Button theme="primary" icon="add" fixedWidth={126}>
              New group
            </Button>
          </Link>}
        </FrameHeader>
        <Frame padding="0">
          <>
            <QueryBar button={false}>
              <TextInput
                placeholder={`Search by name`}
                icon="search"
                loading={isLoading && groups}
                onChange={e => {
                  filterGroups(e.target.value);
                }}
              />
            </QueryBar>
            {_renderContent()}
          </>
        </Frame>
      </PageWrap>
    </>);
}
