import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";
import {
  startOfDay,
  endOfDay,
  addDays,
  subDays,
  addMonths,
  subMonths,
} from "date-fns";
import moment from "moment";
import React, { useState, useContext, useEffect } from "react";
import { post, get } from "../../api";
import { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import Breadcrumb from "../../components/Breadcrumb";
import Breadcrumbs from "../../components/Breadcrumbs";
import Button from "../../components/Button";
import Frame from "../../components/Frame";
import Label from "../../components/Label";
import DeviceStatus from "../../components/DeviceStatus";
import LoadingFrame from "../../components/LoadingFrame";
import InputRow from "../../components/InputRow";
import FrameHeader from "../../components/FrameHeader";
import CheckboxWithLabel from "../../components/CheckboxWithLabel";
import AssetCard from "../../components/AssetCard";
import PageHeader from "../../components/PageHeader";
import TypesContext from "../../contexts/Types";
import Select from "../../components/Select";
import {
  ASSET,
  BATTERY_CRITICAL,
  BATTERY_LOW,
  COMMUNICATION_ERROR,
  DEVICE_REPAIRED,
  EVENT,
  IMPACT,
  INSPECTION,
  OK,
  SIGNALSTRENGTH_CRITICAL,
  SIGNALSTRENGTH_LOW,
  TEMPERATURE_HIGH,
  TEMPERATURE_LOW,
} from "../../constants/statuses";
import {
  ALARM,
  FALSE_ALERT,
  WORK_IN_PROGRESS,
  WORK_COMPLETE,
} from "../../constants/events";
import styles from "./Reports.module.scss";
import { AuthContext } from "../../contexts/Auth";
import { Link, Route } from "react-router-dom";
import Space from "../../components/Space";
import Popover from "../../components/Popover";
import Image from "../../components/Image";
import Lightbox from "../../components/Lightbox";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import sanitizeHtml from "sanitize-html";
import { useMediaQuery } from "react-responsive";
import Icon from "../../components/Icon";
import { assetInspectionStates } from "../../helpers/assetTypes";
import "./Reports.scss";
import "./rsuite.css";
import PageWrap from "../../components/PageWrap";
import Overview from "../../components/Overview";
import { DateRangePicker } from "rsuite";
import ReportInspectionEdit from "../ReportInspectionEdit";
import { isAuthorized } from "../../helpers/authorization";
import { TENANT_ADMIN, CUSTOMER_USER } from "../../constants/roles";
import CollectionListing from "../../components/CollectionListing";
import CollectionListingManual from "../../components/CollectionListingManual";
import ReactPaginate from "react-paginate";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  sentinelLogo,
  robotoBold,
  robotoMedium,
  robotoRegular,
} from "./exportConstants";
import {
  MINOR_REPAIR,
  MINOR_WITH_NO_REPAIR,
  MAJOR,
} from "../../constants/statuses";
import { IndeterminateCheckBoxOutlined } from "@material-ui/icons";

var tableGroups = [];
var selectedGroups = [];
var tableGroupsAdmin = [];
var selectedGroupsAdmin = [];
const days = 1;
var exportAllResults = false;

export default function Reports() {
  const ipadPortrait = useMediaQuery({
    query:
      "(min-width: 768px) and (max-width: 768px) and (orientation : portrait), (max-width:720px) and (orientation : landscape), (max-width:600px)",
  });
  const ipadHeight = useMediaQuery({ query: "(max-width: 1024px)" });
  const desktop = useMediaQuery({ query: "(min-width: 1500px)" });
  const mobile = useMediaQuery({ query: "(max-width: 600px)" });
  const smallMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const [admin, setAdmin] = useState(false);
  const [customersList, setCustomersList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [defaultDate, setDefaultDate] = useState({});
  const { state: currentUser } = useContext(AuthContext);
  const types = useContext(TypesContext);
  const [startingDate, setStartingDate] = useState();
  const [oldestDate, setOldestDate] = useState();
  const [endingDate, setEndingDate] = useState(
    moment().format("MM/DD/YYYY h:mma").toString()
  );
  const [reportResult, setReportResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initLoad, setInitLoad] = useState(true);
  const [lightbox, setLightbox] = useState(false);
  const [previewFiles, setPreviewFiles] = useState([]);
  const [groups, setGroups] = useState([]);
  const [summary, setSummary] = useState([]);
  const [filters, setFilters] = useState(null);
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const [selectedAssetTypes, setSelectedAssetTypes] = useState({
    ALARM: true,
    WORK_IN_PROGRESS: true,
    OK: true,
    WARNING: true,
    CRITICAL: true,
    starting_iterval: 0,
  });
  const [selectedEventTypes, setSelectedEventTypes] = useState({
    ALARM: true,
    WORK_IN_PROGRESS: true,
    WORK_COMPLETE: true,
    WARNING: true,
    CRITICAL: true,
  });

  useEffect(() => {
    async function getGroupsAndOldestEvent() {
      const [groups, oldest] = await Promise.all([
        get(`/users/${currentUser.user.user_id}/groups`),
        get("/reports/oldest"),
      ]);

      if (isAuthorized(currentUser.user.roles, [CUSTOMER_USER])) {
        setSelectedCustomer(currentUser.user.customer_id);
      }
      groups && setGroups(groups);
      groups &&
        groups.map((type) => {
          var id = type.id;
          tableGroups.push(id);
          selectedGroups.push(id);
        });
      setFilters(selectedGroups);
      oldest &&
        setStartingDate(
          moment(oldest.dateStart).format("MM/DD/YYYY").toString()
        );
      oldest && setOldestDate(oldest);
      oldest && setDefaultDate(oldest);
      oldest && oldest.summary && setSummary(oldest.summary);
      setInitLoad(false);
    }

    async function getAdmin() {
      if (isAuthorized(currentUser.user.roles, [TENANT_ADMIN])) {
        /* const [customers] = await Promise.all([
           get('/customers')]);
         customers && customers.results && setCustomersList(customers.results);*/
        setAdmin(true);
      }
    }
    getAdmin();

    getGroupsAndOldestEvent();
  }, []);
  function parseDate(str, format, locale) {
    const parsed = dateFnsParse(str, format, new Date(), { locale });
    if (DateUtils.isDate(parsed)) {
      return parsed;
    }
    return undefined;
  }
  function startDateChange(desireddates) {
    if (desireddates !== undefined) {
      if (desireddates[0] !== undefined) {
        setStartingDate(desireddates[0].toLocaleDateString());
      }
      if (desireddates[1] !== undefined) {
        setEndingDate(desireddates[1].toLocaleDateString());
      }
    }
  }
  function endingDateChange(desireddate) {
    setEndingDate(desireddate.toLocaleDateString());
  }
  function formatDate(date, format, locale) {
    return dateFnsFormat(date, format, { locale });
  }
  function clearSelectionDate() {
    defaultDate &&
      setStartingDate(
        moment(defaultDate.dateStart).format("MM/DD/YYYY").toString()
      );
    defaultDate && setOldestDate(defaultDate);
  }
  const FORMAT = "MM-DD-YYYY";

  if (mobile) {
    var dateFormat = "MMM Do YYYY [at] h:mma";
  } else {
    var dateFormat = "MMMM Do YYYY [at] h:mma";
  }

  const modifiersStyles = {
    birthday: {
      color: "white",
      backgroundColor: "#ffc107",
    },
    thursdays: {
      color: "#ffc107",
      backgroundColor: "#fffdee",
    },
    outside: {
      backgroundColor: "white",
    },
  };
  let newPicker = (
    <DateRangePicker
      onChange={startDateChange}
      onClean={clearSelectionDate}
      format={FORMAT}
      placeholder="Time range"
      showOneCalendar={mobile}
      ranges={[
        {
          label: "Last 24h",
          value: [startOfDay(addDays(new Date(), -1)), endOfDay(new Date())],
        },
        {
          label: "Last 48h",
          value: [startOfDay(addDays(new Date(), -2)), endOfDay(new Date())],
        },
        {
          label: "Last 72h",
          value: [startOfDay(addDays(new Date(), -3)), endOfDay(new Date())],
        },
        {
          label: "Last month",
          value: [startOfDay(addMonths(new Date(), -1)), endOfDay(new Date())],
        },
        {
          label: "Last 3 months",
          value: [startOfDay(addMonths(new Date(), -3)), endOfDay(new Date())],
        },
      ]}
    />
  );

  function reportType(type, alarm) {
    switch (type) {
      case ALARM && WORK_IN_PROGRESS:
        getEvents(type, alarm);
        break;
      case ALARM:
        getEvents(type, alarm);
        break;
      case WORK_COMPLETE:
        getEvents(type, alarm);
        break;
      case FALSE_ALERT:
        getEvents(type, alarm);
        break;
      case ASSET:
        getAssets(alarm);
        break;
      case EVENT:
        getEvents(type, alarm);
        break;
      case INSPECTION:
        getInspections(alarm);
        break;
      default:
        getEvents(type, alarm);
        break;
    }
  }

  async function getAssets(status) {
    setLoading(true);
    const report = await post(`/reports`, {
      customer_id: currentUser.user.customer_id,
      count: 101,
      order: true,
      type: ASSET,
      status: status,
      group_id: filters,
    });
    report && setReportResult(report);
    setLoading(false);
  }

  async function getEvents(type, alarm) {
    setLoading(true);
    const report = await post(`/reports`, {
      customer_id:
        admin === false ? currentUser.user.customer_id : selectedCustomer.id,
      admin: admin,
      datestart: startingDate,
      datestop: endingDate,
      group_id: 0,
      count: 101,
      order: true,
      type: EVENT,
      state: type,
      alarm: alarm,
      group_id: filters,
    });
    report && setReportResult(report);
    setLoading(false);
  }

  async function getInspections(state) {
    setLoading(true);
    const report = await post(`/reports`, {
      customer_id: currentUser.user.customer_id,
      datestart: startingDate,
      datestop: endingDate,
      type: INSPECTION,
      state: state,
      group_id: filters,
    });
    report && setReportResult(report);
    setLoading(false);
  }

  function predefDate(days) {
    if (days !== null) {
      setSelectedAssetTypes({ ...selectedAssetTypes, starting_iterval: days });
      var finalEndDate = moment().subtract(days, "d").format("MM/DD/YYYY");
      setStartingDate(finalEndDate);
    } else {
      setSelectedAssetTypes({ ...selectedAssetTypes, starting_iterval: 0 });
      var finalEndDate = moment(oldestDate.dateStart).format("MM/DD/YYYY");
      setStartingDate(finalEndDate);
    }
  }

  async function getFiltered() {
    var included = [];
    selectedAssetTypes.ALARM && included.push(IMPACT);
    selectedAssetTypes.WORK_IN_PROGRESS && included.push(WORK_IN_PROGRESS);
    selectedAssetTypes.OK && included.push(OK);
    if (selectedAssetTypes.WARNING == true) {
      included.push(SIGNALSTRENGTH_LOW);
      included.push(BATTERY_LOW);
      included.push(TEMPERATURE_LOW);
    }
    if (selectedAssetTypes.CRITICAL == true) {
      included.push(SIGNALSTRENGTH_CRITICAL);
      included.push(BATTERY_CRITICAL);
      included.push(TEMPERATURE_HIGH);
      included.push(COMMUNICATION_ERROR);
    }

    setLoading(true);
    const report = await post(`/reports`, {
      customer_id: currentUser.user.customer_id,
      count: 101,
      order: true,
      type: ASSET,
      status: included.toString(),
      group_id: selectedGroups,
    });
    report && setReportResult(report);
    setLoading(false);
  }

  function getFilteredEvents() {
    var filter = [];
    selectedEventTypes.ALARM === true && filter.push(ALARM);
    selectedEventTypes.WORK_IN_PROGRESS === true &&
      filter.push(WORK_IN_PROGRESS);
    selectedEventTypes.WORK_COMPLETE === true &&
      filter.push(WORK_COMPLETE) &&
      filter.push(DEVICE_REPAIRED);
    reportType(filter, IMPACT);
  }

  async function modifyTypes(e, value) {
    setLoading(true);
    if (value === true) {
      var array = filters;
      var index = array.indexOf(e);
      if (index < 0) {
        array.push(e);
      }
      var temp = [];
      array.map((id) => {
        temp.push(id);
      });
      setFilters(temp);
    } else {
      var array = filters;
      var index = array.indexOf(e);
      delete array[index];
      var temp = [];
      array.map((id) => {
        temp.push(id);
      });
      setFilters(temp);
    }
    setLoading(false);
  }

  function displayPhotosPreview() {
    return (
      <Lightbox
        deleteButton={false}
        attachments={previewFiles}
        initialSlide={0}
        onClose={() => setLightbox(false)}
      />
    );
  }

  function generateLightbox(inspection) {
    setPreviewFiles(inspection.files);
    setLightbox(true);
  }

  function decodeHTML(content) {
    return (
      <div
        className={styles.noteBody}
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(content, {
            allowedTags: [
              "p",
              "blockquote",
              "div",
              "li",
              "ul",
              "b",
              "br",
              "em",
              "i",
              "strong",
              "sub",
              "sup",
              "caption",
            ],
          }),
        }}
      ></div>
    );
  }

  function calculateRepairTime(event) {
    if (
      event.inspection.repair_complete_time !== undefined &&
      event.created_time !== undefined
    ) {
      var hoursDifference = moment(event.inspection.repair_complete_time).diff(
        event.created_time,
        "hours"
      );
      var daysDifference = moment(event.inspection.repair_complete_time).diff(
        event.created_time,
        "days"
      );
      if (hoursDifference < 24) {
        return "Less than a day";
      } else {
        if (daysDifference === 1) {
          return daysDifference + " day";
        }
        return daysDifference + " days";
      }
    }
  }

  function displaySeverity(severity) {
    if (severity !== "" && severity !== undefined) {
      switch (severity) {
        case MINOR_WITH_NO_REPAIR:
          return "1-Minor with no repair";
        case MINOR_REPAIR:
          return "2-Minor repair";
        case MAJOR:
          return "3-Major, Severe, Replacement";
      }
    } else {
      return "0-No issues";
    }
  }

  function displayInsp(event) {
    var inspection = event.inspection;
    return (
      inspection && (
        <div className={styles.inspection}>
          <div className={styles.inspectionItem}>
            <Label theme="light" text="User name"></Label>
            {inspection.user &&
              inspection.user.first_name + " " + inspection.user.last_name}
          </div>
          <div className={styles.inspectionItem}>
            <Label theme="light" text="Asset status"></Label>
            {assetInspectionStates[inspection.damage]}
          </div>
          <div className={styles.inspectionItem}>
            <Label theme="light" text="Severity"></Label>
            {displaySeverity(inspection.severity)}
          </div>

          <div className={styles.inspectionItem}>
            <Label theme="light" text="Work order"></Label>
            {inspection.work_order ? (
              inspection.work_order
            ) : (
              <div className={styles.red}>Missing</div>
            )}
          </div>
          <div className={styles.inspectionItem}>
            <Label theme="light" text="Service request"></Label>
            {inspection.service_request ? (
              inspection.service_request
            ) : (
              <div className={styles.red}>Missing</div>
            )}
          </div>
          <div className={styles.inspectionItem}>
            <Label theme="light" text="Repair cost"></Label>
            {inspection.cost ? (
              "$" + inspection.cost
            ) : (
              <div className={styles.red}>Missing</div>
            )}
          </div>
          <div className={styles.inspectionItem}>
            <Label theme="light" text="Repair completion date"></Label>
            {inspection.repair_complete_time ? (
              moment(inspection.repair_complete_time).format("MMMM Do YYYY")
            ) : (
              <div className={styles.red}>Missing</div>
            )}
          </div>
          {inspection.repair_complete_time ? (
            <div className={styles.inspectionItem}>
              <Label theme="light" text="Repair time"></Label>
              {calculateRepairTime(event)}
            </div>
          ) : (
            <div style={{ display: "none" }}></div>
          )}
          <div className={styles.inspectionItemButton}>
            {inspection.files && inspection.files.length > 0 ? (
              <Button
                icon={"photo"}
                onClick={() => generateLightbox(inspection)}
              >
                Photos
              </Button>
            ) : (
              "No photos"
            )}
          </div>
          <div className={styles.inspectionItemNotes}>
            <Label theme="light" text="Notes"></Label>
            {inspection.body ? (
              decodeHTML(inspection.body)
            ) : (
              <div className={styles.red}>Missing</div>
            )}
          </div>
          <div className={styles.inspectionEdit}>
            <Link to={`/reports/inspection/${event.inspection.id}/edit`}>
              {" "}
              <Button size="s" theme="primary" icon="edit" alt="Edit">
                Edit
              </Button>
            </Link>
          </div>
        </div>
      )
    );
  }

  function getAssetType(assettype_id) {
    if (assettype_id !== null) {
      const selected_type = types.assets.find((t) => t.id === assettype_id);
      if (selected_type != null) {
        return selected_type.name;
      }
    }
  }

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
    //window.scrollTo(0, 0);
  }

  function _renderContent() {
    var offset = currentPage * resultsPerPage;
    const selectResultsPerPage = [10, 25, 50, 75, 100];

    if (reportResult != null) {
      var pageCount = Math.ceil(reportResult.events.length / resultsPerPage);
      switch (reportResult.type) {
        case EVENT:
          var currentPageData = reportResult.events
            .slice(offset, offset + resultsPerPage)
            .map((event, index) => (
              <div key={index} className={styles.event}>
                <Frame>
                  <div className={styles.eventHeader}>
                    <Icon name="error_outline" size="24" color="#ee0e0e" />
                    <FrameHeader
                      title={
                        "Impact - " +
                        moment(event.created_time).local().format(dateFormat)
                      }
                    />
                  </div>
                  <div className={styles.eventWrapper}>
                    <div className={styles.photoOuterWrapper}>
                      <div className={styles.photoWrapper}>
                        <div className={styles.imageCenter}>
                          {event.asset.files ? (
                            event.asset.files[0] && (
                              <Image
                                url={event.asset.files[0].url}
                                max={false}
                                zoom={true}
                              />
                            )
                          ) : (
                            <Image
                              url="/img/photo_placeholder.jpg"
                              max={false}
                              zoom={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <Label theme="light" text="Asset name">
                      {
                        <Link to={`/assets/${event.asset_id}`}>
                          {event.asset && event.asset.name}
                        </Link>
                      }
                    </Label>
                    <Label theme="light" text="Asset type">
                      {event.asset && getAssetType(event.asset.assettype_id)}
                    </Label>
                    {/* <Label theme="light" text="Group name">{event.asset && event.asset.group.name}</Label>*/}
                  </div>
                  {event.inspection ? (
                    <div className={styles.accordionWrapper}>
                      <Accordion>
                        <AccordionSummary>
                          <div
                            className={[
                              styles.accordionHeader,
                              "accordionHeader",
                            ].join(" ")}
                          >
                            <div className={styles.whiteText}>
                              <Icon
                                name="double_arrow"
                                size="24"
                                color="#ee0e0e"
                              />{" "}
                              Inspection{!mobile && " report"} -{" "}
                              {moment(event.inspection.created_time)
                                .local()
                                .format(dateFormat)}
                            </div>
                            <div
                              className={[styles.infoText, "infoText"].join(
                                " "
                              )}
                            >
                              <span>Click to </span>{" "}
                              <Icon
                                name="double_arrow"
                                size="24"
                                color="#ee0e0e"
                              />
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          {displayInsp(event)}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  ) : (
                    <div className={styles.toRight}>
                      <Link
                        to={`/assets/${event.asset.id}/inspection?event=${event.id}&asset=${event.asset.id}`}
                      >
                        {" "}
                        <Button theme="primary" icon="add">
                          New report
                        </Button>
                      </Link>
                    </div>
                  )}
                </Frame>
              </div>
            ));
          return (
            reportResult.events && (
              <>
                <div className={styles.resultsHeader}>
                  <span>
                    <span>
                      {reportResult.events.length != 0
                        ? (smallMobile ? "R" : "Number of r") +
                          "esults: " +
                          reportResult.events.length
                        : "No results"}
                    </span>
                    {reportResult.events.length != 0 ? (
                      <>
                        {smallMobile && <span className={styles.line}></span>}
                        <span className={styles.resultsPerPageContainer}>
                          <span>
                            {!smallMobile && (
                              <span className={styles.line}></span>
                            )}
                            {(smallMobile ? "P" : "Results p") + "er page: "}
                          </span>
                          <Select
                            value={resultsPerPage}
                            onChange={(e) => (
                              setResultsPerPage(parseInt(e.target.value)),
                              setCurrentPage(0),
                              getFilteredEvents()
                            )}
                          >
                            {selectResultsPerPage.map((pp) => (
                              <option key={pp} value={pp}>
                                {pp}
                              </option>
                            ))}
                          </Select>
                        </span>
                      </>
                    ) : null}
                  </span>
                  {reportResult.events.length != 0 ? (
                    <Popover
                      text="Export"
                      align="left"
                      width="unset"
                      // icon="file_download"
                    >
                      {" "}
                      <span className={styles.exportPopoverButtonsContainer}>
                        <Button
                          theme="secondary"
                          icon="file_download"
                          onClick={() => {
                            buildPDF((exportAllResults = false));
                          }}
                          disabled={loading}
                        >
                          Current page &nbsp;
                        </Button>
                        <br />
                        <br />
                        <Button
                          theme="secondary"
                          icon="file_download"
                          onClick={() => {
                            buildPDF((exportAllResults = true));
                          }}
                          disabled={loading}
                        >
                          All
                        </Button>
                      </span>
                    </Popover>
                  ) : null}
                </div>

                {currentPageData}

                <ReactPaginate
                  marginPagesDisplayed={1}
                  pageCount={pageCount}
                  pageRangeDisplayed={smallMobile ? 2 : 3}
                  onPageChange={handlePageClick}
                  previousLinkClassName={styles.paginationButonPrev}
                  nextLinkClassName={styles.paginationButonNext}
                  disabledClassName={styles.paginationButonDisabled}
                  activeClassName={styles.paginationButonActive}
                  previousLabel={"Prev"}
                  nextLabel={"Next"}
                  containerClassName={
                    reportResult.events && reportResult.events.length > 0
                      ? styles.paginationContainer
                      : styles.paginationContainerHidden
                  }
                />
              </>
            )
          );

        case ASSET:
          return (
            reportResult.assets && (
              <>
                <FrameHeader title={"Impacts: " + reportResult.assets.length} />
                {reportResult.assets.map((asset, index) => (
                  <div key={index} className={styles.event}>
                    <Frame>
                      <InputRow>
                        <Space>
                          <AssetCard asset={asset} />{" "}
                        </Space>
                        <Space>
                          {asset.devices && asset.devices[0] && (
                            <DeviceStatus status={asset.devices[0].status} />
                          )}
                        </Space>
                        <Space>
                          <div className={styles.photoWrapper}>
                            {asset.files && asset.files[0] && (
                              <Image
                                url={asset.files[0].url}
                                max={false}
                                zoom={true}
                              />
                            )}
                          </div>
                        </Space>
                      </InputRow>
                    </Frame>
                  </div>
                ))}
              </>
            )
          );
        default:
          return "Please define your search";
      }
    }
  }

  function generateEmptyRow() {
    var EmptyRows = [];
    if (summary.top_groups !== undefined) {
      var topGroupsLength = summary.top_groups.length;
      var desiredTableLength = 5;
      for (null; topGroupsLength < desiredTableLength; topGroupsLength++) {
        EmptyRows.push(
          <tr key={topGroupsLength} className={styles.emptyRow}>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
        );
      }
    }
    return EmptyRows;
  }

  function resetGroupSelection(reset) {
    setLoading(true);
    if (reset) {
      var temp = [];
      groups &&
        groups.map((type) => {
          var id = type.id;
          temp.push(id);
        });
      setFilters(temp);
    } else {
      var temp = [];
      setFilters(temp);
    }
    setLoading(false);
  }

  function generateGroups() {
    return (
      <Popover
        icon="filter_list"
        text="Groups"
        flag={filters.length !== groups.length}
        align="left"
        width="auto"
      >
        <div className={styles.spacer}>
          {" "}
          <Button
            size="s"
            disabled={loading}
            theme="secondary"
            onClick={(e) => resetGroupSelection(true)}
          >
            Select all
          </Button>
          &nbsp;
          <Button
            size="s"
            disabled={loading}
            theme="secondary"
            onClick={(e) => resetGroupSelection(false)}
          >
            Select none
          </Button>
        </div>

        {groups.map((group, index) => (
          <span key={index}>
            <CheckboxWithLabel
              label={group.name}
              checked={filters.indexOf(group.id) > -1 ? true : false}
              disabled={loading}
              onChange={(e) => modifyTypes(group.id, e.target.checked)}
            />
          </span>
        ))}
      </Popover>
    );
  }

  function generateFilterOptions() {
    return (
      <PageWrap noBottomPadding={true}>
        <Overview>
          <div className={styles.controls}>
            <div className={styles.selectTimeRangeWrapper}>{newPicker}</div>
            {/*<div className={styles.selectEventsWrapper}>
            {groups && generateGroups()}
          </div>*/}

            <div className={styles.buttons}>
              <Button
                theme="primary"
                onClick={() => {
                  getFilteredEvents();
                }}
                disabled={loading || selectedCustomer == undefined}
              >
                Show{!mobile && " results"}{" "}
                {admin && selectedCustomer && "for " + selectedCustomer.name}
              </Button>
            </div>
          </div>
        </Overview>
      </PageWrap>
    );
  }

  function generateSummary() {
    return (
      <PageWrap noBottomPadding={true}>
        <FrameHeader title="Summary: Top 5" />
        <Overview>
          <div className={styles.summaryWrapper}>
            <div className={[styles.summary, styles.assetsSummary].join(" ")}>
              <div className={styles.tableOuterWrapper}>
                <div className={styles.tableInnerWrapper}>
                  <table>
                    <tbody>
                      <tr>
                        <th colSpan="2">
                          <div>
                            <span>Asset name</span>
                            <span>Impact count</span>
                          </div>
                        </th>
                      </tr>
                      {summary &&
                        summary.top_assets &&
                        summary.top_assets.map((top, index) => (
                          <tr key={index}>
                            <td>
                              {
                                <Link to={`/assets/${top.asset.id}`}>
                                  {top.asset.name}
                                </Link>
                              }
                            </td>
                            <td>{top.asset_stats.impact_count}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className={[styles.summary, styles.typeSummary].join(" ")}>
              <div className={styles.tableOuterWrapper}>
                <div className={styles.tableInnerWrapper}>
                  <table>
                    <tbody>
                      <tr>
                        <th colSpan="2">
                          <div>
                            <span>Asset type</span>
                            <span>Impact count</span>
                          </div>
                        </th>
                      </tr>
                      {summary &&
                        summary.top_asset_types &&
                        summary.top_asset_types.map((top, index) => (
                          <tr key={index}>
                            <td>{top.asset_type && top.asset_type.name}</td>
                            <td>{top.impact_count}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* <div className={([styles.summary, styles.groupsSummary].join(' '))}>
            <div className={styles.tableOuterWrapper}>
              <div className={styles.tableInnerWrapper}>
                <table><tbody>
                  <tr>
                    <th colSpan="2">
                      <div>
                        <span>Group name &nbsp; </span>
                        <span>Impact count</span>
                      </div>
                    </th>
                  </tr>
                  {summary && summary.top_groups && summary.top_groups.map((top, index) =>
                    <tr key={index}>
                      <td>
                        {top.group.name}
                      </td>
                      <td>
                        {top.impact_count}
                      </td>
                    </tr>
                  )}
                  {generateEmptyRow()}
                </tbody></table></div></div>
          </div>*/}
          </div>
        </Overview>
      </PageWrap>
    );
  }

  async function loadDataForCustomer(customer) {
    setLoading(true);

    const [groups, oldest] = await Promise.all([
      get(`/customers/${customer.id}/groups`),
      get(`/reports/oldest/${customer.id}`),
    ]);

    setFilters("");
    setGroups([]);
    setSummary([]);
    selectedGroupsAdmin = [];
    tableGroupsAdmin = [];
    groups && setGroups(groups);
    groups &&
      groups.map((type) => {
        var id = type.id;
        tableGroupsAdmin.push(id);
        selectedGroupsAdmin.push(id);
      });
    setFilters(selectedGroupsAdmin);
    oldest &&
      setStartingDate(moment(oldest.dateStart).format("MM/DD/YYYY").toString());
    oldest && setOldestDate(oldest);
    oldest && setDefaultDate(oldest);
    oldest && oldest.summary && setSummary(oldest.summary);
    setSelectedCustomer(customer);
    setLoading(false);
  }

  function generateCustomerList() {
    return (
      <PageWrap noBottomPadding={true}>
        <CollectionListingManual
          collection="customers"
          placeholder="Search by customer name"
          queryKey="name"
          loadOnStart={false}
          item={(customer) => (
            <div className={styles.spacer}>
              <Button
                size="s"
                onClick={() => loadDataForCustomer(customer)}
                disabled={loading || customer.id === selectedCustomer?.id}
              >
                {customer.name}
              </Button>
            </div>
          )}
        />
      </PageWrap>
    );
  }

  function buildPDF() {
    var callAddFont = function () {
      this.addFileToVFS("Roboto-Bold-normal.ttf", robotoBold);
      this.addFont("Roboto-Bold-normal.ttf", "Roboto-Bold", "normal");

      this.addFileToVFS("Roboto-Medium-normal.ttf", robotoMedium);
      this.addFont("Roboto-Medium-normal.ttf", "Roboto-Medium", "normal");

      this.addFileToVFS("Roboto-Regular-normal.ttf", robotoRegular);
      this.addFont("Roboto-Regular-normal.ttf", "Roboto-Regular", "normal");
    };
    jsPDF.API.events.push(["addFonts", callAddFont]);

    var rgbYellow = [255, 219, 152];
    var rgbGrey = [245, 245, 245];
    var rgbWhite = [255, 255, 255];
    var customPadding = { top: 0, right: 0, bottom: 0, left: 0 };

    // EVENTS HEADER
    function headRows() {
      return [
        [
          {
            content: "ID",
            colSpan: 1,
            styles: { fontStyle: "bold" },
          },
          {
            content: "Date",
            colSpan: 1,
            styles: { fontStyle: "bold", cellWidth: 46 },
          },
          {
            content: "Asset name",
            colSpan: 2,
            styles: { fontStyle: "bold" },
          },
          {
            content: "Asset type",
            colSpan: 2,
            styles: { fontStyle: "bold" },
          } /*, {
        content: 'Group name',
        colSpan: 4,
        styles: { fontStyle: 'bold' },
      }*/,
        ],
      ];
    }

    var exportId = 1;
    // EVENTS DATA
    function bodyRows(rowCount) {
      var body = [];
      var pdfInspectionData = [];

      for (
        var j = exportAllResults ? 0 : currentPage * resultsPerPage;
        j <
        (exportAllResults
          ? rowCount
          : rowCount + currentPage * resultsPerPage > reportResult.events.length
          ? reportResult.events.length
          : rowCount + currentPage * resultsPerPage);
        j++
      ) {
        var event = reportResult.events[j];

        if (event.inspection !== undefined) {
          pdfInspectionData.cost =
            event.inspection.cost === undefined ||
            event.inspection.cost === "" ||
            event.inspection.cost === " "
              ? "Missing"
              : "$" + event.inspection.cost;
          pdfInspectionData.work_order =
            event.inspection.work_order === undefined ||
            event.inspection.work_order === "" ||
            event.inspection.work_order === " "
              ? "Missing"
              : event.inspection.work_order;
          pdfInspectionData.service_request =
            event.inspection.service_request === undefined ||
            event.inspection.service_request === "" ||
            event.inspection.service_request === " "
              ? "Missing"
              : event.inspection.service_request;
          pdfInspectionData.repair_complete_time =
            event.inspection.repair_complete_time === undefined ||
            event.inspection.repair_complete_time === "" ||
            event.inspection.repair_complete_time === " "
              ? "Missing"
              : calculateRepairTime(event);
          pdfInspectionData.damage =
            event.inspection.damage === undefined ||
            event.inspection.damage === "" ||
            event.inspection.damage === " "
              ? "Missing"
              : assetInspectionStates[event.inspection.damage];
          pdfInspectionData.username =
            event.inspection.user === undefined ||
            event.inspection.user === "" ||
            event.inspection.user === " "
              ? "Missing"
              : event.inspection.user.first_name +
                " " +
                event.inspection.user.last_name;
        }

        // SPACER ROW
        if (
          (exportAllResults && j > 0) ||
          (!exportAllResults && j !== currentPage * resultsPerPage)
        ) {
          body.push([
            {
              content: null,
              colSpan: 10,
              styles: {
                fillColor: rgbWhite,
                fontSize: 0.1,
                minCellHeight: 0.1,
                cellPadding: 1.5,
              },
            },
          ]);
        }

        body.push([
          {
            content: exportId++,
            colSpan: 1,
            styles: { fillColor: rgbGrey },
          },
          {
            content:
              reportResult.events &&
              moment(event.created_time)
                .local()
                .format("MMM Do YYYY [at] h:mma"),
            colSpan: 1,
            styles: { fillColor: rgbGrey, cellWidth: 46 },
          },
          {
            content: reportResult.events && event.asset && event.asset.name,
            colSpan: 2,
            styles: { fillColor: rgbGrey },
          },
          {
            content:
              reportResult.events &&
              event.asset &&
              getAssetType(event.asset.assettype_id),
            colSpan: 2,
            styles: { fillColor: rgbGrey },
          } /*, {
            content: reportResult.events &&  "group name",
            colSpan: 4,
            styles: { fillColor: rgbGrey },
          }*/,
        ]);

        // SPACER ROW
        if (event.inspection !== undefined) {
          body.push([
            {
              content: null,
              colSpan: 10,
              styles: {
                fillColor: rgbWhite,
                fontSize: 0.1,
                minCellHeight: 0.1,
                cellPadding: 0.7,
              },
            },
          ]);
        }

        // INSPECTION FIRST ROW
        if (event.inspection !== undefined) {
          body.push([
            {
              content: "",
              styles: { fillColor: rgbWhite },
            },
            {
              content:
                "Inspection report - " +
                moment(event.inspection.created_time)
                  .local()
                  .format(dateFormat),
              colSpan: 8,
              styles: {
                customBorderLeft: true,
                fillColor: rgbYellow,
                fontStyle: "bold",
                cellPadding: 2.5,
              },
            },
            {
              content: "",
              styles: { fillColor: rgbWhite, customBorderLeft: true },
            },
          ]);

          // INSPECTION HEADER
          body.push([
            {
              content: "",
              styles: {
                fillColor: rgbWhite,
                cellPadding: customPadding,
                valign: "bottom",
              },
            },
            {
              content: "  User name",
              styles: {
                fillColor: rgbYellow,
                fontStyle: "bold",
                cellPadding: customPadding,
                valign: "bottom",
                customBorderLeft: true,
                minCellHeight: 6,
              },
            },
            {
              content: "  Work order",
              styles: {
                fillColor: rgbYellow,
                fontStyle: "bold",
                cellPadding: customPadding,
                valign: "bottom",
              },
            },
            {
              content: "  Service request",
              styles: {
                fillColor: rgbYellow,
                fontStyle: "bold",
                cellPadding: customPadding,
                valign: "bottom",
              },
            },
            {
              content: "  Repair cost",
              styles: {
                fillColor: rgbYellow,
                fontStyle: "bold",
                cellPadding: customPadding,
                valign: "bottom",
              },
            },
            {
              content: "  Repair completion date",
              styles: {
                fillColor: rgbYellow,
                fontStyle: "bold",
                cellPadding: customPadding,
                valign: "bottom",
              },
            },
            {
              content: "  Asset status",
              styles: {
                fillColor: rgbYellow,
                fontStyle: "bold",
                cellPadding: customPadding,
                valign: "bottom",
              },
            },
            {
              content: "",
              styles: {
                fillColor: rgbYellow,
                cellPadding: customPadding,
                valign: "bottom",
              },
            },
            {
              content: "",
              styles: {
                fillColor: rgbYellow,
                cellPadding: customPadding,
                valign: "bottom",
              },
            },
            {
              content: "",
              styles: {
                fillColor: rgbWhite,
                customBorderLeft: true,
                cellPadding: customPadding,
                valign: "bottom",
              },
            },
          ]);

          // INSPECTION DATA
          body.push([
            {
              content: "",
              styles: { fillColor: rgbWhite },
            },
            {
              content: pdfInspectionData.username,
              styles: { customBorderLeft: true, fillColor: rgbYellow },
            },
            {
              content: pdfInspectionData.work_order,
              styles: { fillColor: rgbYellow },
            },
            {
              content: pdfInspectionData.service_request,
              styles: { fillColor: rgbYellow },
            },
            {
              content: pdfInspectionData.cost,
              styles: { fillColor: rgbYellow },
            },
            {
              content: pdfInspectionData.repair_complete_time,
              styles: { fillColor: rgbYellow },
            },
            {
              content: pdfInspectionData.damage,
              styles: { fillColor: rgbYellow },
            },
            {
              content: "",
              styles: { fillColor: rgbYellow, cellWidth: 0.1 },
            },
            {
              content: "",
              styles: { fillColor: rgbYellow, cellWidth: 0.1 },
            },
            {
              content: "",
              styles: { fillColor: rgbWhite, customBorderLeft: true },
            },
          ]);

          //NOTES HEADER ROW
          body.push([
            {
              content: "",
              styles: { fillColor: rgbWhite, cellPadding: customPadding },
            },
            {
              content: "  Notes",
              colSpan: 8,
              styles: {
                customBorderLeft: true,
                fillColor: rgbYellow,
                fontStyle: "bold",
                cellPadding: customPadding,
                valign: "bottom",
                minCellHeight: 6,
              },
            },
            {
              content: "",
              styles: {
                customBorderLeft: true,
                fillColor: rgbWhite,
                cellPadding: customPadding,
              },
            },
          ]);

          //NOTES DATA ROW
          pdfInspectionData.note =
            event.inspection.body === undefined ||
            event.inspection.body === "" ||
            event.inspection.body === " "
              ? "Missing"
              : sanitizeHtml(event.inspection.body, {
                  //sanitizeHtml(event.inspection.body.replace(/(\r\n|\n|\r)/gm, "")
                  allowedTags: ["br"],
                });

          body.push([
            {
              content: "",
              styles: { fillColor: rgbWhite },
            },
            {
              content: pdfInspectionData.note.replace(
                /(<br>|<\/br>|<br \/>)/gim,
                "\n"
              ) /*.replace(" ", " ")*/, //replace space with non-break space (U+00A0) to avoid splitting note cell between two pages
              colSpan: 8,
              styles: {
                customBorderLeft: true,
                customBorderBottom: true,
                fillColor: rgbYellow,
              },
            },
            {
              content: "",
              styles: { customBorderLeft: true, fillColor: rgbWhite },
            },
          ]);
        }
      }
      return body;
    }

    var doc = new jsPDF({ orientation: "l", format: "a4" });
    doc.addImage(sentinelLogo, "PNG", 10, 7, 36, 20);
    doc.setFontSize(35);
    //doc.setFont(undefined, 'bold');
    doc.setFont("Roboto-Bold", "normal");
    var width = doc.internal.pageSize.getWidth();
    doc.text("Impact report", width / 2, 25, { align: "center" });
    doc.autoTable({
      startY: 36,
      head: headRows(),
      body: bodyRows(
        exportAllResults
          ? reportResult.events.length
          : resultsPerPage > reportResult.events.length
          ? reportResult.events.length
          : resultsPerPage
      ),
      //theme: 'grid', //debug theme
      showHead: "firstPage",
      margin: { top: 7, left: 10, bottom: 7, right: 10 },
      rowPageBreak: "avoid",

      willDrawCell: (data) => {
        doc.setFont("Roboto-Regular", "normal");
        if (data.cell.raw.styles && data.cell.raw.styles.fontStyle === "bold") {
          doc.setFont("Roboto-Medium", "normal");
        }

        // "missing" in red
        if (
          data.section == "body" &&
          data.column.index != 0 &&
          data.cell.raw.content == "Missing"
        ) {
          doc.setTextColor(231, 76, 60);
        }
      },

      didDrawCell: (data) => {
        // left border
        if (
          data.section == "body" &&
          data.column.index != 0 &&
          data.cell.raw.styles &&
          data.cell.raw.styles.customBorderLeft
        ) {
          doc.setDrawColor(0, 0, 0);
          doc.setLineWidth(0.25);
          doc.line(
            data.cell.x,
            data.cell.y + data.cell.height,
            data.cell.x,
            data.cell.y
          );
        }

        // inspection - header row
        if (
          data.section == "body" &&
          data.column.index != 0 &&
          data.cell.raw.content &&
          data.cell.raw.content.startsWith("Inspection report - ")
        ) {
          doc.setDrawColor(0, 0, 0);
          doc.setLineWidth(0.25);
          doc.line(
            data.cell.x + data.cell.width,
            data.cell.y,
            data.cell.x,
            data.cell.y
          ); //top line

          doc.setDrawColor(180, 180, 180);
          doc.setLineWidth(0.1);
          doc.line(
            data.cell.x + data.cell.width,
            data.cell.y + data.cell.height - 0.15,
            data.cell.x,
            data.cell.y + data.cell.height - 0.15
          ); //grey line
        }

        // inspection - footer row
        if (
          data.section == "body" &&
          data.column.index != 0 &&
          data.cell.raw.styles &&
          data.cell.raw.styles.customBorderBottom
        ) {
          doc.setDrawColor(0, 0, 0);
          doc.setLineWidth(0.25);
          doc.line(
            data.cell.x + data.cell.width,
            data.cell.y + data.cell.height - 0.2,
            data.cell.x,
            data.cell.y + data.cell.height - 0.2
          ); //bottom line
        }
      },
    });
    var today = new Date();
    var date =
      today.getMonth() + 1 + "-" + today.getDate() + "-" + today.getFullYear();
    doc.save("SentinelReport_" + date + ".pdf");
  }

  return initLoad ? (
    <LoadingFrame />
  ) : (
    <>
      <PageHeader>
        <Breadcrumbs>
          <b>
            <Breadcrumb title="Reports" />
          </b>
        </Breadcrumbs>
      </PageHeader>

      {admin && generateCustomerList()}

      {selectedCustomer == null ? (
        !loading ? (
          <PageWrap>
            <Frame>Select the customer</Frame>
          </PageWrap>
        ) : (
          <LoadingFrame />
        )
      ) : loading ? (
        <LoadingFrame />
      ) : (
        <>
          {generateSummary()}
          {generateFilterOptions()}
        </>
      )}

      {lightbox && displayPhotosPreview()}
      {loading ? <LoadingFrame /> : <PageWrap>{_renderContent()}</PageWrap>}

      <Route
        path={`/reports/inspection/:id/edit`}
        component={ReportInspectionEdit}
      />
    </>
  );
}
