import React, { useState, useEffect, useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { AuthContext } from '../../contexts/Auth';
import { useFetch } from '../../hooks/fetch';
import UserContext from '../../contexts/User';

import Info from '../UserInfo';
import Settings from '../UserSettings';

import Breadcrumb from '../../components/Breadcrumb';
import Loading from '../../components/Loading';
import LoadingFrame from '../../components/LoadingFrame';
import SingleColumnPage from '../../components/SingleColumnPage';
import SubNavigation from '../../components/SubNavigation';

// import styles from './User.module.scss';

export default function User({ history, match: { params } }) {
  const { state } = useContext(AuthContext);
  const [data, loading /*, error */] = useFetch(`/users/${params.id}`);
  const [user, setUser] = useState(null);

  useEffect(() => {
    setUser(data);
  }, [data]);

  const breadcrumbs = () => (
    <>
      <Breadcrumb title="Users" to="/users" />
      <Breadcrumb
        title={
          user ? (
            `${user.first_name} ${user.last_name}`
          ) : (
            <Loading color="gray" />
          )
        }
      />
    </>
  );

  const subNav = [{ to: `/users/${params.id}/info`, name: 'Info' }];

  if (state.user.user_id !== parseInt(params.id)) {
    subNav.push({ to: `/users/${params.id}/settings`, name: 'Settings' });
  }

  return (
    <SingleColumnPage breadcrumbs={breadcrumbs}>
      {loading ? (
        <LoadingFrame />
      ) : (
        <>
          <SubNavigation items={subNav} history={history} />

          <UserContext.Provider value={{ user, setUser }}>
            <Switch>
              <Redirect from="/users/:id" to="/users/:id/info" exact />
              <Route path="/users/:id/info" component={Info} />
              <Route path="/users/:id/settings" component={Settings} />
            </Switch>
          </UserContext.Provider>
        </>
      )}
    </SingleColumnPage>
  );
}
