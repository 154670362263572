/*Mobile & Tracker */
export const SENTINEL_MOBILE_TRACKER = 2;
export const SENTINEL_TMA = 10;

/*Impact Tracker */
export const SENTINEL_V0 = 1;
export const SENTINEL_V3 = 3;
export const SENTINEL_V4 = 4;
export const SENTINEL_V5 = 5;
export const SENTINEL_V6 = 6;
export const SENTINEL_V7 = 7;
export const SENTINEL_V8 = 8;
export const SENTINEL_V9 = 9;
