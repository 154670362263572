import React from 'react';
import moment from 'moment';

import {
  ALARM,
  ALARM_ACKNOWLEDGED,
  ASSET_FILE_ADD,
  DEVICE_CREATED,
  DEVICE_UPDATED,
  DEVICE_ASSIGNED,
  DEVICE_UNASSIGNED,
  DEVICE_NOTE,
  ASSET_MAINTENACE_DISABLE,
  ASSET_MAINTENACE_ENABLE,
  FALSE_ALERT,
  DEVICE_REPAIRED,
} from '../../constants/events';

import ActivityItem from '../ActivityItem';
import DeviceStatus from '../DeviceStatus';
import AssetCard from '../AssetCard';
import Avatar from '../Avatar';
import CheckboxWithLabel from '../CheckboxWithLabel';
import DeviceCard from '../DeviceCard';
import Frame from '../Frame';
import Label from '../Label';
import Pagination from '../Pagination';
import Popover from '../Popover';
import TenantAvatar from '../TenantAvatar';
import Loading from '../Loading';

import styles from './DeviceActivity.module.scss';
import EventTypeChanged from '../EventTypeChanged';
import { IMPACT } from '../../constants/statuses';

export default function DeviceActivity({
  events,
  loading,
  total,
  filters,
  page,
  setFilters,
  setCurrentPage
}) {
  const isFiltering = Object.values(filters).some(v => !v);

  function _renderActvityItem(event) {
    const user = `${event.user.first_name} ${event.user.last_name}`;

    let author = <Avatar name={user} />;
    let title = '';
    let body = '';
    let attachments = event.files;

    switch (event.type) {
      case ALARM:
        title = `An alarm was triggered on this device.`;
        body = event && event.alarm && event.alarm.type && <DeviceStatus status={event.alarm.type} />;
        break;

      case ALARM_ACKNOWLEDGED:
        title = (
          <>
            <b>{user}</b> acknowledged an alarm on this asset.
          </>
        );
        break;
      case ASSET_FILE_ADD:
        title = 'An installation photo was added containing this device.';
        break;

      case DEVICE_CREATED:
        // prettier-ignore
        author = <TenantAvatar />;
        title = 'Device was created and provisioned to your account.';
        body = <DeviceCard device={event.device} />;
        break;

      case DEVICE_ASSIGNED:
        // prettier-ignore
        title = (
          <>
            <b>{user}</b> assigned this device to an asset.
          </>
        );
        body = <AssetCard asset={event.asset} />;
        break;

      case DEVICE_UNASSIGNED:
        // prettier-ignore
        title = (
          <>
            <b>{user}</b> unassigned this device from an asset.
          </>
        );
        body = <AssetCard asset={event.asset} />;
        break;

      case DEVICE_UPDATED:
        // prettier-ignore
        title = (
          <>
            <b>{user}</b> updated information on this device.
          </>
        );
        break;
        case ASSET_MAINTENACE_ENABLE:
          title = (
            <>
              <b>{user}</b> enabled the maintenance mode
            </>
          );
          body = event.body;
          break;
        case ASSET_MAINTENACE_DISABLE:
          title = (
            <>
              <b>{user}</b> disabled the maintenance mode
            </>
          );
          body = event.body;
          break;
      case DEVICE_NOTE:
        // prettier-ignore
        title = (
          <>
            <b>{user}</b> added a note to this device.
          </>
        );
        body = event.body;
        break;
        case FALSE_ALERT:
          title = (
            <>
            <b>{user}</b> marked impact alarm as a false alert ({moment.utc(event.body).local().format('MMMM Do YYYY [at] h:mma')}).
            </>
          );
          body = (
            <EventTypeChanged from={IMPACT} to={FALSE_ALERT} />
          );
          break;
          case DEVICE_REPAIRED:
          title = (
            <>
              <b>{user}</b> marked impact alarm as asset under repair ({moment.utc(event.body).local().format('MMMM Do YYYY [at] h:mma')}).
            </>
          );
          body = (
            <EventTypeChanged from={IMPACT} to={DEVICE_REPAIRED} />
          );
          break;
      default:
        return <>{event.type}</>;
    }

    return (
      <ActivityItem
        key={event.id}
        author={author}
        title={title}
        body={body}
        attachments={attachments}
        date={moment(event.created_time).format('MMMM Do YYYY [at] h:mma')}
      />
    );
  }

  return (
    <Frame padding={0}>
      <div className={styles.filterBar}>
        <Popover
          icon="filter_list"
          text="Filter activity"
          flag={isFiltering}
          align="left"
          width={300}
        >
          <Label text="Activity types" theme="light" />

          <CheckboxWithLabel
            checked={filters[DEVICE_NOTE]}
            onChange={e =>
              setFilters({ ...filters, DEVICE_NOTE: e.target.checked })
            }
            label="Notes"
          />
          <CheckboxWithLabel
            checked={filters[DEVICE_CREATED]}
            onChange={e =>
              setFilters({ ...filters, DEVICE_CREATED: e.target.checked })
            }
            label="Device created"
          />
          <CheckboxWithLabel
            checked={filters[DEVICE_UPDATED]}
            onChange={e =>
              setFilters({ ...filters, DEVICE_UPDATED: e.target.checked })
            }
            label="Device updated"
          />
          <CheckboxWithLabel
            checked={filters[DEVICE_ASSIGNED]}
            onChange={e =>
              setFilters({ ...filters, DEVICE_ASSIGNED: e.target.checked })
            }
            label="Device associated with an Asset"
          />
          <CheckboxWithLabel
            checked={filters[DEVICE_UNASSIGNED]}
            onChange={e =>
              setFilters({ ...filters, DEVICE_UNASSIGNED: e.target.checked })
            }
            label="Device removed from an Asset"
          />
          <CheckboxWithLabel
            checked={filters[ASSET_FILE_ADD]}
            onChange={e =>
              setFilters({ ...filters, ASSET_FILE_ADD: e.target.checked })
            }
            label="Installation photo added"
          />

          <CheckboxWithLabel
            checked={filters[ALARM_ACKNOWLEDGED]}
            onChange={e =>
              setFilters({ ...filters, ALARM_ACKNOWLEDGED: e.target.checked })
            }
            label="Alarm acknowledged"
          />
          <CheckboxWithLabel
            checked={filters[ALARM]}
            onChange={e =>
              setFilters({ ...filters, ALARM: e.target.checked })
            }
            label="Alarms"
          />

          <CheckboxWithLabel
            checked={filters[FALSE_ALERT]}
            onChange={e =>
              setFilters({ ...filters, FALSE_ALERT: e.target.checked })
            }
            label="False alarm"
          />
          <CheckboxWithLabel
            checked={filters[DEVICE_REPAIRED]}
            onChange={e =>
              setFilters({ ...filters, DEVICE_REPAIRED: e.target.checked })
            }
            label="Device repaired"
          />

        </Popover>

        <div className={styles.status}>
          {loading && (
            <div className={styles.loading}>
              <Loading color="gray" />
            </div>
          )}

          <span className={styles.totalText}>
            {total ? total : 0} activity item{total !== 1 && 's'}
          </span>
        </div>
      </div>
      <div className={styles.events}>
        {events.map(event => _renderActvityItem(event))}
      </div>

      <Pagination
        total={total}
        perPage={10}
        currentPage={page}
        onPageChange={page => setCurrentPage(page)}
      />
    </Frame>
  );
}
