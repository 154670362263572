import { uniqBy, filter } from "lodash";

export const initialMapState = {
  markers: [],
  isMapShowing: false,
  centerSmallMap: false,
  /** Camera */
  boundsOverridden: false,
  bounds: null,
  isolatedMarker: null,
  displayGPSbutton: false,
  disabled: false,
};

export const MapReducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_MAP": {
      return { ...state, isMapShowing: !state.isMapShowing };
    }

    case "RESET_MAP_STATE": {
      return {
        ...state,
        markers: [],
        isMapShowing: false,
        centerSmallMap: false,
        boundsOverridden: false,
        bounds: null,
        isolatedMarker: null,
        displayGPSbutton: false,
        disabled: false,
      };
    }
    case "SET_MARKERS": {
      const { markers } = action.payload;
      const uniqueMarkers = uniqBy([...markers, ...state.markers], "id");

      return { ...state, markers: uniqueMarkers };
    }

    case "REMOVE_MARKER": {
      const id = action.payload.id;
      const markers = filter(state.markers, (m) => m.id !== id);

      return { ...state, markers };
    }
    case "REMOVE_ALL_MARKERS": {
      const markers = [];
      return { ...state, markers };
    }

    case "UPDATE_BOUNDS": {
      const { bounds } = action.payload;
      return { ...state, boundsOverridden: true, bounds };
    }

    case "ISOLATE_MARKER": {
      const { coords } = action.payload;
      return {
        ...state,
        boundsOverridden: false,
        isolatedMarker: coords,
        displayGPSbutton: true,
      };
    }

    case "SHOW_ALL_MARKERS": {
      return {
        ...state,
        boundsOverridden: false,
        isolatedMarker: null,
        displayGPSbutton: false,
      };
    }

    case "SHOW_ALL_MARKERS": {
      return {
        ...state,
        boundsOverridden: false,
        isolatedMarker: null,
        displayGPSbutton: false,
      };
    }
    case "DISPLAY_GPS_RECALIBRATE": {
      return { ...state, displayGPSbutton: true };
    }

    case "UPDATE_ALL_MARKERS_QUIET": {
      return { ...state, quiet: true };
    }

    case "CENTER_SMALL_MAP": {
      return { ...state, centerSmallMap: true };
    }

    case "DISABLE_MAP": {
      return { ...state, disabled: true };
    }

    default:
      return state;
  }
};
