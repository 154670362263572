import React, { useState } from 'react';

import { useDebounce } from '../../hooks/inputs';
import { useFetch } from '../../hooks/fetch';

import Frame from '../../components/Frame';
import Label from '../../components/Label/Label';
import LoadingFrame from '../../components/LoadingFrame';
import Popover from '../../components/Popover';
import QueryBar from '../../components/QueryBar';
import RowItem from '../RowItem';
import Select from '../../components/Select';
import TextInput from '../../components/TextInput';

import styles from './CollectionListing.module.scss';

const defaultItem = i => <RowItem key={i.id} title={i.id} />;

export default function CollectionListing({
  collection,
  item = defaultItem,
  params,
  queryKey = 'name',
  sortOptions = false,
  stopLoad = false
}) {
  const [query, setQuery] = useState('');
  const [sortBy, setSortBy] = useState('-created_time');
  const debouncedQuery = useDebounce(query, 350);

  const [data, loading /* , error */] = useFetch(`/${collection}`, {
    [`${queryKey}Contains`]: debouncedQuery,
    orderBy: sortBy,
    ...params
  });

  const showCollection = () => <>{data.results.map(entity => item(entity))}</>;

  const showEmptyState = () => (
    <div className={styles.empty}>
      <span>No results</span>
    </div>
  );

  function _renderContent() {
    if (loading && !data) {
      return <LoadingFrame />;
    } else {
      return data.results.length ? showCollection() : showEmptyState();
    }
  }

  return (stopLoad === false &&
    <>
      <Frame padding="0">
        <QueryBar button={sortOptions} >
          <TextInput
            value={query}
            placeholder={`Search by ${queryKey}`}
            icon="search"
            loading={loading && data}
            onChange={e => {
              setQuery(e.target.value);
            }}
          />

          {sortOptions && <Popover
            icon="filter_list"
            text="Sort and filter"
            align="right"
            width={300}
          >
            <Label text="Sort">
              <Select onChange={e => setSortBy(e.target.value)}>
                {sortOptions}
              </Select>
            </Label>
          </Popover>}
        </QueryBar>

        {_renderContent()}
      </Frame>
    </>
  );
}
