import React, { useContext, useEffect, useState } from "react";
import { Route, Link } from "react-router-dom";
import TypesContext from "../../contexts/Types";
import { get, post, put, destroy } from "../../api";
import { AuthContext } from "../../contexts/Auth";
import { CUSTOMER_USER, SYS_ADMIN, TENANT_ADMIN } from "../../constants/roles";
import { isAuthorized, hasPermission } from "../../helpers/authorization";
import moment from "moment";
import {
  IMPACT,
  SIGNALSTRENGTH_LOW,
  SIGNALSTRENGTH_CRITICAL,
  TEMPERATURE_LOW,
  TEMPERATURE_HIGH,
  BATTERY_LOW,
  BATTERY_CRITICAL,
  COMMUNICATION_ERROR,
  OK,
  INACTIVE,
  WORK_IN_PROGRESS,
  READY,
  ORIENTATION_CHANGE,
  CONNECTIVITY_ISSUE,
  INACTIVE_EXPIRED_LICENSE,
} from "../../constants/statuses";
import NoResults from "../../components/NoResults";
import NewAssetWizard from "../NewAssetWizard";
import ActionLabel from "../../components/ActionLabel";
import Breadcrumb from "../../components/Breadcrumb";
import Breadcrumbs from "../../components/Breadcrumbs";
import Button from "../../components/Button";
import DataValue from "../../components/DataValue";
import LoadingFrame from "../../components/LoadingFrame";
import Overview from "../../components/Overview";
import PageHeader from "../../components/PageHeader";
import PageWrap from "../../components/PageWrap";
import Space from "../../components/Space";
import StatusIndicator from "../../components/StatusIndicator";
import TableItem from "../../components/TableItem";
import styles from "./AssetsFiltered.module.scss";
import StatusFilters from "../../components/StatusFilters";
import Frame from "../../components/Frame";
import Popover from "../../components/Popover";
import UniversalFilter from "../../components/UniversalFilter";
import getStatus from "../../helpers/statuses.js";
import TextInput from "../../components/TextInput";
import Icon from "../../components/Icon";
import Select from "../../components/Select";
import MediaQuery from "react-responsive";
import {
  TOOLTIP_TOTAL_ASSETS,
  TOOLTIP_ACTIVE_ASSETS,
  TOOLTIP_INACTIVE_ASSETS,
  TOOLTIP_READY_ASSETS,
  TOOLTIP_IMPACT_DETECTED,
  TOOLTIP_WORK_IN_PROGRESS,
  TOOLTIP_CRITICAL_WARNINGS,
  TOOLTIP_REGULAR_WARNINGS,
  TOOLTIP_EXPIRED_SUBSCRIPTION,
} from "../../helpers/tooltips.js";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useConfirm } from "material-ui-confirm";
import Chip from "@mui/material/Chip";
import AddNewChip from "../../components/AddNewChip/AddNewChip";
import AssetGroupPopup from "../../components/AssetGroupPopup/AssetGroupPopup";

export default function AssetsFiltered({ history }) {
  const { state } = useContext(AuthContext);
  const [newSite, setNewSite] = useState("");
  const [newNotificationGroup, setNewNotificationGroup] = useState("");
  const [initialAssets, setInitialAssets] = useState([]);
  const [types, setTypes] = useState({});
  const [firmwaretypes, setFirmwareTypes] = useState([]);
  const typesassets = useContext(TypesContext);
  const [query, setQuery] = useState("");
  const [sortBy, setSortBy] = useState("name");
  const [statuses, setStatuses] = useState("");
  const [sortOrder, setSortOrder] = useState(true);
  const [dataLoading, setDataLoading] = useState(false);
  const [topLoaded, setTopLoaded] = useState(false);
  const [activity, setActivity] = useState("OK,INACTIVE");
  const [assets, setAssets] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [groups, setGroups] = useState([]);
  const [counts, setCounts] = useState({
    active: 0,
    inactive: 0,
    impacted: 0,
    warning: 0,
    critical: 0,
    work_in_progress: 0,
    ready: 0,
    expired: 0,
  });
  const filter = createFilterOptions();
  const confirm = useConfirm();
  var tableSites = [];
  var tableGroups = [];

  var tableTypes = [];
  var tableFirmwares = [];
  var customerTypes = [];
  var helperFWtable = [];
  const [attenuatorFilters, setAttenuatorFilters] = useState("");
  const [sitesFilters, setSitesFilters] = useState("");
  const [groupFilters, setGroupFilters] = useState("");
  const [firmwareFilters, setFirmwareFilters] = useState("");
  const [customerFilters, setCustomerFilters] = useState("");
  const [loading, setLoading] = useState(true);
  const [alarmsLoading, setAlarmsLoading] = useState([]);
  const [assetsLoading, setAssetsLoading] = useState([]);
  const [assetAlarms, setAssetAlarms] = useState([]);
  const canEdit = hasPermission(state.user.perms, "ADMIN");
  async function fetchGroups() {
    const res = await get(`/users/${state.user.user_id}/groups`);
    if (res) {
      setGroups(res);
      setTypes({ ...types, groups: res });
      tableGroups = [];
      res.map((group) => {
        var id = group.id;
        tableGroups.push(id);
      });
      setGroupFilters("");
    }
  }

  async function getAssets(first, query) {
    first && setLoading(true);
    const res = await get("/assets", {
      //state: first ? ("OK,INACTIVE"):activity,
      asset_type_ids: attenuatorFilters ? attenuatorFilters : null,
      sites: sitesFilters ? sitesFilters : null,
      include_devices: "1",
      orderBy: "name",
      firmwares: null,
      customers: null,
      //nameContains: query,
      device_statuses: statuses,
      include_last_alarm: true,
      count_impacts: true,
    });

    if (res != null) {
      res && setAssets(res.results);
      res && first && setInitialAssets(res.results);

      if (
        isAuthorized(state.user.roles, [TENANT_ADMIN]) &&
        hasPermission(state.user.perms, "ADMIN")
      ) {
        res &&
          res.results.map(
            (fw) =>
              fw.devices &&
              fw.devices.length &&
              tableFirmwares.find(
                (t) => t === parseInt(fw.devices[0].firmware, 10)
              ) == undefined &&
              tableFirmwares.push(parseInt(fw.devices[0].firmware, 10)) &&
              helperFWtable.find((t) => t.id === fw.devices[0].firmware) ==
                undefined &&
              helperFWtable.push({
                id: parseInt(fw.devices[0].firmware, 10),
                name: fw.devices[0].firmware,
              })
          );
        setFirmwareFilters(tableFirmwares.sort((a, b) => a - b));
        setFirmwareTypes(helperFWtable.sort((a, b) => a.id - b.id));
      }
    }
  }

  useEffect(() => {
    var isApiSubscribed = true;
    async function retrieveAllDetails() {
      const [
        impacted,
        critical,
        warning,
        work_in_progress,
        ready,
        expired,
        groups,
        assets,
        assetTypes,
        deviceTypes,
        customers,
      ] = await Promise.all([
        // get('/assets', { active: 'true', include: 'COUNT(*)', take: '1' }),
        // get('/assets', { active: 'false', include: 'COUNT(*)', take: '1' }),
        get("/devices", {
          statuses: `${IMPACT}`,
          active: "true",
          include: "COUNT(*)",
          take: "1",
        }),
        get("/devices", {
          statuses: `${SIGNALSTRENGTH_CRITICAL},${BATTERY_CRITICAL},${TEMPERATURE_HIGH},${COMMUNICATION_ERROR},${CONNECTIVITY_ISSUE}`,
          active: "true",
          include: "COUNT(*)",
          take: "1",
        }),
        get("/devices", {
          statuses: `${SIGNALSTRENGTH_LOW},${BATTERY_LOW},${TEMPERATURE_LOW},${ORIENTATION_CHANGE}`,
          active: "true",
          include: "COUNT(*)",
        }),
        get("/devices", {
          statuses: `${WORK_IN_PROGRESS}`,
          active: "true",
          include: "COUNT(*)",
          take: "1",
        }),
        get("/devices", {
          statuses: `${READY}`,
          active: "true",
          include: "COUNT(*)",
          take: "1",
        }),
        get("/devices", {
          statuses: `${INACTIVE_EXPIRED_LICENSE}`,
          active: "true",
          include: "COUNT(*)",
          take: "1",
        }),
        get(`/users/${state.user.user_id}/groups`),
        get("/assets", {
          asset_type_ids: attenuatorFilters ? attenuatorFilters : null,
          groups: groupFilters ? groupFilters : null,
          include_devices: "1",
          include_groups: "1",
          orderBy: "name",
          firmwares: null,
          customers: null,
          device_statuses: statuses,
          include_last_alarm: isAuthorized(state.user.roles, [TENANT_ADMIN])
            ? true
            : true, //true,
          count_impacts: isAuthorized(state.user.roles, [TENANT_ADMIN])
            ? true
            : true, //true,
        }),
        get("/available_asset_types"),
        get("/devicetypes"),
        get("/customers"),
      ]);
      //Counts

      var active =
        assets &&
        assets.results &&
        assets.results.filter((a) => a.active === true);
      var inactive =
        assets &&
        assets.results &&
        assets.results.filter((a) => a.active === false);

      if (isApiSubscribed) {
        setCounts({
          active: active.length,
          inactive: inactive.length,
          impacted: impacted.total,
          critical: critical.total,
          warning: warning.total,
          work_in_progress: work_in_progress.total,
          ready: ready.total,
          expired: expired.total,
        });
        assets && setAssets(assets.results);
        assets && setInitialAssets(assets.results);

        if (
          isAuthorized(state.user.roles, [TENANT_ADMIN]) &&
          hasPermission(state.user.perms, "ADMIN")
        ) {
          assets &&
            assets.results.map(
              (fw) =>
                fw.devices &&
                fw.devices.length &&
                tableFirmwares.find(
                  (t) => t === parseInt(fw.devices[0].firmware, 10)
                ) == undefined &&
                tableFirmwares.push(parseInt(fw.devices[0].firmware, 10)) &&
                helperFWtable.find((t) => t.id === fw.devices[0].firmware) ==
                  undefined &&
                helperFWtable.push({
                  id: parseInt(fw.devices[0].firmware, 10),
                  name: fw.devices[0].firmware,
                })
            );
          setFirmwareFilters(tableFirmwares.sort((a, b) => a - b));
          setFirmwareTypes(helperFWtable.sort((a, b) => a.id - b.id));
        }
        var desiredGroups = groups;
        //Groups
        if (groups && groups.length) {
          setGroups(groups);
          groups.map((group) => {
            tableGroups.push(group.id);
          });
          setGroupFilters(tableGroups);
        }

        if (
          isAuthorized(state.user.roles, [TENANT_ADMIN, CUSTOMER_USER]) &&
          hasPermission(state.user.perms, "ADMIN")
        ) {
          const [groups_for_c_admin] = await Promise.all([
            get(
              isAuthorized(state.user.roles, [TENANT_ADMIN])
                ? `/groups/all`
                : `/customers/${state.user.customer_id}/groups`
            ),
          ]);
          groups_for_c_admin && setGroups(groups_for_c_admin);
          desiredGroups = groups_for_c_admin;
          tableGroups = [];
          groups_for_c_admin.map((group) => {
            tableGroups.push(group.id);
          });
          setGroupFilters(tableGroups);
        }
        //Types

        setTypes({
          assets: assetTypes.results,
          devices: deviceTypes.results,
          groups: desiredGroups,
          customers: customers.results.sort((a, b) =>
            a.name > b.name ? 1 : -1
          ),
        });
        assetTypes.results.map((type) => {
          var id = type.id;
          tableTypes.push(id);
        });
        setAttenuatorFilters(tableTypes);

        customers.results.map((customer) => {
          var id = customer.id;
          customerTypes.push(id);
        });
        setCustomerFilters(customerTypes);

        setLoading(false);
      }
    }

    retrieveAllDetails();

    return () => {
      isApiSubscribed = false;
    };
  }, []);

  async function refresh() {
    // setDataLoading(true);
    //setDataLoading(false);
  }
  const pageHeaderActions = () => (
    <>
      {isAuthorized(state.user.roles, [CUSTOMER_USER]) &&
        hasPermission(state.user.perms, "ASSET_C") && (
          <Link to="/assets_dashboard/new">
            <Button icon="add" theme="primary">
              New asset
            </Button>
          </Link>
        )}
    </>
  );

  async function handleSorting(e, name) {
    e.preventDefault();

    setSortBy(name);
    setSortOrder(!sortOrder);
    setDataLoading(true);
    const myData = [].concat(assets);
    switch (name) {
      case "name":
        if (sortOrder == true) {
          myData.sort((a, b) => (a.name < b.name ? 1 : -1));
        } else {
          myData.sort((a, b) => (a.name > b.name ? 1 : -1));
        }
        break;
      case "assettype_id":
        if (sortOrder == true) {
          myData.sort((a, b) =>
            types.assets.find((t) => t.id === a.assettype_id).name <
            types.assets.find((t) => t.id === b.assettype_id).name
              ? 1
              : -1
          );
        } else {
          myData.sort((a, b) =>
            types.assets.find((t) => t.id === a.assettype_id).name >
            types.assets.find((t) => t.id === b.assettype_id).name
              ? 1
              : -1
          );
        }
        break;

      case "active":
        if (sortOrder == true) {
          myData.sort((a, b) => (a.active < b.active ? 1 : -1));
        } else {
          myData.sort((a, b) => (a.active > b.active ? 1 : -1));
        }
        break;

      case "status":
        if (sortOrder == true) {
          myData.sort((a, b) =>
            (a.devices && a.devices.length && a.devices[0]).status <
            (b.devices && b.devices.length && b.devices[0]).status
              ? 1
              : -1
          );
        } else {
          myData.sort((a, b) =>
            (a.devices && a.devices.length && a.devices[0]).status >
            (b.devices && b.devices.length && b.devices[0]).status
              ? 1
              : -1
          );
        }
        break;
      case "site_id":
        if (sortOrder == true) {
          myData.sort((a, b) =>
            (a.site_id && a.site.name) < (b.site_id && b.site.name) ? 1 : -1
          );
        } else {
          myData.sort((a, b) =>
            (a.site_id && a.site.name) > (b.site_id && b.site.name) ? 1 : -1
          );
        }
        break;
      case "customer":
        if (sortOrder == true) {
          myData.sort((a, b) =>
            (a.customer_id && a.customer.name) <
            (b.customer_id && b.customer.name)
              ? 1
              : -1
          );
        } else {
          myData.sort((a, b) =>
            (a.customer_id && a.customer.name) >
            (b.customer_id && b.customer.name)
              ? 1
              : -1
          );
        }
        break;
      case "battery":
        if (sortOrder == true) {
          myData.sort((a, b) =>
            (a.devices && a.devices.length && a.devices[0]).battery <
            (b.devices && b.devices.length && b.devices[0]).battery
              ? 1
              : -1
          );
        } else {
          myData.sort((a, b) =>
            (a.devices && a.devices.length && a.devices[0]).battery >
            (b.devices && b.devices.length && b.devices[0]).battery
              ? 1
              : -1
          );
        }
        break;
      case "firmware":
        if (sortOrder == true) {
          myData.sort((a, b) =>
            (a.devices && a.devices.length && a.devices[0]).firmware <
            (b.devices && b.devices.length && b.devices[0]).firmware
              ? 1
              : -1
          );
        } else {
          myData.sort((a, b) =>
            (a.devices && a.devices.length && a.devices[0]).firmware >
            (b.devices && b.devices.length && b.devices[0]).firmware
              ? 1
              : -1
          );
        }
        break;
      case "serial":
        if (sortOrder == true) {
          myData.sort((a, b) =>
            (a.devices && a.devices.length && a.devices[0]).serial_number <
            (b.devices && b.devices.length && b.devices[0]).serial_number
              ? 1
              : -1
          );
        } else {
          myData.sort((a, b) =>
            (a.devices && a.devices.length && a.devices[0]).serial_number >
            (b.devices && b.devices.length && b.devices[0]).serial_number
              ? 1
              : -1
          );
        }
        break;
      case "last_impact_activity":
        if (sortOrder == true) {
          myData.sort(
            (a, b) =>
              moment.utc(a.last_impact_activity) -
              moment.utc(b.last_impact_activity)
          );
        } else {
          myData
            .sort(
              (a, b) =>
                moment.utc(a.last_impact_activity) -
                moment.utc(b.last_impact_activity)
            )
            .reverse();
        }
        break;

      case "count_impacts":
        if (sortOrder == true) {
          myData.sort(
            (a, b) => moment.utc(a.count_impacts) - moment.utc(b.count_impacts)
          );
        } else {
          myData
            .sort(
              (a, b) =>
                moment.utc(a.count_impacts) - moment.utc(b.count_impacts)
            )
            .reverse();
        }
        break;
      default:
        break;
    }

    setAssets(myData);
    setDataLoading(false);
  }

  function generateOptions(value, displayName) {
    return sortBy === value ? (
      sortOrder == false ? (
        <>
          {displayName}
          <Icon name="keyboard_arrow_down" />
        </>
      ) : (
        <>
          {displayName}
          <Icon name="keyboard_arrow_up" />
        </>
      )
    ) : (
      displayName
    );
  }

  function generateIconOptions(value) {
    return sortBy === value ? (
      sortOrder == false ? (
        <Icon name="arrow_downward" />
      ) : (
        <Icon name="arrow_upward" />
      )
    ) : (
      <Icon name="swap_vert" />
    );
  }

  async function applyFilter(type, values) {
    var res;
    if (topLoaded) {
      setDataLoading(true);
      switch (type) {
        case "name":
          var res = initialAssets.filter(function (e) {
            return e.name.toLowerCase().includes(values.toLowerCase());
          });
          setAssets(res);
          break;

        case "assettype_id":
          res = await get("/assets", {
            asset_type_ids: values ? values : null,
            groups: groupFilters ? groupFilters : null,
            include_devices: "1",
            orderBy: "name",
            firmwares: firmwareFilters ? firmwareFilters : null,
            customers: customerFilters ? customerFilters : null,
            device_statuses: statuses,
            include_last_alarm: true,
            count_impacts: true,
            include_groups: "1",
          });

          if (res != null) {
            res && setAssets(res.results);
          }
          break;
        case "active":
          res = await get("/assets", {
            asset_type_ids: attenuatorFilters ? attenuatorFilters : null,
            groups: groupFilters ? groupFilters : null,
            include_devices: "1",
            orderBy: "name",
            firmwares: firmwareFilters ? firmwareFilters : null,
            customers: customerFilters ? customerFilters : null,
            device_statuses: statuses,
            include_last_alarm: true,
            count_impacts: true,
            include_groups: "1",
          });

          if (res != null) {
            res && setAssets(res.results);
          }
          break;

        case "status":
          res = await get("/assets", {
            asset_type_ids: attenuatorFilters ? attenuatorFilters : null,
            groups: groupFilters ? groupFilters : null,
            include_devices: "1",
            orderBy: "name",
            firmwares: firmwareFilters ? firmwareFilters : null,
            customers: customerFilters ? customerFilters : null,
            device_statuses: values,
            include_last_alarm: true,
            count_impacts: true,
            include_groups: "1",
          });

          if (res != null) {
            res && setAssets(res.results);
          }
          break;

        case "site":
          setDataLoading(true);
          res = await get("/assets", {
            asset_type_ids: attenuatorFilters ? attenuatorFilters : null,
            sites: values ? values : null,
            include_devices: "1",
            orderBy: "name",
            firmwares: firmwareFilters ? firmwareFilters : null,
            customers: customerFilters ? customerFilters : null,
            device_statuses: statuses,
            include_last_alarm: true,
            count_impacts: true,
          });

          if (res != null) {
            res && setAssets(res.results);
          }
          break;
        case "group":
          setDataLoading(true);
          res = await get("/assets", {
            asset_type_ids: attenuatorFilters ? attenuatorFilters : null,
            groups: values ? values : null,
            include_devices: "1",
            orderBy: "name",
            firmwares: firmwareFilters ? firmwareFilters : null,
            customers: customerFilters ? customerFilters : null,
            device_statuses: statuses,
            include_last_alarm: true,
            count_impacts: true,
            include_groups: "1",
          });

          if (res != null) {
            res && setAssets(res.results);
          }
          break;
        case "firmware":
          setDataLoading(true);
          res = await get("/assets", {
            asset_type_ids: attenuatorFilters ? attenuatorFilters : null,
            groups: groupFilters ? groupFilters : null,
            include_devices: "1",
            orderBy: "name",
            firmwares: values ? values : null,
            customers: customerFilters ? customerFilters : null,
            device_statuses: statuses,
            include_last_alarm: true,
            count_impacts: true,
            include_groups: "1",
          });

          if (res != null) {
            res && setAssets(res.results);
          }
          break;

        case "customer":
          setDataLoading(true);
          res = await get("/assets", {
            asset_type_ids: attenuatorFilters ? attenuatorFilters : null,
            groups: groupFilters ? groupFilters : null,
            include_devices: "1",
            orderBy: "name",
            firmwares: firmwareFilters ? firmwareFilters : null,
            customers: values ? values : null,
            device_statuses: statuses,
            include_last_alarm: true,
            count_impacts: true,
            include_groups: "1",
          });

          if (res != null) {
            res && setAssets(res.results);
          }
          break;
        default:
          break;
      }
      setDataLoading(false);
    }
  }

  async function createNewNotificationGroup(assetId, name) {
    var res = await post(`/assets/notification/new`, {
      asset_id: assetId,
      name: name,
    });
    if (res && res.id === -1) {
      confirm({
        description: "A group with this name already exists.",
        title: null,
        dialogProps: { fullScreen: false },
        cancellationButtonProps: { style: { display: "none" } },
        confirmationButtonProps: { color: "primary", disableRipple: true },
      })
        .then(() => {})
        .catch(() => {});
    } else {
      fetchGroups();
    }
  }

  async function updateAssetGroupId(assetId, groupId, name) {
    var shouldRefresh = true;
    if (groupId !== 0) {
      shouldRefresh = true;
      setAssetsLoading((as) => [...as, assetId]);
      var res = await put(`/assets/${assetId}/group/${groupId}`);
    } else {
      setAssetsLoading((as) => [...as, assetId]);
      shouldRefresh = true;
      await createNewNotificationGroup(assetId, name);
    }
    if (shouldRefresh) {
      const updatedAsset = await get(`/singleasset/${assetId}`);
      if (
        updatedAsset !== undefined &&
        updatedAsset.results != undefined &&
        updatedAsset.results.length != 0
      ) {
        var assetToRefresh = updatedAsset.results[0];
        handleSwap(assetToRefresh.id, assetToRefresh);
      }
    }
  }

  function renderControlsAndColumns() {
    const timer = setTimeout(() => {
      setTopLoaded(true);
    }, 500);
    return (
      <Frame padding={6}>
        <TableItem key={1001}>
          <Space size="s" width="px400">
            <ActionLabel
              text={
                <Button
                  transparent={true}
                  icon={generateIconOptions("name")}
                  onClick={(e) => handleSorting(e, "name")}
                >
                  <div className={styles.header}>Name</div>
                </Button>
              }
            >
              <MediaQuery deviceWidth={1024}>
                {" "}
                {/*ipad only */}
                <TextInput
                  value={query}
                  placeholder="Enter name"
                  assetDashboard={true}
                  disabled={dataLoading}
                  loading={assets ? !!assets.length && loading : false}
                  onChange={(e) => (
                    setQuery(e.target.value),
                    applyFilter("name", e.target.value)
                  )}
                />
              </MediaQuery>
              <MediaQuery maxDeviceWidth={1023}>
                <TextInput
                  value={query}
                  placeholder="Enter name"
                  icon="search"
                  assetDashboard={true}
                  disabled={dataLoading}
                  loading={assets ? !!assets.length && loading : false}
                  onChange={(e) => (
                    setQuery(e.target.value),
                    applyFilter("name", e.target.value)
                  )}
                />
              </MediaQuery>
              <MediaQuery minDeviceWidth={1025}>
                <TextInput
                  value={query}
                  placeholder="Enter name"
                  icon="search"
                  assetDashboard={true}
                  disabled={dataLoading}
                  loading={assets ? !!assets.length && loading : false}
                  onChange={(e) => (
                    setQuery(e.target.value),
                    applyFilter("name", e.target.value)
                  )}
                />
              </MediaQuery>
            </ActionLabel>
          </Space>
          <Space size="s">
            <ActionLabel
              text={
                <Button
                  transparent={true}
                  icon={generateIconOptions("assettype_id")}
                  onClick={(e) => handleSorting(e, "assettype_id")}
                >
                  <div className={styles.header}>Asset type</div>
                </Button>
              }
            >
              <Popover
                key={9999}
                icon="filter_list"
                text={
                  attenuatorFilters == "" ? (
                    <div className={styles.filterListAll}>All types</div>
                  ) : (
                    "Filtered"
                  )
                }
                flag={attenuatorFilters !== ""}
                align="right"
                assetDashboard={true}
                width={300}
              >
                <UniversalFilter
                  key={999}
                  inputArray={types.assets}
                  inputFilter={attenuatorFilters}
                  onChange={(attenuatorFilters) => (
                    setAttenuatorFilters(attenuatorFilters),
                    applyFilter("assettype_id", attenuatorFilters)
                  )}
                  onRefresh={refresh}
                  dataLoading={dataLoading}
                />
              </Popover>
            </ActionLabel>
          </Space>

          <Space size="s">
            <ActionLabel
              text={
                <Button
                  transparent={true}
                  icon={generateIconOptions("status")}
                  onClick={(e) => handleSorting(e, "status")}
                >
                  <div className={styles.header}>Asset condition</div>
                </Button>
              }
            >
              <Popover
                icon="filter_list"
                text={
                  statuses == "" ? (
                    <div className={styles.filterListAll}>All conditions</div>
                  ) : (
                    "Filtered"
                  )
                }
                flag={statuses !== ""}
                assetDashboard={true}
                align="right"
                width={280}
              >
                <StatusFilters
                  onChange={(statuses) => (
                    setStatuses(statuses), applyFilter("status", statuses)
                  )}
                  reset={true}
                  unselectAll={true}
                  onRefresh={refresh}
                  dataLoading={dataLoading}
                />
              </Popover>
            </ActionLabel>
          </Space>

          <Space size="s">
            <ActionLabel
              text={
                <Button
                  transparent={true}
                  icon={generateIconOptions("group_id")}
                  onClick={(e) => handleSorting(e, "group_id")}
                >
                  <div className={styles.header}>Asset group</div>
                </Button>
              }
            >
              <Popover
                icon="filter_list"
                text={
                  groupFilters == "" ? (
                    <div className={styles.filterListAll}>All groups</div>
                  ) : (
                    "Filtered"
                  )
                }
                flag={groupFilters !== ""}
                align="right"
                assetDashboard={true}
                width={280}
              >
                <UniversalFilter
                  unselectAll={true}
                  inputArray={types.groups}
                  inputFilter={groupFilters}
                  onChange={(groupFilters) => (
                    setGroupFilters(groupFilters),
                    applyFilter("group", groupFilters)
                  )}
                  onRefresh={refresh}
                  dataLoading={dataLoading}
                />
              </Popover>
            </ActionLabel>
          </Space>
          {isAuthorized(state.user.roles, [TENANT_ADMIN]) &&
            hasPermission(state.user.perms, "ADMIN") && (
              <Space size="s">
                <ActionLabel
                  text={
                    <Button
                      transparent={true}
                      icon={generateIconOptions("customer")}
                      onClick={(e) => handleSorting(e, "customer")}
                    >
                      <div className={styles.header}>Customer</div>
                    </Button>
                  }
                >
                  <Popover
                    icon="filter_list"
                    text={
                      customerFilters == "" ? (
                        <div className={styles.filterListAll}>
                          All customers
                        </div>
                      ) : (
                        "Filtered"
                      )
                    }
                    flag={customerFilters !== ""}
                    align="right"
                    assetDashboard={true}
                    width={340}
                  >
                    <UniversalFilter
                      unselectAll={true}
                      onlyDefault={false}
                      inputArray={types.customers}
                      inputFilter={customerFilters}
                      onChange={(customerFilters) => (
                        setCustomerFilters(customerFilters),
                        applyFilter("customer", customerFilters)
                      )}
                      onRefresh={refresh}
                      dataLoading={dataLoading}
                    />
                  </Popover>
                </ActionLabel>
              </Space>
            )}
          <Space size="s">
            <ActionLabel
              text={
                <Button
                  transparent={true}
                  icon={generateIconOptions("serial")}
                  onClick={(e) => handleSorting(e, "serial")}
                >
                  <div className={styles.header}>Device serial</div>
                </Button>
              }
            ></ActionLabel>
          </Space>

          <Space size="s" max_width="max20">
            <ActionLabel
              text={
                <Button
                  transparent={true}
                  icon={generateIconOptions("battery")}
                  onClick={(e) => handleSorting(e, "battery")}
                >
                  <div className={styles.header}>Device battery</div>
                </Button>
              }
            ></ActionLabel>
          </Space>
          {isAuthorized(state.user.roles, [TENANT_ADMIN]) &&
            hasPermission(state.user.perms, "ADMIN") && (
              <Space size="s" max_width="max20">
                <ActionLabel
                  text={
                    <Button
                      transparent={true}
                      icon={generateIconOptions("firmware")}
                      onClick={(e) => handleSorting(e, "firmware")}
                    >
                      <div className={styles.header}>Firmware</div>
                    </Button>
                  }
                >
                  <Popover
                    icon="filter_list"
                    text={
                      firmwareFilters == "" ? (
                        <div className={styles.filterListAll}>All versions</div>
                      ) : (
                        "Filtered"
                      )
                    }
                    flag={firmwareFilters !== ""}
                    align="right"
                    assetDashboard={true}
                    width={260}
                  >
                    <UniversalFilter
                      unselectAll={true}
                      onlyDefault={false}
                      inputArray={firmwaretypes}
                      inputFilter={firmwareFilters}
                      onChange={(firmwareFilters) => (
                        setFirmwareFilters(firmwareFilters),
                        applyFilter("firmware", firmwareFilters)
                      )}
                      onRefresh={refresh}
                      dataLoading={dataLoading}
                    />
                  </Popover>
                </ActionLabel>
              </Space>
            )}
          <Space size="s">
            <ActionLabel
              text={
                <Button
                  transparent={true}
                  icon={generateIconOptions("last_impact_activity")}
                  onClick={(e) => handleSorting(e, "last_impact_activity")}
                >
                  <div className={styles.header}>Last impact date</div>
                </Button>
              }
            ></ActionLabel>
          </Space>

          <Space size="s">
            <ActionLabel
              text={
                <Button
                  transparent={true}
                  icon={generateIconOptions("count_impacts")}
                  onClick={(e) => handleSorting(e, "count_impacts")}
                >
                  <div className={styles.header}>Number of impacts</div>
                </Button>
              }
            ></ActionLabel>
          </Space>
        </TableItem>
      </Frame>
    );
  }

  async function getAlarmsForAssetDevice(deviceId) {
    setAlarmsLoading((al) => [...al, deviceId]);
    var res = await get(`/alarms/device/${deviceId}`, {
      status: "ACTIVE_UNACK",
    });
    if (res?.results) {
      var existing = assetAlarms.find((x) => x.deviceId === deviceId);
      if (existing === undefined) {
        setAssetAlarms((aa) => [
          ...aa,
          { deviceId: deviceId, alarms: res.results },
        ]);
      } else {
        setAssetAlarms(assetAlarms.filter((aa) => aa.deviceId !== deviceId));
      }
      setAlarmsLoading(alarmsLoading.filter((al) => al !== deviceId));
    } else {
      setAlarmsLoading(alarmsLoading.filter((al) => al !== deviceId));
    }
  }

  async function refreshId(assetId) {
    const updatedAsset = await get(`/singleasset/${assetId}`);
    if (
      updatedAsset !== undefined &&
      updatedAsset.results != undefined &&
      updatedAsset.results.length != 0
    ) {
      var assetToRefresh = updatedAsset.results[0];
      handleSwap(assetToRefresh.id, assetToRefresh);
    }

    try {
      var selectedDevice = assets.filter((a) => a.id === assetId);
      if (selectedDevice != undefined && selectedDevice[0].devices.length > 0) {
        var deviceId = selectedDevice[0].devices[0].id;
        setAlarmsLoading(alarmsLoading.filter((al) => al !== deviceId));
        // var device = assetAlarms.find(x => x.deviceId === deviceId);
        getAlarmsForAssetDevice(deviceId);
      }
    } catch (e) {
      console.log(e);
    }
    return true;
  }

  const handleSwap = (selectedItemId, newObject) => {
    const selectedIndex = assets.findIndex(
      (item) => item.id === selectedItemId
    );
    if (selectedIndex === -1) {
      return;
    }
    const newArray = [...assets];
    newArray[selectedIndex] = newObject;
    setAssets(newArray);
    setAssetsLoading(assetsLoading.filter((al) => al !== selectedItemId));
  };

  async function hangleGroupChange(asset, groupchange) {
    if (groupchange !== undefined && groupchange.length > 0) {
      var differenceId = groupchange.filter(
        (element) => !asset.groups.includes(element)
      );
      differenceId.length > 0 &&
        (await updateAssetGroupId(
          asset.id,
          differenceId[0].id,
          differenceId[0].name
        ));
    }
  }

  async function removeAssetFromGroup(assetId, groupId) {
    confirm({
      description: "Are you sure you want to remove this asset from the group?",
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true },
    })
      .then(async () => {
        setAssetsLoading((as) => [...as, assetId]);
        var res = await destroy(`/assets/${assetId}/group/${groupId}`);
        const updatedAsset = await get(`/singleasset/${assetId}`);
        if (
          updatedAsset !== undefined &&
          updatedAsset.results != undefined &&
          updatedAsset.results.length != 0
        ) {
          var assetToRefresh = updatedAsset.results[0];
          handleSwap(assetToRefresh.id, assetToRefresh);
        }
      })
      .catch(() => {
        /* ... */
      });
  }

  function _renderAssets() {
    return dataLoading ? (
      <LoadingFrame />
    ) : (
      <>
        {selectedAsset && (
          <AssetGroupPopup
            asset={selectedAsset}
            groups={types.groups}
            onClose={() => setSelectedAsset(null)}
          />
        )}
        {assets.length ? (
          assets.map((a, i) => {
            if (a === undefined) {
              return <NoResults message="No assets found" />;
            }
            const device = a && a.devices && a.devices.length && a.devices[0];
            const type = a && types.assets.find((t) => t.id === a.assettype_id);
            const fullDateTime =
              a &&
              moment
                .utc(a.last_impact_activity)
                .format("dddd, MMMM Do YYYY, h:mm:ss a");
            const timeAgo =
              a &&
              moment
                .utc(a.last_impact_activity)
                .local()
                .format("MMM Do YYYY [at] h:mma"); //.fromNow();

            return (
              <TableItem
                key={i}
                header={true}
                asset={a}
                right={false}
                extraDiv={
                  assetAlarms.some((data) => data.deviceId === device.id)
                    ? assetAlarms.find((x) => x.deviceId === device.id)
                    : false
                }
                refreshId={refreshId}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {a.alarms_count > 0 && (
                    <div style={{ marginRight: 10 }}>
                      <Button
                        fixedWidth={58}
                        isLoading={
                          alarmsLoading.filter((el) => el === device.id)?.length
                        }
                        icon={"notifications"}
                        onClick={() => getAlarmsForAssetDevice(device.id)}
                        disabled={
                          !device ||
                          alarmsLoading.filter((el) => el === device.id)?.length
                        }
                      >
                        <div className={styles.notification} />
                      </Button>
                    </div>
                  )}
                  <Link to={`/assets/${a.id}`}>
                    <Space size="zero">
                      <b>{a.name}</b>{" "}
                    </Space>
                  </Link>
                </div>
                <Link to={`/assets/${a.id}`}>
                  <Space size="zero">{type && type.name && type.name} </Space>
                </Link>
                {/*<Link to={`/assets/${a.id}`}><Space size="zero"><div className={styles.wrap}><StatusIndicator status={a.active} />{a.active ? ("Active ") : ("Inactive")}</div></Space></Link>*/}
                <Link to={`/assets/${a.id}`}>
                  <Space size="zero">
                    <div className={styles.wrap}>
                      <StatusIndicator status={device.status} padding={0} />
                      <span> {getStatus(device.status).name}</span>
                    </div>
                  </Space>
                </Link>
                {
                  <Space size="zero">
                    {canEdit ? (
                      <Button
                        align="left"
                        fixedWidth={200}
                        onClick={() => setSelectedAsset(a)}
                      >
                        {a.groups && a.groups.length > 0
                          ? a.groups.length == 1
                            ? "1 group assigned"
                            : a.groups.length + " groups assigned"
                          : "No groups assigned"}
                      </Button>
                    ) : (
                      <Popover
                        fullwidth={true}
                        spacing={-180}
                        text={
                          a.groups && a.groups.length > 0
                            ? a.groups.length == 1
                              ? "1 group assigned"
                              : a.groups.length + " groups assigned"
                            : "No groups assigned"
                        }
                        align="left"
                        width={400}
                      >
                        {
                          <Autocomplete
                            multiple
                            id="tags-standard"
                            filterSelectedOptions
                            readOnly={true}
                            options={types.groups.filter(
                              (x) => x.customer_id == a.customer_id
                            )}
                            getOptionLabel={(option) =>
                              option.id == 0 ? (
                                <b>Add "{option.name}"</b>
                              ) : (
                                option.name
                              )
                            }
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" />
                            )}
                            value={a.groups}
                          />
                        }
                      </Popover>
                    )}
                  </Space>
                }
                {isAuthorized(state.user.roles, [TENANT_ADMIN]) &&
                  hasPermission(state.user.perms, "ADMIN") && (
                    <Link to={`/assets/${a.id}`}>
                      <Space size="zero">
                        {a.customer ? a.customer.name : "No customer"}
                      </Space>
                    </Link>
                  )}
                <Link to={`/assets/${a.id}`}>
                  <Space size="zero">
                    {device.serial_number ? (
                      <b>{device.serial_number}</b>
                    ) : (
                      "No device installed"
                    )}
                  </Space>
                </Link>
                <Link to={`/assets/${a.id}`}>
                  <Space size="zero" max_width="max20">
                    {device.battery ? device.battery + "%" : "Device inactive"}
                  </Space>
                </Link>
                {isAuthorized(state.user.roles, [TENANT_ADMIN]) &&
                  hasPermission(state.user.perms, "ADMIN") && (
                    <Link to={`/assets/${a.id}`}>
                      <Space size="zero" max_width="max20">
                        {device.firmware
                          ? "v" + device.firmware
                          : "Device inactive"}
                      </Space>
                    </Link>
                  )}
                <Link to={`/assets/${a.id}`}>
                  <Space size="zero">
                    {a.last_impact_activity ? (
                      <span title={fullDateTime}>{`${timeAgo}`}</span>
                    ) : (
                      "No impacts detected"
                    )}
                  </Space>
                </Link>
                <Link to={`/assets/${a.id}`}>
                  <Space size="zero">
                    {a.count_impacts ? (
                      <span title={fullDateTime}>{a.count_impacts}</span>
                    ) : (
                      "-"
                    )}
                  </Space>
                </Link>
              </TableItem>
            );
          })
        ) : (
          <NoResults message="No assets found" />
        )}
      </>
    );
  }

  function _renderContent() {
    return (
      <PageWrap>
        <Overview>
          <DataValue label="Total assets" tooltip={TOOLTIP_TOTAL_ASSETS}>
            {counts.active + counts.inactive}
          </DataValue>
          <DataValue tooltip={TOOLTIP_ACTIVE_ASSETS} label="Active assets">
            <StatusIndicator status={OK} /> {counts.active}
          </DataValue>
          <DataValue tooltip={TOOLTIP_INACTIVE_ASSETS} label="Inactive assets">
            <StatusIndicator status={INACTIVE} /> {counts.inactive}
          </DataValue>
          <DataValue tooltip={TOOLTIP_READY_ASSETS} label="Ready">
            <StatusIndicator status={READY} /> {counts.ready}
          </DataValue>
          <DataValue tooltip={TOOLTIP_IMPACT_DETECTED} label="Impact detected">
            <StatusIndicator status={IMPACT} /> {counts.impacted}
          </DataValue>
          <DataValue
            tooltip={TOOLTIP_WORK_IN_PROGRESS}
            label="Work in progress"
          >
            <StatusIndicator status={WORK_IN_PROGRESS} />{" "}
            {counts.work_in_progress}
          </DataValue>
          <DataValue
            tooltip={TOOLTIP_CRITICAL_WARNINGS}
            label="Critical warnings"
          >
            <StatusIndicator status={COMMUNICATION_ERROR} /> {counts.critical}
          </DataValue>
          <DataValue tooltip={TOOLTIP_REGULAR_WARNINGS} label="Warnings">
            <StatusIndicator status={BATTERY_LOW} /> {counts.warning}
          </DataValue>
          <DataValue
            tooltip={TOOLTIP_EXPIRED_SUBSCRIPTION}
            label="Expired subscription"
          >
            <StatusIndicator status={INACTIVE_EXPIRED_LICENSE} />{" "}
            {counts.expired}
          </DataValue>
        </Overview>
        <Space size="s" />
        {renderControlsAndColumns()}
        <Space size="s" />
        <Frame padding={0}>
          <div className={styles.rows}>
            {topLoaded &&
              (assets ? (
                _renderAssets()
              ) : (
                <NoResults message="No assets found" />
              ))}
          </div>
        </Frame>
      </PageWrap>
    );
  }

  return (
    <>
      <PageHeader right={pageHeaderActions()}>
        <Breadcrumbs>
          <Breadcrumb title="Assets" />
        </Breadcrumbs>
        <Route path="/assets_dashboard/new" component={NewAssetWizard} exact />
      </PageHeader>
      {loading ? <LoadingFrame /> : _renderContent()}
    </>
  );
}
