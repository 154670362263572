export const initialAssetState = {
  asset: {},
  device: {},
  installPhoto: {},
  relation: {},
  loading: 0,
  saving: false,

  activity: {
    events: [],
    loading: true,
    total: 0,
    count: 0,
    page: 1,
    filters: {
      ALARM: true,
      ALARM_ACKNOWLEDGED: false,
      ASSET_CREATED: true,
      ASSET_DELETED: true,
      ASSET_UPDATED: false,
      DEVICE_ASSIGNED: true,
      DEVICE_UNASSIGNED: true,
      INSPECTION_CREATED: true,
      INSPECTION_FILE_ADD: true,
      ASSET_NOTE: true,
      ASSET_FILE_ADD: true,
      FALSE_ALERT: true,
      DEVICE_REPAIRED: true,
      WORK_IN_PROGRESS: true,
      WORK_COMPLETE: true,
      LICENSE_EXPIRED: false,
      LICENSE_DEACTIVATED: false,
      LICENSE_UPDATED: true,
      RESUMED_CHECK_IN: true,
    },
  },
};

export const AssetReducer = (state, action) => {
  switch (action.type) {
    case "INCREMENT_LOADING":
      return { ...state, loading: state.loading + 1 };

    case "DECREMENT_LOADING":
      return { ...state, loading: state.loading - 1 };

    case "UPDATE_SAVING":
      return { ...state, saving: action.payload.saving };

    case "UPDATE_ASSET":
      return { ...state, asset: action.payload.asset };

    case "UPDATE_DEVICE":
      return { ...state, device: action.payload.device };

    case "UPDATE_DEVICE_SILENT":
      return { ...state, device: action.payload.device };

    case "UPDATE_RELATION":
      return { ...state, relation: action.payload.relation };

    case "REMOVE_RELATION":
      return { ...state, relation: {}, device: {} };

    case "UPDATE_INSTALL_PHOTO":
      return { ...state, installPhoto: action.payload.installPhoto };

    case "UPDATE_ACTIVITY_LOADING":
      return {
        ...state,
        activity: { ...state.activity, loading: action.payload.loading },
      };

    case "UPDATE_ACTIVITY_PAGE":
      return {
        ...state,
        activity: { ...state.activity, page: action.payload.page },
      };

    case "UPDATE_ACTIVITY_FILTERS":
      return {
        ...state,
        activity: {
          ...state.activity,
          filters: action.payload.filters,
          page: 1,
        },
      };

    case "UPDATE_EVENTS":
      return {
        ...state,
        activity: {
          ...state.activity,
          events: action.payload.events,
          page: action.payload.page,
          total: action.payload.total,
        },
      };

    default:
      return state;
  }
};
