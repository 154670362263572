import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import {
  OK_INDEX,
  WARNING_INDEX,
  WIP_INDEX,
  CRITICAL_INDEX,
  IMPACT_INDEX,
  EXPIRED_INDEX,
} from "../../helpers/pinPostions";
import history from "../../helpers/history";
import getStatus from "../../helpers/statuses";
import MapContext from "../../contexts/Map";
import { toggleMap } from "../../actions/Map";
import { useWindowSize } from "../../hooks/window";
import { put } from "../../api";
import Button from "../Button";
import Loading from "../Loading";
import styles from "./Map.module.scss";
import NavContext from "../../contexts/Nav";
import { useMediaQuery } from "react-responsive";
import { useConfirm } from "material-ui-confirm";
import {
  SENTINEL_TMA,
  SENTINEL_MOBILE_TRACKER,
} from "../../constants/sentinel_types.js";
import Modal from "../../components/Modal";
import TextInput from "../../components/TextInput";
import Label from "../../components/Label";
import InputRow from "../../components/InputRow";
import "./Map.css";

const google = window.google;
const popoverTemplate = `

  <div class="map-popover">
    <label class="map-popover__label">Asset name</label>
    <div class="map-popover__name">{assetName}</div>

    <label class="map-popover__label">Status</label>
    <div class="map-popover__status">
      <span class="map-popover__icon map-popover__icon--{status} material-icons">{icon}</span>
      <span>{statusName}</span>
    </div>

  </div>
`;
const popoverTemplateNoGroup = `
  <div class="map-popover">
    <label class="map-popover__label">Asset name</label>
    <div class="map-popover__name">{assetName}</div>
    <label class="map-popover__label">Status</label>
    <div class="map-popover__status">
      <span class="map-popover__icon map-popover__icon--{status} material-icons">{icon}</span>
      <span>{statusName}</span>
    </div>
  </div>
`;

const defaultBonds = { lat: 10.7, lng: -10.8 };
const defaultBondsSmallMap = { lat: 0.0, lng: 10.2 };
let markers = [];

var desired_zoom_level = 17; //range 2-22

export default function Map({ customBackButton = false }) {
  const { height } = useWindowSize();
  const [state, dispatch] = useContext(MapContext);
  const { isNavShowing, setIsNavShowing } = useContext(NavContext);
  const [loading, setLoading] = useState(true);
  const [map, setMap] = useState(null);
  const [controlsChanged, setControlsChanged] = useState(false);
  const [streetView, setStreetView] = useState(false);
  const [bounds, setBounds] = useState(new google.maps.LatLngBounds());
  const [boundsChanged, setBoundsChanged] = useState(false);
  const zoomMapQuery3 = useMediaQuery({
    query:
      "(min-width: 768px) and (max-width: 1024px) and (orientation : portrait), (min-width: 1024px) and (max-width: 1366px)",
  });
  const zoomMapQuery2 = useMediaQuery({ query: "(max-width: 600px)" });
  const confirm = useConfirm();
  const [gpsModal, openGpsModal] = useState(false);
  const [bbModeModal, setbbModeModal] = useState(true);
  const [gpsUserPosition, setGpsUserPosition] = useState({});
  const [mapMovementDisabled, setMapMovementDisabled] = useState(false);
  useEffect(() => {
    zoomMapQuery2 &&
      window.sessionStorage.getItem("bbMode") === "true" &&
      window.sessionStorage.getItem("qrRedirect") != "true" &&
      history.push(`/assets`);
    const init = new google.maps.Map(document.getElementById("map"), {
      center:
        state.centerSmallMap === false
          ? new google.maps.LatLng(defaultBonds.lat, defaultBonds.lng)
          : new google.maps.LatLng(
              defaultBondsSmallMap.lat,
              defaultBondsSmallMap.lng
            ),
      zoom: 3,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      gestureHandling: "greedy",
      panControl: false,
      panControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      rotateControl: false,
      rotateControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      zoomControl: true,
      zoomControlOptions: {
        style: google.maps.ZoomControlStyle.SMALL,
      },

      mapTypeControl: true,
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.LEFT_TOP,
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        mapTypeIds: ["roadmap", "satellite"],
      },
      streetViewControl: true,
      streetViewControlOptions: {
        //  position: google.maps.ControlPosition.LEFT_TOP,
      },
    });

    const trafficLayer = new google.maps.TrafficLayer();
    trafficLayer.setMap(init);

    setMap(init);
    setLoading(false);

    setTimeout(function () {
      google.maps.event.addListener(init, "bounds_changed", function () {
        setBoundsChanged(true);
      });
      google.maps.event.addListener(init, "mouseover", () => {
        setControlsChanged(true);
      });
      google.maps.event.addListener(init, "mouseup", () => {
        setControlsChanged(true);
      });
    }, 1500);
  }, []);

  function getPinIcon(marker) {
    var status = getStatus(marker.status);
    if (marker.devicetypeid === SENTINEL_TMA) {
      switch (status.pin) {
        case "ok":
          return "/pins/tma_ok.png";
        case "warning":
          return "/pins/tma_warning.png";
        case "critical":
          return "/pins/tma_critical.png";
        case "impact":
          return "/pins/tma_impact.png";
        case "in_progress":
          return "/pins/tma_in_progress_shadow.png";
        default:
          break;
      }
    } else if (marker.devicetypeid === SENTINEL_MOBILE_TRACKER) {
      switch (status.pin) {
        case "ok":
          return "/pins/tracker_ok.png";
        case "warning":
          return "/pins/tracker_warning.png";
        case "critical":
          return "/pins/tracker_critical.png";
        case "impact":
          return "/pins/tracker_impact.png";
        case "in_progress":
          return "/pins/tracker_in_progress_shadow.png";
        default:
          break;
      }
    } else {
      switch (status.pin) {
        case "ok":
          return "/pins/ok.png";
        case "warning":
          return "/pins/warning.png";
        case "critical":
          return "/pins/critical.png";
        case "impact":
          return "/pins/impact.png";
        case "in_progress":
          return "/pins/in_progress_shadow.png";
        case "expired":
          return "/pins/expired.png";
        case "no_group":
          return "/pins/no_group.png";
        case "schedule":
          return "/pins/no_group.png";
        default:
          break;
      }
    }
  }

  google.maps.Map.prototype.clearOverlays = function () {
    for (var i = 0; i < markers.length; i++) {
      markers[i].setMap(null);
    }
    markers.length = 0;
  };
  function getZIndexValue(marker) {
    var status = getStatus(marker.status);
    switch (status.pin) {
      case "ok":
        return OK_INDEX;
      case "warning":
        return WARNING_INDEX;
      case "in_progress":
        return WIP_INDEX;
      case "impact":
        return IMPACT_INDEX;
      case "critical":
        return CRITICAL_INDEX;
      case "expired":
        return EXPIRED_INDEX;
      case "schedule":
        return OK_INDEX;
      default:
        break;
    }
  }
  useEffect(() => {
    if (map) {
      let points = [];
      var init_bounds = new google.maps.LatLngBounds();

      if (state.markers?.length) {
        map.clearOverlays();
        state?.markers
          .filter((x) => x.longitude !== 0)
          .map((marker) => {
            if (marker.longitude && marker.latitude) {
              if (marker.longitude !== 0 || marker.latitude !== 0) {
                const data = {
                  assetId: marker.assets && marker?.assets[0]?.id,
                  assetName: marker.assets
                    ? marker?.assets[0]?.name
                    : marker.serial_number,
                  longitude: marker.longitude,
                  latitude: marker.latitude,
                  lastUpdated: marker.status_updated_time,
                  status: marker.status,
                  devicetypeid: marker.devicetype_id,
                  groupName:
                    marker.assets &&
                    marker?.assets[0]?.groups &&
                    marker?.assets[0]?.groups.map((obj) => obj.name).join(", "),
                  primaryContact:
                    marker.assets &&
                    marker?.assets[0]?.groups &&
                    marker?.assets[0]?.groups
                      .map(
                        (obj) =>
                          obj.primary_contact &&
                          obj.primary_contact.first_name +
                            " " +
                            obj.primary_contact.last_name +
                            " " +
                            obj.primary_contact.phone
                      )
                      .join(", "),
                  // primaryContactNumber: marker.assets[0].groups && marker.assets[0].groups.map(obj => obj.primary_contact && obj.primary_contact.phone).join(', '),
                  //siteName: marker.assets[0].site?.name
                };

                var image = {
                  url: getPinIcon(data),
                  size:
                    marker.devicetype_id === SENTINEL_TMA ||
                    marker.devicetype_id === SENTINEL_MOBILE_TRACKER
                      ? marker.devicetype_id === SENTINEL_TMA
                        ? new google.maps.Size(44, 40)
                        : new google.maps.Size(30, 30)
                      : new google.maps.Size(25, 34),
                  origin: new google.maps.Point(0, 0),
                  anchor:
                    marker.devicetype_id === SENTINEL_TMA ||
                    marker.devicetype_id === SENTINEL_MOBILE_TRACKER
                      ? marker.devicetype_id === SENTINEL_TMA
                        ? new google.maps.Point(22, 40)
                        : new google.maps.Point(15, 15)
                      : new google.maps.Point(12, 34),
                };

                const timeAgo = moment(data.lastUpdated).fromNow();

                const infowindow = new google.maps.InfoWindow({
                  content: (marker.assets
                    ? popoverTemplate
                    : popoverTemplateNoGroup
                  )
                    .replace(/{assetName}/g, data.assetName)
                    .replace(/{lastUpdated}/g, timeAgo)
                    .replace(/{status}/g, data.status)
                    .replace(/{statusName}/g, getStatus(data.status).name)
                    .replace(/{icon}/g, getStatus(data.status).icon)
                    .replace(/{assetId}/g, data.assetId)
                    .replace(/{notificationGroup}/g, data.groupName)
                    .replace(/{primaryContact}/g, data.primaryContact),
                  //.replace(/{siteName}/g,data.siteName)
                  // .replace(/{phoneNumber}/g, '<div class="map-popover__name">' + data.primaryContactNumber + '</div>'),
                  closeBoxURL: "",
                });

                var marker = new google.maps.Marker({
                  position: { lat: data.latitude, lng: data.longitude },
                  map: map,
                  icon: image,
                  assetId: data?.assetId,
                  zIndex: parseInt(getZIndexValue(data)),
                });

                marker.addListener("click", () => {
                  if (!state.isolatedMarker) {
                    history.push(`/assets/${marker.assetId}`);
                  }
                });

                /*marker.addListener('rightclick', () => {
                openInNewTab(`/assets/${marker.assetId}`);
              });*/

                marker.addListener("mouseover", () => {
                  infowindow.open(map, marker);
                  //var x = document.getElementsByClassName('gm-ui-hover-effect')[0].remove();
                });
                marker.addListener("mouseout", () => {
                  infowindow.close(map, marker);
                });
                init_bounds.extend({ lat: data.latitude, lng: data.longitude });

                markers.push(marker);

                if (data.longitude && data.latitude) {
                  return points.push(marker);
                }
              }
            }
            return false;
          });

        if (points.length !== 0) {
          setBounds(init_bounds);
          setBoundsChanged(false);

          if (state.isolatedMarker) {
            var point = {
              lat: state.isolatedMarker[1],
              lng: state.isolatedMarker[0],
            };
            //map.setTilt(45);
            map.setCenter(point);
            map.setZoom(desired_zoom_level);
          } else if (state.markers.length) {
            //map.setTilt(45);
            map.fitBounds(init_bounds);
            map.setCenter(init_bounds.getCenter());
            var swLat = init_bounds.getSouthWest().lat();
            var swLng = init_bounds.getSouthWest().lng();
            var neLat = init_bounds.getNorthEast().lat();
            var neLng = init_bounds.getNorthEast().lng();
            var latDiff = Math.abs(swLat - neLat);
            var swDiff = Math.abs(swLng - neLng);
            var boundsThreshold = 75;

            if (latDiff > boundsThreshold || swDiff > boundsThreshold) {
              if (zoomMapQuery3) {
                map.setZoom(3);
              }
              if (zoomMapQuery2) {
                map.setZoom(2);
              }
            }
          } else {
            //empty map
            /* var point = { lat: 36.00, lng: -121.20};
             var marker = new google.maps.Marker({
               position: { lat: point.lat, lng: point.lng },
               map: map,

             });
             init_bounds.extend({ lat: point.lat, lng: point.lng });

             setBounds(init_bounds);
             setBoundsChanged(false);
             map.setTilt(45);
             map.setCenter(point);
   */
          }
        }
      }
    }
  }, [
    map,
    state.markers,
    state.isolatedMarker,
    state.displayGPSbutton,
    state.centerSmallMap,
    state.isMapShowing,
  ]);

  useEffect(() => {
    /*if (map && map.inst) {
      const bounds = map.inst.getCamera().bounds;

      setTimeout(() => {
        dispatch(updateBounds(bounds));
        map.inst.resize();
      }, 0);
    }*/
  }, [state.isMapShowing, height]);
  useEffect(() => {
    if ((!state.markers || !state.markers.length) && state.centerSmallMap) {
      map.center = new google.maps.LatLng(
        defaultBondsSmallMap.lat,
        defaultBondsSmallMap.lng
      );
      map.zoom = 2;
    }
  }, [state.centerSmallMap]);

  const showAllMarkers = () => {
    map.fitBounds(bounds);
    map.setCenter(bounds.getCenter());
  };

  const centerOnIsolatedMarker = () => {
    var point = { lat: state.isolatedMarker[1], lng: state.isolatedMarker[0] };
    map.setCenter(point);
    map.setZoom(desired_zoom_level);
    setBoundsChanged(false);
  };

  const centerOnEmptyMapSmall = () => {
    var point = { lat: 32.0, lng: -10.2 };
    map.setCenter(point);
    map.setZoom(desired_zoom_level);
    setBoundsChanged(false);
  };

  function _sendGpsRecalibrationRequest(id) {
    var res = put(`/devices/${id}/gps/1`);
    if (res) {
      confirm({
        description: "GPS will be recalibrated within 24 hours",
        title: null,
        dialogProps: { fullScreen: false },
        cancellationButtonProps: { color: "error", disabled: true },
        confirmationButtonProps: { color: "primary", disableRipple: true },
      })
        .then(() => {})
        .catch(() => {
          /* ... */
        });
    }
  }

  function openInNewTab(url) {
    const win = window.open(url, "_blank");
  }

  async function openStreetView() {
    if (streetView === false) {
      var point = {
        lat: state.isolatedMarker[1],
        lng: state.isolatedMarker[0],
      };
      const streetviewService = new google.maps.StreetViewService();
      await streetviewService.getPanorama(
        { location: point },
        (result, status) => {
          if (status === google.maps.StreetViewStatus.OK && result) {
            const panorama = new google.maps.StreetViewPanorama(
              document.getElementById("pano"),
              {
                position: point,
                panControl: false,
                linksControl: false,
              }
            );
            setStreetView(!streetView);
            map.setStreetView(panorama);
          } else {
            alert("Unable to load Street View. Select the location manually.");
            setStreetView(false);
            map.setStreetView(null);
          }
        }
      );
    } else {
      setStreetView(!streetView);
      map.setStreetView(null);
    }
  }

  function _renderFooter(gpsUserPosition) {
    return (
      <>
        <Button onClick={() => openGpsModal(false)}>Cancel</Button>
        <Button
          onClick={() => putGpsPosition()}
          theme="primary"
          disabled={
            !gpsUserPosition.latitude ||
            !gpsUserPosition.longitude ||
            longlatValidate(gpsUserPosition.latitude, "lat") ||
            longlatValidate(gpsUserPosition.longitude, "lon")
          }
        >
          Save
        </Button>
      </>
    );
  }

  async function putGpsPosition() {
    var res = await put(`/devices/${state.markers[0].id}/gpsposition`, {
      latitude: gpsUserPosition.latitude,
      longitude: gpsUserPosition.longitude,
    });

    if (res.ok) {
      window.location.reload();
    } else {
      confirm({
        description: "Something went wrong, Try again.",
        title: null,
        dialogProps: { fullScreen: false },
        cancellationButtonProps: { color: "error", disabled: true },
        confirmationButtonProps: { color: "primary", disableRipple: true },
      })
        .then(() => {})
        .catch(() => {
          /* ... */
        });
    }
  }

  function bbMode() {
    window.sessionStorage.setItem("bbMode", "true");
    setbbModeModal(false);
    history.push(`/assets`);
  }

  function desktopMode() {
    window.sessionStorage.setItem("bbMode", "false");
    setbbModeModal(false);
    history.push(`/assets`);
  }

  function longlatValidate(val, pos) {
    if (val) {
      if (pos === "lat") {
        return !val.match(
          /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/
        );
      }
      if (pos === "lon") {
        return !val.match(
          /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/
        );
      }
    } else {
      return null;
    }
  }

  return (
    <>
      <div id="pano" className={streetView === true ? styles.pano : ""}></div>

      {!loading &&
        state.markers.length == 1 &&
        state.markers[0] &&
        !(state.markers[0].longitude || state.markers[0].latitude) &&
        (state.markers[0].status === "OK" ||
          state.markers[0].status === "WORK_IN_PROGRESS" ||
          state.markers[0].status === "IMPACT") && (
          <>
            {gpsModal && (
              <Modal small footer={_renderFooter(gpsUserPosition)}>
                <InputRow>
                  <Label text="Latitude">
                    <TextInput
                      placeholder="eg. 38.907192"
                      error={
                        longlatValidate(gpsUserPosition.latitude, "lat") &&
                        "Enter a valid latitude."
                      }
                      value={gpsUserPosition.latitude}
                      onChange={(e) => {
                        setGpsUserPosition({
                          ...gpsUserPosition,
                          latitude: e.target.value,
                        });
                      }}
                    />
                  </Label>
                  <Label text="Longitude">
                    <TextInput
                      error={
                        longlatValidate(gpsUserPosition.longitude, "lon") &&
                        "Enter a valid longitude."
                      }
                      value={gpsUserPosition.longitude}
                      placeholder="eg. -77.036873"
                      onChange={(e) => {
                        setGpsUserPosition({
                          ...gpsUserPosition,
                          longitude: e.target.value,
                        });
                      }}
                    />
                  </Label>
                </InputRow>
              </Modal>
            )}

            <div className={styles.mapNoGpsOverlay}>
              <div className={styles.mapNoGpsOverlayBanner}>
                <div className={styles.mapNoGpsOverlayText}>
                  GPS is being recalibrated
                  <div className={styles.coordinatesButtonContainer}>
                    <Button
                      onClick={() => openGpsModal(true)}
                      theme="primary"
                      icon="add"
                    >
                      Add coordinates
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

      <div
        id="map"
        className={streetView === true ? styles.map : styles.mapFull}
      >
        {" "}
      </div>

      {bbModeModal &&
        zoomMapQuery2 &&
        !window.sessionStorage.getItem("bbMode") && (
          <Modal simpleMode>
            <span className={styles.simpleModeContainer}>
              <Button
                icon="directions_car"
                onClick={() => {
                  bbMode();
                }}
              >
                Driving mode
              </Button>
              <Button icon={"space_dashboard"} onClick={() => desktopMode()}>
                Dashboard mode
              </Button>
            </span>
          </Modal>
        )}

      {!loading && state.markers[0] && (
        <div className={styles.bottom}>
          {state.isolatedMarker && (
            <Button
              margin={8}
              icon="room"
              width="100"
              onClick={() => _sendGpsRecalibrationRequest(state.markers[0].id)}
            >
              Recalibrate GPS
            </Button>
          )}
          {state.isolatedMarker &&
            state.markers[0].longitude &&
            state.markers[0].latitude &&
            state.markers[0] && (
              <Button
                margin={8}
                icon="streetview"
                onClick={() => openStreetView()}
              >
                Street View
              </Button>
            )}
          {state.isolatedMarker
            ? boundsChanged === true && (
                <Button
                  icon="pin_drop"
                  onClick={() => centerOnIsolatedMarker()}
                >
                  Center on asset
                </Button>
              )
            : state.markers[0] && (
                <Button icon="zoom_out_map" onClick={() => showAllMarkers()}>
                  View all
                </Button>
              )}
        </div>
      )}

      {customBackButton === false ? (
        <div
          className={[styles.hideButton, styles.backButtonContainer].join(" ")}
        >
          <Button icon="arrow_back" onClick={() => dispatch(toggleMap())}>
            Back
          </Button>
        </div>
      ) : null}
      <div className={[styles.hideButton, styles.menu].join(" ")}>
        <Button icon="menu" onClick={() => setIsNavShowing(!isNavShowing)}>
          Menu
        </Button>
      </div>
      {loading && (
        <div className={styles.loading}>
          <Loading color="gray" size={36} />
        </div>
      )}
    </>
  );
}
