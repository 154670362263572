import React, { useEffect, useState, useContext } from "react";
import { get, post } from "../../api";
import { AuthContext } from "../../contexts/Auth";
import TypesContext from "../../contexts/Types";
import history from '../../helpers/history';
import Modal from "../../components/Modal";
import Frame from "../../components/Frame";
import FrameHeader from "../../components/FrameHeader";
import Space from "../../components/Space";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import Select from "../../components/Select";
import moment from "moment";
import queryString from "query-string";
import Label from "../../components/Label";
import InputRow from "../../components/InputRow";
import LoadingFrame from "../../components/LoadingFrame";

export default function SubscriptionAdd({ match: { params }, location: { search } }) {


  const { state: currentUser } = useContext(AuthContext);
  const queryParams = queryString.parse(search);

  const [loading, setLoading] = useState(false);
  const [subscription, setSubscription] = useState({
    license_count: 0,
    duration: 0,
    customer_id: params.id,
    additional_info: "",
    payment_reference: "",
    active: false
  });
  function validated() { return !(subscription.duration == 0 || subscription.license_count == 0); }


  async function createSubscription() {

    const res = await post(`/subscriptions`, subscription);
    res && history.push(`/customers/${params.id}/subscriptions/`);
  }

  function _renderFooter() {
    return (
      <>
        <Button theme="secondary"
          onClick={() => history.push(`/customers/${params.id}/subscriptions/`)}>
          Cancel
        </Button>
        <Button
          disabled={!validated()}
          theme="primary"
          onClick={() => createSubscription()}
        >
          Save
        </Button>
      </>

    );
  }


  return (

    <Modal footer={_renderFooter()} >
      {loading ? <LoadingFrame /> : (
        subscription && (<>
          <FrameHeader title="Subscription details" />
          <Frame>

            <InputRow>
              <Label text="License Count" theme="light">
                <TextInput
                  value={subscription.license_count}
                  placeholder={"Enter number info"}
                  onChange={e => {
                    setSubscription({ ...subscription, license_count: e.target.value })
                  }}
                />

              </Label>
              <Label text="Duration (in days)" theme="light">
                <TextInput
                  value={subscription.duration}
                  onChange={e => {
                    setSubscription({ ...subscription, duration: e.target.value })
                  }}
                />
              </Label>
            </InputRow>

            <InputRow>
              <Label text="Additional Info" theme="light">
                <TextInput
                  value={subscription.additional_info}
                  placeholder={"Enter additional info"}
                  onChange={e => {
                    setSubscription({ ...subscription, additional_info: e.target.value })
                  }}
                />
              </Label>
              <Label text="Payment Reference" theme="light">
                <TextInput
                  value={subscription.payment_reference}
                  placeholder={"Enter Payment Reference"}
                  onChange={e => {
                    setSubscription({ ...subscription, payment_reference: e.target.value })
                  }}
                />
              </Label>
              <Label text="Active">
                <Select
                  onChange={e => setSubscription({ ...subscription, active: e.target.value })}
                  value={subscription.active}
                >
                  <option value={false}>Inactive</option>
                  <option value={true}>Active</option>
                </Select>
              </Label>
            </InputRow>
          </Frame>
        </>)
      )}
    </Modal>
  );

}
