export default {
  id: "",
  country: "",
  address: "",
  address2: "",
  city: "",
  email: "",
  name: "",
  phone: "",
  state: "",
  title: "",
  tenant_id: "",
  zip: ""
};
