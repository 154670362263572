import React from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Icon from '../Icon';
import Loading from '../Loading';
import { useMediaQuery } from 'react-responsive'
import styles from './Breadcrumb.module.scss';

export default function Breadcrumb({ tooltip = false, title, to,warning=false }) {

  function _title() {

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
    if (tooltip === true && isMobile === true) { tooltip = title } else { tooltip = null }

    return (
      <h3 className={warning ? styles.red: styles.title} data-tip={tooltip}>
        {title ? returnProperLenghtTitle() : <Loading color="gray" size={18} />}
        {tooltip && (
          <ReactTooltip
            place="bottom"
            multiline={true}
            className={`${styles.tooltip} ${styles.deviceId}`}
            overridePosition={({ left, top }, _e, _t, node) => { return { top, left: typeof node === 'string' ? left : Math.max(left, 8), }; }}
          />)}
      </h3>

    );
  }

  const returnProperLenghtTitle=()=>{
    var maxlimit = 53;
      if((title).length > maxlimit){
        return (title).substring(0,maxlimit-3) + '...';
      }
      return title;
    }

  function _segment() {
    return (
      <div className={styles.breadcrumb + ' breadcrumb'}>
        {to ? (
          <Link className={styles.link} to={to}>
            {_title()}
          </Link>
        ) : (
          _title()
        )}

        <div className={styles.separator}>
          <Icon name="chevron_right" size={24} />
        </div>
      </div>
    );
  }

  return _segment();
}
