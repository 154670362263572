export const initialDeviceState = {
  device: {},
  asset: {},
  installPhoto: {},
  relation: {},
  loading: 0,

  activity: {
    events: [],
    loading: true,
    total: 0,
    count: 0,
    page: 1,
    filters: {
      ALARM: true,
      ALARM_ACKNOWLEDGED: false,
      DEVICE_CREATED: true,
      DEVICE_ASSIGNED: true,
      DEVICE_UNASSIGNED: true,
      DEVICE_UPDATED: false,
      DEVICE_NOTE: true,
      ASSET_FILE_ADD: true,
      FALSE_ALERT:true,
      DEVICE_REPAIRED:true,
    }
  }
};

export const DeviceReducer = (state, action) => {
  switch (action.type) {
    case 'INCREMENT_LOADING':
      return { ...state, loading: state.loading + 1 };

    case 'DECREMENT_LOADING':
      return { ...state, loading: state.loading - 1 };

    case 'UPDATE_DEVICE':
      return { ...state, device: action.payload.device };

    case 'UPDATE_DEVICE_SILENT':
      return { ...state, device: action.payload.device };

    case 'UPDATE_ASSET':
      return { ...state, asset: action.payload.asset };

    case 'UPDATE_RELATION':
      return { ...state, relation: action.payload.relation };

    case 'REMOVE_RELATION':
      return { ...state, relation: {}, asset: {} };

    case 'UPDATE_INSTALL_PHOTO':
      return { ...state, installPhoto: action.payload.installPhoto };

    case 'UPDATE_ACTIVITY_LOADING':
      return {
        ...state,
        activity: { ...state.activity, loading: action.payload.loading }
      };

    case 'UPDATE_ACTIVITY_PAGE':
      return {
        ...state,
        activity: { ...state.activity, page: action.payload.page }
      };

    case 'UPDATE_ACTIVITY_FILTERS':
      return {
        ...state,
        activity: {
          ...state.activity,
          filters: action.payload.filters,
          page: 1
        }
      };

    case 'UPDATE_EVENTS':
      return {
        ...state,
        activity: {
          ...state.activity,
          events: action.payload.events,
          page: action.payload.page,
          total: action.payload.total
        }
      };

    default:
      return state;
  }
};
