import React from "react";
import { destroy } from '../../api';
import Button from "../Button";
import Space from "../Space";
import Modal from "../Modal";
import styles from './InstallOnAssetStepLicense.module.scss';
import Icon from "../Icon";
import history from "../../helpers/history";
export default function InstallOnAssetStepLicense({ back, next, subscriptions, selected_sub_id, setSelectedSub, assetId }) {


  if (subscriptions.length !== 0) {
    setSelectedSub(subscriptions[0].id)
  }

  function filterSubs(input) {
    const uniqueYears = [];
    if (input !== undefined && input.length > 0) {
      input.map(sub => {
        if (uniqueYears.filter(x => x.duration !== sub.duration)) {
          uniqueYears.push(sub)
        }
      });
    }
    return uniqueYears;
  }

  function _renderFooter() {
    return (
      <>
        <Button onClick={back}>Back</Button>
        <Button theme="primary" onClick={next} disabled={selected_sub_id == 0}>
          Next
        </Button>
      </>
    );
  }

  async function rocAndGoToSub() {
    await destroy(`/assets/${assetId}`);
    history.push("/customer/subscriptions/new");
  }


  function _buyLicenseContent() {

    return (<div className={styles.buyNew}>
      <div className={styles.wrap}>
        <div className={styles.error}>
          <Icon name="error" size={64} />
        </div>
        <h1>No subscription found</h1>
      </div>
      <p className={styles.body}>Click below to purchase a new subscription.</p>
      <div style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <Button theme="primary" icon="add" onClick={() => rocAndGoToSub()}>
          Buy subscription
        </Button>
      
      </div>
    </div>)
  }

  return (
    <Modal footer={_renderFooter()}>

      <Space size="s">
        <h2>Step 2: Subscription</h2>
      </Space>

      {subscriptions ? (
        subscriptions.length > 0 ? (
          <>
            <Space size="s">
              <p></p>
            </Space>
            <div className={styles.wrap}>
              <div className={styles.icon}>
                <Icon name="check_circle" size={64} />
              </div>
              <h1>Subscription found</h1>
            </div>
            <p className={styles.body}>Let's proceed to the next step.</p>
          </>) : (_buyLicenseContent())
      ) : (_buyLicenseContent())}
    
    </Modal>
  );
}
