import React from 'react';
import styles from './CheckboxWithLabel.module.scss';
import Checkbox from '../Checkbox';
import Loading from '../Loading';
export default function CheckboxWithLabel({
  label,
  description,
  disabled,
  fullwidth=false,
  hideFromReport,
  loading=false,
  ...checkboxProps
}) {
  return (
    <label className={fullwidth == false ? (styles.checkboxWithLabel):(hideFromReport? [styles.checkboxWithLabelFullwidth, styles.hideFromReport].join(' ') : styles.checkboxWithLabelFullwidth )}>
      <div className={hideFromReport? [styles.row, styles.hideFromReport].join(' ') : styles.row}>
        {loading ? (<Loading color='gray'/>):(<Checkbox {...checkboxProps} disabled={disabled} hideFromReport={hideFromReport}/>)}
        {label && <span className={hideFromReport? [styles.label, styles.hideFromReport].join(' ') : styles.label}>{label}</span>}
      </div>
      {description && <span className={styles.description}>{description}</span>}
    </label>
  );
}
