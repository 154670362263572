import React from 'react';
import { Link } from 'react-router-dom';
import styles from './RowItem.module.scss';

export default function RowItem({
  title,
  subtitle,
  to,
  rightspace = false,
  left,
  right,
  size = 'm',
  mobile = false,
  bbMode
}) {
  const row = (

    <>
      {left && <div className={styles.left}>{left}</div>}

      <div className={styles.middle}>
        {title && <div className={styles.title}>{title}</div>}
        {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        {right && <div className={styles.rightMobile}>{right}</div>}
      </div>

      {right && <div className={styles.right}>{right}</div>}

    </>
  );

  const classes = mobile === true ? 
  (bbMode? ([styles.bbMode, styles.mobileView, styles.row, styles[`size--${size}`]].join(' ')) : ([styles.mobileView, styles.row, styles[`size--${size}`]].join(' ')))
  : 
  ([styles.row, styles[`size--${size}`]].join(' '));

  return to ? (

    rightspace == false ? (
      <Link to={to} className={classes}>
        {row}
      </Link>
    )
      :
      (
        <div className={styles.multiwrap}>
          <a href={to} className={styles.row2}>
            {left && <div className={styles.left}>{left}</div>}

            <div>
              {title && <div className={styles.title}>{title}</div>}
              {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
              {right && <div className={styles.rightMobile}>{right}</div>}
            </div>
          </a>
          {right && <div className={styles.rightnopad}>{right}</div>}
        </div>
      )

  ) : (<div className={classes}>{row}</div>)
    ;
}
