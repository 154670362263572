import React, { useState } from "react";

import { post, upload } from "../../api";

import Modal from "../Modal";
import Button from "../Button";
import FileUploader from "../FileUploader";
import FileUploadProgress from "../FileUploadProgress";
import Label from "../Label";
import Space from "../Space";

import styles from "./InstallDeviceStep3.module.scss";

export default function InstallDeviceStep3({
  back,
  selectedAsset,
  deviceId,
  next,
  processRelation,
  switch_owner=false,
  step=false,
  auto_create_license_for_new_device=false,
}) {
  const [saving, setSaving] = useState(false);
  const [attachments, setAttachments] = useState([]);

  async function _finish() {
    setSaving(true);
   
    if (selectedAsset !== undefined && selectedAsset!=null) {
      
      let assetId;
      if (typeof selectedAsset === "object") {
        assetId = selectedAsset.id;
      } else {
        assetId = selectedAsset;
      }
      
      const relation = await post("/relations", {
        from_type: "ASSET",
        to_type: "DEVICE",
        from_id: selectedAsset.id,
        to_id: deviceId,
        switch_owner: switch_owner,
        auto_license: auto_create_license_for_new_device
      });

      let fileReqs = [];
      if (relation) {
        
        attachments.forEach(file => {
          let assetId;
          const formData = new FormData();

          if (typeof selectedAsset === "object") {
            assetId = selectedAsset.id;
          } else {
            assetId = selectedAsset;
          }

          formData.set("entity_type", "ASSET");
          formData.set("entity_id", assetId);
          formData.set("type", "IMAGE");
          formData.append("file", file);
         
          const res = upload(formData);
          fileReqs.push(res);
        });

        if (fileReqs.length > 0) {
          
          await Promise.all(fileReqs);
        }
       
        next(relation);
      }
      
    }
    else {
      processRelation({
        files: attachments, relation: {
          from_type: "ASSET",
          to_type: "DEVICE",
          from_id: 0,
          to_id: deviceId,
          switch_owner: switch_owner,
          auto_license: auto_create_license_for_new_device
        }
      })
    }
  }

 
  function _renderFooter() {
    return (
      <>
       {back && <Button  onClick={back}  disabled={saving}>Back</Button>}
        <Button
          theme="primary"
          onClick={_finish}
          isLoading={saving}
          disabled={saving}
        >
          Next
        </Button>
      </>
    );
  }
  function _removeFile(i) {

    if (attachments != undefined) {
      const newAttachments = attachments
        .slice(0, i)
        .concat(attachments.slice(i + 1, attachments.length));

      setAttachments(newAttachments);
    
    } 
  }
  function saveFile(attachment) {
    if (attachment != undefined) {
      const isArray = Array.isArray(attachment);
      if (isArray) {
        var firstElement = attachment[0];
        if (firstElement !== undefined && firstElement.name !== undefined) {
          setAttachments([...attachments, ...attachment])
        } else {
          setAttachments([...attachments, ...firstElement])
        }
      } else {
        var newArray = [attachment]
        setAttachments([...attachments, ...newArray])
      }
    } else {
      alert('File saving error!');
    }
  }
  return (
    <Modal  onTop={true} footer={_renderFooter()}>
      <Space size="l">
        <h2>Step {step==false ? 5 : 4}: Upload photos of the installation</h2>
      </Space>

      <Space size="m">
        <div className={styles.examplesWrap}>
          <div className={styles.examples}>
            <div className={styles.example}>
              <img src="/img/sample_att_1.png" alt="" />
            </div>

            <div className={styles.example}>
              <img src="/img/sample_att_2.png" alt="" />
            </div>
          </div>
        </div>
      </Space>

      <Space size="m">
        <p style={{ textAlign: "center" }}>
          You're almost there! The final step is to take photos of the device installed on the asset.<br />
          You can upload photos now, or choose to upload them later.
        </p>
      </Space>

      <Space size="m">
        <Label text="Installation photos" theme="light" />
        <Space size="m">
          <FileUploader
            onSelect={newFiles => saveFile([newFiles])}
          />
        </Space>

        <FileUploadProgress
          files={attachments}
          uploading={saving}
          onRemove={i => _removeFile(i)}
        />
      </Space>
    </Modal>
  );
}
