import React from 'react';
import Center from '../Center';
import Space from '../Space';
import Logo from '../Logo';
import styles from './SignupFrame.module.scss';

export default function SignupFrame({
  children,
  heading,
  subHeading
}) {
  return (
    <Center>
      <main className={styles.frameWrap}>
        <div className={styles.frame}>
          <Space size="m">
            <div className={styles.logoWrap}>
              <Logo size="200" />
            </div>
          </Space>
          {(heading || subHeading) && (
            <Space size="m">
              <h2 className={styles.heading}>{heading}</h2>
              <p>{subHeading}</p>
            </Space>
          )}
          {children}
        </div>
      </main>
    </Center>
  );
}
