import { get, destroy } from '../api';

export const fetchDevice = id => async dispatch => {
  dispatch({ type: 'INCREMENT_LOADING' });

  const { asset, device } = await _fetchDevice(id);
  if (asset != null && asset != undefined) {
    dispatch({ type: 'UPDATE_DEVICE', payload: { device } });
    dispatch({ type: 'UPDATE_ASSET', payload: { asset } });

    const installPhoto = await _fetchInstallPhoto(asset.id);
    dispatch({ type: 'UPDATE_INSTALL_PHOTO', payload: { installPhoto } });

    dispatch({ type: 'DECREMENT_LOADING' });
  }
};
export const fetchDeviceOnly = id => async dispatch => {
  // dispatch({ type: 'INCREMENT_LOADING' });
  const { asset, device } = await _fetchDevice(id);
  dispatch({ type: 'UPDATE_DEVICE_SILENT', payload: { device } });
 
};
export const fetchRelation = (assetId, deviceId) => async dispatch => {
  dispatch({ type: 'INCREMENT_LOADING' });

  const res = await get(`/relations`, {
    from_type: 'ASSET',
    from_id: assetId,
    to_type: 'DEVICE',
    to_id: deviceId
  });

  const relation = res && res.results && res.results.length && res.results[0];

  dispatch({ type: 'UPDATE_RELATION', payload: { relation } });
  dispatch({ type: 'DECREMENT_LOADING' });
};

export const removeRelation = (deviceId, relationId) => async dispatch => {
  dispatch({ type: 'INCREMENT_LOADING' });

  await destroy(`/relations/${relationId}`);
  const { device } = await _fetchDevice(deviceId);
  if (device != null && device != undefined) {
    dispatch({ type: 'UPDATE_DEVICE', payload: { device } });
    dispatch({ type: 'UPDATE_ASSET', payload: { asset: {} } });
    dispatch({ type: 'REMOVE_RELATION' });
    dispatch({ type: 'DECREMENT_LOADING' });
  }
};

export const updateRelation = (deviceId, relation) => async dispatch => {
  dispatch({ type: 'INCREMENT_LOADING' });
  dispatch({ type: 'UPDATE_RELATION', payload: { relation } });

  const { device, asset } = await _fetchDevice(deviceId);
  if (device != null && device != undefined) {
    dispatch({ type: 'UPDATE_DEVICE', payload: { device } });
    dispatch({ type: 'UPDATE_ASSET', payload: { asset } });

    const installPhoto = await _fetchInstallPhoto(asset.id);
    dispatch({ type: 'UPDATE_INSTALL_PHOTO', payload: { installPhoto } });

    dispatch({ type: 'DECREMENT_LOADING' });
  }
};

export const fetchActivity = (deviceId, filters, page) => async dispatch => {
  dispatch({ type: 'UPDATE_ACTIVITY_LOADING', payload: { loading: true } });

  const types = Object.entries(filters)
    .map(f => f[1] && f[0])
    .filter(Boolean)
    .join(',');

  const res = await get(`/devices/events`, {
    device_id: deviceId,
    include_files: '1',
    types,
    orderBy: '-created_time',
    take: 10,
    skip: (page - 1) * 10,
    include: 'COUNT(*)'
  });

  dispatch({
    type: 'UPDATE_EVENTS',
    payload: { events: res.results, page, total: res.total }
  });

  dispatch({ type: 'UPDATE_ACTIVITY_LOADING', payload: { loading: false } });
};

export const updateActivityPage = page => dispatch => {
  dispatch({ type: 'UPDATE_ACTIVITY_PAGE', payload: { page } });
};

export const updateActivityFilters = filters => dispatch => {
  dispatch({ type: 'UPDATE_ACTIVITY_FILTERS', payload: { filters } });
};

/** Private */
/** --------------------------------------------------------------- */

async function _fetchDevice(id) {
  const res = await get(`/devices/${id}`, { include_assets: '1' });
  if (res != null && res != undefined) {
    const { assets, ...device } = res;
    const asset = assets && assets.length > 0 && assets[0];

    return { device, asset };
  } else {
    return { undefined, undefined };
  }
}

async function _fetchInstallPhoto(id) {
  const files = await get(`/files`, {
    entity_type: 'ASSET',
    entity_id: id,
    take: 1,
    orderBy: '-created_time'
  });

  return files.results.length && files.results[0];
}
