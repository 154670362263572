import React from 'react';
import { NavLink } from 'react-router-dom';

import history from '../../helpers/history';

import FrameHeader from '../../components/FrameHeader';
import Tabs from '../../components/Tabs';
import Select from '../../components/Select';

import styles from './SubNavigation.module.scss';

export default function SubNavigation({ items }) {
  const navigate = e => {
    history.push(e.target.value);
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.mobile}>
        <FrameHeader title="Navigation" />
        <Select
          value={history.location.pathname}
          onChange={navigate}
          role="navigation"
        >
          {items.map(n => (
            <option key={n.to} value={n.to}>
              {n.name}
            </option>
          ))}
        </Select>
      </div>
      <div className={styles.desktop}>
        <Tabs>
          {items.map(n => (
            <NavLink key={n.to} to={n.to} exact>
              {n.name}
            </NavLink>
          ))}
        </Tabs>
      </div>
    </div>
  );
}
