import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Tenants from '../Tenants';
import Tenant from '../Tenant';
import Invite from '../InviteTenant';

// import styles from './TenantsIndex.module.scss';

export default function TenantsIndex() {
  return (
    <>
      <Switch>
        <Route path="/tenants" component={Tenants} exact />
        <Route path="/tenants/invite" component={Invite} />
        <Route path="/tenants/:id" component={Tenant} />
      </Switch>
    </>
  );
}
