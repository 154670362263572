import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AuthContext } from '../../contexts/Auth';
import { CUSTOMER_USER } from '../../constants/roles';
import { isAuthorized } from '../../helpers/authorization';
import { humanUserRole } from '../../helpers/user';

import Badge from '../../components/Badge';
import Breadcrumb from '../../components/Breadcrumb';
import Button from '../../components/Button';
import CollectionListing from '../../components/CollectionListing';
import FrameHeader from '../../components/FrameHeader';
import RowItem from '../../components/RowItem';
import SingleColumnPage from '../../components/SingleColumnPage';
import Avatar from '../../components/Avatar';

// import styles from './Users.module.scss';

export default function Users() {
  const { state } = useContext(AuthContext);
  const breadcrumbs = () => <Breadcrumb title="Users" />;

  return (
    <SingleColumnPage breadcrumbs={breadcrumbs}>
      <FrameHeader title="Users">
        {isAuthorized(state.user.roles, [CUSTOMER_USER]) && (
          <Link to="/users/new">
            <Button icon="add" theme="primary">
              Add User
            </Button>
          </Link>
        )}
      </FrameHeader>
      <CollectionListing
        collection="users"
        params={{ include_groups: 1 }}
        queryKey="email"
        item={u => (
          <RowItem
            key={u.id}
            left={<Avatar name={`${u.first_name} ${u.last_name}`} />}
            right={<Badge text={humanUserRole(u.authority)} />}
            title={`${u.first_name} ${u.last_name}`}
            subtitle={u.customer && u.customer.name}
            to={`/users/${u.id}`}
          />
        )}
        /*sortOptions={
          <>
           
            <option value="first_name">First name</option>
            <option value="last_name">Last name</option>
            <option value="email">Email</option>
          </>
        }*/
      />
    </SingleColumnPage>
  );
}
