import React from 'react';
import styles from './Loading.module.scss';

export default function Loading({ size = 24, color = 'light' }) {
  return (
    <div className={styles.loading} style={{ width: size, height: size }}>
      <svg className={styles.svg} viewBox="25 25 50 50">
        <circle
          className={styles.path + ' ' + styles[color]}
          cx="50"
          cy="50"
          r="20"
          fill="none"
          strokeWidth="6"
          strokeMiterlimit="10"
        />
      </svg>
    </div>
  );
}
