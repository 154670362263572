import React from 'react';
import styles from './Label.module.scss';
import Icon from '../Icon';
import ReactTooltip from 'react-tooltip';
export default function Label({ text, children, icon, theme, style, className,required = false ,tooltip}) {
  const classes = [styles.label, styles[theme]].join(' ');

  return (<>
    <label style={{ ...style }} className={className} >
      <span className={classes} data-tip={tooltip}>{text} {required && <p className={styles.red}> *</p>} {tooltip && <div className={styles.tooltipItemWrapper}><Icon name={"info_outline"} size={20} /></div>}</span>
     
      <div className={styles.value}>
        {icon && (
          <span className={styles.icon}>
            <Icon name={icon} />
          </span>
        )}
        {children}
      </div>
      
    </label>
     <ReactTooltip 
     multiline={true} 
     className={styles.tooltip}
     overridePosition={({ left, top }, _e, _t, node) => { return { top, left: typeof node === 'string' ? left : Math.max(left, 8), }; }}
     />
     </>
  );
}
