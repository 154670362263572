import React, { useEffect, useState } from 'react';
import {
  normalizePermissions,
  denormalizePermissions
} from '../../helpers/user';
import { get, put, post, destroy } from '../../api';
import UserInfoForm from '../../components/UserInfoForm';
import FrameHeader from '../../components/FrameHeader';
import LoadingFrame from '../../components/LoadingFrame';

export default function UserUserInfo({ match: { params } }) {
  const [user, setUser] = useState(null);
  const [groups, setGroups] = useState([]);
  const [permissions, setPermissions] = useState({});
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  async function fetchData() {
    const { permissions } = await get(`/users/${params.id}/permissions`);
    const user = await get(`/users/${params.id}`);
    user.groups = await get(`/users/${params.id}/groups`);
    const groups = await get(`/groups`, {
      orderBy: '-created_time',
      customer_id: user.customer_id,
      include_users: '1'
    });
    var notPartOfGroup = [];
    if (groups !== null && groups.results !== null) {
      groups.results.map(group => {
        const isObjectPresent = user.groups.find((o) => o.id === group.id);
        if (!isObjectPresent) {
          notPartOfGroup.push(group);
        }
      })
    }
    if (notPartOfGroup && notPartOfGroup.length > 0) {
      notPartOfGroup = notPartOfGroup.slice().sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    }
    setGroups(notPartOfGroup);
    setPermissions(denormalizePermissions(permissions));
    setUser(user);
    setLoading(false);
  }
  useEffect(() => {
    fetchData();
  }, []);

  const updateUserGroup = async (userId, groupId) => {
    setLoading(true);
    const [userGroups] = await Promise.all([
      post('/relations', {
        from_id: groupId,
        from_type: 'GROUP',
        to_id: userId,
        to_type: 'USER',
        relation_type_group: 'COMMON'
      })
    ]);
  };

  async function removeUserFromGroup(userId, groupId) {
    setLoading(true);
    const relation = await get('/relations', {
      from_id: groupId,
      from_type: 'GROUP',
      to_id: userId,
      to_type: 'USER'
    });

    if (relation && relation.results && relation.results.length) {
      const toDelete = relation.results[0].id;
      await destroy(`/relations/${toDelete}`);
    }
  }


  const updateUser = async (user, perms) => {
    setSaving(true);
    setPermissions(perms);
    const [userReq /*, permsReq */] = await Promise.all([
      put(`/users/${user.id}`, { ...user }),
      put(`/users/${user.id}/permissions`, {
        permissions: normalizePermissions(perms)
      })
    ]);
    fetchData();
    setSaving(false);
  };

  if (loading) return <LoadingFrame />;

  return (
    <>
      <FrameHeader title="Info" />
      <UserInfoForm
        state={user}
        groups={groups}
        permissions={permissions}
        saving={saving}
        onSave={updateUser}
        onUpdateUserGroup={updateUserGroup}
        onRemoveUserGroup={removeUserFromGroup}
      />
    </>
  );
}
