import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './routes/App';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import history from './helpers/history';
import { ConfirmProvider } from 'material-ui-confirm';

var reactPlugin = new ReactPlugin();
let appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APPLICATION_INSIGHTS_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { debug: false, history: history }
    }
  }
});

appInsights.loadAppInsights();

ReactDOM.render( <ConfirmProvider><App /></ConfirmProvider>, document.getElementById('root'));
