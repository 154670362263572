import React, { useEffect, useState,useContext } from 'react';
import moment from 'moment';
import { get } from '../../api';
import FrameHeader from '../../components/FrameHeader';
import Frame from '../../components/Frame';
import RowItem from '../../components/RowItem';
import LoadingFrame from '../../components/LoadingFrame';
import Space from '../../components/Space';
import styles from './CustomerDevices.module.scss';
import { SYS_ADMIN, TENANT_ADMIN } from '../../constants/roles';
import { isAuthorized } from "../../helpers/authorization";
import { AuthContext } from "../../contexts/Auth";
export default function CustomerDevices({ match: { params }, admin = false }) {
  const [loading, setLoading] = useState(true);
  const { state: currentUser } = useContext(AuthContext);
  const [activeDevices, setActiveDevices] = useState([]);
  const [activeCount, setActiveCount] = useState(0);
  const [inactiveDevices, setInactiveDevices] = useState([]);
  const [inactiveCount, setInactiveCount] = useState(0);
  useEffect(() => {
    var isApiSubscribed = true;
    async function fetchDevices() {
      const finalParams = params.params !== undefined ? params.params : params;
      const [active, inactive] = await Promise.all([
        get(`/deviceslist`, {
          customer_id: finalParams.id,
          active: true,
        }),

        get(`/deviceslist`, {
          customer_id: finalParams.id,
          active: false,
        })
      ]);

      if (isApiSubscribed) {
        setActiveDevices(active);
        setActiveCount(active.length);
        setInactiveDevices(inactive);
        setInactiveCount(inactive.length);
        setLoading(false);
      }
    }
    fetchDevices();

    return () => {
      isApiSubscribed = false;
    };
  }, [admin]);

  return loading ? (
    <LoadingFrame />
  ) : (
      <>
        <Space size="l">
          <FrameHeader title={`Active devices (${activeCount})`} />
          <Frame padding={0}>
            {activeDevices.length ? (
              activeDevices.map(device => (
                <RowItem
                  key={device.id}
                  title={device.name}
                  //subtitle={subtitle(device.created_time)}
                  to={(isAuthorized(currentUser.user.roles, [SYS_ADMIN, TENANT_ADMIN]) || admin===true)  && `/sentineldevice/${device.id}`}
                />
              ))
            ) : (
                <div className={styles.empty}>No active devices</div>
              )}
          </Frame>
        </Space>
        <FrameHeader title={`Inactive devices (${inactiveCount})`} />
        <Frame padding={0}>
          {inactiveDevices.length ? (
            inactiveDevices.map(device => (
              <RowItem
                key={device.id}
                title={device.name}
                //subtitle={subtitle(device.created_time)}
                to={(isAuthorized(currentUser.user.roles, [SYS_ADMIN, TENANT_ADMIN]) || admin===true) && `/sentineldevice/${device.id}`}
                //right={admin === true && <Button theme="primary" onClick={() => alert('remove')}>Remove</Button>}
              //to={`/devices/${device.id}`}
              />
            ))
          ) : (
              <div className={styles.empty}>No inactive devices</div>
            )}
        </Frame>
      </>
    );
}
