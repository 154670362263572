import React from 'react';
import styles from './Button.module.scss';

import Loading from '../Loading';
import Icon from '../Icon';

export default function Button({
  isLoading,
  theme,
  size,
  icon,
  color,
  children,
  alt,
  transparent = false,
  fixedWidth,
  noHeight,
  centerNoFlex = false,
  align = 'center',
  selected = false,
  ...rest
}) {
  const buttonClassNames = [
    styles.button,
    styles[size],
    styles[theme],
    styles[align],
    icon ? styles['button--icon'] : '',
    selected && styles['selected']
  ]
    .filter(c => c)
    .join(' ');
  const transparentCLasses = [
    styles.transparent,
    styles[size],

    icon ? styles['button--icon'] : ''
  ]
    .filter(c => c)
    .join(' ');
  const _loadingSpinner = () => (
    <div className={styles.loadingSpinner}>
      <Loading size={24} color={theme === 'primary' ? 'light' : 'dark'} />
    </div>
  );

  return (
    <button className={transparent === false ? buttonClassNames : transparentCLasses} {...rest} alt={alt} style={fixedWidth ? { width: fixedWidth } : (noHeight ? ({ height: 'unset' }) : null)}>
      <span className={isLoading ? styles.isLoading : centerNoFlex ? styles.centerNoFlex : styles.content}>
        {transparent === false && (icon && <Icon name={icon} size={20} color={color} />)}
        {icon && children && <div className={styles.spacer} />}
        {children && <span style={fixedWidth ? { width: '100%' } : null}>{children}</span>}
        {transparent === true && (icon && <Icon name={icon} size={20} color={color} />)}
      </span>
      {isLoading ? _loadingSpinner() : null}
    </button>
  );
}
