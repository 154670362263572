import React from 'react';
import styles from './Checkbox.module.scss';
import Icon from '../Icon';

export default function Checkbox({disabled=false, hideFromReport, ...checkboxProps }) {
  const checkboxClasses = hideFromReport? ([styles.checkbox, styles.hideFromReport].join(' ')) : (styles.checkbox);
  return (
    <label className={checkboxClasses}>
      <input className={styles.input} type="checkbox" {...checkboxProps} disabled={disabled}/>
      <div className={styles.label}> 
        <Icon name="done" size={16} />
      </div>
    </label>
  );
}
