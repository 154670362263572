import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/Auth';
import { hasPermission } from '../../helpers/authorization';
import { destroy, post } from '../../api';
import Avatar from '../../components/Avatar';
import Button from '../../components/Button';
import CollectionListing from '../../components/CollectionListing';
import FrameHeader from '../../components/FrameHeader';
import RowItemActions from '../../components/RowItemActions';
import { Redirect } from 'react-router-dom';
import Popover from '../../components/Popover';
import styles from './UserCustomerUsers.module.scss';
import { useConfirm } from 'material-ui-confirm';

export default function UserCustomerUsers({ match: { params } }) {
  const { state } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const confirm = useConfirm();
  function displayOptions(u) {
    return (<Popover
      text="Action"
      align="right"
      width={200}
    > <div className={styles.wrap}>
        {!u.activated && hasPermission(state.user.perms, 'ADMIN') && <Button theme="primary" onClick={
          (e) => {
            confirm({
              description: `Are you sure you want to resend activation email to ${u.first_name} ${u.last_name}?`,
              title: null,
              dialogProps: { fullScreen: false },
              cancellationButtonProps: { color: "error", disableRipple: true },
              confirmationButtonProps: { color: "primary", disableRipple: true }
            })
              .then(() => {
                _sendActivationLink(u.email);
              })
              .catch(() => { /* ... */ });
          }
        }>Resend Invite
        </Button>}

        {!u.user_disabled &&
          <Button theme={'secondary'} onClick={(e) => {
            confirm({
              description: `Are you sure you want to disable user ${u.first_name} ${u.last_name}?`,
              title: null,
              dialogProps: { fullScreen: false },
              cancellationButtonProps: { color: "error", disableRipple: true },
              confirmationButtonProps: { color: "primary", disableRipple: true }
            })
              .then(() => {
                _disableUser(u.email)
              })
              .catch(() => { /* ... */ });
          }
          }>Disable</Button>
        }
        {u.user_disabled &&
          <Button theme={'secondary'} onClick={(e) => {
            confirm({
              description: `Are you sure you want to enable user ${u.first_name} ${u.last_name}?`,
              title: null,
              dialogProps: { fullScreen: false },
              cancellationButtonProps: { color: "error", disableRipple: true },
              confirmationButtonProps: { color: "primary", disableRipple: true }
            })
              .then(() => {
                _enableUser(u.email)
              })
              .catch(() => { /* ... */ });
          }
          }>Enable</Button>
        }

        {'  '}

        {hasPermission(state.user.perms, 'ADMIN') && <Button theme="danger" onClick={
          (e) => {
            confirm({
              description: `Are you sure you want to delete user ${u.first_name} ${u.last_name}?`,
              title: null,
              dialogProps: { fullScreen: false },
              cancellationButtonProps: { color: "error", disableRipple: true },
              confirmationButtonProps: { color: "primary", disableRipple: true }
            })
              .then(() => {
                _deleteUser(u.id);
              })
              .catch(() => { /* ... */ });
          }
        }>Delete</Button>
        }
      </div>
    </Popover>
    )
  }

  async function _disableUser(userEmail) {
    setLoading(true);
    const res = await Promise.all([
      post(`/users/disable`, {
        email: userEmail,
      })
    ]);
    window.location.reload();
  }
  async function _enableUser(userEmail) {
    setLoading(true);
    const res = await Promise.all([
      post(`/users/enable`, {
        email: userEmail,
      })
    ]);
    window.location.reload();
  }
  async function _sendActivationLink(userEmail) {
    const res = await Promise.all([
      post(`/users/sendActivationMail`, {
        email: userEmail,
      })
    ]);
  }
  async function _deleteUser(userId) {
    setLoading(true);
    const res = await Promise.all([
      destroy(`/users/${userId}`)
    ]);
    window.location.reload();
  }
  function generateTitle(u) {
    var name = `${u.first_name} ${u.last_name}`;
    if (u.user_disabled === true) {
      name += " - Disabled";
    }
    return name;
  }
  const userRowItem = u => (
    <RowItemActions
      key={u.id}
      left={<Avatar name={`${u.first_name} ${u.last_name}`} theme={u.user_disabled ? "pending" : "default"}/>}
      right={displayOptions(u)}
      title={generateTitle(u)}
      subtitle={u.email}
      to={`/customer/users/${u.id}`}
    />
  );

  return (loading ? (<Redirect to={'/customer/users/'} noThrow />) : (
    <>
      <FrameHeader title="Users">
        <Link to={`/customer/users/new`}>
          <Button theme="primary" icon="add" fixedWidth={126}>
            New user
          </Button>
        </Link>
      </FrameHeader>

      <CollectionListing
        stopLoad={loading}
        collection="users"
        queryKey="email"
        params={{ customer_id: state.user.customer_id }}
        item={userRowItem}
      />
    </>
  )
  );
}
