import React, { useContext, useState, useEffect } from "react";

import {
  normalizePermissions,
  denormalizePermissions,
} from "../../helpers/user";
import { put, get } from "../../api";
import UserContext from "../../contexts/User";

import UserInfoForm from "../../components/UserInfoForm";
import FrameHeader from "../../components/FrameHeader";
import LoadingFrame from "../../components/LoadingFrame";

// import styles from './TenantInfo.module.scss';

export default function UserInfo() {
  const { user, setUser } = useContext(UserContext);

  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState({});
  const [saving, setSaving] = useState(false);
  const [customerGroups, setCustomerGroups] = useState([]);
  useEffect(() => {
    async function getPermissions() {
      const { permissions } = await get(`/users/${user.id}/permissions`);

      setPermissions(denormalizePermissions(permissions));
      user.groups = await get(`/users/${user.id}/groups`);
      const groups = await get(`/groups`, {
        orderBy: "-created_time",
        customer_id: user.customer_id,
        include_users: "1",
      });
      var notPartOfGroup = [];
      if (groups !== null && groups.results !== null) {
        groups.results.map((group) => {
          const isObjectPresent = user.groups.find((o) => o.id === group.id);
          if (!isObjectPresent) {
            notPartOfGroup.push(group);
          }
        });
      }
      if (notPartOfGroup && notPartOfGroup.length > 0) {
        notPartOfGroup = notPartOfGroup.slice().sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      }
      setCustomerGroups(notPartOfGroup);

      setLoading(false);
    }

    getPermissions();
  }, []);

  const updateUser = async (user, perms) => {
    setSaving(true);

    setPermissions(perms);

    const [userReq /*, permsReq */] = await Promise.all([
      put(`/users/${user.id}`, { ...user }),
      put(`/users/${user.id}/permissions`, {
        permissions: normalizePermissions(perms),
      }),
    ]);

    setUser(userReq);
    setSaving(false);
  };

  return (
    <>
      <FrameHeader title="Info" />

      {loading ? (
        <LoadingFrame />
      ) : (
        <UserInfoForm
          state={user}
          groups={customerGroups}
          permissions={permissions}
          saving={saving}
          onSave={updateUser}
        />
      )}
    </>
  );
}
