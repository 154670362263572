import React from 'react';
import { useFormState } from 'react-use-form-state';

import validate from '../../helpers/validate';
import { states } from '../../helpers/forms';

import Frame from '../../components/Frame';
import Button from '../../components/Button';
import FrameFooter from '../../components/FrameFooter';
import InputRow from '../../components/InputRow';
import Label from '../../components/Label';
import Select from '../../components/Select';
import TextInput from '../../components/TextInput';

// import styles from './TenantInfoForm.module.scss';

export default function TenantInfoForm({ state, saving, onSave, isNew }) {
  const [formState, { text, email, select }] = useFormState({ ...state });
  const valid = validate(formState, {
    required: ['name', 'email', 'address', 'city', 'zip']
  });

  function _save(e) {
    e.preventDefault();
    onSave(formState.values);
  }

  return (
    <>
      <Frame>
        <form onSubmit={_save}>
          <InputRow>
            <Label text="Name">
              <TextInput
                {...text('name')}
                error={formState.touched.name && formState.errors.name}
                required
              />
            </Label>

            <Label text="Email">
              <TextInput
                value={state.email}
                {...email('email')}
                error={formState.touched.email && formState.errors.email}
                required
              />
            </Label>
          </InputRow>

          <InputRow>
            <Label text="Address" style={{ flex: '2.13' }}>
              <TextInput
                {...text('address')}
                error={formState.touched.address && formState.errors.address}
                required
              />
            </Label>

            <Label text="Apt, suite, unit, etc.">
              <TextInput {...text('address2')} />
            </Label>
          </InputRow>
          <InputRow>
            <Label text="City">
              <TextInput
                {...text('city')}
                error={formState.touched.city && formState.errors.city}
                required
              />
            </Label>

            <Label text="State">
              <Select {...select('state')}>{states()}</Select>
            </Label>

            <Label text="Zip">
              <TextInput
                {...text('zip')}
                error={formState.touched.zip && formState.errors.zip}
                required
              />
            </Label>
          </InputRow>

          <FrameFooter>
            <Button
              type="submit"
              theme="primary"
              isLoading={saving}
              disabled={!valid}
            >
              {isNew ? 'Create' : 'Save'}
            </Button>
          </FrameFooter>
        </form>
      </Frame>
    </>
  );
}
