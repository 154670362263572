/* prettier-ignore */
export const headers = () => {
  const bearerToken = window.localStorage.getItem('bearerToken');

  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    ...(bearerToken && { 'Authorization': `Bearer ${bearerToken}` })
  };
};

export const serialize = obj => {
  if (!Object.keys(obj).length) return '';

  const queryString = Object.keys(obj)
    .reduce(function(a, k) {
      obj[k] &&
        a.push(k + '=' + encodeURIComponent(obj[k]).replace(/%2C/g, ','));
      return a;
    }, [])
    .join('&');

  return queryString ? `?${queryString}` : '';
};
