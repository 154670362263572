import React, { useState } from 'react';

import Image from '../Image';
import Lightbox from '../Lightbox';

import styles from './AttachmentDisplay.module.scss';

export default function AttachmentDisplay({ attachments,assetId=0 }) {
  const [lightbox, setLightbox] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);

  function _openLightbox(i) {
    setLightbox(true);
    setInitialSlide(i);
  }

  return (
    <div className={styles.wrap}>
      {attachments.map((a, i) => (
        <div
          key={a.id}
          onClick={() => _openLightbox(i)}
          className={styles.attachmentFrame}
          tabIndex="1"
        >
          <Image url={a.url} filename={a.filename} width="200" />
        </div>
      ))}

      {lightbox && (
        <Lightbox
          attachments={attachments}
          initialSlide={initialSlide}
          assetId={assetId}
          onClose={() => setLightbox(false)}
        />
      )}
    </div>
  );
}
