import React, { useEffect, useState } from 'react';
import { get } from '../../api';
import moment from 'moment';
import Frame from '../../components/Frame';
import LoadingFrame from '../../components/LoadingFrame';
import Label from '../../components/Label';
import InputRow from '../../components/InputRow';
import FrameHeader from '../../components/FrameHeader';
import Space from '../../components/Space';
import TextInput from '../../components/TextInput';
import Button from "../../components/Button";
import styles from './AdminPortalLogs.module.scss';
import CheckboxWithLabel from '../../components/CheckboxWithLabel';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { Link } from 'react-router-dom';
export default function AdminPortalLogs() {
  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState([]);
  const [limit, setLimit] = useState(100);
  const [type, setType] = useState({
    INFORMATION: true,
    WARNING: true,
    ERROR: true,
    CRITICAL: true,
    TELEMETRY:true,
  })
  useEffect(() => {

    getLogs();
  }, []);

  function statuses() {

    let statuses = '';

    if (type.INFORMATION) {
      statuses += `INFORMATION,`;
    }
    if (type.WARNING) {
      statuses += `WARNING,`;
    }
    if (type.ERROR) {
      statuses += `ERROR,`;
    }
    if (type.CRITICAL) {
      statuses += `CRITICAL,`;
    }
    if (type.TELEMETRY) {
      statuses += `TELEMETRY,`;
    }
    return statuses.slice(0, -1);
  }
  async function getLogs() {
    setLoading(true);
    const res = await get(`/logs`, {
      limit: limit,
      type: statuses()
    }
    );
    res && setLogs(res.results);
    setLoading(false);
  }


  function genereateOptions() {

    return (
      <>
        <Accordion>
          <AccordionSummary>
            Show options
          </AccordionSummary>
          <AccordionDetails>
            <InputRow>
              <Space size="s">
                <Label text="# of records" />
                <TextInput
                  value={limit}
                  keyboardType="numeric"
                  spinHide={true}
                  type="number"
                  maxLength={3}
                  placeholder="Enter nr of logs to retrieve"
                  onChange={e => {
                    setLimit(e.target.value);
                  }}
                />
              </Space>
              <Space>
                <Label text="Types" />

                <div className={styles.filter}>
                  <CheckboxWithLabel
                    label="INFORMATION"
                    checked={type.INFORMATION}
                    onChange={e => setType({ ...type, INFORMATION: e.target.checked })}
                    disabled={loading}
                  />

                  <div className={`${styles.status} ${styles.information}`} />
                </div>
                <div className={styles.filter}>
                  <CheckboxWithLabel
                    label="WARNING"
                    checked={type.WARNING}
                    onChange={e => setType({ ...type, WARNING: e.target.checked })}
                    disabled={loading}
                  />

                  <div className={`${styles.status} ${styles.warning}`} />
                </div>
                <div className={styles.filter}>
                  <CheckboxWithLabel
                    label="ERROR"
                    checked={type.ERROR}
                    onChange={e => setType({ ...type, ERROR: e.target.checked })}
                    disabled={loading}
                  />

                  <div className={`${styles.status} ${styles.error}`} />
                </div>
                <div className={styles.filter}>
                  <CheckboxWithLabel
                    label="CRITICAL"
                    checked={type.CRITICAL}
                    onChange={e => setType({ ...type, CRITICAL: e.target.checked })}
                    disabled={loading}
                  />

                  <div className={`${styles.status} ${styles.critical}`} />
                </div>
                <div className={styles.filter}>
                  <CheckboxWithLabel
                    label="TELEMETRY"
                    checked={type.TELEMETRY}
                    onChange={e => setType({ ...type, TELEMETRY: e.target.checked })}
                    disabled={loading}
                  />

                  <div className={`${styles.status} ${styles.telemetry}`} />
                </div>
              </Space>
              <Space>
                <Label text="Customer" />
              </Space>

              <Space>
                <Button onClick={() => getLogs()}>Show</Button>
              </Space>
            </InputRow>

          </AccordionDetails>
        </Accordion>
      </>
    );



  }


function logIcon(log_type){
  switch(log_type){
    case "INFORMATION": return(<div className={styles.indicator}><div className={`${styles.status} ${styles.information}`} />Information</div>)
    case "WARNING": return(<div className={styles.indicator}><div className={`${styles.status} ${styles.warning}`} />Warning</div>)
    case "ERROR": return(<div className={styles.indicator}><div className={`${styles.status} ${styles.error}`} />Error</div>)
    case "CRITICAL": return(<div className={styles.indicator}><div className={`${styles.status} ${styles.critical}`} />Information</div>)
    case "TELEMETRY": return(<div className={styles.indicator}><div className={`${styles.status} ${styles.telemery}`} />Telemetry</div>)
  }
}

  function generateLogDetails(log) {
    return (
      <div className={styles.row}>
        <InputRow>
          <Space size="s">
            <Label  theme="light"  text="Type">{logIcon(log.type)}</Label>
          </Space>
          <Space size="s">
            {log.user && (<Label  theme="light" text="User">{log.user && <Link to={`/users/${log.user.id}`}>{log.user.first_name + " " + log.user.last_name}</Link>}</Label>)}
          </Space>
          <Space size="s">
            {log.customer && (<Label  theme="light" text="Customer"><Link to={`/customers/${log.customer.id}`}>{log.customer.name}</Link></Label>)}
          </Space>

          <Space size="s">
            <Label  theme="light" text="Date">{moment.utc(log.created_time).format('dddd, MMMM Do YYYY, h:mm:ss a')}</Label>
          </Space>
        </InputRow>
        <InputRow>
          <Space size="s">
            <Label theme="light" text={log.context}><div className={styles.wrap}>{log.body}</div></Label>
          </Space>

        </InputRow>
      </div>);
  }

  return (
    <>
      {genereateOptions()}
      {loading ? <LoadingFrame /> : (<>

        <FrameHeader title="Logs" />
        <Frame padding={0}>
          {logs && logs.length > 0 ? (
            <>
              {logs.map(log =>
                <>
                  {generateLogDetails(log)}
                </>
              )}
            </>
          ) : ("No logs")}
        </Frame>
      </>)}
    </>
  );
}
