import React from 'react';
import { Link } from 'react-router-dom';

import Avatar from '../../components/Avatar';
import Button from '../../components/Button';
import CollectionListing from '../../components/CollectionListing';
import FrameHeader from '../../components/FrameHeader';
import RowItem from '../../components/RowItem';

// import styles from './TenantAdmins.module.scss';

export default function TenantAdmins({ match: { params } }) {
  const adminRowItem = a => (
    <RowItem
      key={a.id}
      left={<Avatar name={`${a.first_name} ${a.last_name}`} />}
      title={`${a.first_name} ${a.last_name}`}
      subtitle={a.email}
      to={`/users/${a.id}`}
    />
  );

  return (
    <>
      <FrameHeader title="Admins">
        <Link to={`/tenants/${params.id}/admins/new`}>
          <Button theme="primary" icon="add">
            New Admin
          </Button>
        </Link>
      </FrameHeader>

      <CollectionListing
        collection="users"
        queryKey="email"
        params={{ tenant_id: params.id, authority: 'TENANT_ADMIN' }}
        item={adminRowItem}
        sortOptions={
          <>
           
            <option value="first_name">First name</option>
            <option value="last_name">Last name</option>
            <option value="email">Email</option>
          </>
        }
      />
    </>
  );
}
