import React, { useContext } from 'react';

import NavContext from '../../contexts/Nav';

import Button from '../Button';

import styles from './PageHeader.module.scss';

export default function PageHeader({ right, children, noZIndex }) {
  const { isNavShowing, setIsNavShowing } = useContext(NavContext);

  return (
    <header className={styles.header} style={noZIndex ? { zIndex: 1 } : null}>
      <div className={styles.menuButton}>
        <Button icon="menu" onClick={() => setIsNavShowing(!isNavShowing)} />
      </div>

      <div className={styles.content}>{children}</div>

      {right && <div className={styles.right}>{right}</div>}
    </header>
  );
}
