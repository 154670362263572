import { SYS_ADMIN, TENANT_ADMIN, CUSTOMER_USER } from '../constants/roles';

export const humanUserRole = role => {
  switch (role) {
    case SYS_ADMIN:
      return 'System admin';

    case TENANT_ADMIN:
      return 'Tenant admin';

    case CUSTOMER_USER:
    default:
      return 'User';
  }
};

export const normalizePermissions = perms => {
  // prettier-ignore
  return [
    ...(perms.admin ? ['ADMIN'] : []),
    ...(perms.assets ? ['ASSET_C', 'ASSET_U', 'ASSET_D'] : []),
    ...(perms.inspection ? ['INSPECTION_C', 'INSPECTION_U', 'ALARM_U'] : []),
    ...(perms.deleteInspection ? ['INSPECTION_D'] : []),
    /** The perms below need to be added to every user */
    'EVENT_C',
    'EVENT_U',
    'USER_R',
    'ASSET_R',
    'DEVICE_R',
    'EVENT_R',
    'INSPECTION_R',
    'GROUP_R',
    'ALARM_R'
  ];
};

export const denormalizePermissions = perms => {
  // prettier-ignore
  return {
    admin: perms.includes('ADMIN'),
    assets:
      perms.includes('ASSET_C') &&
      perms.includes('ASSET_U') &&
      perms.includes('ASSET_D'),
    inspection:
      perms.includes('INSPECTION_C') &&
      perms.includes('INSPECTION_U') &&
      perms.includes('ALARM_U'),
    deleteInspection: perms.includes('INSPECTION_D')
  }
};
