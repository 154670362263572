import React, { useContext, useRef, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/Auth";
import { checkAutoLogin } from "../../actions/Auth";
import LoadingFrame from '../../components/LoadingFrame';
import Center from '../../components/Center';
import Frame from '../../components/Frame';
import Space from '../../components/Space';
import Logo from '../../components/Logo';
import styles from "./OneTimeLogin.module.scss";
export default function OneTimeLogin({ match: { params } }) {
  const { state, dispatch } = useContext(AuthContext);
  useEffect(() => {
    async function AutoLogIn() {
      await checkAutoLogin(params.token);
    }
    AutoLogIn();
  }, []);
 
  return (
    <>
      <Center>
        <main className={styles.frameWrap}>
          <Frame>
            <Space size="xl">
              <div className={styles.logoWrap}>
                <Logo size="200" />
              </div>
            </Space>
              <Space size="xl">
                <h2 className={styles.heading}> Please wait, we are logging you in.</h2>
               <LoadingFrame/>
              </Space>
          </Frame>
          </main>
      </Center>
    </>
  );
}
