import React from 'react';

export default function assetTypesSelect(assets) {
  const groups = assets.reduce((objectsByKeyValue, obj) => {
    const value = obj['additional_info'];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

  return Object.keys(groups).map(group => (
    <optgroup key={group} label={group}>
      {groups[group].map(p => (
        <option key={p.id} value={p.id}>
          {p.name}
        </option>
      ))}
    </optgroup>
  ));
}
export const assetInspectionStates = {
  NONE: 'Asset not damaged',
  MINOR: 'Minor',
  MAJOR: 'Major',
  NEEDS_REPLACING: 'Needs replacing',
  WORKING_CONDITION: 'Asset not damaged',
  NEEDS_REPAIRING: 'Asset damaged',
  DAMAGED: 'Asset damaged',
};