import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';

import { post } from '../../api';

import AlertBanner from '../../components/AlertBanner';
import Button from '../../components/Button';
import LoginFrame from '../../components/LoginFrame';
import Space from '../../components/Space';

// import styles from './Expired.module.scss';

export default function Expired({ location: { search } }) {
  const queryParams = queryString.parse(search);

  const [redirect, setRedirect] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    !queryParams.token && setRedirect('/log-in');
  }, []);

  async function _resendActivationEmail() {
    setLoading(true);

    const res = await post(`/users/sendActivationMail`, {
      token: queryParams.token
    });

    if (res.ok) {
      setSuccess(true);
    } else {
      setError(true);
      setLoading(false);
    }
  }

  async function _resendResetPasswordEmail() {
    setLoading(true);

    const res = await post(`/users/forgotPassword`, {
      token: queryParams.token
    });

    if (res.ok) {
      setSuccess(true);
    } else {
      setError(true);
      setLoading(false);
    }
  }

  function _renderSucess() {
    return (
      <Space size="l">
        <AlertBanner>
          We've just successfully resent your{' '}
          {queryParams.type === 'activate' ? 'activation' : 'reset password'}{' '}
          link to your email.
        </AlertBanner>
      </Space>
    );
  }

  function _renderError() {
    return (
      <Space size="l">
        <AlertBanner theme="danger">
          Something went wrong when trying to resend your{' '}
          {queryParams.type === 'activate' ? 'activation' : 'reset password'}{' '}
          link. Please try again later.
        </AlertBanner>
      </Space>
    );
  }

  return redirect ? (
    <Redirect from="/expired" to={redirect} nothrow />
  ) : (
    <LoginFrame
      heading="This link has expired"
      subHeading={`Request a new link to ${
        queryParams.type === 'activate'
          ? 'activate your account'
          : 'reset your password'
      } below.`}
    >
      {success && _renderSucess()}
      {error && _renderError()}

      {!success && (
        <Space size="l">
          <Button
            theme="primary"
            isLoading={loading}
            onClick={
              queryParams.type === 'activate'
                ? _resendActivationEmail
                : _resendResetPasswordEmail
            }
          >
            Resend link
          </Button>
        </Space>
      )}
    </LoginFrame>
  );
}
