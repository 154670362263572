import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../../components/Button';
import CollectionListing from '../../components/CollectionListing';
import FrameHeader from '../../components/FrameHeader';
import RowItem from '../../components/RowItem';

// import styles from './TenantCustomers.module.scss';

export default function TenantCustomers({ match: { params } }) {
  const customerRowItem = c => (
    <RowItem
      key={c.id}
      title={c.name}
      subtitle={`${c.city}, ${c.state}`}
      to={`/customers/${c.id}`}
    />
  );

  return (
    <>
      <FrameHeader title="Customers">
        <Link to={`/tenants/${params.id}/customers/new`}>
          <Button theme="primary" icon="add">
            New customer
          </Button>
        </Link>
      </FrameHeader>

      <CollectionListing
        collection="customers"
        params={{ tenant_id: params.id }}
        item={customerRowItem}
      />
    </>
  );
}
