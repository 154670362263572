import React, { useState, useContext, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Map from '../../components/Map';
import useThunk from "../../hooks/thunk";
import NavContext from "../../contexts/Nav";
import { AuthContext } from "../../contexts/Auth";
import AlarmsContext from "../../contexts/Alarms";
import { clearIntent } from "../../actions/Auth";
import { get } from '../../api';
import { fetchAlarms } from "../../actions/Alarms";
import { AlarmsReducer, initialAlarmsState } from "../../reducers/Alarms";
import { SYS_ADMIN, TENANT_ADMIN, CUSTOMER_USER } from "../../constants/roles";
import AssetsFiltered from "../AssetsFiltered";
import Account from "../Account";
import Alarms from "../Alarms";
import Reports from "../Reports";
import AuthorizedRoute from "../../components/AuthorizedRoute";
import CustomersIndex from "../CustomersIndex";
import CustomerIndex from "../CustomerIndex";
import Manage from "../Manage";
import Navigation from "../../components/Navigation";
import TenantsIndex from "../TenantsIndex";
import UsersIndex from "../UsersIndex";
import Help from "../Help";
import styles from "./Sentinel.module.scss";
import MapDisplay from "../MapDisplay";
import TypesContext from '../../contexts/Types';
import DevicesSimple from '../DevicesSimple';
import AdminPortal from "../AdminPortal";
import NewPendingCustomer from "../NewPendingCustomer";
import Groups from "../Groups";

export default function Sentinel({ location }) {
  const { state, dispatch } = useContext(AuthContext);
  const [alarmsState, alarmsDispatch] = useThunk(
    AlarmsReducer,
    initialAlarmsState
  );
  async function fetchTypes() {
    //setLoading(true);

    const [assetTypes, deviceTypes] = await Promise.all([
      get('/assettypes'),
      get('/devicetypes')
    ]);

    assetTypes.results && setTypes({
      assets: assetTypes.results,
      devices: deviceTypes.results
    });

    //setLoading(false);
  }
  const [isNavShowing, setIsNavShowing] = useState(false);
  const [currentPath, setCurrentPath] = useState(null);
  const [types, setTypes] = useState({});
  useEffect(() => {
    /** Check if there's any post-auth intent */
    if (state.intent) {
      dispatch(clearIntent());
    }

    /** Check initial alarms state */
    alarmsDispatch(fetchAlarms());
    fetchTypes();
    /** Re-check alarm state every 30 seconds */
    const timer = setInterval(() => {
      alarmsDispatch(fetchAlarms());
    }, 1000 * 30);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const routeChanged = currentPath !== location.pathname;

    if (routeChanged) {
      setCurrentPath(location.pathname);
      setIsNavShowing(false);
    }
  }, [location.pathname, currentPath]);

  const sentinelClasses = [
    styles.sentinel,
    isNavShowing ? styles["sentinel--nav-showing"] : ""
  ].join(" ");

  return state.intent ? (
    <Redirect to={state.intent} noThrow />
  ) : (
    <TypesContext.Provider value={types}>
      <AlarmsContext.Provider value={[alarmsState, alarmsDispatch]}>
        <NavContext.Provider value={{ isNavShowing, setIsNavShowing }}>
          <div className={sentinelClasses}>
            <div className={styles.nav}>
              <Navigation
                user={state.user}
                alarmCount={
                  alarmsState && alarmsState.alarms
                    ? alarmsState.alarms.length
                    : 0
                }
              />
            </div>

            <main className={styles.page}>
              <Switch>
                <AuthorizedRoute
                  userRoles={state.user.roles}
                  allowedRoles={[SYS_ADMIN, TENANT_ADMIN, CUSTOMER_USER]}
                  path="/alarms"
                  component={Alarms}
                />
                <AuthorizedRoute
                  userRoles={state.user.roles}
                  allowedRoles={[SYS_ADMIN, TENANT_ADMIN, CUSTOMER_USER]}
                  path="/reports"
                  component={Reports}
                />

                <AuthorizedRoute
                  userRoles={state.user.roles}
                  allowedRoles={[SYS_ADMIN]}
                  path="/tenants"
                  component={TenantsIndex}
                />

                <AuthorizedRoute
                  userRoles={state.user.roles}
                  allowedRoles={[SYS_ADMIN, TENANT_ADMIN]}
                  path="/customers"
                  component={CustomersIndex}
                />

                <AuthorizedRoute
                  userRoles={state.user.roles}
                  allowedRoles={[CUSTOMER_USER]}
                  userPermissions={state.user.perms}
                  allowedPermissions={["ADMIN"]}
                  path="/customer"
                  component={CustomerIndex}
                />
                <AuthorizedRoute
                  userRoles={state.user.roles}
                  allowedRoles={[CUSTOMER_USER]}
                  path="/groups"
                  component={Groups}
                />

                <AuthorizedRoute
                  userRoles={state.user.roles}
                  allowedRoles={[SYS_ADMIN, TENANT_ADMIN]}
                  path="/users"
                  component={UsersIndex}
                />

                <AuthorizedRoute
                  userRoles={state.user.roles}
                  allowedRoles={[SYS_ADMIN, TENANT_ADMIN, CUSTOMER_USER]}
                  path="/assets_dashboard"
                  component={AssetsFiltered}
                />
                <AuthorizedRoute
                  userRoles={state.user.roles}
                  allowedRoles={[SYS_ADMIN, TENANT_ADMIN]}
                  path="/admin_portal"
                  component={AdminPortal}
                />
                <AuthorizedRoute
                  userRoles={state.user.roles}
                  allowedRoles={[SYS_ADMIN, TENANT_ADMIN]}
                  path="/pendingcustomer/:id(\d+)"
                  component={NewPendingCustomer} />
                <Route path="/map" component={MapDisplay} />



                <Route path="/account" component={Account} />
                <Route path="/devices" component={DevicesSimple} />
                <Route path="/help" component={Help} />
                <AuthorizedRoute
                  userRoles={state.user.roles}
                  allowedRoles={[SYS_ADMIN, TENANT_ADMIN, CUSTOMER_USER]}
                  path="/"
                  component={Manage}
                />

              </Switch>
            </main>
          </div>
        </NavContext.Provider>
      </AlarmsContext.Provider>
    </TypesContext.Provider>
  );
}
