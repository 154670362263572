import React, { useState,useContext } from 'react';
import Button from '../Button';
import Image from '../Image';
import Modal from '../Modal';
import {destroy } from '../../api';
import styles from './Lightbox.module.scss';
import { CUSTOMER_USER, SYS_ADMIN, TENANT_ADMIN } from '../../constants/roles';
import { isAuthorized, hasPermission } from "../../helpers/authorization";
import { AuthContext } from "../../contexts/Auth";
import { useConfirm } from 'material-ui-confirm';
export default function Lightbox({ attachments, initialSlide, onClose,assetId=0,deleteButton=true }) {
  const { state} = useContext(AuthContext);
  const [currentSlide, setCurrentSlide] = useState(initialSlide);
  const confirm = useConfirm();

  function deleteFileConfirmation(fileId) {
    confirm({
      description: "Are you sure you want to delete this file?",
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true }
    })
      .then(() => {
        deleteFile(fileId);
      })
      .catch(() => { /* ... */ });
  }
  async function deleteFile(fileId) {
    await destroy(`/files/${fileId}`);
    onClose();
    assetId > 0 && window.location.replace(`/assets/${assetId}`);
  }
  function _renderFooter() {
    return (
      <>
        <div className={styles.controls}>
          <Button
            icon="chevron_left"
            disabled={currentSlide - 1 < 0}
            onClick={() => setCurrentSlide(currentSlide - 1)}
          />
          <Button
            icon="chevron_right"
            disabled={currentSlide + 2 > attachments.length}
            onClick={() => setCurrentSlide(currentSlide + 1)}
          />
          {currentSlide + 1} of {attachments.length}
        </div>
        {deleteButton && isAuthorized(state.user.roles, [SYS_ADMIN, TENANT_ADMIN,CUSTOMER_USER]) && (hasPermission(state.user.perms,'ADMIN') || hasPermission(state.user.perms, 'ASSET_C')) && (<Button  onClick={() => deleteFileConfirmation(attachments[currentSlide].id)}  theme='danger'  alt="Delete file" >Delete</Button>)}
        <Button theme="primary" onClick={() => onClose()}>
          Close
        </Button>
      </>
    );
  }

  return (
    <Modal footer={_renderFooter()}>
      {attachments.map((a, i) => (
        <div
          key={a.id}
          style={{ display: currentSlide === i ? 'block' : 'none' }}
        >
          <Image url={a.url} filename={a.filename} width="100%" />
        </div>
      ))}
    </Modal>
  );
}
