import React, { useState, useEffect, useContext } from "react";
import { useFormState } from "react-use-form-state";
import { post, get, put, upload } from "../../api";
import validate from "../../helpers/validate";
import assetTypesSelect from "../../helpers/assetTypes";
import TypesContext from "../../contexts/Types";
import { AuthContext } from "../../contexts/Auth";
import history from "../../helpers/history";
import queryString from "query-string";
import Button from "../../components/Button";
import Label from "../../components/Label";
import Modal from "../../components/Modal";
import InputRow from "../../components/InputRow";
import TextInput from "../../components/TextInput";
import Select from "../../components/Select";
import Space from "../../components/Space";
import LoadingFrame from "../../components/LoadingFrame";
import InstallOnAssetStep1 from "../../components/InstallOnAssetStep1";
import InstallOnAssetStep2 from "../../components/InstallOnAssetStep2";
import InstallOnAssetStep3 from "../../components/InstallOnAssetStep3";
import InstallOnAssetStep4 from "../../components/InstallOnAssetStep4";
import styles from "./NewAssetWizard.module.scss";
import Icon from "../../components/Icon";
import { useConfirm } from "material-ui-confirm";
import { READY } from "../../constants/statuses";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { hasPermission } from "../../helpers/authorization";
import Chip from "@mui/material/Chip";
import AddNewChip from "../../components/AddNewChip/AddNewChip";

export default function NewAssetWizard({ location: { search } }) {
  const queryParams = queryString.parse(search);
  const { state } = useContext(AuthContext);
  const isGroupAdmin = hasPermission(state.user.perms, "ADMIN");
  const types = useContext(TypesContext);
  const [switchOwner, setSwitchOwner] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const [device, setDevice] = useState(null);
  const [selected_sub_id, setSelectedSub] = useState(0);
  const [devices, setDevices] = useState([]);
  const [thresholds, setThresholds] = useState({});
  const confirm = useConfirm();
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState([]);
  const [groupsToAdd, setGroupsToAdd] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [step, setStep] = useState(0);
  const [assetId, setAssetId] = useState(0);
  const [saving, setSaving] = useState(false);
  const [formState, { text, select }] = useFormState({
    name: "",
    type: 72,
    serial: "",
    group_id: 1,
    site_id: 1,
  });
  const filter = createFilterOptions();
  const valid = validate(formState, { required: ["name"] });

  useEffect(() => {
    async function fetchGroupsAndSites() {
      const devices = await get(`/deviceslist`, {
        active: "false",
        customer_id: state.user.customer_id,
      });
      setDevices(devices);

      const [groups] = await Promise.all([
        get(`/users/${state.user.user_id}/groups`),
      ]);

      if (groups && groups.length) {
        setGroups(groups);
        var groupsAvailable = [];
        groups.map((group) => {
          groupsAvailable.push({ id: group.id, name: group.name });
        });
        setGroupsToAdd(groupsAvailable);
      }

      const subscriptions = await get(`/customer/subscriptions/newasset`);
      setSubscriptions(subscriptions);
      if (subscriptions.length !== 0) {
        setSelectedSub(subscriptions[0].id);
      }

      setLoading(false);
    }

    fetchGroupsAndSites();
  }, []);

  async function GoToSub() {
    history.push("/customer/subscriptions/new");
  }

  function _buyLicenseContent() {
    return (
      <div className={styles.buyNew}>
        <div className={styles.wrap}>
          <div className={styles.error}>
            <Icon name="error" size={64} />
          </div>
          <h1>No subscription found</h1>
        </div>
        <p className={styles.body}>
          Click below to purchase a new subscription.
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button theme="primary" icon="add" onClick={() => GoToSub()}>
            Buy subscription
          </Button>
        </div>
      </div>
    );
  }

  async function _checkNameAndMoveFurther() {
    const { name, type, serial, group_id, site_id } = formState.values;
    const checkName = await get(`/assets/name`, {
      name: encodeURIComponent(name),
    });
    if (checkName != undefined) {
      if (checkName === false) {
        confirm({
          description:
            "The asset name already exists. Type in a new asset name.",
          title: null,
          dialogProps: { fullScreen: false },
          cancellationButtonProps: { style: { display: "none" } },
          confirmationButtonProps: { color: "primary", disableRipple: true },
        })
          .then(() => {})
          .catch(() => {
            /* ... */
          });
      } else {
        setStep(step + 1);
      }
    }
  }

  async function checkName(name) {
    formState.setField("name", name);
  }

  function _renderFooterOrientation(thresholds) {
    setThresholds(thresholds);
    setStep(step + 1);
  }

  function _renderFooterBack() {
    return (
      <>
        <Button disabled={saving} onClick={() => setStep(step - 1)}>
          Back
        </Button>
        <Button
          theme="primary"
          onClick={(e) => setStep(step + 1)}
          isLoading={saving}
          disabled={!valid} // || subscriptions.length == 0}
        >
          Next
        </Button>
      </>
    );
  }

  function _renderFooter(step) {
    switch (step) {
      case 0:
        return (
          <>
            {step <= 0 ? (
              <Button onClick={() => history.goBack()}>Cancel</Button>
            ) : (
              <Button disabled={saving} onClick={() => setStep(step - 1)}>
                Back
              </Button>
            )}

            <Button
              theme="primary"
              onClick={(e) => _checkNameAndMoveFurther()}
              isLoading={saving}
              disabled={!valid} // || subscriptions.length == 0}
            >
              Next
            </Button>
          </>
        );

      case 1:
        return (
          <>
            <Button disabled={saving} onClick={() => setStep(step - 1)}>
              Back
            </Button>
            <Button
              theme="primary"
              onClick={(e) => setStep(step + 1)}
              isLoading={saving}
              disabled={selectedDevice !== null}
            >
              Next
            </Button>
          </>
        );
    }
  }

  async function determineNextStep(deviceId, serial) {
    setLoading(true);
    const devices = await get(`/deviceslist`, {
      active: "false",
      customer_id: state.user.customer_id,
    });
    setDevices(devices);

    if (deviceId != null) {
      setSelectedDevice(deviceId);
      var dev = devices.filter((x) => x.id === Number(deviceId))[0];
      dev &&
        setDevice(dev) &&
        setSwitchOwner(dev.customer_id !== state.user.customer_id);
    } else {
      var dev = devices.filter((x) => x.serial_number === Number(serial))[0];
      dev && setDevice(dev);
      setSelectedDevice(dev.id);
      setSwitchOwner(dev.customer_id !== state.user.customer_id);
    }

    setStep(step + 1); // license page
    setLoading(false);
  }

  async function _finish(attachments) {
    setSaving(true);
    const { name, type, serial, group_id, site_id } = formState.values;
    let tenant_id = state.user.tenant_id;
    let customer_id = state.user.customer_id;
    const asset = await post(`/assets`, {
      name,
      assettype_id: type,
      serial,
      tenant_id,
      customer_id,
      groups: selectedGroups,
    });
    let assetId = asset?.id;

    var newDevice =
      (device.status === READY ||
        device.customer_activation_time == undefined) &&
      device.active === false;
    setAssetId(assetId);
    const relation = await post("/relations", {
      from_type: "ASSET",
      from_id: assetId,
      to_type: "DEVICE",
      to_id: selectedDevice,
      switch_owner: newDevice ? newDevice : switchOwner,
      auto_license:
        (device.status === READY ||
          device.customer_activation_time == undefined) &&
        device.active === false, //READY
    });

    let fileReqs = [];

    attachments.forEach((file) => {
      const formData = new FormData();

      formData.set("entity_type", "ASSET");
      formData.set("entity_id", assetId);
      formData.set("type", "IMAGE");
      formData.append("file", file);

      const res = upload(formData);
      fileReqs.push(res);
    });

    if (fileReqs.length > 0) {
      await Promise.all(fileReqs);
    }
    relation &&
      asset &&
      (await put(`/assets/${assetId}`, {
        ...asset,
        ...thresholds,
        skipGroup: true,
      }));
    //##how to handle auto licence creation

    if (relation && asset) {
      const license = await post(`/customer/subscriptions/auto/assign`, {
        auto_assign: true,
        devices: [selectedDevice],
      });
    }
    setSaving(false);
    setStep(step + 1);
    return true;
  }

  const handleSubmit = (values) => {
    selectedGroups.push(values.inputValue);
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      console.log("Enter key pressed");
    }
  };

  function _renderContent() {
    const { name, type, serial, group_id } = formState.values;
    switch (step) {
      case 0:
        return (
          <>
            <Space size="m">
              <h2>New asset</h2>
            </Space>
            {
              <>
                <InputRow>
                  <Label text="Name">
                    <TextInput
                      {...text("name")}
                      error={formState.errors.name}
                      placeholder=""
                      required
                      onChange={(e) => {
                        checkName(e.target.value);
                      }}
                    />
                  </Label>
                </InputRow>

                <InputRow>
                  <Label text="Type">
                    <Select {...select("type")}>
                      {assetTypesSelect(types.assets)}
                    </Select>
                  </Label>
                </InputRow>

                {isGroupAdmin && groupsToAdd && (
                  <InputRow>
                    <Label text="Group">
                      <Autocomplete
                        multiple
                        id="tags-standard"
                        filterSelectedOptions
                        options={groupsToAdd}
                        getOptionLabel={(option) =>
                          option.id == 0 ? (
                            <b>Add "{option.name}"</b>
                          ) : (
                            option.name
                          )
                        }
                        onChange={(event, value) => {
                          setSelectedGroups(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            placeholder="Enter name"
                            onKeyDown={handleEnterKeyPress}
                          />
                        )}
                        renderTags={(tagValue, getTagProps) => {
                          return tagValue.map((option, index) =>
                            option.id == 0 ? (
                              <AddNewChip
                                {...getTagProps({ index })}
                                label={option.name}
                              />
                            ) : (
                              <Chip
                                {...getTagProps({ index })}
                                label={option.name}
                              />
                            )
                          );
                        }}
                        value={selectedGroups}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);
                          if (filtered === undefined) return [];
                          var filteredduplicates = filtered.filter(
                            ({ id: id1 }) =>
                              !selectedGroups.some(({ id: id2 }) => id2 === id1)
                          );
                          const exist = filteredduplicates.find(
                            (item) =>
                              item.name.toLowerCase() ==
                              params?.inputValue.toLowerCase()
                          );
                          const existInSelected = selectedGroups.find(
                            (item) =>
                              item.name.toLowerCase() ==
                              params?.inputValue.toLowerCase()
                          );
                          isGroupAdmin &&
                            params.inputValue !== "" &&
                            !exist &&
                            !existInSelected &&
                            filtered.push({
                              id: 0,
                              title: `Add "${params.inputValue}"`,
                              name: params.inputValue,
                            });
                          return filtered;
                        }}
                      />
                    </Label>
                  </InputRow>
                )}
              </>
            }
          </>
        );

      case 1:
        return (
          <InstallOnAssetStep1
            asset={{
              name: encodeURIComponent(name),
              id: 0,
              assettype_id: type,
            }}
            devices={devices}
            subscriptions={subscriptions}
            loading={loading}
            back={() => setStep(step - 1)}
            next={(deviceId, serial) => {
              determineNextStep(deviceId, serial);
            }}
            setDevice={(device) => setDevice(device)}
            footer={_renderFooterBack}
          />
        );

      case 2:
        return (
          <InstallOnAssetStep2
            asset={{ name: "test", id: assetId, assettype_id: 1 }}
            back={() => setStep(step - 1)}
            next={() => setStep(step + 1)}
            customFooter={(thresholds) => _renderFooterOrientation(thresholds)}
          />
        );
      case 3:
        return (
          <InstallOnAssetStep3
            switch_owner={switchOwner}
            back={() => setStep(step - 1)}
            assetId={0}
            finish={(attachments) => _finish(attachments)}
            selectedDevice={selectedDevice}
            saving_loader={saving}
          />
        );

      case 4:
        return <InstallOnAssetStep4 assetId={assetId} />;
    }
  }
  return (
    <Modal hideFooter={step != 0} footer={_renderFooter(0)}>
      {loading ? <LoadingFrame /> : _renderContent()}
    </Modal>
  );
}
