import React, { useState, useEffect,useContext } from 'react';
import { SYS_ADMIN, TENANT_ADMIN,CUSTOMER_USER} from '../../constants/roles';
import { get,post, put } from '../../api';
import {isAuthorized, hasPermission } from "../../helpers/authorization";
import { AuthContext } from "../../contexts/Auth";
import Button from '../../components/Button';
import Frame from '../../components/Frame';
import FrameHeader from '../../components/FrameHeader';
import FrameFooter from '../../components/FrameFooter';
import Label from '../../components/Label';
import LoadingFrame from '../../components/LoadingFrame';
import NoResults from '../../components/NoResults';
import RowItem from '../../components/RowItem';
import Space from '../../components/Space';
import TextInput from '../../components/TextInput';
import styles from './SiteDetail.module.scss';
import { useConfirm } from 'material-ui-confirm';
export default function SiteDetail({ match: { params } }) {
  const { state} = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [site, setSite] = useState({});
  const [assigned,setAssigned] = useState([]);
  const [notassigned,setNotAssigned] = useState([]);
  const [assetLoading, setAssetLoading] = useState(0);
  const siteId = params.siteId;
  const confirm = useConfirm();

  useEffect(() => {
  
    fetchAssetsFromSite();
  }, []);

  async function fetchAssetsFromSite() {
    let defaultUrl = `/site/${siteId}`;
    if(siteId===undefined){
      defaultUrl=`/assets/sites`;
    }
    const [site, assigned, notassigned] = await Promise.all([
      get(defaultUrl),
      get('/assets', { site_id: siteId,customer_id:params.id}),
      get('/assets', { site_id: 1,customer_id:params.id})
    ]);

    site && setSite(site);
    assigned && setAssigned(assigned.results);
    notassigned && setNotAssigned(notassigned.results);
    setLoading(false);
  }

  async function removeFromSite(assetId) {
    const confirm = true;/*window.confirm(
      "Are you sure you want to remove this asset from the site?"
    );*/
    if (confirm) {
      setAssetLoading(assetId);
      await put(`/assets/${assetId}/site/1`);
      setAssetLoading(0);
      fetchAssetsFromSite();
    }
  }

  async function updateSite(){
    setLoading(true)
    await put(`/site/${siteId}/update/`,{
      name:site.name,
      
    });

    setLoading(false)
  }
  async function addToSite(assetId) {
    const confirm =true;/* window.confirm(
      "Are you sure you want to add this asset to the site?"
    );*/
    if (confirm) {
      setAssetLoading(assetId);
      await put(`/assets/${assetId}/site/${siteId}`);
      setAssetLoading(0);
      fetchAssetsFromSite();
    }
  }
  
  function removeSiteConfirmation(u) {
    confirm({
      description: 'Are you sure you want to remove this site?',
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true }
    })
      .then(() => {
        removeSite(u);
      })
      .catch(() => { /* ... */ });
  }

  async function removeSite(u) {
    setLoading(true);
    await post(`/site/${site.id}/remove`)
    if (params.id !== undefined) {
      window.location.replace(`/customers/${params.id}`)
    } else {
      window.location.replace(`/customer/sites`)
    }
  }

  return ( loading ? (<LoadingFrame />):(
  <>
      {site ? (
      <>
      <Space size="l">
        <FrameHeader title="Info" />
        <Frame>
              <Label text="Site name">
                <TextInput value={site.name}
                  placeholder={""}
                  onChange={e => setSite({ ...site, name: e.target.value })}
                  required
                  disabled={!isAuthorized(state.user.roles, [SYS_ADMIN, TENANT_ADMIN, CUSTOMER_USER]) && hasPermission(state.user.perms, 'ADMIN')} />
              </Label>
          
        </Frame>
        <FrameFooter>
        <Button
             onClick={()=>updateSite()}
              theme="primary"
            >
             Save
            </Button>
        </FrameFooter>
      </Space>
  
      <Space size="l">
        <FrameHeader title="Assets in this site" />
        <Frame padding={0}>
          {assigned.length ? (
            assigned.map(u => (
              <RowItem
                key={u.id}
                title={`${u.name}`}
               
                right={
                  <Button
                    onClick={() => removeFromSite(u.id)}
                    isLoading={assetLoading === u.id}
                  >
                    Remove from site
                  </Button>
                }
              />
            ))
          ) : (
            <NoResults message="There are no assets in this site yet." />
          )}
        </Frame>
      </Space>
      <FrameHeader title="Assets not in this site" />
      <Frame padding={0}>
        {notassigned.length ? (
          notassigned.map(u => (
            <RowItem
              key={u.id}
              title={`${u.name}`}
              right={
                <Button
                  onClick={() => addToSite(u.id)}
                  isLoading={assetLoading === u.id}
                >
                  Add to site
                </Button>
              }
            />
          ))
        ) : (
          <NoResults />
        )}
      </Frame>
      
      {site && isAuthorized(state.user.roles, [SYS_ADMIN, TENANT_ADMIN,CUSTOMER_USER])&& hasPermission(state.user.perms, 'ADMIN')  && (
        <div className={styles.deleteWrap}><Space size="l"/><Button theme="danger" onClick={() => removeSiteConfirmation()}>Delete site</Button></div>)
      }
      </>): (<>Unable to load site details, sorry.</>)}
  
  </>)
   
  );
}
