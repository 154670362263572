import React from 'react';
import styles from './FrameSectionHeader.module.scss';
import Icon from '../Icon';

export default function FrameHeader({ icon, title, offsetPadding = true }) {
  const classes = [
    styles.frameHeader,
    offsetPadding ? styles.offsetPadding : ''
  ].join(' ');
  return (
    <h2 className={classes}>
      <div className={styles.icon}>
        <Icon name={icon} />
      </div>
      {title}
    </h2>
  );
}
