import React from 'react';
import { Link } from 'react-router-dom';

import Icon from '../Icon';
import Button from '../Button';
import Modal from '../Modal';

import styles from './InstallOnAssetStep4.module.scss';

export default function InstallOnAssetStep4({ assetId }) {
  return (
    <Modal hideFooter={true}>
      <div className={styles.wrap}>
        <div className={styles.icon}>
          <Icon name="check_circle" size={64} />
        </div>

        <h1>All set!</h1>
        <p className={styles.body}>
          The device is now running through a brief calibration process and will
          begin monitoring shortly.
        </p>

        <Link to={`/assets/${assetId}`}>
          <Button theme="primary">Finish</Button>
        </Link>
      </div>
    </Modal>
  );
}
