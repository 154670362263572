import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Customers from '../Customers';
import Customer from '../Customer';
import NewCustomer from '../NewCustomer';

// import styles from './TenantsIndex.module.scss';

export default function TenantsIndex() {
  return (
    <>
      <Switch>
        <Route path="/customers" component={Customers} exact />
        <Route path="/customers/new" component={NewCustomer} />
        <Route path="/customers/:id" component={Customer} />
      </Switch>
    </>
  );
}
