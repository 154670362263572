import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { destroy } from '../../api';
import CustomerContext from '../../contexts/Customer';

import FrameSectionHeader from '../../components/FrameSectionHeader';
import FrameHeader from '../../components/FrameHeader';
import Frame from '../../components/Frame';
import DeleteConfirmation from '../../components/DeleteConfirmation';

// import styles from './CustomerSettings.module.scss';

export default function CustomerSettings({ match: { params } }) {
  const [redirect, setRedirect] = useState(false);
  const { customer } = useContext(CustomerContext);

  const deleteCustomer = async () => {
    await destroy(`/customers/${params.id}`);
    setRedirect(`/customers`);
  };

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <>
      <FrameHeader title="Settings" />
      <Frame>
        <FrameSectionHeader icon="delete" title="Delete" />
        <DeleteConfirmation
          challenge={customer.name}
          name="Customer"
          onDelete={deleteCustomer}
        />
      </Frame>
    </>
  );
}
