export const initialAlarmsState = {
  alarms: [],
  loading: false
};

export const AlarmsReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_ALARMS':
      return { ...state, alarms: action.payload.alarms };

    case 'UPDATE_LOADING':
      return { ...state, loading: state.loading };

    default:
      return state;
  }
};
