import React, { useEffect, useRef } from "react";
import styles from "./Modal.module.scss";
import { is } from "date-fns/locale";
import LoadingFrame from "../LoadingFrame";
export default function Modal({
  children,
  footer,
  header,
  small = false,
  simpleMode,
  simpleModeAssetsList,
  onTop = false,
  hideFooter = false,
  isLoading,
}) {
  const ref = useRef(null);
  const isTouch = "ontouchstart" in document.documentElement;
  useEffect(() => {
    return () => {
      //enableBodyScroll(ref.current);
    };
  }, []);

  const classes = [styles.wrap, isTouch ? styles.touch : ""].join(" ");
  return (
    <div>
      <div className={classes}>
        <div
          className={styles.overlay}
          style={simpleMode && { height: "100vh" }}
        />
        <div
          className={small ? styles.framesmall : styles.frame}
          style={simpleMode && { background: "#f8f8f8" }}
        >
          <div className={isLoading && styles.disabled}>
            {isLoading && (
              <div className={styles.loadingFrameStyle}>
                {" "}
                <LoadingFrame />
                <p className={styles.loadingText}>Please wait...</p>
              </div>
            )}
            {simpleMode && header && (
              <div
                style={simpleMode && { justifyContent: "center" }}
                className={styles.header}
              >
                {header}
              </div>
            )}
            <div
              ref={ref}
              className={small == false && styles.scrollWrap}
              style={
                simpleMode &&
                ((header && { top: "65px" }) || (footer && { bottom: "65px" }))
              }
            >
              <div
                style={simpleMode && { height: "100%" }}
                className={styles.innerFrame}
              >
                {children}
              </div>
            </div>
            {footer && !hideFooter && (
              <footer /*style={simpleMode && { justifyContent: "center" }}*/
                className={onTop ? styles.footerOnTop : styles.footer}
              >
                {footer}
              </footer>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
