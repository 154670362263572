import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import TenantModel from '../../models/tenant';

import Button from '../../components/Button';
import Breadcrumb from '../../components/Breadcrumb';
import FrameHeader from '../../components/FrameHeader';
import SingleColumnPage from '../../components/SingleColumnPage';
import TenantInfoForm from '../../components/TenantInfoForm';
import { post } from '../../api';

// import styles from './InviteTenant.module.scss';

export default function InviteTenant() {
  const [form, setForm] = useState(TenantModel);
  const [saving, setSaving] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const breadcrumbs = () => (
    <>
      <Breadcrumb title="Tenants" to="/tenants" />
      <Breadcrumb title="New Tenant" />
    </>
  );

  const createTenant = async () => {
    setSaving(true);
    const res = await post('/tenants', { ...form });
    setSaving(false);
    setRedirect(`/tenants/${res.id}`);
  };

  const inviteTenant = () => (
    <SingleColumnPage breadcrumbs={breadcrumbs}>
      <FrameHeader title="New Tenant" />
      <TenantInfoForm state={form} setState={setForm}>
        <Button theme="primary" onClick={createTenant} isLoading={saving}>
          Create
        </Button>
      </TenantInfoForm>
    </SingleColumnPage>
  );

  return redirect ? <Redirect to={redirect} /> : inviteTenant();
}
