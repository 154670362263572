import React, { useEffect } from "react";
import { Router, Switch, Route } from "react-router-dom";
import history from "../../helpers/history";
import ScrollToTop from "react-router-scroll-top";
import useThunk from "../../hooks/thunk";
import { AuthContext } from "../../contexts/Auth";
import { authWithToken } from "../../actions/Auth";
import { initialAuthState, AuthReducer } from "../../reducers/Auth";
import LogIn from "../LogIn";
import OneTimeLogin from "../OneTimeLogin";
import SignUp from "../SignUp";
import Forgot from "../Forgot";
import Register from "../Register";
import Expired from "../Expired";
import Sentinel from "../Sentinel";
import ResetPassword from "../ResetPassword";
import ErrorPage from "../Error";
import TermsOfService from "../TermsOfService";
import DeviceInstallRedirect from "../DeviceInstallRedirect";
import Center from "../../components/Center";
import Loading from "../../components/Loading";
import ProtectedRoute from "../../components/ProtectedRoute";
import "./App.module.scss";
import SystemStatusBanner from "../../components/SystemStatusBanner";
import CacheBuster from "react-cache-buster";
import packageJson from "../../../package.json";
export default function App() {
  const [state, dispatch] = useThunk(AuthReducer, initialAuthState);
  const isProduction =
    window.environment.environment === "PRODUCTION" ||
    window.environment.environment === "DEV";
  useEffect(() => {
    const refreshToken = window.localStorage.getItem("refreshToken");
    refreshToken
      ? dispatch(authWithToken(refreshToken))
      : dispatch({ type: "AUTH_STATE_CHECKED" });
  }, []);

  const _app = () => (
    <>
      {/*<SystemStatusBanner />*/}
      <CacheBuster
        currentVersion={packageJson.version}
        isEnabled={isProduction} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
        metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
      >
        <AuthContext.Provider value={{ state, dispatch }}>
          <Router history={history}>
            <ScrollToTop>
              <Switch>
                <Route exact path="/log-in" component={LogIn} />

                <Route
                  exact
                  path="/onetimecode/:token"
                  component={OneTimeLogin}
                />

                <Route exact path="/sign-up" component={SignUp} />
                <Route exact path="/forgot" component={Forgot} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/reset-password" component={ResetPassword} />
                <Route exact path="/expired" component={Expired} />
                <Route
                  exact
                  path="/terms-of-service"
                  component={TermsOfService}
                />

                <Route
                  exact
                  path="/error/:statusCode?/:errorId?"
                  component={ErrorPage}
                />

                {/* QR Code redirects */}
                <ProtectedRoute
                  exact
                  path="/qr/device/:serialNumber"
                  component={DeviceInstallRedirect}
                />

                <ProtectedRoute path="/" component={Sentinel} />
              </Switch>
            </ScrollToTop>
          </Router>
        </AuthContext.Provider>
      </CacheBuster>
    </>
  );

  const _loading = () => (
    <Center>
      <Loading color="gray" size={42} />
    </Center>
  );

  return state.isCheckingAuthState ? _loading() : _app();
}
