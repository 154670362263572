import React from 'react';
import getStatus from '../../helpers/statuses';
import StatusIndicator from '../StatusIndicator';
import styles from './EventTypeChanged.module.scss';
export default function EventTypeChanged({
  from,
  to,
  changing = false,
}) {
  return (
    <div className={styles.wrap}>
      <StatusIndicator status={from} />
      <div className={styles.text}>
        <h3 className={styles.status}>{getStatus(from).name}</h3>
      </div>
      {to && (<><div className={styles.wrapclear}>
        <p className={styles.subtitle} >{changing ? ("is changing to"):("changed to")}</p>
      </div>
      
      <div className={styles.spacer}></div>
     
      <StatusIndicator status={to} />
      <div className={styles.text}>
        <h3 className={styles.status}>{getStatus(to).name}</h3>
      </div> </>)}
    </div>
  );
}
