
import Chip from '@mui/material/Chip';
import Icon from '../Icon';
export default function AddNewChip(props, label) {
  return (
    <Chip
      {...props}
      color="primary"
      icon={<div><Icon marginTop={6} name='add_circle' size={24} /></div>}
      sx={{
        background: '#f26925'
      }}
    />
  );
}
