import React, { useState, useEffect } from 'react';

import Button from '../Button';

import styles from './Pagination.module.scss';

export default function Pagination({
  total,
  perPage,
  currentPage,
  onPageChange
}) {
  const [pages, setPages] = useState([]);

  useEffect(() => {
    const count = Math.ceil(total / perPage);
    const pages = [...Array(count)].map((k, i) => i + 1);

    setPages(pages);
  }, [total, perPage]);

  return (
    <div className={styles.wrap}>
      {total>perPage && pages.map(p => (
        <div className={styles.page} key={p}>
          <Button disabled={p === currentPage} onClick={() => onPageChange(p)}>
            {p}
          </Button>
        </div>
      ))}
    </div>
  );
}
