import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { useFetch } from '../../hooks/fetch';
import CustomerContext from '../../contexts/Customer';
import SubscriptionAdd from '../SubscriptionAdd';
import NewUser from '../NewCustomerUser';
import Info from '../CustomerInfo';
import Devices from '../CustomerDevices';
import Subscriptions from '../CustomerSubscriptions';
import Sites from '../CustomerSites';
import Groups from '../CustomerGroups';
import NewGroup from '../NewCustomerGroup';
import GroupDetail from '../GroupDetail';
import SiteDetail from '../SiteDetail';
import DefaultGroupDetail from '../DefaultGroupDetail';
import Users from '../CustomerUsers';
import Settings from '../CustomerSettings';
import LicenseDetail from '../LicenseDetail/LicenseDetail';
import LicenseAdd from '../LicenseAdd';
import Breadcrumb from '../../components/Breadcrumb';
import LoadingFrame from '../../components/LoadingFrame';
import SingleColumnPage from '../../components/SingleColumnPage';
import SubNavigation from '../../components/SubNavigation';

// import styles from './Customer.module.scss';

export default function Customer({ history, match: { params } }) {
  const [data, loading /*, error */] = useFetch(`/customers/${params.id}`);
  const [customer, setCustomer] = useState(null);

  useEffect(() => {
    setCustomer(data);
  }, [data]);

  const breadcrumbs = () => (
    <>
      <Breadcrumb title="Customers" to="/customers" />
      <Breadcrumb title={customer && customer.name} />
    </>
  );

  const subNav = [
    { to: `/customers/${params.id}/info`, name: 'Info' },
    { to: `/customers/${params.id}/devices`, name: 'Devices' },
    //{ to: `/customers/${params.id}/sites`, name: 'Sites' },
    { to: `/customers/${params.id}/groups`, name: 'Groups' },
    { to: `/customers/${params.id}/users`, name: 'Users' },
    { to: `/customers/${params.id}/subscriptions`, name: 'Subscription' },
    { to: `/customers/${params.id}/settings`, name: 'Settings' }
  ];

  return (
    <SingleColumnPage breadcrumbs={breadcrumbs}>
      {loading ? (
        <LoadingFrame />
      ) : (
        <>
          <SubNavigation items={subNav} history={history} />

          <CustomerContext.Provider value={{ customer, setCustomer }}>
            <Switch>
              <Redirect from="/customers/:id" to="/customers/:id/info" exact />
              <Route path="/customers/:id/info" component={Info} />
              {/*<Route path="/customers/:id/sites" component={Sites} exact />*/}
              <Route path="/customers/:id/devices" component={Devices} />
              <Route path="/customers/:id/groups" component={Groups} exact />
              <Route path="/customers/:id/subscriptions" component={Subscriptions} exact />
              <Route
                path="/customers/:id/groups/new"
                component={NewGroup}
                exact
              />
              {/* <Route
                path="/customers/:id/groups/default"
                component={DefaultGroupDetail}
                exact
      /> */}
              <Route
                path="/customers/:id/subscriptions/:subscriptionId/license/:licenseId"
                component={LicenseDetail}
                exact
              />
              <Route
                path="/customers/:id/subscriptions/new"
                component={SubscriptionAdd}
                exact
              />
              <Route
                path="/customers/:id/subscriptions/:subscriptionId/new"
                component={LicenseAdd}
                exact
              />
              <Route
                path="/customers/:id/groups/:groupId"
                component={GroupDetail}
                exact
              />
              {/*<Route
                path="/customers/:id/sites/:siteId"
                component={SiteDetail}
                exact
      />*/}

              <Route path="/customers/:id/users" component={Users} exact />
              <Route
                path="/customers/:id/users/new"
                component={NewUser}
                exact
              />
              <Route path="/customers/:id/settings" component={Settings} />
            </Switch>
          </CustomerContext.Provider>
        </>
      )}
    </SingleColumnPage>
  );
}
