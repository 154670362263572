import React, { forwardRef } from 'react';
import Icon from '../Icon';
import Loading from '../Loading';
import styles from './TextInput.module.scss';

export default forwardRef(({ icon, loading,disabled=false, assetDashboard, spinHide, error,serial_width, ...rest }, ref) => {
  const classes = [
    styles.input,
    serial_width ? styles.serialWidth : '',
    icon ? styles.withIcon : '',
    loading ? styles.withLoading : '',
    error ? styles.error : '',
    spinHide ? styles.spinHide: '',
    assetDashboard ? styles.assetDashboard: '',
  ].join(' ');

  return (
    <div className={styles.textInput}>
      <div className={styles.wrap}>
        {icon && (
          <div className={styles.icon}>
            <Icon name={icon} size={20} />
          </div>
        )}
        <input disabled={disabled} className={classes} {...rest} ref={ref} />
        {loading && (
          <div className={styles.loading}>
            <Loading color="gray" size={20} />
          </div>
        )}

        {error && <div className={styles.errorMessage}>{error}</div>}
      </div>
    </div>
  );
});
