import queryString from "query-string";
import React, { useContext, useEffect, useState } from "react";
import { updateRelation } from "../../actions/Asset";
import { destroy, get,post } from "../../api";
import InstallOnAssetStep1 from "../../components/InstallOnAssetStep1";
import InstallOnAssetStep2 from "../../components/InstallOnAssetStep2";
import InstallOnAssetStep3 from "../../components/InstallOnAssetStep3";
import InstallOnAssetStep4 from "../../components/InstallOnAssetStep4";
import InstallOnAssetStep5 from "../../components/InstallOnAssetStep5";
import {READY} from '../../constants/statuses';
import InstallOnAssetStepLicense from "../../components/InstallOnAssetStepLicense";
import LoadingFrame from "../../components/LoadingFrame";
import Modal from "../../components/Modal";
import AssetContext from "../../contexts/Asset";
import { AuthContext } from "../../contexts/Auth";
import history from "../../helpers/history";
import { useConfirm } from 'material-ui-confirm';
export default function InstallOnAsset({ match: { params }, location: { search } }) {
  const queryParams = queryString.parse(search);
  const { state: currentUser } = useContext(AuthContext);
  const [state, dispatch] = useContext(AssetContext);
  const { customer_id } = state.asset;
  const [devices, setDevices] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [selected_sub_id, setSelectedSub] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [switchOwner, setSwitchOwner] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(params.devId);
  const [device, setDevice] = useState(null);
  const [removeOnCancel, setRemoveOnCancel] = useState(false);
  const confirm = useConfirm();
  function isDeviceActivated(deviceId) {
    return devices.filter(x => x.id === Number(deviceId))[0].activation_time !== undefined;
  }
  function returnDeviceSelected(deviceId) {
    return devices.filter(x => x.id === Number(deviceId))[0];
  }
  function returnDeviceSelectedBySerial(serial) {
    return devices.filter(x => x.serial_number === Number(serial))[0];
  }
  async function fetchInactiveDevices(assign = false) {
    setLoading(true);
    const devices = await get(`/deviceslist`, {
      active: "false",
      customer_id: customer_id
    });
    setDevices(devices);
    setLoading(false);
  }

  async function fetchSubscriptions() {
    setLoading(true);
    const subscriptions = await get(`/customer/subscriptions/newasset`,{only_available:true});
    setSubscriptions(subscriptions);
    setLoading(false);
  }
  useEffect(() => {
    fetchInactiveDevices();
    fetchSubscriptions();
    let roc = queryParams.roc; // remove on cancel
    roc && roc !== '' && setRemoveOnCancel(true);

  }, []);
  function deleteAssetConfirmation() {
    confirm({
      description: 'Are you sure you want to cancel? The Sentinel device will not be activated.',
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true }
    })
      .then(() => {
        deleteAsset();
      })
      .catch(() => { /* ... */ });
  };

  async function deleteAsset() {
    await destroy(`/assets/${state.asset.id}`);
    if (window.screen.width < 1000) {
      history.push("/assets");
    } else {
      history.push("/assets_dashboard");
    }
  }

  function _renderLoading() {
    return (
      <Modal>
        <LoadingFrame />
      </Modal>
    );
  }

  function quitSilent() {
    confirm({
      description: 'Are you sure you want to cancel?',
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true }
    })
      .then(() => {
        history.push(`/assets/${state.asset.id}`);
      })
      .catch(() => { /* ... */ });
  }
  async function applyLicense() {
    const license = await post(`/customer/subscriptions/auto/assign`, {
      auto_assign: true,
      devices: [selectedDevice]
    });
    
  }
  async function determineNextStep(deviceId, serial) {
    setLoading(true);
    if (device === null) {//seleted from list
      const devices = await get(`/deviceslist`, {
        active: "false",
        customer_id: customer_id
      });
      setDevices(devices);

      if (deviceId != null) {
        setSelectedDevice(deviceId);
        var dev = devices.filter(x => x.id === Number(deviceId))[0];
        dev && setDevice(dev) && setSwitchOwner(dev.customer_id !== currentUser.user.customer_id);

        var newDevice = (dev.status===READY || dev.customer_activation_time===undefined) && dev.active===false;

        if(newDevice===true){
          setPage(2);//ok
        }else{
          if (subscriptions.length !== 0) { // subs available
            setSelectedSub(subscriptions[0].id)
            setPage(2); 
          }else{
            setPage(6); // subscription page with redirect
          }
        }

      } else {
        var dev = devices.filter(x => x.serial_number === Number(serial))[0]
        dev && setDevice(dev);
        setSelectedDevice(dev.id);
        setSwitchOwner(dev.customer_id !== currentUser.user.customer_id);
        var newDevice = (dev.status===READY || dev.customer_activation_time===undefined) && dev.active===false;
        
        if(newDevice===true){
          setPage(2);//ok
        }else{
          if (subscriptions.length !== 0) { // subs available
            setSelectedSub(subscriptions[0].id)
            setPage(2); 
          }else{
            setPage(6); // subscription page with redirect
          }
        }

      }
    }else{ // entered serial number (new device)

      setSelectedDevice(device.id);
      setSwitchOwner(device.customer_id !== currentUser.user.customer_id);
      var newDevice = device.status===READY && device.active===false;
      setSwitchOwner(device.customer_id !== currentUser.user.customer_id && newDevice);
      setPage(2); // skiplicense page
    }
    setLoading(false);
  }

  async function updateAssetRelation(relation){

    if(switchOwner===false){
      applyLicense();
    } 
    var newDevice = device.status===READY && device.active===false;
    dispatch(updateRelation(state.asset.id, relation));
    setPage(4);

  }
  function _renderPage() {
    switch (page) {
      case 1:
        return (
          <InstallOnAssetStep1
            asset={state.asset}
            devices={devices}
            loading={loading}
            back={() => removeOnCancel ? deleteAssetConfirmation() : quitSilent()}
            //activation_feedback={fetch =>{fetchInactiveDevices()}}
            next={(deviceId, serial) => {
              determineNextStep(deviceId, serial);
            }}
            setDevice={device => setDevice(device)}
          
          />);
    
      case 2:
        return (
          <InstallOnAssetStep2
            asset={state.asset}
            back={() => setPage(1)}
            next={() => setPage(3)}
          />
        );

      case 3:
        return (
          <InstallOnAssetStep3
            switch_owner={switchOwner}
            back={() => setPage(2)}
            assetId={state.asset.id}
            selectedDevice={selectedDevice}
            next={relation => {
              updateAssetRelation(relation)}
            }
          />
        );

      case 4:
        return <InstallOnAssetStep4 assetId={state.asset.id} />;

      case 5:
        //activation
        return (<InstallOnAssetStep5
          deviceInput={device}
          back={() => setPage(1)}
          activation_feedback={fetch => { fetchInactiveDevices() }}
          next={() => {
            setPage(2);
          }}
        />);
        case 6:
          //license selection
          return (<InstallOnAssetStepLicense
            assetId={state.asset.id}
            deviceInput={device}
            back={() => setPage(1)}
            subscriptions={subscriptions}
            selected_sub_id={selected_sub_id}
            setSelectedSub={setSelectedSub}
            next={() => {
              setPage(2);
            }}
          />);
  
      default:
        return null;
    }
  }

  return state.loading > 0 ? _renderLoading() : _renderPage();
}
