import React, { useState, useEffect, useContext } from 'react';
import { put, get } from '../../api';
import Chip from '@mui/material/Chip';
import AddNewChip from '../AddNewChip/AddNewChip';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import Space from '../../components/Space';
import { AuthContext } from '../../contexts/Auth';
import { hasPermission } from "../../helpers/authorization";
import LoadingFrame from '../../components/LoadingFrame';

export default function AssetGroupPopup({ asset, groups, onClose }) {
  const { state: currentUser } = useContext(AuthContext);
  const isGroupAdmin = hasPermission(currentUser.user.perms, 'ADMIN');
  const [loading, setLoading] = useState(true);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groupsToAdd, setGroupsToAdd] = useState([]);
  const [groupsToRemove, setGroupsToRemove] = useState([]);
  const [saving, setSaving] = useState(false);
  const filter = createFilterOptions();

  useEffect(() => {
    const tempGroups = asset.groups.slice();
    setSelectedGroups(tempGroups);
    setLoading(false);

  }, []);

  async function saveEdits() {
    setSaving(true);
    asset.groups = selectedGroups;
    const update = await put(`/assets/${asset.id}`, asset);
    const groups = await get(`/assets/${asset.id}/groups`);
    update && groups && (asset.groups = groups);
    setSelectedGroups(asset.groups);
    onClose();
  }

  function _renderFooter() {
    return (
      <>
        <Button onClick={() => onClose()}>Cancel</Button>
        <Button
          theme="primary"
          onClick={saveEdits}
          isLoading={loading || saving}
          disabled={saving || !enableSave()}
        >
          Save
        </Button>
      </>
    );
  }

  function enableSave() {
    return (
      groupsToAdd.length > 0 ||
      groupsToRemove.length > 0
    );
  }

  function groupHandler(value, reason, detail) {
    var item = detail?.option;
    if (reason == 'removeOption') {
      var justAdded = groupsToAdd?.find(x => x.id == item.id);
      if (!justAdded) {
        setGroupsToRemove([...groupsToRemove, detail?.option]);
      } else {
        setGroupsToAdd(groupsToAdd.filter(x => x.id != item.id));
      }
      setSelectedGroups(selectedGroups.filter(x => x.id != item.id));
    }

    if (reason == 'selectOption') {
      var item = detail?.option
      //check if existing in removed group
      var currentGroups = selectedGroups;
      var justRemoved = groupsToRemove?.find(x => x.id == item.id);
      if (!justRemoved) {
        item.new = true;
        setGroupsToAdd([...groupsToAdd, item]);
      } else {
        setGroupsToRemove(groupsToRemove.filter(x => x.id != item.id));
      }
      currentGroups?.push(item);
      setSelectedGroups(currentGroups);
    }
  }

  return (
    <Modal small footer={_renderFooter()}>

      <Space size="m">
        <h2>{asset.name} - Groups</h2>
      </Space>

      {loading ? (
        <LoadingFrame />
      ) : (
        <Autocomplete
          multiple
          id="tags-standard"
          filterSelectedOptions
          options={groups.filter(x => x.customer_id == asset.customer_id)}
          readOnly={!isGroupAdmin}
          getOptionLabel={(option) => option.id == 0 ? <b>Add "{option.name}"</b> : option.name}
          onChange={(event, value, reason, detail) => { groupHandler(value, reason, detail) }}
          renderInput={(params) => (<TextField {...params} variant="standard" placeholder={isGroupAdmin && "Enter name"} />)}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => (option.id == 0 ? <AddNewChip {...getTagProps({ index })} label={option.name} /> : <Chip {...getTagProps({ index })} label={option.name} />));
          }}
          value={selectedGroups}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            if (filtered === undefined) return [];
            var filteredduplicates = filtered.filter(({ id: id1 }) => !selectedGroups.some(({ id: id2 }) => id2 === id1));
            const exist = filteredduplicates.find(item => item.name.toLowerCase() == params.inputValue.toLowerCase());
            const existInSelected = selectedGroups.find(item => item.name.toLowerCase() == params?.inputValue.toLowerCase());
            if (params.inputValue !== '' && !exist && !existInSelected) {
              var selectedValueInSelectedGroups = selectedGroups.find(item => item.name.toLowerCase() == params?.inputValue.toLowerCase());
              if (!selectedValueInSelectedGroups) {
                filteredduplicates.push({ id: 0, title: `Add "${params.inputValue}"`, name: `${params.inputValue}`, });
              }
            }
            return filteredduplicates;
          }
          }
        />)}
    </Modal>);
}
