import React from 'react';
import styles from './CenteredColumn.module.scss';

export default function CenteredColumn({ children }) {
  return (
    <div className={styles.wrap}>
      <div className={styles.column}>{children}</div>
    </div>
  );
}
