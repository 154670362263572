import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { destroy, get, post} from '../../api';
import Button from '../../components/Button';
import InputRow from '../../components/InputRow';
import Label from '../../components/Label';
import LoadingFrame from '../../components/LoadingFrame';
import Modal from '../../components/Modal';
import Select from '../../components/Select';
import Space from '../../components/Space';
import TextInput from '../../components/TextInput';
import moment from 'moment';
import { isAuthorized, hasPermission } from "../../helpers/authorization";
import { AuthContext } from "../../contexts/Auth";
import { CUSTOMER_USER, SYS_ADMIN, TENANT_ADMIN } from '../../constants/roles';
import { ALARM, ASSET_FILE_ADD, FALSE_ALERT, WORK_COMPLETE } from '../../constants/events';
import { WORK_IN_PROGRESS } from '../../constants/statuses';
import { useConfirm } from 'material-ui-confirm';
export default function AssetEventEdit({ match: { params } }) {
  const { state } = useContext(AuthContext);
  const [event, setEvent] = useState({});
  const [types, setTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const confirm = useConfirm();

  useEffect(() => {
    async function fetchEvent() {
      const res = await get(`/events/${params.id}`,{asset_id:params.assetId});
      res && setEvent(res);
      const types = await get(`/events/types`);
      types && setTypes(types);
      setLoading(false);
    }
    fetchEvent();
  }, []);


  function eventTypeAvailable(type){

    var available = types.filter(x=>x.type==type);
    return available.length!==0
  }
  function _saveEventConfirmation()
  {
    confirm({ 
      description: 'Are you sure you want to modify this event?', 
      title: null, 
      dialogProps: { fullScreen: false }, 
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true }
     })
      .then(() => {
        _saveEvent();
      })
      .catch(() => { /* ... */ });
  }
  async function _saveEvent() {
      setSaving(true);
      const saveEvent = await post(`/events/${params.id}`,event);
      saveEvent && window.location.replace(`/assets/${params.assetId}/`);
  }
  
  function changeTime(time) {
    setEvent({ ...event, created_time: time})
  }

  function time(timestamp) {
    if (timestamp.includes("Date")) {
      return moment.utc(timestamp).format('MM/d/YYYY h:mma');
    } else {
      return timestamp;
    }
  }

  function _removeConfirmation()
  {
    confirm({ 
      description: 'Are you sure you want to delete this event?', 
      title: null, 
      dialogProps: { fullScreen: false }, 
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true }
     })
      .then(() => {
        _remove();
      })
      .catch(() => { /* ... */ });
  }

  async function _remove() {
      setSaving(true);
      const del = await destroy(`/events/${params.id}`);
      del && window.location.replace(`/assets/${params.assetId}/`);
  }

  function reference(id) {
    switch (event.type) {
      case ALARM: return "Alarm id";
      case WORK_IN_PROGRESS: return "Alarm id";
      case FALSE_ALERT: return "Alarm id";
      case WORK_COMPLETE: return "Alarm id";
      case ASSET_FILE_ADD: return "File id";
      default: return "Reference id";
    }
  }
  
  function _renderFooter() {
    return (
      <>
        {isAuthorized(state.user.roles, [SYS_ADMIN, TENANT_ADMIN, CUSTOMER_USER]) && (hasPermission(state.user.perms, 'ADMIN') || hasPermission(state.user.perms, 'ASSET_C')) && <Button theme="danger" onClick={() => _removeConfirmation()} disabled={saving}>
          Delete
        </Button>}
        <Link to={`/assets/${params.assetId}`}>
          <Button>Cancel</Button>
        </Link>
        <Button theme="primary" onClick={() => _saveEventConfirmation()} isLoading={saving}>
          Save
        </Button>
      </>
    );
  }
  return (
    <Modal footer={_renderFooter()}>
      {loading ? (
        <LoadingFrame />
      ) : ((types && event) ? (
        <>
          <Space size="l">
            <h2>Edit event</h2>
          </Space>

          <InputRow>
              {eventTypeAvailable(event.type) && (<Label text="Event type">
                <Select
                  value={event.type}
                  onChange={e => setEvent({ ...event, type: e.target.value })}>
                  {types.map(type => (
                    <option key={type.id} value={type.type}>
                      {type.type}
                    </option>
                  ))}
                </Select>
              </Label>)}
            <Label text="Event created time UTC(month,day,year)">
              <TextInput
                value={time(event.created_time)}
                onChange={e => changeTime(e.target.value)} />
            </Label>
          </InputRow>

          <InputRow>
            {event.body && (<Label text="Event body">
              <TextInput
                value={event.body}
                onChange={e => setEvent({ ...event, body: e.target.value })} />
            </Label>)}
          </InputRow>

          <InputRow>
            {event.reference_id && (<Label text={reference(event.type)}>
              <TextInput
                value={event.reference_id}
                onChange={e => setEvent({ ...event, reference_id: e.target.value })} />
            </Label>)}

            {event.device_id && (<Label text="Device id">
              <TextInput
                value={event.device_id}
                onChange={e => setEvent({ ...event, device_id: e.target.value })} />
            </Label>)}
          </InputRow>
        </>) : (<>Error</>)
        )}
    </Modal>
  );
}
