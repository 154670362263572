import React, { useState, useContext, useEffect } from "react";
import { useFormState } from "react-use-form-state";
import { hasPermission } from "../../helpers/authorization";
import { AuthContext } from "../../contexts/Auth";
import { post } from "../../api";
import validate from "../../helpers/validate";
import Button from "../../components/Button";
import Frame from "../../components/Frame";
import FrameFooter from "../../components/FrameFooter";
import InputRow from "../../components/InputRow";
import Label from "../../components/Label";
import TextInput from "../../components/TextInput";
import FrameHeader from "../../components/FrameHeader";
import CheckboxWithLabel from "../../components/CheckboxWithLabel";
import TextField from "@mui/material/TextField";
import Space from "../../components/Space";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import flags from "react-phone-number-input/flags";
import { useConfirm } from "material-ui-confirm";
import Chip from "@mui/material/Chip";
import AddNewChip from "../../components/AddNewChip/AddNewChip";
import styles from "./UserInfoForm.module.scss";
export default function UserInfoForm({
  state,
  groups,
  permissions,
  saving,
  onSave,
  onUpdateUserGroup,
  onRemoveUserGroup,
  isNew,
}) {
  const { state: currentUser } = useContext(AuthContext);
  const isGroupAdmin = hasPermission(currentUser.user.perms, "ADMIN");
  const confirm = useConfirm();
  const [permState, setPermState] = useState(
    permissions || {
      admin: false,
      assets: true,
      inspection: true,
    }
  );
  const [initialPermissions, setInitialPermissions] = useState(
    permissions || {
      admin: false,
      assets: true,
      inspection: true,
    }
  );
  const filter = createFilterOptions();
  const [phoneValidationError, setphoneValidationError] = useState(undefined);
  const [cboxNotificationEmail, setCboxNotificationEmail] = useState(
    !state.disable_notification
  );
  const [cboxnotificationTxt, setCboxNotificationTxt] = useState(
    !state.disable_notification_txt
  );
  const [formState, { text, tel, email, checkbox }] = useFormState({
    ...state,
  });
  const [selectedGroups, setSelectedGroups] = useState([]);
  const valid = validate(formState, {
    required: ["first_name", "last_name", "phone", ...(isNew ? ["email"] : [])],
  });
  const isLoggedInUser = currentUser.user.user_id === formState.values.id;
  const [groupsToAdd, setGroupsToAdd] = useState([]);
  const [groupsToRemove, setGroupsToRemove] = useState([]);

  function enableSave() {
    return (
      formState?.values.first_name != state.first_name ||
      formState?.values.last_name != state.last_name ||
      formState?.values.phone != state.phone ||
      formState?.values.groups != state.groups ||
      formState?.values.disable_notification != state.disable_notification ||
      formState?.values.disable_notification_txt !=
        state.disable_notification_txt ||
      groupsToAdd?.length > 0 ||
      groupsToRemove?.length > 0 ||
      permState.admin != initialPermissions.admin ||
      permState.assets != initialPermissions.assets ||
      permState.deleteInspection != initialPermissions.deleteInspection ||
      permState.inspection != initialPermissions.inspection
    );
  }

  useEffect(() => {
    state.groups !== undefined
      ? setSelectedGroups(state.groups)
      : setSelectedGroups([]);
  }, []);

  function changeState(target, first) {
    if (first) {
      formState.values.disable_notification = !target.target.checked;
      setCboxNotificationEmail(target.target.checked);
    }
    if (!first) {
      formState.values.disable_notification_txt = !target.target.checked;
      setCboxNotificationTxt(target.target.checked);
    }
  }

  function updateEmail(val) {
    if (val != undefined && val != "") {
      if (isValidPhoneNumber(val)) {
        formState.setField("phone", val);
        setphoneValidationError(false);
      } else {
        setphoneValidationError("Enter a valid phone number.");
      }
    } else {
      setphoneValidationError("Fill out this field.");
    }
  }

  async function createNewGrop(user, groupName) {
    const res = await post("/groups", {
      name: groupName,
      tenant_id: user.tenant_id,
      customer_id: user.customer_id,
    });
    res && !isLoggedInUser && onUpdateUserGroup(user.id, res.id);
  }

  async function SaveUserAndGroups(values, permState) {
    groupsToAdd &&
      (await Promise.all(
        groupsToAdd.map(async (group) => {
          if (group.id != 0) {
            await onUpdateUserGroup(formState.values.id, group.id);
          } else {
            await createNewGrop(formState.values, group.name);
          }
        })
      ));

    groupsToRemove &&
      (await Promise.all(
        groupsToRemove.map(async (group) => {
          await onRemoveUserGroup(formState.values.id, group.id);
        })
      ));

    onSave(values, permState);
  }

  function groupHandler(value, reason, detail) {
    var item = detail?.option;
    if (reason == "removeOption") {
      var justAdded = groupsToAdd?.find((x) => x.id == item.id);
      if (!justAdded) {
        setGroupsToRemove([...groupsToRemove, detail?.option]);
      } else {
        setGroupsToAdd(groupsToAdd?.filter((x) => x.id != item.id));
      }
      setSelectedGroups(selectedGroups?.filter((x) => x.id != item.id));
    }

    if (reason == "selectOption") {
      var item = detail?.option;
      //check if existing in removed group
      var currentGroups = selectedGroups;
      var justRemoved = groupsToRemove?.find((x) => x.id == item.id);
      if (!justRemoved) {
        item.new = true;
        setGroupsToAdd([...groupsToAdd, item]);
      } else {
        setGroupsToRemove(groupsToRemove?.filter((x) => x.id != item.id));
      }
      currentGroups?.push(item);
      setSelectedGroups(currentGroups);
    }
  }

  function renderGroups() {
    return (
      <>
        <div className={styles.break} />
        <FrameHeader title="Groups" />

        <Autocomplete
          multiple
          id="tags-standard"
          filterSelectedOptions
          readOnly={!isGroupAdmin}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder={isGroupAdmin && "Enter name"}
            />
          )}
          options={groups ? groups : []}
          getOptionLabel={(option) =>
            option?.id == 0 ? <b>Add "{option.name}"</b> : option.name
          }
          onChange={(event, value, reason, detail) => {
            groupHandler(value, reason, detail);
          }}
          renderTags={(tagValue, getTagProps) => {
            return tagValue?.map((option, index) =>
              option?.id == 0 ? (
                <AddNewChip {...getTagProps({ index })} label={option.name} />
              ) : (
                <Chip {...getTagProps({ index })} label={option.name} />
              )
            );
          }}
          value={selectedGroups}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            if (filtered === undefined) return [];
            var filteredduplicates = filtered?.filter(
              ({ id: id1 }) =>
                !selectedGroups.some(({ id: id2 }) => id2 === id1)
            );
            const exist = filteredduplicates?.find(
              (item) =>
                item.name.toLowerCase() == params.inputValue.toLowerCase()
            );
            const existInSelected = selectedGroups.find(
              (item) =>
                item.name.toLowerCase() == params?.inputValue.toLowerCase()
            );
            if (params.inputValue !== "" && !exist && !existInSelected) {
              var selectedValueInSelectedGroups = selectedGroups.find(
                (item) =>
                  item.name.toLowerCase() == params?.inputValue.toLowerCase()
              );
              if (!selectedValueInSelectedGroups) {
                filteredduplicates.push({
                  id: 0,
                  title: `Add "${params.inputValue}"`,
                  name: `${params.inputValue}`,
                });
              }
            }
            return filteredduplicates;
          }}
        />
        <Space size={"xs"} />
      </>
    );
  }

  return (
    <>
      <Frame>
        <InputRow>
          <Label text="First name">
            <TextInput
              {...text("first_name")}
              error={
                formState.touched.first_name && formState.errors.first_name
              }
              required
            />
          </Label>

          <Label text="Last name">
            <TextInput
              {...text("last_name")}
              error={formState.touched.last_name && formState.errors.last_name}
              required
            />
          </Label>
        </InputRow>

        <InputRow>
          <Label text="Email">
            {isNew ? (
              <TextInput
                {...email("email")}
                error={formState.touched.email && formState.errors.email}
                required
              />
            ) : (
              <TextInput value={state.email} readOnly disabled />
            )}
          </Label>
          <Label text="Telephone">
            <PhoneInput
              defaultCountry="US"
              countryCallingCodeEditable={false}
              required
              international
              error={phoneValidationError}
              flags={flags}
              inputComponent={TextInput}
              onChange={(val) => updateEmail(val)}
              value={
                formState.values.phone ? formState.values.phone : undefined
              }
            />
          </Label>
        </InputRow>

        {renderGroups()}
        <div className={styles.break} />
        <FrameHeader title="Notifications" />
        <InputRow>
          <CheckboxWithLabel
            fullwidth={true}
            checked={cboxNotificationEmail}
            label="Email"
            description="This user will receive email notifications from the Sentinel dashboard."
            onChange={(e) => {
              changeState(e, true);
            }}
          />

          <CheckboxWithLabel
            fullwidth={true}
            checked={cboxnotificationTxt}
            label="Text message"
            description="This user will receive text notifications from the Sentinel dashboard."
            onChange={(e) => {
              changeState(e, false);
            }}
          />
        </InputRow>

        {hasPermission(currentUser.user.perms, "ADMIN") && !isLoggedInUser && (
          <>
            <div className={styles.break} />
            <FrameHeader title="Permissions" />
            <InputRow>
              <CheckboxWithLabel
                fullwidth={true}
                label="Admin"
                description="This user can manage all content on the account, including adding and removing users and their permissions."
                checked={permState.admin}
                onChange={(e) =>
                  setPermState({ ...permState, admin: e.target.checked })
                }
              />

              <CheckboxWithLabel
                fullwidth={true}
                label="Can manage assets"
                description="This user can create, edit, and delete assets."
                disabled={permState.admin}
                checked={permState.admin || permState.assets}
                onChange={(e) =>
                  setPermState({ ...permState, assets: e.target.checked })
                }
              />
            </InputRow>
            <InputRow>
              <CheckboxWithLabel
                fullwidth={true}
                label="Can create notes and inspections"
                description="This user can create notes and inspections on devices and assets."
                disabled={permState.admin}
                checked={permState.admin || permState.inspection}
                onChange={(e) =>
                  setPermState({ ...permState, inspection: e.target.checked })
                }
              />
              <div />
            </InputRow>
          </>
        )}

        <FrameFooter>
          <Button
            theme="primary"
            onClick={() => SaveUserAndGroups(formState.values, permState)}
            isLoading={saving}
            disabled={!valid || phoneValidationError || !enableSave()}
          >
            {isNew ? "Create" : "Save"}
          </Button>
        </FrameFooter>
      </Frame>
    </>
  );
}
