import React from 'react';
import styles from './Space.module.scss';

export default function Space({ children, size = 'm',max_width=false }) {
  var className = styles[`${size}`];
  
  if(max_width!=false){className+=' '+styles[`${max_width}`]}
  
  return <div className={className}>{children}</div>;
}
