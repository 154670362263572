import React from "react";
import styles from "./Avatar.module.scss";

export default function Avatar({ name, theme = "default" }) {
  const split = name.split(" ");
  const initials =
    split != undefined
      ? split[0][0] +
        (split[split.length - 1].length > 1 ? split[split.length - 1][0] : "")
      : "SA";

  const classes = [styles.avatar, styles[theme]].join(" ");

  return <div className={classes}>{initials}</div>;
}
