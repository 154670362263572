import React from 'react';
import { Link } from 'react-router-dom';
import Center from '../Center';
import Frame from '../Frame';
import Space from '../Space';
import Logo from '../Logo';
import styles from './LoginFrame.module.scss';
export default function LoginFrame({
  children,
  heading,
  subHeading,
  showForgotPassword,
  showSignUp = true,
  showAutoLink,
}) {
  return (
    <Center>
      <main className={styles.frameWrap}>
        <Frame>
          <Space size="xl">
            <div className={styles.logoWrap}>
              <Logo size="200" />
            </div>
          </Space>

          {(heading || subHeading) && (
            <Space size="xl">
              <h2 className={styles.heading}>{heading}</h2>
              <p>{subHeading}</p>
            </Space>
          )}
          {children}
          {showAutoLink && (
            <Link to="/auto-log-in">
              E-mail a login link
            </Link>
          )}
        </Frame>

        {showForgotPassword && (
          <div>
            <Link className={styles.forgotPassword} to="/forgot">
              Forgot password?
            </Link>
          </div>
        )}

        {showSignUp && (
          <>
            <div style={showForgotPassword ? null : { marginTop: 24 }} className={styles.createAccount}>
              Don't have an account yet?
              <br />
              <Link to="/sign-up">Create an account</Link>
            </div>
          </>
        )}
      </main>
    </Center>
  );
}
