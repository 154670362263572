export default {
  address: '',
  address2: '',
  city: '',
  email: '',
  id: '',
  name: '',
  phone: '',
  state: '',
  title: '',
  zip: ''
};
