import React, { useEffect, useState, useContext } from "react";
import { createTheme } from "baseui";
import { PinCode } from "baseui/pin-code";
import { get, post, put, upload } from "../../api";
import LoadingFrame from "../../components/LoadingFrame";
import Modal from "../../components/Modal";
import styles from "./DeviceInstallRedirect.module.scss";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import Space from "../../components/Space";
import Icon from "../../components/Icon";
import DeviceCard from "../../components/DeviceCard";
import Image from "../../components/Image";
import { Redirect } from "react-router-dom";
import InstallDeviceStep1 from "../../components/InstallDeviceStep1";
import InstallDeviceStep2 from "../../components/InstallDeviceStep2";
import InstallDeviceStep3 from "../../components/InstallDeviceStep3";
import TypesContext from "../../contexts/Types";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { BaseProvider, styled } from "baseui";
import { hasPermission } from "../../helpers/authorization";
import { AuthContext } from "../../contexts/Auth";
import { useConfirm } from "material-ui-confirm";
import InputRow from "../../components/InputRow";
import QrCodeScanner from "../../components/QrCodeScanner";
import Label from "../../components/Label";
import Select from "../../components/Select";

export default function DeviceInstallRedirect({ computedMatch: { params } }) {
  const { state: currentUser } = useContext(AuthContext);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [types, setTypes] = useState({});
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [switchOwner, setSwitchOwner] = useState(false);
  const [redirect, setRedirect] = useState("");
  const [step, setStep] = useState(0);
  const [activationFailureCounter, setActivationFailureCounter] = useState(0);
  const [proceed, setProceed] = useState(false);
  const [alreadyActivated, setAlreadyActivated] = useState(false);
  const [selectionMode, setSelectionMode] = useState("");
  const [selectedDeviceForTrasfer, setSelectedDeviceForTransfer] = useState(0);
  const [assetsList, setAssetsList] = useState([]);
  const [selectedAssetForTransfer, setSelectedAssetForTransfer] = useState({});
  const [currentDeviceForTransfer, setCurrentDeviceForTransfer] = useState({});
  const [threshold, setThreshold] = useState({});
  const ACTIVATE = 0;
  const SWAP_OR_ASSIGN = 1;
  const INSTALL_ON_ASSET = 2;
  const ORIENTATION = 3;
  const PHOTO = 4;
  const COMPLETE = 5;
  const SWAP = 6;

  const { serialNumber } = params;
  const [checkingPin, setCheckingPin] = useState(false);
  const [checkingQR, setCheckingQR] = useState(false);
  const [resultQR, setResultQR] = useState(null);
  const [device, setDevice] = useState(null);
  const [displayDevice, setDisplayDevice] = useState(null);
  const [error, setError] = useState(false);
  const [values, setValues] = useState(["", "", "", ""]);
  const [assetOutput, setAssetOutput] = useState({});
  const [pinRequired, setPinRequired] = useState(true);
  const [allowedUser, setAllowedUser] = useState(false);
  const [subscription, setSubscription] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const engine = new Styletron();

  const primitives = {
    inputFillActive: "#a9a9a9",
    inputFill: "#d9d9d9",
  };
  const overrides = {
    colors: {
      inputFillActive: primitives.inputFillActive,
      inputFill: primitives.inputFill,
    },
  };
  const pinTheme = createTheme(primitives, overrides);
  const [activating, setActivating] = useState(false);
  const [activated, setActivated] = useState(false);
  const confirm = useConfirm();
  useEffect(() => {
    async function fetchDevice() {
      const [assetTypes, deviceTypes, groups, assetsList, subscription] =
        await Promise.all([
          get("/assettypes"),
          get("/devicetypes"),
          get(`/users/${currentUser.user.user_id}/groups`),
          get("/devices", { active: "true", include_assets: "1" }),
          get(`/customer/subscriptions/activate`, { only_available: true }),
        ]);

      if (groups && groups.length) {
        var groupsAvailable = [];
        groups.map((group) => {
          groupsAvailable.push({ id: group.id, name: group.name });
        });
        setGroups(groupsAvailable);
      }
      setTypes({
        assets: assetTypes.results,
        devices: deviceTypes.results,
      });

      assetsList && setAssetsList(assetsList.results);
      subscription && setSubscription(subscription);

      const autoProvision = await get(`/getautoprovision/${serialNumber}`);
      setPinRequired(autoProvision);
      autoProvision === false && setProceed(true);
      const devices = await get(`/deviceredirect`, {
        count: 1,
        serial_number: serialNumber,
        include_assets: true,
      });

      if (devices && devices.results && devices.results.length) {
        const _device = devices.results[0];
        _device && setDisplayDevice(_device);
        const assets = await get(`/assets`, {
          active: "false",
          _device,
        });

        setAssets(assets.results);
        const activation_date = _device.activation_time;
        if (activation_date === undefined) {
          setStep(ACTIVATE);
          setSwitchOwner(_device.customer_id !== currentUser.user.customer_id);
          if (autoProvision === false) {
            setDevice(_device);
          }
        } else {
          setAlreadyActivated(true);
          setActivated(true);
          setProceed(true);
          setDevice(_device);
          setSwitchOwner(_device.customer_id !== currentUser.user.customer_id);
          setStep(SWAP_OR_ASSIGN);
        }

        if (_device.active === true && _device.assets !== undefined) {
          var asset = _device.assets[0];
          if (asset !== undefined && asset.id !== 0) {
            setRedirect(`/assets/${asset.id}?qr=true`);
          }
        }
      } else {
        /** Couldn't find a device with the provided serial number */
        setRedirect("/");
      }
      (hasPermission(currentUser.user.perms, "ADMIN") ||
        hasPermission(currentUser.user.perms, "ASSET_U")) &&
        setAllowedUser(true);
      setLoading(false);
    }

    fetchDevice();
  }, []);

  function markError() {
    setActivationFailureCounter(activationFailureCounter + 1);
    setError(true);
  }
  async function nextStep(step) {
    setLoading(true);
    setStep(step);
    setLoading(false);
    if (step == 5 && alreadyActivated == false) {
      activateTheDevice();
    }
  }
  function prevStep() {
    setLoading(true);
    if (step <= 4) {
      setStep(step - 1);
    }
    setLoading(false);
  }
  async function activateTheDevice() {
    setActivating(true);
    const device = await post(`/device/${serialNumber}/activate/`, {
      activation_code: values.toString(),
      require_pin: pinRequired,
    });
    setActivated(true);
    /*
    device && device.active == true ? (
      device.assets && device.assets[0] && setActivated(true) && setSelectedAsset(device.assets[0]))
      :
      (markError())
*/
    setActivating(false);
  }
  async function changeValues(input) {
    setValues(input);
    setError(false);
    if (
      input[0] !== "" &&
      input[1] !== "" &&
      input[2] !== "" &&
      input[3] !== ""
    ) {
      setCheckingPin(true);
      const device = await post(`/device/${serialNumber}/pin/`, {
        activation_code: input.toString(),
      });
      if (device !== undefined && device.id !== 0) {
        setDevice(device);
        setProceed(true);
      } else {
        setValues([, , ,]);
        setError(true);
        setValues(["", "", "", ""]);
      }
      setCheckingPin(false);
    }
  }
  function quit() {
    confirm({
      description:
        "Are you sure you want to cancel? The Sentinel device will not be activated.",
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true },
    })
      .then(() => {
        window.location.replace("/");
      })
      .catch(() => {
        /* ... */
      });
  }
  function quitActivated() {
    confirm({
      description: "Are you sure you want to cancel the device installation?",
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true },
    })
      .then(() => {
        setLoading(true);
        setRedirect("/");
        setLoading(false);
        window.location.replace("/");
      })
      .catch(() => {
        /* ... */
      });
  }
  function renderInput() {
    return (
      <>
        <Space size="mobile" />
        <div className={styles.wrap}>
          <h3>
            Enter the 4-digit PIN code that is located on the Sentinel device.
          </h3>
        </div>
        <Space size="s" />
        <div className={styles.inputWrap}>
          <StyletronProvider value={engine}>
            <BaseProvider theme={pinTheme}>
              <PinCode
                values={values}
                onChange={({ values }) => changeValues(values)}
                disabled={checkingPin || proceed}
                clearOnEscape
                placeholder=""
                autoFocus
              />
            </BaseProvider>
          </StyletronProvider>
        </div>
      </>
    );
  }
  async function transferLicense() {
    const transfer = await post(`/license/transfer`, {
      device_id: selectedDeviceForTrasfer,
      target_device_id: currentDeviceForTransfer,
      asset_id: selectedAssetForTransfer,
    });

    transfer && setLoading(false);
    //dispatch(fetchAsset(params.id));
    transfer && window.location.reload(); //replace(`/assets/${state.asset.id}`)
  }
  function setNewDeviceForTransfer(device_id) {
    setSelectedDeviceForTransfer(device_id);
    if (device_id > 0) {
      let selectdDevice = assetsList.find((x) => x.id === parseInt(device_id));
      if (selectdDevice !== undefined) {
        let asset = selectdDevice?.assets[0];
        asset && setSelectedAssetForTransfer(asset.id);
      }
    }
    displayDevice && setCurrentDeviceForTransfer(displayDevice.id);
  }
  async function updateLicense() {
    confirm({
      description:
        "Are you sure you want to swap the current device to the one selected?",
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true },
    })
      .then(() => {
        transferLicense();
      })
      .catch((e) => {});
  }
  function _renderFooter() {
    if (step == SWAP) {
      return (
        <>
          <Button theme="secondary" onClick={() => setStep(SWAP_OR_ASSIGN)}>
            Back
          </Button>
          <Button
            theme="primary"
            onClick={() => updateLicense()}
            disabled={selectedDeviceForTrasfer <= 0}
          >
            Swap
          </Button>
        </>
      );
    }

    return allowedUser == true ? (
      step < 4 && (
        <>
          <Button
            theme="secondary"
            onClick={() => (alreadyActivated ? quitActivated() : quit())}
          >
            Cancel
          </Button>
          {alreadyActivated ? (
            <Button
              theme="primary"
              onClick={() =>
                selectionMode === "manual" ? nextStep(step + 1) : setStep(SWAP)
              }
              disabled={!proceed || selectionMode === ""}
            >
              Next
            </Button>
          ) : step === 0 ? (
            <Button theme="primary" onClick={() => nextStep(step + 1)}>
              Next
            </Button>
          ) : (
            <Button
              theme="primary"
              onClick={() =>
                selectionMode === "manual" ? nextStep(step + 1) : setStep(SWAP)
              }
              disabled={!proceed || selectionMode === ""}
            >
              Next
            </Button>
          )}
        </>
      )
    ) : (
      <Button
        theme="primary"
        onClick={() => (setLoading(true), setRedirect("/"), setLoading(false))}
      >
        Close
      </Button>
    );
  }
  function stepOption(label, initialValue, explanation) {
    const active = selectionMode === initialValue;
    const classes = [styles.label, active && styles.labelActive].join(" ");

    return (
      <label className={classes}>
        <div className={styles.img}>
          <div className={styles[initialValue]}>
            <Icon
              name={initialValue === "qr" ? "cached" : "create"}
              size={64}
            />
          </div>
        </div>
        <div className={styles.flexContainer}>
          <div className={styles.flexRow}>
            <div className={styles.text}>{active ? <b>{label}</b> : label}</div>
          </div>
          <div className={styles.flexRow}>
            <div className={styles.sub_text}>
              <p>{explanation}</p>
            </div>
          </div>
        </div>
        <input
          type="radio"
          name="damage"
          value={initialValue}
          onChange={(e) => setSelectionMode(initialValue)}
          checked={active}
        />
      </label>
    );
  }
  function stepOptionSwap(label, initialValue, explanation) {
    const active = selectionMode === initialValue;
    const classes = [styles.label, active && styles.labelActive].join(" ");

    return (
      <label className={classes}>
        <div className={styles.img}>
          <div className={styles[initialValue]}>
            <Icon
              name={initialValue === "qr" ? "qr_code" : "create"}
              size={64}
            />
          </div>
        </div>
        <div className={styles.flexContainer}>
          <div className={styles.flexRow}>
            <div className={styles.text}>{active ? <b>{label}</b> : label}</div>
          </div>
          {explanation && (
            <div className={styles.flexRow}>
              <div className={styles.sub_text}>
                <p>{explanation}</p>
              </div>
            </div>
          )}
        </div>
        <input
          type="radio"
          name="damage"
          value={initialValue}
          onChange={(e) => setSelectionMode(initialValue)}
          checked={active}
        />
      </label>
    );
  }
  function qrCheckResult(response) {
    setCheckingQR(true);
    setSelectedDeviceForTransfer(0);
    if (response != undefined) {
      setResultQR(response);
      switch (response.result) {
        case 1:
          setNewDeviceForTransfer(response.device_id);
          break;
      }
    }
    setCheckingQR(false);
  }
  function displayQRResult() {
    let text = "";
    if (resultQR) {
      switch (resultQR.result) {
        case -2:
          text = "Scan the active device installed on an asset";
          break;
        case -1:
          text = "Sorry! Unable to identify the device!";
          break;
        case 0:
          text = "Unable to read the QR code, scan again.";
          break;
        case 1:
          text = "Device identified. Let's proceed to the next step.";
          break;
      }
    }

    return (
      <>
        <Space size="m" />
        <div className={styles.wrap}>
          {resultQR.result === 1 ? (
            <>
              <div className={styles.icon}>
                <Icon name="check_circle" size={64} />
              </div>
              <h1>QR code scanned properly</h1>
              <p className={styles.body}>{text}</p>
            </>
          ) : (
            <>
              <div className={styles.error}>
                <Icon name="error" size={64} />
              </div>
              <p className={styles.body}>{text}</p>
            </>
          )}
        </div>
      </>
    );
  }
  function renderContent() {
    if (allowedUser === false) {
      return (
        <Space size="s">
          <div className={styles.wrap}>
            <div className={styles.error}>
              <Icon name="error" size={32} />
            </div>
            <h1>User not authorized</h1>
            <p className={styles.body}>
              Your user has no permission to activate and install devices on
              assets. Contact the administrator.
            </p>
          </div>
        </Space>
      );
    }

    function renderOptions() {
      switch (selectionMode) {
        case "qr":
          return (
            <>
              <Space size="xs">
                <div className={styles.lineHeight}>
                  Scan the QR code on the new device.
                </div>
              </Space>
              <QrCodeScanner
                setCheckingQR={setCheckingQR}
                onResult={(result) => qrCheckResult(result)}
                active={true}
              />
              <Space size="s">
                {checkingQR ? <LoadingFrame /> : resultQR && displayQRResult()}
              </Space>
            </>
          );
        case "manual":
          return (
            <Space size="xxs">
              <div className={styles.deviceWrap}>
                {assetsList && assetsList.length > 0 && (
                  <>
                    <Space size="m">
                      <Label text="Serial number">
                        <Select
                          disabled={loading}
                          value={selectedDeviceForTrasfer}
                          onChange={(e) =>
                            setNewDeviceForTransfer(e.target.value)
                          }
                        >
                          <option value={0}>Choose a serial...</option>
                          {assetsList.map((d) => (
                            <option key={d.id} value={d.id}>
                              {d.serial_number}
                            </option>
                          ))}
                        </Select>
                      </Label>
                    </Space>

                    <Space size="m">
                      {selectedDeviceForTrasfer > 0 && (
                        <div>
                          {assetsList
                            .filter((x) => x.id == selectedDeviceForTrasfer)
                            .map((dev) => (
                              <Space size="s">
                                <Label text="Asset Name">
                                  {dev.assets[0].name}
                                </Label>
                              </Space>
                            ))}
                        </div>
                      )}
                    </Space>
                  </>
                )}
              </div>
            </Space>
          );

        default:
          return;
      }
    }

    switch (step) {
      case ACTIVATE:
        return (
          <>
            {alreadyActivated == false ? (
              <>
                <Space size="s">
                  <h2>Step 1: Activate your device</h2>
                </Space>
                {displayDevice && <DeviceCard device={displayDevice} />}

                {pinRequired ? (
                  <>
                    <Space size="s" />
                    <Space size="s">
                      <div className={styles.bannerImage}>
                        <Image
                          url={"/img/SentinelPinLocation.png"}
                          token={false}
                          zoom={true}
                        />
                      </div>
                    </Space>
                    <Space size="s" />
                    <Space size="s">{renderInput()}</Space>
                    <Space size="s">
                      {checkingPin && <LoadingFrame />}
                      <div className={styles.wrap}>
                        {device && device.id !== 0 && (
                          <>
                            <div className={styles.icon}>
                              <Icon name="check_circle" size={32} />
                            </div>
                            <h1>PIN correct</h1>
                            <p className={styles.body}>
                              PIN matches our records. Let's proceed to the next
                              step.
                            </p>
                          </>
                        )}
                        {error && (
                          <>
                            <div className={styles.error}>
                              <Icon name="error" size={32} />
                            </div>
                            <h1>PIN incorrect</h1>
                            <p className={styles.body}>
                              Looks like you entered an incorrect PIN code.
                            </p>
                          </>
                        )}
                      </div>
                    </Space>
                  </>
                ) : (
                  <>
                    <Space size="l" />
                    <Space size="l" />

                    <Space size="s">
                      <div className={styles.wrap}>
                        <div className={styles.wrapvert}>
                          <div className={styles.icon}>
                            <Icon name="check_circle" size={22} />
                          </div>
                          <h1 className={styles.margin}>
                            Get ready for activation
                          </h1>
                        </div>
                        <p className={styles.body}>
                          Getting ready to activate your device. Let's proceed
                          to the next step to continue the activation process.
                        </p>
                      </div>
                    </Space>
                  </>
                )}
              </>
            ) : (
              <>
                <Space size="s">
                  <h2>Device already activated</h2>
                </Space>
                {displayDevice && <DeviceCard device={displayDevice} />}
                <Space size="l" />
                <Space size="l" />
                <Space size="s">
                  <div className={styles.wrap}>
                    <h1>Device already activated</h1>
                    <p className={styles.body}>
                      Looks like your device is already activated. Let's proceed
                      to the next step.
                    </p>
                  </div>
                </Space>
              </>
            )}
          </>
        );
        break;

      case SWAP_OR_ASSIGN:
        return (
          <>
            <Space size="s">
              <h2>
                Step {alreadyActivated ? 1 : 2}: New device or swap devices
              </h2>
            </Space>

            <Space size="s">What would you like to do?</Space>
            <div className={styles.center}>
              <InputRow>
                <div className={styles.wrap2}>
                  {stepOption(
                    "New device",
                    "manual",
                    "Choose this option if you want to activate a new device."
                  )}
                  {stepOption(
                    "Swap devices",
                    "qr",
                    "Choose this option if you want to swap a device from an existing asset."
                  )}
                </div>
              </InputRow>
            </div>
          </>
        );
        break;

      case SWAP:
        return (
          <>
            <Space size="s">
              <h2>Step 3: Swap devices</h2>
            </Space>
            <Space size="s">
              Select or Scan the new device that will replace the current one.
            </Space>
            <div className={styles.center}>
              <InputRow>
                <div className={styles.wrap2}>
                  {stepOptionSwap("Scan QR", "qr")}
                  {stepOptionSwap("Select", "manual")}
                </div>
              </InputRow>
            </div>
            {renderOptions()}
          </>
        );

        break;

      case INSTALL_ON_ASSET:
        return (
          <>
            {assets && device && types && (
              <InstallDeviceStep1
                device={device}
                assets={assets}
                groups={groups}
                selectedGroups={selectedGroups}
                setSelectedGroups={setSelectedGroups}
                subscription={subscription}
                assetTypes={types}
                loading={loading}
                skipSave={selectedAsset == null}
                step={false}
                existingAsset={(asset) => {
                  if (typeof asset === "string") {
                    setSelectedAsset(
                      assets.find((a) => a.id === parseInt(asset))
                    );
                  }
                  if (asset == null) {
                    setSelectedAsset(null);
                  }
                }}
                back={() => prevStep()}
                next={(asset) => {
                  if (typeof asset === "string") {
                    setSelectedAsset(
                      assets.find((a) => a.id === parseInt(asset))
                    );
                  } else {
                    setAssetOutput(asset);
                  }
                  nextStep(step + 1);
                }}
              />
            )}
          </>
        );
        break;

      case ORIENTATION:
        return (
          <InstallDeviceStep2
            selectedAsset={selectedAsset}
            step={alreadyActivated}
            skipSave={selectedAsset?.id != 0}
            passThresholds={(threshold) => setThreshold(threshold)}
            back={() => prevStep()}
            next={() => nextStep(step + 1)}
          />
        );
        break;

      case PHOTO:
        return (
          <InstallDeviceStep3
            back={() => prevStep()}
            deviceId={device.id}
            step={alreadyActivated}
            switch_owner={switchOwner}
            selectedAsset={selectedAsset}
            auto_create_license_for_new_device={!pinRequired}
            processRelation={(relation) => createRelation(relation)}
            next={(relation) => {
              nextStep(step + 1);
            }}
          />
        );
        break;

      case COMPLETE:
        return activating ? (
          <div className={styles.wrap}>
            <div className={styles.icon}>
              <LoadingFrame />
            </div>

            <h1>Activating...</h1>
            <p className={styles.body}>
              We are activating your device now, please wait.
            </p>
          </div>
        ) : activated == true ? (
          <div className={styles.wrap}>
            <div className={styles.icon}>
              <Icon name="check_circle" size={32} />
            </div>

            <h1>All set</h1>
            <p className={styles.body}>
              The device is now running through a brief calibration process and
              will begin monitoring shortly.
            </p>
            <Space size="l" />
            <Link to={`/assets/${selectedAsset.id}`}>
              <Button theme="primary">Finish</Button>
            </Link>
          </div>
        ) : activationFailureCounter <= 2 ? (
          <>
            <div className={styles.wrap}>
              <div className={styles.error}>
                <Icon name="error" size={32} />
              </div>

              <h1>Device activation failed</h1>
              <p className={styles.body}>
                Whoops. Something went wrong. Click activate to try again.
              </p>
              <Space size="l" />
              <Button onClick={() => activateTheDevice()} theme="primary">
                Activate
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className={styles.wrap}>
              <div className={styles.error}>
                <Icon name="error" size={32} />
              </div>

              <h1>Device activation failed</h1>
              <p className={styles.body}>
                Looks like something went wrong with the activation process.
                <br />
                Contact the Sentinel support team to troubleshoot this issue.
                <br />
                <br />
                Call 949-361-5663 or e-mail sentinelsupport@traffixdevices.com
              </p>
              <Space size="l" />
              <Button
                onClick={() => window.location.replace("/")}
                theme="primary"
              >
                Finish
              </Button>
            </div>
          </>
        );
        break;
    }
  }

  async function createRelation(relationToCreate) {
    var attachments = relationToCreate.files;
    var relation = relationToCreate.relation;
    assetOutput.groups = selectedGroups;

    const [res] = await Promise.all([post(`/assets`, assetOutput)]);

    if (res) {
      relation.from_id = res.id;
      setSelectedAsset(res);

      const createRelation = await post("/relations", relation);
      if (createRelation) {
        let fileReqs = [];
        attachments.forEach((file) => {
          const formData = new FormData();
          formData.set("entity_type", "ASSET");
          formData.set("entity_id", relation.from_id);
          formData.set("type", "IMAGE");
          formData.append("file", file);
          const res = upload(formData);
          fileReqs.push(res);
        });

        if (fileReqs.length > 0) {
          await Promise.all(fileReqs);
        }
        res &&
          threshold &&
          (await put(`/assets/${relation.from_id}`, {
            ...res,
            ...threshold,
            skipGroup: true,
          }));
        nextStep(step + 1);
      }
    }
  }
  return loading ? (
    <LoadingFrame />
  ) : redirect !== "" ? (
    <Redirect to={redirect} noThrow />
  ) : (
    <TypesContext.Provider value={types}>
      <Modal
        hideFooter={
          step === INSTALL_ON_ASSET || step === PHOTO || step == ORIENTATION
        }
        footer={_renderFooter()}
      >
        {renderContent()}
      </Modal>
    </TypesContext.Provider>
  );
}
