import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { destroy } from '../../api';
import TenantContext from '../../contexts/Tenant';

import FrameSectionHeader from '../../components/FrameSectionHeader';
import FrameHeader from '../../components/FrameHeader';
import Frame from '../../components/Frame';
import DeleteConfirmation from '../../components/DeleteConfirmation';

// import styles from './TenantSettings.module.scss';

export default function TenantSettings({ match: { params } }) {
  const [redirect, setRedirect] = useState(false);
  const { tenant } = useContext(TenantContext);

  const deleteTenant = async () => {
    await destroy(`/tenants/${params.id}`);
    setRedirect(`/tenants`);
  };

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <>
      <FrameHeader title="Settings" />
      <Frame>
        <FrameSectionHeader icon="delete" title="Delete" />
        <DeleteConfirmation
          challenge={tenant.name}
          name="Tenant"
          onDelete={deleteTenant}
        />
      </Frame>
    </>
  );
}
