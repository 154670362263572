import React from 'react';
import styles from './NavItem.module.scss';
import Icon from '../Icon';

export default function NavItem({ title, icon, notification }) {
  return (
    <div className={styles.navItem}>
      <div className={styles.icon}>
        <Icon name={icon} size={24} />
      </div>
      <h5 className={styles.title}>{title}</h5>
      {notification > 0 && <div className={styles.notification} />}
    </div>
  );
}
