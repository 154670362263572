import React, { useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';

import { put, post } from '../../api';
import UserModel from '../../models/user';
import { AuthContext } from '../../contexts/Auth';
import { CUSTOMER_USER } from '../../constants/roles';

import Breadcrumb from '../../components/Breadcrumb';
import UserInfoForm from '../../components/UserInfoForm';
import FrameHeader from '../../components/FrameHeader';
import SingleColumnPage from '../../components/SingleColumnPage';
import { normalizePermissions } from '../../helpers/user';

// import styles from './NewUser.module.scss';

export default function NewUser({ match: { params } }) {
  const { state } = useContext(AuthContext);
  const [saving, setSaving] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const createUser = async (user, perms) => {
    setSaving(true);

    const res = await post('/users', {
      ...user,
      tenant_id: state.user.tenant_id,
      customer_id: state.user.customer_id,
      authority: CUSTOMER_USER
    });

    await put(`/users/${res.id}/permissions`, {
      permissions: normalizePermissions(perms)
    });

    await post('/users/sendActivationMail', {
      email: res.email
    });

    setRedirect(`/users`);
  };

  const breadcrumbs = () => (
    <>
      <Breadcrumb title="Users" to="/users" />
      <Breadcrumb title="New user" />
    </>
  );

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <SingleColumnPage breadcrumbs={breadcrumbs}>
      <FrameHeader title="New user" />
      <UserInfoForm
        state={UserModel}
        saving={saving}
        onSave={(user, perms) => createUser(user, perms)}
        isNew
      />
    </SingleColumnPage>
  );
}
