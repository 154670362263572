import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { destroy, get, post,upload } from '../../api';
import Button from '../../components/Button';
import Label from '../../components/Label';
import LoadingFrame from '../../components/LoadingFrame';
import Modal from '../../components/Modal';
import Space from '../../components/Space';
import { DefaultEditor } from 'react-simple-wysiwyg';
import {isAuthorized, hasPermission } from "../../helpers/authorization";
import { AuthContext } from "../../contexts/Auth";
import { CUSTOMER_USER, SYS_ADMIN,TENANT_ADMIN } from '../../constants/roles';
import FileUploader from '../../components/FileUploader';
import FileUploadProgress from '../../components/FileUploadProgress';
import styles from './AssetNoteEdit.module.scss';
import { useConfirm } from 'material-ui-confirm';
export default function AssetNoteEdit({ match: { params }}) {
  const { state} = useContext(AuthContext);
  const [event, setEvent] = useState({});
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const confirm = useConfirm();
  useEffect(() => {
    async function fetchNote() {
      const res = await get(`/events/${params.id}`);
      res && setEvent(res);
      setLoading(false);
    }
    fetchNote();
  }, []);
  async function _saveNote() {
    setSaving(true);
    const saveEvent = await post(`/events/${params.id}`,event);
    let attachmentReqs = [];

    attachments.forEach(attachment => {
      const formData = new FormData();

      formData.set('entity_type', 'EVENT');
      formData.set('entity_id', event.id);
      formData.set('type', 'IMAGE');
      formData.append('file', attachment);

      const res = upload(formData);
      attachmentReqs.push(res);
    });
    await Promise.all(attachmentReqs);

    window.location.replace(`/assets/${params.assetId}/`);
  }
  async function saveFile(attachment) {
    if (attachment != undefined) {
      const isArray = Array.isArray(attachment);
      if (isArray) {
        var firstElement = attachment[0];
        if (firstElement !== undefined && firstElement.name !== undefined) {
          setAttachments([...attachments, ...attachment])
        } else {
          setAttachments([...attachments, ...firstElement])
        }
      } else {
        var newArray = [attachment]
        setAttachments([...attachments, ...newArray])
      }
    } 
  }
  async function _removeFile(i) {

    if (attachments != undefined) {
      const newAttachments = attachments
        .slice(0, i)
        .concat(attachments.slice(i + 1, attachments.length));

      setAttachments(newAttachments);
    } else {
      alert('delete error');
    }
  }

  function _removeConfirmation() {
    confirm({
      description: 'Are you sure you want to delete this note?',
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true }
    })
      .then(() => {
        _remove();
      })
      .catch(() => { /* ... */ });
  }

  async function _remove() {
    setSaving(true);
    const res = await destroy(`/events/${params.id}`);
    window.location.replace(`/assets/${params.assetId}/`);
  }

  function _renderFooter() {
    return (
      <>
        {isAuthorized(state.user.roles, [SYS_ADMIN, TENANT_ADMIN,CUSTOMER_USER]) && (hasPermission(state.user.perms,'ADMIN') || hasPermission(state.user.perms, 'ASSET_C')) && <Button theme="danger" onClick={() => _removeConfirmation()} disabled={saving}>
          Delete
        </Button>}
        <Link to={`/assets/${params.assetId}`}>
          <Button>Cancel</Button>
        </Link>
        <Button theme="primary" onClick={() => _saveNote()} isLoading={saving}>
          Save
        </Button>
      </>
    );
  }
  return (
    <Modal footer={_renderFooter()}>
      {loading ? (
        <LoadingFrame />
      ) : (
          <>
            <Space size="l">
              <h2>Edit note</h2>
            </Space>
            <Space size="m">
              <Label text="Notes"> </Label>
              <div  className={styles.wyswigOuterWrapper}>
              <DefaultEditor
                placeholder="Enter any additional info here"
                value={event.body}
                onChange={e => setEvent({ ...event, body: e.target.value })}
                className={styles.wyswigWrapper}
              />
              </div>
            </Space>
            <Space size="s">
            <p>Upload photos</p>
          </Space>
          <Space size="m">
            <Space size="m">
              <FileUploader
                onError={errMsg => alert(errMsg)}
                onSelect={files => saveFile([files])}
              />
            </Space>
            <FileUploadProgress
              preview={true}
              smallPreview={true}
              files={attachments}
              uploading={saving}
              onRemove={i => _removeFile(i)}
            />
          </Space>
          </>
        )}
    </Modal>
  );
}
