import React, { useContext, useRef, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useFormState } from "react-use-form-state";
import queryString from "query-string";
import validate from "../../helpers/validate";
import { AuthContext } from "../../contexts/Auth";
import { authWithCredentials, requestAutoLogin } from "../../actions/Auth";
import Space from "../../components/Space";
import Button from "../../components/Button";
import Label from "../../components/Label";
import TextInput from "../../components/TextInput";
import InputRow from "../../components/InputRow";
import LoginFrame from "../../components/LoginFrame";
import AlertBanner from "../../components/AlertBanner";
import styles from "./LogIn.module.scss";
export default function LogIn({ location: { search } }) {
  const queryParams = queryString.parse(search);
  const { state, dispatch } = useContext(AuthContext);
  const [autoLogin, setAutoLogin] = useState(false);
  const [autoLoginError, setAutoLoginError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formState, { email, password }] = useFormState();
  const valid = validate(formState, {
    required: ["email", "password"]
  });
  const emailEl = useRef(null);

  useEffect(() => {
    if (!state.isAuthenticated) emailEl.current.focus();
  }, []);

  async function authenticate(e) {
    e && e.preventDefault();

    const credentials = {
      email: formState.values.email,
      password: formState.values.password
    };

    dispatch(authWithCredentials(credentials));
  }
  async function requestLoginLink() {
    setLoading(true);
    setAutoLoginError(false);
    var request = await requestAutoLogin(formState.values.email);
    setAutoLogin(true);
    setLoading(false);
  }
  function _logInFirst() {
    return (
      <Space size="l">
        <AlertBanner>
          You'll need to log in before
          <br /> accessing that page.
        </AlertBanner>
      </Space>
    );
  }
  function _email_link_requested() {
    return (
      <Space size="l">
        <AlertBanner>
          We've just sent you an email that includes a link to log in.
        </AlertBanner>
      </Space>
    );
  }
  function _renderExpiredToken() {
    return (
      <Space size="l">
         <AlertBanner theme="danger">
          Login link has expired
        </AlertBanner>
      </Space>
    );
  }
  function _tokenExpired() {
    return (
      <Space size="l">
        <AlertBanner theme="danger">
          Your session has expired.
          <br />
          Log in with your credentials below.
        </AlertBanner>
      </Space>
    );
  }

  function _error() {
    return (
      <Space size="l">
        <AlertBanner theme="danger">{state.error.message}</AlertBanner>
      </Space>
    );
  }

  function _activated() {
    return (
      <Space size="l">
        <AlertBanner>
          Your account was successfully activated. You may log in with your
          credentials below.
        </AlertBanner>
      </Space>
    );
  }

  function _reset() {
    return (
      <Space size="l">
        <AlertBanner>
          Your password was successfully reset. You may log in with your
          credentials below.
        </AlertBanner>
      </Space>
    );
  }

  function _redirect() {
    return <Redirect from="log-in" to="/" nothrow />;
  }

  function _loginForm() {
    const error = state.error && state.error.message;
    return (
      <>
        <LoginFrame showForgotPassword showAutoLink={false}>
          {state.intent && !autoLogin && _logInFirst()}

          {error && !autoLogin && _error()}
          {!error && queryParams.tokenExpired && _tokenExpired()}
          {autoLogin && _email_link_requested()}
      
          {queryParams.activated && _activated()}
          {queryParams.reset && _reset()}
          {queryParams.loginLinkExpired && !autoLogin && _renderExpiredToken()}
          {!autoLogin && <>
            <div className={styles.inputWrap}>
              <Space size="s">
                <Label text="Email">
                  <TextInput
                    ref={emailEl}
                    {...email("email")}
                    required
                    error={formState.touched.email && formState.errors.email}
                  />
                </Label>
              </Space>
              <Space size="l">
                <Label text="Password">
                  <TextInput
                    {...password("password")}
                    required
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        formState.values.password != "" && authenticate();
                      }
                    }}
                  /*error={
                    formState.touched.password && formState.errors.password
                  }*/
                  />
                </Label>
              </Space>
            </div>
            <InputRow>
              <Button
              centerNoFlex={true}
                onClick={() => requestLoginLink()}
                theme="primary"
                isLoading={loading}
                disabled={formState.values.password != ""}
              >
                Request login link
              </Button>
              <Button
                centerNoFlex={true}
                onClick={() => authenticate()}
                type="submit"
                theme="primary"
                isLoading={state.isAuthenticating}
                disabled={!valid}
              >
                Log in
              </Button>
            </InputRow>
          </>
          }
        </LoginFrame>
      </>
    );
  }

  return state.isAuthenticated ? _redirect() : _loginForm();
}
