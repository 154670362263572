import React, { useEffect, useState, useContext } from "react";
import styles from "./PaymentProfile.module.scss";
import Label from "../Label/Label";
import Space from "../Space/Space";

import Button from "../Button";
import { useConfirm } from "material-ui-confirm";
import { destroy, get, post } from "../../api";
import Modal from "../Modal";
import LoadingFrame from "../LoadingFrame";
import TextInput from "../../components/TextInput";
import CustomerInfoForm from "../../components/CustomerInfoForm";
import CustomerContext from "../../contexts/Customer";
import { AuthContext } from "../../contexts/Auth";
import InputRow from "../../components/InputRow";
import "react-credit-cards/es/styles-compiled.css";
import { useMediaQuery } from "react-responsive";
import Icon from "../../components/Icon";
import { isAuthorized, hasPermission } from "../../helpers/authorization";
import { SYS_ADMIN, TENANT_ADMIN, CUSTOMER_USER } from "../../constants/roles";
import Frame from "../Frame";
import FrameHeader from "../FrameHeader";
import { add, set } from "date-fns";
import { tr } from "date-fns/locale";
export default function PaymentProfile({
  onRefresh,
  openAddModal,
  closeAddModal,
  quietRefresh,
}) {
  const confirm = useConfirm();
  const isMobile = useMediaQuery({ query: "(max-width: 650px)" });
  const { customer, setCustomer } = useContext(CustomerContext);
  const [paymentProfile, setPaymentProfile] = useState(null);
  const [loading, setLoading] = React.useState(false);
  const [isEditModal, setEditModalOpen] = useState(false);
  const [isCreateModal, setCreateModalOpen] = useState(openAddModal);
  const [currentProfile, setCurrentProfile] = useState(null);
  const [focused, setFocused] = useState("");
  const [customer_modified, setCustomerModified] = useState(null);
  const { state: currentUser } = useContext(AuthContext);
  const [displayNumber, setDisplayNumber] = useState("");
  const [number, setNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [displayExpiry, setDisplayExpiry] = useState("");
  const [cvc, setCvc] = useState("");
  const [name, setName] = useState(currentUser.user.given_name);
  const [surname, setSurname] = useState(currentUser.user.last_name);
  const [emptying, setEmptying] = useState(false);
  const [wasFormCleared, setWasFormCleared] = useState(false);

  async function fetchData() {
    setLoading(true);
    setPaymentProfile(null);

    const res = await get(`/users/${currentUser.user.user_id}`);

    if (res) {
      try {
        const { given_name, last_name } = res;
        given_name && setName(given_name);
        last_name && setSurname(last_name);
      } catch (e) {}
    }
    const customerPaymentProfile = await Promise.all([
      get(`/customers/paymentprofile`),
    ]);
    customerPaymentProfile &&
      customerPaymentProfile[0] &&
      customerPaymentProfile[0].length > 0 &&
      setPaymentProfile(customerPaymentProfile[0][0]);
    customerPaymentProfile &&
      customerPaymentProfile[0] &&
      customerPaymentProfile[0].length > 0 &&
      customerPaymentProfile[0][0].billTo &&
      setCustomerModified(customerPaymentProfile[0][0].billTo);
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setCreateModalOpen(openAddModal);
  }, [openAddModal]);

  async function deletePaymentMethod(id) {
    confirm({
      description: "Are you sure you want to delete this payment method?",
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true },
    })
      .then(async () => {
        await destroy(`/customers/paymentprofile/${id}`);
        setEditModalOpen(false);
        clearEditForm();
        setLoading(true);
        await fetchData();
        onRefresh();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async function refreshAddress(customer) {
    setCustomerModified(customer);
  }

  const handleExpiryChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value.length > 2) {
      setDisplayExpiry(value.substring(0, 2) + "/" + value.substring(2, 4));
      setExpiry(value.substring(0, 2) + value.substring(2, 4)); // Store without slash
    } else {
      setDisplayExpiry(value);
      setExpiry(value);
    }
  };

  const handleNumberChange = (e) => {
    const onlyNums = e.target.value.replace(/\D/g, ""); // Remove non-digits
    let formatted = "";

    for (let i = 0; i < onlyNums.length; i += 4) {
      formatted += (i ? " " : "") + onlyNums.slice(i, i + 4);
    }
    setDisplayNumber(formatted); // Set formatted value for display
    setNumber(onlyNums); // Set cleaned value for storing or processing
  };

  async function save() {
    setLoading(true);
    const response = await post("/customers/modifypaymentprofile", {
      customer: customer_modified || customer,
      first_name: name,
      last_name: surname,
      card: {
        cardNumber: number,
        expirationDate: expiry,
        cardCode: cvc,
      },
    });

    if (!response.success) {
      setLoading(false);

      confirm({
        description: response.content,
        title:
          "Unable to " +
          (isCreateModal == true ? "save" : "update") +
          " payment method",
        dialogProps: { fullScreen: false },
        cancellationButtonProps: { style: { display: "none" } },
        confirmationButtonProps: { color: "primary", disableRipple: true },
      })
        .then(() => {})
        .catch(() => {
          /* ... */
        });
    } else {
      setLoading(true);
      if (isEditModal) {
        setCreateModalOpen(false);
        closeAddModal();
        await fetchData();
        onRefresh();
      }

      if (isCreateModal) {
        setCreateModalOpen(false);
        closeAddModal();
        await fetchData();
        quietRefresh();
      }
      //setEditModalOpen(true);
    }
  }

  function closeEdit() {
    setCustomerModified(paymentProfile.billTo);
    setEditModalOpen(false);
    setWasFormCleared(false);
  }

  function _renderFooter() {
    return (
      <>
        {isEditModal && (
          <Button
            theme="danger"
            onClick={() =>
              deletePaymentMethod(paymentProfile.customerPaymentProfileId)
            }
          >
            Remove
          </Button>
        )}

        <Button
          onClick={() => {
            // eslint-disable-next-line no-unused-expressions
            isEditModal
              ? closeEdit()
              : (setCreateModalOpen(false), closeAddModal());
          }}
        >
          Cancel
        </Button>

        <Button
          theme="primary"
          onClick={save}
          disabled={
            name == "" ||
            surname == "" ||
            number == "" ||
            expiry == "" ||
            cvc == "" ||
            displayNumber == ""
          }
        >
          {isEditModal ? "Save" : "Add"}
        </Button>
      </>
    );
  }

  function openEditModal() {
    clearEditForm();
    setCurrentProfile(paymentProfile);
    setName(paymentProfile.billTo.firstName);
    setSurname(paymentProfile.billTo.lastName);
    setNumber(paymentProfile.payment.creditCard.cardNumber);
    setDisplayNumber(paymentProfile.payment.creditCard.cardNumber);
    setExpiry(paymentProfile.payment.creditCard.expirationDate);
    setDisplayExpiry(paymentProfile.payment.creditCard.expirationDate);
    setCvc("XXX");
    setEditModalOpen(true);
  }

  function clearEditForm() {
    setNumber("");
    setDisplayNumber("");
    setExpiry("");
    setDisplayExpiry("");
    setCvc("");
  }

  function changeOnEditAndClearForm(value, type) {
    if (value) {
      if (!wasFormCleared) {
        setWasFormCleared(true);
        setEmptying(true);
        clearEditForm();
        customer.address = "";
        customer.city = "";
        customer.state = "";
        customer.zip = "";
        customer.country = "";
        setName("");
        setSurname("");
        setCustomerModified(customer);
        setCustomer(customer);
      }
      if (type == "name") {
        setName(value);
      }
      if (type == "surname") {
        setSurname(value);
      }
    }
  }

  return (
    <>
      <FrameHeader title={"Payment details"}></FrameHeader>
      <Frame>
        {loading ? (
          <LoadingFrame />
        ) : (
          <>
            {isEditModal && (
              <Modal footer={_renderFooter()}>
                <div>
                  <InputRow>
                    <Label text="First name" theme="light">
                      <TextInput
                        type="text"
                        name="name"
                        required
                        value={name}
                        onChange={(e) =>
                          changeOnEditAndClearForm(e.target.value, "name")
                        }
                        onFocus={(e) => setFocused(e.target.name)}
                      />
                    </Label>
                    <Label text="Last name" theme="light">
                      <TextInput
                        type="text"
                        name="surname"
                        required
                        value={surname}
                        onChange={(e) =>
                          changeOnEditAndClearForm(e.target.value, "surname")
                        }
                        onFocus={(e) => setFocused(e.target.name)}
                      />
                    </Label>
                  </InputRow>

                  <CustomerInfoForm
                    simple
                    disabled={loading}
                    state={customer_modified || customer}
                    reset={emptying}
                    setEmptying={setEmptying}
                    updatedCustomerForm={(customer) => refreshAddress(customer)}
                  />
                </div>
                <InputRow>
                  <div>
                    <Label text="Credit card number" theme="light">
                      <TextInput
                        type="tel"
                        name="number"
                        value={displayNumber} // Use the display value for the input
                        onChange={handleNumberChange}
                        onFocus={(e) => setFocused(e.target.name)}
                        required
                      />
                    </Label>
                  </div>
                </InputRow>
                <InputRow>
                  <Label text="Expiration date" theme="light">
                    <TextInput
                      type="text"
                      name="expiry"
                      value={displayExpiry} // Use the display value for the input
                      onChange={handleExpiryChange}
                      onFocus={(e) => setFocused(e.target.name)}
                      maxLength="5" // MM/YY, including the slash
                      required
                    />
                  </Label>

                  {isMobile && <div className={styles.spacerTop} />}
                  <Label text="Card verification number" theme="light">
                    <TextInput
                      type="tel"
                      name="cvc"
                      value={cvc}
                      onChange={(e) => setCvc(e.target.value)}
                      onFocus={(e) => setFocused(e.target.name)}
                      maxLength="4" // including the Amex
                      required
                    />
                  </Label>
                  <div />
                </InputRow>
              </Modal>
            )}
            {isCreateModal && (
              <Modal footer={_renderFooter()}>
                <>
                  <div>
                    <InputRow>
                      <Label text="First name" theme="light">
                        <TextInput
                          type="text"
                          name="test"
                          required
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          onFocus={(e) => setFocused(e.target.name)}
                        />
                      </Label>
                      <Label text="Last name" theme="light">
                        <TextInput
                          type="text"
                          name="surname"
                          required
                          value={surname}
                          onFocus={(e) => setFocused(e.target.name)}
                          onChange={(e) => setSurname(e.target.value)}
                        />
                      </Label>
                    </InputRow>

                    <CustomerInfoForm
                      simple
                      disabled={loading}
                      state={customer_modified || customer}
                      updatedCustomerForm={(customer) =>
                        refreshAddress(customer)
                      }
                    />
                  </div>
                  <InputRow>
                    <div>
                      <Label text="Credit card number" theme="light">
                        <TextInput
                          type="tel"
                          name="number"
                          value={displayNumber} // Use the display value for the input
                          onChange={handleNumberChange}
                          onFocus={(e) => setFocused(e.target.name)}
                          required
                        />
                      </Label>
                    </div>
                  </InputRow>
                  {!isMobile ? (
                    <InputRow>
                      <Label text="Expiration date" theme="light">
                        <TextInput
                          type="text"
                          name="expiry"
                          value={displayExpiry} // Use the display value for the input
                          onChange={handleExpiryChange}
                          onFocus={(e) => setFocused(e.target.name)}
                          maxLength="5" // MM/YY, including the slash
                          required
                        />
                      </Label>
                      {isMobile && <div className={styles.spacerTop} />}
                      <Label text="Card verification number" theme="light">
                        <TextInput
                          type="tel"
                          name="cvc"
                          onChange={(e) => setCvc(e.target.value)}
                          onFocus={(e) => setFocused(e.target.name)}
                          maxLength="4" // including the Amex
                          required
                        />
                      </Label>
                      <div />
                    </InputRow>
                  ) : (
                    <div className={styles.mobileCC}>
                      <Label text="Expiration date" theme="light">
                        <TextInput
                          type="text"
                          name="expiry"
                          value={displayExpiry} // Use the display value for the input
                          onChange={handleExpiryChange}
                          onFocus={(e) => setFocused(e.target.name)}
                          maxLength="5" // MM/YY, including the slash
                          required
                        />
                      </Label>
                      <div className={styles.mobilemargin} />
                      {isMobile && <div className={styles.spacerTop} />}
                      <Label text="CVC" theme="light">
                        <TextInput
                          type="tel"
                          name="cvc"
                          onChange={(e) => setCvc(e.target.value)}
                          onFocus={(e) => setFocused(e.target.name)}
                          maxLength="4" // including the Amex
                          required
                        />
                      </Label>
                    </div>
                  )}
                </>
              </Modal>
            )}
            <>
              {paymentProfile != null ? (
                <>
                  <InputRow>
                    <Label text="Billing name" theme="light">
                      {paymentProfile.billTo.firstName +
                        " " +
                        paymentProfile.billTo.lastName}
                    </Label>

                    {!loading &&
                      isAuthorized(currentUser.user.roles, [
                        SYS_ADMIN,
                        TENANT_ADMIN,
                        CUSTOMER_USER,
                      ]) &&
                      hasPermission(currentUser.user.perms, "ADMIN") && (
                        <div className={styles.editButton}>
                          {paymentProfile != null ? (
                            <Button
                              theme="primary"
                              icon="edit"
                              onClick={() => openEditModal()}
                            >
                              Edit
                            </Button>
                          ) : (
                            <Button
                              theme="primary"
                              icon="edit"
                              onClick={() => setCreateModalOpen(true)}
                            >
                              Add
                            </Button>
                          )}
                        </div>
                      )}
                  </InputRow>

                  <Label text="Credit card number" theme="light">
                    {paymentProfile.payment.creditCard.cardType +
                      " / " +
                      paymentProfile.payment.creditCard.cardNumber +
                      " / " +
                      paymentProfile.payment.creditCard.expirationDate}
                  </Label>
                </>
              ) : (
                <div>
                  {!loading &&
                    isAuthorized(currentUser.user.roles, [
                      SYS_ADMIN,
                      TENANT_ADMIN,
                      CUSTOMER_USER,
                    ]) &&
                    hasPermission(currentUser.user.perms, "ADMIN") && (
                      <div className={styles.centeredContent}>
                        <>
                          <p className={styles.fullWidth}>
                            No credit card on file.
                          </p>
                          <div className={styles.editButton}>
                            {paymentProfile != null && (
                              <Button
                                theme="primary"
                                icon="edit"
                                onClick={() => openEditModal()}
                              >
                                Edit
                              </Button>
                            )}
                          </div>
                        </>
                      </div>
                    )}
                </div>
              )}
            </>
          </>
        )}
      </Frame>
    </>
  );
}
