import React, { useState, useEffect, useContext } from 'react';
import { get, post } from '../../api';
import EventTypeChanged from '../../components/EventTypeChanged';
import history from '../../helpers/history';
import Button from '../../components/Button';
import styles from './RepairStart.module.scss';
import LoadingFrame from '../../components/LoadingFrame';
import Modal from '../../components/Modal';
import Space from '../../components/Space';
import { IMPACT } from '../../constants/statuses';
import { AuthContext } from "../../contexts/Auth";
import { WORK_IN_PROGRESS, WORK_COMPLETE } from '../../constants/events';
export default function RepairStart({ match: { params } }) {
  const [loading, setLoading] = useState(true);
  const { state } = useContext(AuthContext);
  const [error, setError] = useState(false);
  const [activated, setActivated] = useState(false);
  const [alarm, setAlarm] = useState({});
  const [step, setStep] = useState(0);
  const [event, setEvent] = useState({});
  useEffect(() => {
    async function StartRepair() {
      const isAlarmAvailable = await get(`/alarms/${params.id}`);
      const eventState = await get(`/events/`, { reference_id: params.id })
      eventState && eventState.results[0] && determineStep(eventState.results[0]) 
      eventState && eventState.results[0] && setEvent(eventState.results[0]) 
      isAlarmAvailable.status == 204 && setError(true)
      isAlarmAvailable ? isAlarmAvailable.repair_started && setActivated(true) : (setError(true))
      setAlarm(isAlarmAvailable)
      setLoading(false)
    }
    StartRepair();
  }, []);

  async function sendRequest(actionIndicator) {
    setLoading(true);
    const result = await post(`/alarms/${params.id}/action/${actionIndicator}`);
    setActivated(true);
    if (actionIndicator === 3) {
      setStep(1);
    } else if (actionIndicator === 4) {
      setStep(3);
    }
    setLoading(false);
  }

  function determineStep(event) {
    if (event !== undefined) {
      switch (event.type) {
        case IMPACT:
          setStep(0);
          break;
        case WORK_IN_PROGRESS:
          if (state.user.user_id === event.user.id) {
            setStep(2);
          } else {
            setStep(-1);
          }
          break;
        case WORK_COMPLETE:
          setStep(3);
          break;
      }
    }
  }

  function renderContent() {
    switch (step) {

      case -1: // not auth
        return (<>
          <Space size="s"><h2>{alarm.asset.name}</h2></Space>
          <Space size="s"><p className={styles.text}><b>{event.user.first_name} {event.user.last_name}</b> has already started <b>Work in progress</b> on current asset.</p></Space>
          <EventTypeChanged from={IMPACT} to={WORK_IN_PROGRESS}/>
        </>)
        break;

      case 0: // impact
        return (<>
          <Space size="s"><h2>{alarm.asset.name}</h2></Space>
          <Space size="s"><p className={styles.text}>Are you sure you want to disable impact notifications and start work in progress?</p></Space>
          <EventTypeChanged from={IMPACT} to={WORK_IN_PROGRESS} changing={true} />
        </>)
        break;
      case 1: // work in progress enabled
        return (<>
          <Space size="s"><h2>{alarm.asset.name}</h2></Space>
          <Space size="s"><p className={styles.text}>Impact notifications have been disabled until repair is complete.</p></Space>
          <EventTypeChanged from={IMPACT} to={WORK_IN_PROGRESS} />
        </>)
      case 2: // work complete
        return (<>
          <Space size="s"><h2>{alarm.asset.name}</h2></Space>
          <Space size="s"><p className={styles.text}>Are you sure you want to enable impact notifications and complete repair?</p></Space>
          <EventTypeChanged from={WORK_IN_PROGRESS} to={WORK_COMPLETE} changing={true} />
        </>)

      case 3:
        return (<>
          <Space size="s"><h2>{alarm.asset.name}</h2></Space>
          <Space size="s"><p className={styles.text}>Impact notifications have been enabled and repair is complete.</p></Space>
          <EventTypeChanged from={WORK_IN_PROGRESS} to={WORK_COMPLETE} />
        </>)
    }
  }
  function _renderFooter() {

    switch (step) {
      case -1:
        return (
          <>
            <Button theme="primary" onClick={() => window.location.replace("/assets/" + params.assetId)}>
              OK
          </Button>
          </>
        );
      case 0:
        return (
          <>
          {alarm && <Button theme="seondary" onClick={() => history.push("/assets/" + params.assetId)}>
              CANCEL
            </Button>}
            <Button theme="primary" onClick={() => sendRequest(3)}>
              OK
            </Button>
            
          </>
        );
      case 1:
        return (<Button theme="primary" onClick={() => window.location.replace("/assets/" + params.assetId)}>
          OK
        </Button>)
      case 2:
        return (
          <>
           
            {alarm && <Button theme="seondary" onClick={() => history.push("/assets/" + params.assetId)}>
              CANCEL
            </Button>}
            <Button theme="primary" onClick={() => sendRequest(4)}>
              OK
            </Button>

          </>
        );
      case 3:
        return (
          <Button theme="primary" onClick={() => window.location.replace("/assets/" + params.assetId)}>
            OK
          </Button>
        );
    }
  }
  return (
    <Modal small={true} footer={loading == false && _renderFooter()}>
      {loading ? (
        <LoadingFrame />
      ) : (
          <Space size="s">
            {error ? (<p className={styles.text}>Sorry! Unable to retrieve desired alarm! </p>) : (
              renderContent()
            )}
          </Space>
        )}
    </Modal>
  );
}
