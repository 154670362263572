import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDebounce } from '../../hooks/inputs';
import { useFetch } from '../../hooks/fetch';
import Button from '../../components/Button';
import FrameHeader from '../../components/FrameHeader';
import RowItem from '../../components/RowItem';
import Frame from '../../components/Frame';
import LoadingFrame from '../../components/LoadingFrame';
import QueryBar from '../../components/QueryBar';
import TextInput from '../../components/TextInput';
// import styles from './UserCustomerGroups.module.scss';

export default function UserCustomerGroups({ match: { params } }) {
  const [query, setQuery] = useState('');
  const [sortBy] = useState('-created_time');
  const debouncedQuery = useDebounce(query, 350);

  const [data, loading /* , error */] = useFetch(`/groups`, {
    nameContains: debouncedQuery,
    orderBy: sortBy,
    customer_id: params.id,
    include_users: '1'
  });

  function _renderContent() {
    if (loading && !data) {
      return <LoadingFrame />;
    } else {
      return (
        <>

          {data.results.length
            ? data.results.map(g => (
              <RowItem
                key={g.id}
                title={g.name}
                to={`/customer/groups/${g.id}`}
              />
            ))
            : null}
        </>
      );
    }
  }

  return (
    <>
      <FrameHeader title="Groups">
        <Link to={`/customer/groups/new`}>
          <Button theme="primary" icon="add" fixedWidth={126}>
            New group
          </Button>
        </Link>
      </FrameHeader>
      <Frame padding="0">
        <QueryBar button={false}>
          <TextInput
            value={query}
            placeholder={`Search by name`}
            icon="search"
            loading={loading && data}
            onChange={e => {
              setQuery(e.target.value);
            }}
          />
        </QueryBar>

        {_renderContent()}
      </Frame>
    </>
  );
}
