import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { SYS_ADMIN, TENANT_ADMIN, CUSTOMER_USER } from '../../constants/roles';
import { get, post, put } from '../../api';
import { isAuthorized, hasPermission } from "../../helpers/authorization";
import { AuthContext } from "../../contexts/Auth";
import Button from '../../components/Button';
import Frame from '../../components/Frame';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import FrameHeader from '../../components/FrameHeader';
import moment from 'moment';
import InputRow from "../../components/InputRow";
import CheckboxWithLabel from "../../components/CheckboxWithLabel";
import LoadingFrame from '../../components/LoadingFrame';
import Modal from "../../components/Modal";
import Space from '../../components/Space';
import styles from './CustomerSubscriptions.module.scss';
import { useConfirm } from 'material-ui-confirm';
import queryString from "query-string";
import Icon from '../../components/Icon';
export default function CustomerSubscriptions({ match: { params }, location: { search }, admin = false }) {
  const { state } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const queryParams = queryString.parse(search);
  const [subcriptionSummary, setSubcriptionSummary] = useState([]);
  const [selectedDevicesToAssign, setSelectedDevicesToAssign] = useState([]);
  const [assigning, setAssigning] = useState(false);
  const [devicesLoading, setDevicesLoading] = useState(false);
  const [noLicense, setNoLicense] = useState([]);
  const [assigningLoading, setAssigningLoading] = useState(false);
  const [expiredLicense, setExpiredLicense] = useState([]);
  const [autoRenew, setAutoRenew] = useState(true);
  const [expanded, setExpanded] = useState(true);
  const [disabledAutomatic, setDisabledAutomatic] = useState(false);
  const [loadingLicenseId, setLoadingLicenseId] = useState(0);
  const confirm = useConfirm();
  var selectedDevices = [];
  const finalParams = params.params !== undefined ? params.params : params;

  async function fetchCustomerSubscriptons() {

    const subcriptionSummary = await Promise.all([
      get(`/customer/${finalParams.id}/subscriptions`)
    ]);
    subcriptionSummary && subcriptionSummary[0] && setSubcriptionSummary(subcriptionSummary[0]);
    //subcriptionSummary && subcriptionSummary[0] && subcriptionSummary[0].devices_with_active_license && setActiveLicense(subcriptionSummary[0].devices_with_active_license)
    subcriptionSummary && subcriptionSummary[0] && subcriptionSummary[0].devices_with_expired_license && setExpiredLicense(subcriptionSummary[0].devices_with_expired_license)
    subcriptionSummary && subcriptionSummary[0] && subcriptionSummary[0].devices_no_license && setNoLicense(subcriptionSummary[0].devices_no_license)
    subcriptionSummary && subcriptionSummary[0] && subcriptionSummary[0].devices_with_expired_license.map(type => {
      var id = type.id;
      selectedDevices.push(id);
    })

    setSelectedDevicesToAssign(selectedDevices);
    if (subcriptionSummary[0].subscriptions_active - subcriptionSummary[0].subscriptions_in_use - subcriptionSummary[0].devices_with_active_license.length < subcriptionSummary[0].devices_with_expired_license.length) {
      setDisabledAutomatic(true);
      setAutoRenew(false);
      setSelectedDevicesToAssign([])
    }

    if (queryParams.assign) {
      setAssigning(true);
    }
    setLoading(false);
  }
  useEffect(() => {

    fetchCustomerSubscriptons();

  }, []);
  function activateNewLicenseForDeviceID(dev_id) {
    confirm({
      description: "Are you sure you want to assign a subscription for this device?",
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true }
    })
      .then(() => {
        assignAutoLicenseForDevice(dev_id);
      })
      .catch(() => { /* ... */ });
  }

  async function assignAutoLicenseForDevice(device_id) {
    var result = await post(`/customer/${finalParams.id}/subscriptions/auto/assign`, { devices: [device_id] });
    refresh();
  }

  async function assignDevices() {
    setAssigningLoading(true);
    const res = await post(`/customer/${finalParams.id}/subscriptions/auto/assign`, {
      auto_assign: autoRenew,
      devices: selectedDevicesToAssign
    });

    if (res) {
      window.location.replace(`/customers/${finalParams.id}/subscriptions`);
    } else {
      alert('Sorry! We encountered an error!');
    }
    setAssigningLoading(false);
  }
  async function changeStateForLicenseId(licenseId, state) {
    setLoadingLicenseId(licenseId);
    const res = await post(`/subscriptions/licenses/${licenseId}`, {
      state: state,
    });

    if (!res) {
      alert('Sorry! We encountered an error!');
    }
    await fetchCustomerSubscriptons();
    setLoadingLicenseId(0);
  }


  async function refresh() {
    setLoading(true);
    const subcriptionSummary = await Promise.all([
      get(`/customer/${finalParams.id}/subscriptions`)
    ]);
    subcriptionSummary && subcriptionSummary[0] && setSubcriptionSummary(subcriptionSummary[0]);
    setLoading(false);
  }

  function _renderModelFooter() {
    return (
      <>
        <Button
          onClick={() => setAssigning(false)}>
          Cancel
        </Button>
        <Button
          disabled={selectedDevicesToAssign.length === 0 || assigningLoading}
          theme="primary"
          isLoading={assigningLoading}
          onClick={() => assignDevices()}>
          Assign
        </Button>
      </>
    );
  }

  async function modifyTypes(device_id, checked) {
    setDevicesLoading(true);

    if (checked == true) {
      var array = selectedDevicesToAssign;
      var index = array.indexOf(device_id);
      if (index < 0) {
        array.push(device_id);

        var tempArray = [];
        array.map(item => {
          if (item != null) {
            tempArray.push(item)
          }
        })
        setSelectedDevicesToAssign(tempArray);
      }
    } else {
      var array = selectedDevicesToAssign;
      var index = array.indexOf(device_id);
      delete array[index];
      var tempArray = [];
      array.map(item => {
        if (item != null) {
          tempArray.push(item)
        }
      })
      setSelectedDevicesToAssign(tempArray);
    }
    setDevicesLoading(false);
  }

  async function selectAll() {
    setDevicesLoading(true);
    var tempDev = [];
    expiredLicense.map(type => {
      var id = type.id;
      tempDev.push(id);
    })
    setSelectedDevicesToAssign(tempDev);
    setDevicesLoading(false);
  }
  function selectAutomatic(value) {

    if (value === true) {
      selectAll()
    }
    setAutoRenew(value)
  }
  function stepOption(label, explanation, value) {
    const active = autoRenew === value;
    const classes = [styles.label, active && styles.labelActive].join(" ");

    return (
      <label className={classes}>
        <div className={styles.img}>
          <div className={styles[value]}>
            <Icon name={value === true ? "auto_mode" : "list"} size={56} />
          </div>
        </div>
        <div className={styles.flexContainer}>
          <div className={styles.flexRow}><div className={styles.text}>{active ? <b>{label}</b> : label}</div></div>
          <div className={styles.flexRow}><div className={styles.sub_text}><p>{explanation}</p></div></div>
        </div>
        <input
          type="radio"
          name="damage"
          value={value}
          disaled={disabledAutomatic}
          onChange={e => selectAutomatic(value)}
          checked={active}
          disabled={disabledAutomatic}
        />
      </label>
    );
  }
  function renderContent() {
    return (<>
      <Space size="s">
        {subcriptionSummary.devices_with_expired_license &&
          (<>{
            subcriptionSummary.subscriptions_active > 0 ?
              (subcriptionSummary.available_subscriptions > 1
                ?
                ("There are " + (subcriptionSummary.available_subscriptions) + " subscriptions available. Choose the serial numbers below.")
                :
                (subcriptionSummary.available_subscriptions == 0 ? ("You do not have any available subscriptions.") : ("There is 1 subscription available. Choose the serial number below."))
              )
              : ("Zero devices available")}
          </>)
        }
      </Space>

      {subcriptionSummary.devices_with_expired_license && (subcriptionSummary.available_subscriptions < subcriptionSummary.devices_with_expired_license.length) && (<Space size="s"><p className={styles.red}>
        {"There are " + (subcriptionSummary.devices_with_expired_license.length) + " devices with expired subscriptions."}
      </p></Space>)}

      <div className={styles.center}>
        <InputRow>
          <div className={styles.wrap2}>
            {stepOption(
              "Automatically",
              "Choose this option if you want the subscriptions automatically assigned.",
              true
            )}
            {stepOption(
              "Manually",
              "Choose this option if you want to manually assign the subscription.",
              false
            )}
          </div>
        </InputRow>
      </div>
      <Space size="s" />
      {autoRenew ? (
        ""
      ) : (devicesLoading == false && noLicense ? (
        <>
          <Space size="s">
            <Button size={"s"} onClick={() => selectAll()} disabled={disabledAutomatic}>Select All</Button>
            <Button size={"s"} onClick={() => setSelectedDevicesToAssign([])}>Select None</Button>
          </Space>
          {devicesLoading ? (<LoadingFrame />) : (expiredLicense.map(dev => {

            return (<div key={dev.id} className={styles.filter}>
              {<CheckboxWithLabel
                key={dev.id}
                label={dev.serial_number}
                checked={selectedDevicesToAssign.indexOf(dev.id) > -1 ? (true) : (false)}
                onChange={e => modifyTypes(dev.id, e.target.checked)}
                disabled={selectedDevicesToAssign.length + 1 > subcriptionSummary.available_subscriptions && shouldBeDisabled(dev.id)}
              />}
            </div>
            )
          }))}</>) : ("No expired subscriptions"))}
    </>
    )
  }

  function shouldBeDisabled(dev_id) {
    return selectedDevicesToAssign.indexOf(dev_id) > -1 ? (false) : (true)
  }

  return (loading ? (<LoadingFrame />) : (
    <>
      {subcriptionSummary ? (
        <>
          <Space size="l">
            <FrameHeader title={`Subscription`} >
              {isAuthorized(state.user.roles, [SYS_ADMIN, TENANT_ADMIN, CUSTOMER_USER]) && hasPermission(state.user.perms, 'ADMIN') && (
                <Link to={`/customers/${finalParams.id}/subscriptions/new`}>
                  <Button theme="primary" icon="add">
                    Buy subscription
                  </Button>
                </Link>)}
            </FrameHeader>
            <Frame>
              <>
                <Space size='s'>


                  Available: <b>{subcriptionSummary.available_subscriptions}</b>
                </Space>

                <div className={styles.accordionWrapper}>
                  <Accordion
                    expanded={expanded}
                    onChange={() => setExpanded(!expanded)}>
                    <AccordionSummary>
                      <div className={([styles.accordionHeader, 'accordionHeader'].join(' '))}>
                        <div className={styles.whiteText}>Auto renew enabled ({subcriptionSummary.licenses.filter(x => x.auto_renew === true).length})</div>
                        <div className={([styles.infoText, 'infoText'].join(' '))}>
                          <span>Click to </span> <Icon name="double_arrow" size="24" color="#eeeeee" /></div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>

                      <div className={styles.license}>
                        <div style={{ background: 'white', justifyContent: 'space-between' }}>
                          <div className={styles.filterMenu}>
                            <Space size='s'>
                              Expired: <b>{subcriptionSummary.licenses_expired_count}</b>
                            </Space>
                            <Space size='noMargin'>
                              Expiring soon: <b>{subcriptionSummary.subscriptions_expiring_this_cycle}</b>
                            </Space>
                          </div>
                          <div style={{ width: 50 }} />
                          <div className={styles.filterMenu} style={{ alignSelf: 'center' }}>
                            <Space size="noMargin">
                              <Button
                                icon="add"
                                noHeight={true}

                                disabled={subcriptionSummary.available_subscriptions <= 0 || expiredLicense.length === 0}// || subcriptionSummary.subscriptions_in_use==expiredLicense.length}
                                onClick={() => setAssigning(true)}
                              >Assign subscription</Button>
                            </Space>
                          </div>
                        </div>

                        <div className={[styles.row, styles.rowHeader].join(' ')}>
                          <div className={styles.rowItem}><b>Serial Number</b></div>
                          <div className={styles.rowItem}><b>Status</b></div>
                          <div className={styles.rowItem}><b>Expiration</b></div>
                          <div className={styles.rowItem}><b>Renew</b></div>
                        </div>
                        {subcriptionSummary.licenses.length !== 0 && (
                          subcriptionSummary.licenses.filter(x => x.auto_renew == true || x.active == true).map(lic => (
                            <>
                              <div className={[styles.row, moment(lic.expiry_date).diff(moment.utc(), 'days') <= 31 && styles.expiringsoon].join(' ')}>
                                <div className={styles.rowItem}>
                                  {lic.device ? <Link to={`/assets/${lic.asset_link}`}>{lic.device?.serial_number} </Link> : "No device"}</div>
                                <div className={styles.rowItem}>{lic.active ? "Active" : <p className={styles.expired}><b>Expired</b></p>}</div>
                                {lic.active ? (<div className={styles.rowItem}>
                                  {moment.utc(lic.expiry_date).format('MMM DD YYYY')}
                                </div>) : (<div className={styles.popover}>
                                  <Button
                                    onClick={() => activateNewLicenseForDeviceID(lic.device_id)}
                                    disabled={subcriptionSummary.available_subscriptions <= 0}
                                  >Assign subscription</Button>
                                </div>)}
                                <div className={styles.rowItem}><CheckboxWithLabel
                                  key={lic.id}
                                  loading={loadingLicenseId == lic.id}
                                  checked={lic.auto_renew}
                                  onChange={e => changeStateForLicenseId(lic.id, e.target.checked)}
                                /></div>
                              </div>
                            </>
                          )
                          ))
                        }
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary>
                      <div className={([styles.accordionHeader, 'accordionHeader'].join(' '))}>
                        <div className={styles.whiteText}>Auto renew disabled ({subcriptionSummary.licenses_expired_no_renew.length})</div>
                        <div className={([styles.infoText, 'infoText'].join(' '))}>
                          <span>Click to </span> <Icon name="double_arrow" size="24" color="#eeeeee" /></div>
                      </div>

                    </AccordionSummary>
                    <AccordionDetails>
                      <div className={styles.license}>
                        {subcriptionSummary.licenses_expired_no_renew.length !== 0 && (
                          <>
                            <div className={[styles.row, styles.rowHeader].join(' ')}>
                              <div className={styles.rowItem}><b>Serial Number</b></div>
                              <div className={styles.rowItem}><b>Status</b></div>
                              <div className={styles.rowItem}><b>Expiration</b></div>
                              <div className={styles.rowItem}><b>Renew</b></div>
                            </div>
                            {subcriptionSummary.licenses_expired_no_renew.map(lic => (
                              <div className={styles.row}>
                                <div className={styles.rowItem}>
                                  <Link to={`/assets/${lic.asset_link}`}>{lic.device.serial_number}</Link></div>
                                <div className={styles.rowItem}>{lic.active ? "Active" : <p className={styles.expired}><b>Expired</b></p>}</div>
                                {lic.active ? (<div className={styles.rowItem}>
                                  {moment.utc(lic.expiry_date).format('MMM DD YYYY')}
                                </div>) : (<div className={styles.popover}>
                                  <Button
                                    onClick={() => activateNewLicenseForDeviceID(lic.device_id)}
                                    disabled={subcriptionSummary.available_subscriptions <= 0}
                                  >Assign subscription</Button>
                                </div>)}

                                <div className={styles.rowItem}><CheckboxWithLabel
                                  key={lic.id}
                                  checked={lic.auto_renew}
                                  loading={loadingLicenseId == lic.id}
                                  onChange={e => changeStateForLicenseId(lic.id, e.target.checked)}

                                /></div>
                              </div>)
                            )}</>)
                        }
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div>
                </div>
              </>
            </Frame>
          </Space>

          {assigning && <Modal footer={_renderModelFooter()}>
            <>
              <Space size="s">
                <h2>Assign subscription</h2>
              </Space>
              {renderContent()}
            </>
          </Modal>}
        </>) : (<>Unable to load subscription details, sorry.</>)
      }
    </>)
  );
}
