import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { put, get, post, upload } from '../../api';
import assetTypesSelect from '../../helpers/assetTypes';
import TypesContext from '../../contexts/Types';
import { AuthContext } from '../../contexts/Auth';
import history from '../../helpers/history';
import Button from '../../components/Button';
import Label from '../../components/Label';
import Modal from '../../components/Modal';
import InputRow from '../../components/InputRow';
import TextInput from '../../components/TextInput';
import Select from '../../components/Select';
import Space from '../../components/Space';
import LoadingFrame from '../../components/LoadingFrame';
import AssetContext from '../../contexts/Asset';
import { hasPermission, isAuthorized } from "../../helpers/authorization";
import { fetchAsset } from '../../actions/Asset';
import styles from "./AssetEdit.module.scss";
import Image from '../../components/Image';
import Frame from '../../components/Frame';
import { SYS_ADMIN, TENANT_ADMIN } from '../../constants/roles';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useConfirm } from 'material-ui-confirm';
import Chip from '@mui/material/Chip';
import AddNewChip from '../../components/AddNewChip/AddNewChip';

export default function AssetEdit({ match: { params } }) {
  const [state, dispatch] = useContext(AssetContext);
  const { state: currentUser } = useContext(AuthContext);
  const isGroupAdmin = hasPermission(currentUser.user.perms, 'ADMIN');
  const types = useContext(TypesContext);
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [newPhoto, setNewPhoto] = useState(null);
  const [saving, setSaving] = useState(false);
  const [asset, setAsset] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [groupsToAdd, setGroupsToAdd] = useState([]);
  const [groupsToRemove, setGroupsToRemove] = useState([]);
  const filter = createFilterOptions();
  const confirm = useConfirm();

  function enableSave() {
    return (!loading && asset?.name !== initialValues?.asset?.name ||
      asset?.assettype_id !== initialValues?.asset?.assettype_id ||
      groupsToAdd.length > 0 ||
      groupsToRemove.length > 0 ||
      newPhoto != null
    );
  }

  useEffect(() => {
    async function fetchGroups() {
      const [asset, assetGroups, groups] = await Promise.all([
        get(`/assets/${params.assetId}`),
        get(`/assets/${params.assetId}/groups`),
        get(`/users/${currentUser.user.user_id}/groups`),
        get(`/assets/sites`),
      ]);
      asset && (asset.groups = assetGroups);
      asset && asset.groups && setSelectedGroups(asset.groups);
      asset && setAsset(asset);

      setInitialValues({
        asset: asset,
        groups: groups
      })

      if (groups) {
        setGroups(groups);
        if (hasPermission(currentUser.user.perms, "ADMIN")) {
          const allCustomerGroups = await get(`/groups`, {
            orderBy: '-created_time',
            customer_id: asset.customer_id,
            include_users: '1'
          });
          var notPartOfGroup = [];
          if (allCustomerGroups.results) {
            allCustomerGroups.results.map(group => {
              const isObjectPresent = asset.groups.find((o) => o.id === group.id);
              if (!isObjectPresent) {
                notPartOfGroup.push(group);
              }
            })
          }
          if (notPartOfGroup && notPartOfGroup.length > 0) {
            notPartOfGroup = notPartOfGroup.slice().sort((a, b) => {
              const nameA = a.name.toLowerCase();
              const nameB = b.name.toLowerCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            });
            setGroups(notPartOfGroup);
          }
        }
      }

      setLoading(false);
    }
    fetchGroups();
  }, []);

  function valid() {
    return asset.name !== '';
  }

  function _renderFooter() {
    return (
      <>
        {state.asset && <Link to={`/assets/${state.asset.id}`}>
          <Button>Cancel</Button>
        </Link>}
        {(hasPermission(currentUser.user.perms, "ASSET_U") || hasPermission(currentUser.user.perms, "ADMIN"))
          &&
          <Button
            theme="primary"
            onClick={saveEdits}
            isLoading={saving}
            disabled={!valid || !enableSave()}
          >
            Save
          </Button>
        }
      </>
    );
  }

  async function _chooseFiles(e) {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
    const files = (e.dataTransfer && e.dataTransfer.files) || (e.target && e.target.files);
    const valid = Array.from(files).every(f => validTypes.includes(f.type));
    if (valid) {
      setNewPhoto(files[0]);

    } else {
      alert('Select valid photo file!');
    }
  }

  function returnImage() {
    if (newPhoto !== null) {
      return (<Image url={URL.createObjectURL(newPhoto)} zoom={true} token={false} />);
    } else {
      if (state.installPhoto.id) {
        return (
          <Image url={state.installPhoto.url} zoom={true} />);
      }
      else {
        return (
          <Image url="/img/photo_placeholder.jpg" />);
      }
    }
  }

  async function saveEdits() {
    setSaving(true);
    const formData = new FormData();
    formData.set('entity_type', 'ASSET');
    formData.set('entity_id', asset.id);
    formData.set('type', 'IMAGE');
    formData.append('file', newPhoto);
    const res = await upload(formData);
    asset.groups = selectedGroups;
    const update = await put(`/assets/${asset.id}`, asset);
    dispatch(fetchAsset(state.asset.id));
    history.push(`/assets/${state.asset.id}`);
  }

  function groupHandler(value, reason, detail) {
    var item = detail?.option;
    if (reason == 'removeOption') {
      var justAdded = groupsToAdd?.find(x => x.id == item.id);
      if (!justAdded) {
        setGroupsToRemove([...groupsToRemove, detail?.option]);
      } else {
        setGroupsToAdd(groupsToAdd.filter(x => x.id != item.id));
      }
      setSelectedGroups(selectedGroups.filter(x => x.id != item.id));
    }

    if (reason == 'selectOption') {
      var item = detail?.option
      //check if existing in removed group
      var currentGroups = selectedGroups;
      var justRemoved = groupsToRemove?.find(x => x.id == item.id);
      if (!justRemoved) {
        item.new = true;
        setGroupsToAdd([...groupsToAdd, item]);
      } else {
        setGroupsToRemove(groupsToRemove.filter(x => x.id != item.id));
      }
      currentGroups?.push(item);
      setSelectedGroups(currentGroups);
    }
  }

  return (
    <Modal footer={_renderFooter()}>
      {loading ? (
        <LoadingFrame />
      ) : (
        <>
          <Space size="m">
            <h2>Edit asset</h2>
          </Space>
          {state.device.id && (
            <div className={styles.bannerImage}>

              <div className={styles.floatleft}>
                <Button icon="cloud_upload" >Change photo</Button>
                <input
                  className={styles.input}
                  onChange={_chooseFiles}
                  type="file"
                  accept="image/*"
                />
              </div>
              {returnImage()}
            </div>
          )}
          {hasPermission(currentUser.user.perms, "ASSET_U") ? (<div className={styles.mainInfo}>
            <fieldset
              disabled={!hasPermission(currentUser.user.perms, "ASSET_U")}
            >
              <Frame>
                <InputRow>
                  <Label text="Name">
                    <TextInput
                      value={asset.name}
                      onChange={e =>
                        setAsset({ ...asset, name: e.target.value })
                      }
                    />
                  </Label>
                </InputRow>

                <InputRow>
                  <Label text="Type">
                    <Select
                      value={asset.assettype_id}
                      onChange={e =>
                        setAsset({
                          ...asset,
                          assettype_id: Number(e.target.value)
                        })
                      }
                    >
                      {assetTypesSelect(types.assets)}
                    </Select>
                  </Label>
                </InputRow>

                <InputRow>
                  <Label text="Group">
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      filterSelectedOptions
                      options={groups}
                      readOnly={!isGroupAdmin}
                      getOptionLabel={(option) => option.id == 0 ? <b>Add "{option.name}"</b> : option.name}
                      onChange={(event, value, reason, detail) => { groupHandler(value, reason, detail) }}
                      renderInput={(params) => (<TextField {...params} variant="standard" placeholder={isGroupAdmin && "Enter name"} />)}
                      renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => (option.id == 0 ? <AddNewChip {...getTagProps({ index })} label={option.name} /> : <Chip {...getTagProps({ index })} label={option.name} />));
                      }}
                      value={selectedGroups}
                      filterOptions={(options, params) => {
                        var filtered = filter(options, params);
                        if (filtered === undefined) return [];
                        var filteredduplicates = filtered.filter(({ id: id1 }) => !selectedGroups.some(({ id: id2 }) => id2 === id1));
                        const exist = filteredduplicates.find(item => item.name.toLowerCase() == params.inputValue.toLowerCase());
                        const existInSelected = selectedGroups.find(item => item.name.toLowerCase() == params?.inputValue.toLowerCase());
                        if (params.inputValue !== '' && !exist && !existInSelected) {
                          var selectedValueInSelectedGroups = selectedGroups.find(item => item.name.toLowerCase() == params?.inputValue.toLowerCase());
                          if (!selectedValueInSelectedGroups) {
                            filteredduplicates.push({ id: 0, title: `Add "${params.inputValue}"`, name: `${params.inputValue}`, });
                          }
                        }
                        return filteredduplicates;
                      }
                      }
                    />
                  </Label>
                </InputRow>
              </Frame>
              <br />
            </fieldset>
          </div>) : (null)}
        </>
      )
      }
    </Modal >
  );
}
