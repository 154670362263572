import React from 'react';
import AttachmentDisplay from '../AttachmentDisplay';
import Space from '../Space';
import styles from './ActivityItem.module.scss';
export default function ActivityItem({
  author,
  title,
  body,
  attachments,
  date,
  updated,
  action,
  assetId=0,
  hideFromReport=null,
}) {
const titleActionWrapperClasses = hideFromReport? ([styles.titleActionWrapper, styles.eventWithHideFromReport].join(' ')) : (styles.titleActionWrapper);
const titleClasses = hideFromReport? ([styles.title, styles.eventWithHideFromReport].join(' ')) : (styles.title);

  return (
    <div className={styles.wrap}>
      <div className={styles.timeline} />
      <div className={styles.avatar}>{author}</div>
      <div className={styles.bodyWrap}>
        <div className={titleActionWrapperClasses}><div className={titleClasses}>{title}</div>
          {action && <div className={styles.action}>{action}</div>}</div>
        {body && <div className={styles.body}>{body}</div>}
        {attachments.length > 0 && (
          <Space size="m">
            <AttachmentDisplay attachments={attachments} assetId={assetId} />
          </Space>
        )}
        {date && <div className={styles.datetime}>{date}</div>}
        {updated && <div className={styles.datetime}>{updated}</div>}
        {(hideFromReport && hideFromReport != true) && hideFromReport}
      </div>
    </div>
  );
}