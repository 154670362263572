import React from 'react';
import Loading from '../Loading/Loading';

import styles from './LoadingFrame.module.scss';

export default function LoadingFrame() {
  return (
    <div className={styles.wrap}>
      <Loading color="gray" size={48} />
    </div>
  );
}
