import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { isAuthorized, hasPermission } from '../../helpers/authorization';

export default function AuthorizedRoute({
  userRoles,
  allowedRoles,
  userPermissions,
  allowedPermissions,
  ...routeProps
}) {
  const authorized = userRoles ? isAuthorized(userRoles, allowedRoles) : true;
  const permission = userPermissions
    ? hasPermission(userPermissions, allowedPermissions)
    : true;

  if (authorized && permission) {
    return <Route {...routeProps} />;
  } else {
    return <Redirect to="/not-authorized" />;
  }
}
