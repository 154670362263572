export default function validate(formState, options) {
  const valid = Object.values(formState.validity).every(v => v);

  if (options.required) {
    return (
      valid &&
      options.required.map(r => formState.values[r]).every(v => v && !!v.length)
    );
  }

  return valid;
}
