import React, { useState, useEffect } from "react";
import { useFormState } from "react-use-form-state";
import validate from "../../helpers/validate";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import Space from "../../components/Space";
import Button from "../../components/Button";
import Frame from "../../components/Frame";
import FrameFooter from "../../components/FrameFooter";
import InputRow from "../../components/InputRow";
import Label from "../../components/Label";
import TextInput from "../../components/TextInput";
import styles from "./CustomerInfoForm.module.scss";
import Select from "../../components/Select";
import { TOOLTIP_CUSTOMER_EMAIL_INTERVAL } from "../../helpers/tooltips.js";
export default function CustomerInfoForm({
  state,
  saving,
  onSave,
  isNew,
  simple = false,
  updatedCustomerForm,
  reset = false,
  setEmptying = null,
  disabled = false,
}) {
  const [formState, { text, email, select }] = useFormState({
    ...state,
  });
  const [initialValues, setInitialValues] = useState(state);
  const valid = validate(formState, {
    required: ["name", "email", "country", "address", "city", "zip"],
  });
  const [address, setAddress] = useState(getAddress());

  useEffect(() => {
    if (reset) {
      setAddress("");
      setEmptying && setEmptying(false);
    }
  }, [state]);

  function enableSave() {
    return (
      address !== getAddress(initialValues) ||
      formState?.values.name != initialValues?.name ||
      formState?.values.email != initialValues?.email ||
      formState?.values.email_notifications_interval !=
        initialValues?.email_notifications_interval
    );
  }

  function MapResults(result) {
    setAddress(result.formatted_address);
    result.address_components[0] &&
      formState.setField(
        "address",
        result.address_components[0].long_name +
          " " +
          result.address_components[1].long_name
      );
    result.address_components[2] &&
      formState.setField("city", result.address_components[2].long_name);
    result.address_components[4] &&
      formState.setField("state", result.address_components[4].long_name);
    result.address_components[5] &&
      formState.setField("country", result.address_components[5].long_name);

    if (result.address_components[6]) {
      //check if address_components[6] is a number
      if (isNaN(result.address_components[6].long_name)) {
        //check if address_components[7] is a number
        if (result.address_components[7]) {
          if (!isNaN(result.address_components[7].long_name)) {
            formState.setField("zip", result.address_components[7].long_name);
          } else {
            formState.setField("zip", "no-zip-code");
          }
        } else {
          formState.setField("zip", "no-zip-code");
        }
      } else {
        formState.setField("zip", result.address_components[6].long_name);
      }
    }

    updatedCustomerForm && updatedCustomerForm(formState.values);
  }

  function getAddress(values = formState.values) {
    var address = "";

    if (values.address !== undefined && values.address !== "") {
      address += values.address + ", ";
    }
    if (values.city !== undefined && values.city !== "") {
      address += values.city + ", ";
    }
    if (values.zip !== undefined && values.zip !== "") {
      address += values.zip + ", ";
    }
    if (values.state !== undefined && values.state !== "") {
      address += values.state + ", ";
    }
    if (values.country !== undefined && values.country !== "") {
      address += values.country;
    }
    return address;
  }

  function handleChange(address) {
    setAddress(address);
  }

  function handleSelect(address) {
    geocodeByAddress(address).then((results) => MapResults(results[0]));
  }

  function _save(e) {
    e.preventDefault();
    onSave(formState.values);
  }
  const notificationPreferenes = () => (
    <>
      <option value="0">Do not summarize</option>
      <option value="1">
        Summarize consecutive impact notifications for the next hour
      </option>
      <option value="2">
        Summarize consecutive impact notifications for the next 2 hours
      </option>
      <option value="5">
        Summarize consecutive impact notifications for the next 5 hours
      </option>
    </>
  );

  return (
    <>
      <Frame noframe={simple}>
        <form onSubmit={_save}>
          {!simple && (
            <InputRow>
              <Label text="Name">
                <TextInput
                  {...text("name")}
                  error={formState.touched.name && formState.errors.name}
                  required
                />
              </Label>

              <Label text="Email">
                <TextInput
                  {...email("email")}
                  error={formState.touched.email && formState.errors.email}
                  required
                />
              </Label>
            </InputRow>
          )}
          <InputRow>
            <Space size="s">
              <div>
                <span
                  style={{
                    display: "block",
                    color: simple ? "#777777" : "black",
                    paddingBottom: 14,
                  }}
                >
                  {simple ? "Billing address" : "Address"}
                </span>
              </div>

              <PlacesAutocomplete
                value={address}
                onChange={handleChange}
                onSelect={handleSelect}
                className={{ pointerEvents: "none", opacity: 0.5 }}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      {...getInputProps({
                        //placeholder: 'Enter address',
                        className: styles.autocomplete,
                      })}
                    />
                    <div className={styles.autocompleteDiv}>
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? styles.autocompleteSuggestionActive
                          : styles.autocompleteSuggestion;

                        const style = suggestion.active
                          ? { backgroundColor: "#eeeeee", cursor: "pointer" }
                          : { backgroundColor: "#ffffff", cursor: "pointer" };

                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </Space>
            {!simple && (
              <Label text="Customer ID">
                <TextInput {...text("id")} disabled />
              </Label>
            )}
          </InputRow>
          {!simple && (
            <>
              <div className={styles.break} />
              <InputRow>
                <Label
                  tooltip={TOOLTIP_CUSTOMER_EMAIL_INTERVAL}
                  text="Impact summary notification interval"
                >
                  <Select {...select("email_notifications_interval")}>
                    {notificationPreferenes()}
                  </Select>
                </Label>
              </InputRow>
            </>
          )}
          {saving && (
            <FrameFooter>
              <Button
                type="submit"
                theme="primary"
                isLoading={saving}
                disabled={!valid || !enableSave()}
              >
                {isNew ? "Create" : "Save"}
              </Button>
            </FrameFooter>
          )}
        </form>
      </Frame>
    </>
  );
}
