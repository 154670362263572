import React, { useState, useContext } from 'react';
import { useFormState } from 'react-use-form-state';
import { post, get } from '../../api';
import validate from '../../helpers/validate';
import assetTypesSelect from '../../helpers/assetTypes';
import { AuthContext } from '../../contexts/Auth';
import TypesContext from '../../contexts/Types';
import Modal from '../../components/Modal';
import Button from '../Button';
import Label from '../Label';
import Select from '../Select';
import Space from '../Space';
import InputRow from '../InputRow';
import TextInput from '../TextInput';
import styles from './InstallDeviceStep1.module.scss';
import { useConfirm } from 'material-ui-confirm';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { hasPermission, isAuthorized } from "../../helpers/authorization";
import Chip from '@mui/material/Chip';
import AddNewChip from '../../components/AddNewChip/AddNewChip';

export default function InstallDeviceStep1({ device, assets, groups, selectedGroups, setSelectedGroups, loading, subscription, next, back, step = false, skipSave = false, existingAsset }) {
  const { state } = useContext(AuthContext);
  const isGroupAdmin = hasPermission(state.user.perms, 'ADMIN');

  const types = useContext(TypesContext);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [saving, setSaving] = useState(false);
  const [disableEntryType, setDisableEtryType] = useState(0);
  const [formState, { text, select }] = useFormState({
    name: '',
    type: 72,
    serial: ''
  });
  const valid = validate(formState, { required: ['name'] });

  const confirm = useConfirm();
  const filter = createFilterOptions();

  async function _createAsset() {
    const { tenant_id, customer_id } = state.user;
    const { name, type, serial } = formState.values;
    const checkName = await get(`/assets/name`, {
      name: encodeURIComponent(name)
    });
    if (checkName != undefined) {
      if (checkName === false) {
        alert("The asset name already exists. Type in a new asset name.");
      } else {
        setSaving(true);
        const res = await post(`/assets`, {
          name,
          assettype_id: type,
          serial,
          tenant_id,
          customer_id,
          groups
        });
        next(res);
      }
    }
  }

  async function _handleNext() {
    const { tenant_id, customer_id } = state.user;
    const { name, type, serial } = formState.values;
    if (selectedAsset != null) {
      //selectedAsset.groups = selectedGroups;
      next(selectedAsset);
    } else {
      const checkName = await get(`/assets/name`, {
        name: encodeURIComponent(name)
      });
      if (checkName != undefined) {
        if (checkName === false) {
          confirm({
            description: "The asset name already exists. Type in a new asset name.",
            title: null,
            dialogProps: { fullScreen: false },
            cancellationButtonProps: { style: { 'display': 'none' } },
            confirmationButtonProps: { color: "primary", disableRipple: true }
          })
            .then(() => {

            })
            .catch(() => { /* ... */ });
        } else {
          skipSave == false ? _createAsset() : next({
            name: name,
            assettype_id: type,
            serial,
            tenant_id,
            customer_id
          });
        }
      }
    }
  }

  function _renderFooter() {
    return (
      <div>
        {back && !step && <Button onClick={back}>Back</Button>}
        <Button
          theme="primary"
          onClick={_handleNext}
          isLoading={saving}
          disabled={!selectedAsset && !valid}
        >
          Next
        </Button>
      </div>
    );
  }
  function handleTextInput(e) {
    if (e.target.value.length > 0) {
      formState.setField('name', e.target.value);
      setDisableEtryType(1);
      existingAsset(null);
      setSelectedAsset(null);
    } else {
      setDisableEtryType(0);
      formState.setField('name', '');
      setSelectedAsset(null);
      existingAsset(null);

    }
  }
  return (
    <Modal onTop={true}
      footer={_renderFooter()}>
      <Space size="s">
        {subscription && subscription.length == 0 ? (<h2>Step 3: New Asset</h2>) : (<h2>Step {step == false ? 3 : 1}: Assign an asset</h2>)}
      </Space>
      {subscription && subscription.length == 0 ? ("") : (<Space size="m">
        <p>
          First, select or create the asset this device will be installed on.
        </p>
      </Space>)}
      <Space size="l">
        <Label text="Asset" theme="light" />
        <div className={styles.assetWrap}>
          {assets && assets.length > 0 && (<>
            <Space size="s">
              <Label text="Choose an inactive asset">
                <Select
                  disabled={loading || disableEntryType == 1}

                  value={selectedAsset}
                  onChange={e => { setSelectedAsset(e.target.value); existingAsset(e.target.value == -1 ? null : e.target.value); setDisableEtryType(e.target.value == -1 ? -1 : 2); }}
                >
                  <option value={-1}>---</option>
                  {assets.map(a => (
                    <option key={a.id} value={a.id}>
                      {a.name}
                    </option>
                  ))}
                </Select>
              </Label>
            </Space>
            <Space size="l" />
            <Space size="m">
              <p>Or create a new asset:</p>
            </Space>
          </>)}
          <InputRow>
            <Label text="Name">
              <TextInput

                onChange={e => handleTextInput(e)}
                error={formState.errors.name}
                disabled={loading || disableEntryType == 2}
                required
              />
            </Label>
          </InputRow>
          <InputRow>
            <Label text="Type">
              <Select {...select('type')}>
                {assetTypesSelect(types.assets)}
              </Select>
            </Label>
          </InputRow>
          {isGroupAdmin && <InputRow>
            <Label text="Group">
              <Autocomplete
                multiple
                id="tags-standard"
                filterSelectedOptions
                options={groups}
                getOptionLabel={(option) => option.id == 0 ? <b>Add "{option.name}"</b> : option.name}
                onChange={(event, value) => { setSelectedGroups(value); }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Enter name"
                  />
                )}
                renderTags={(tagValue, getTagProps) => {
                  return tagValue.map((option, index) => (option.id == 0 ? <AddNewChip {...getTagProps({ index })} label={option.name} /> : <Chip {...getTagProps({ index })} label={option.name} />));
                }}
                value={selectedGroups}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  if (filtered === undefined) return [];
                  var filteredduplicates = filtered.filter(({ id: id1 }) => !selectedGroups.some(({ id: id2 }) => id2 === id1));
                  const exist = filteredduplicates?.find(item => item?.name.toLowerCase() == params?.inputValue.toLowerCase());
                  const existInSelected = selectedGroups?.find(item => item?.name.toLowerCase() == params?.inputValue.toLowerCase());
                  isGroupAdmin && params.inputValue !== '' && !exist && !existInSelected &&
                    filtered.push({
                      id: 0,
                      title: `Add "${params.inputValue}"`,
                      name: params.inputValue,
                    });

                  return filtered;
                }}
              />
            </Label>
          </InputRow>}
        </div>
      </Space>
    </Modal>
  );
}
