import React from 'react';
import ReactTooltip from 'react-tooltip';
import styles from './DataValue.module.scss';
import Icon from '../Icon';

export default function DataValue({ label, tooltip, children, mobile = false }) {

  const classesWrap = mobile === true ? ([styles.mobileView, styles.wrap].join(' ')) : (styles.wrap);
  const classesLabel = [styles.label, tooltip ? styles.tooltipItemWrapper : ''].join(' ');

  return (
    <div className={classesWrap}>
      <label className={classesLabel} data-tip={tooltip} >
        <>
          <>{label} {tooltip && <Icon name={"info_outline"} size={20} />} </>
        </>
      </label>
      
      <ReactTooltip 
      multiline={true} 
      className={styles.tooltip}
      overridePosition={({ left, top }, _e, _t, node) => { return { top, left: typeof node === 'string' ? left : Math.max(left, 8), }; }}
      />

      <div className={styles.value}>{children}</div>
    </div>
  );
  }