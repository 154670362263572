import React from "react";
import styles from "./Frame.module.scss";

export default function Frame({ children, padding = 24, noframe = false }) {
  return noframe ? (
    children
  ) : (
    <section className={styles.frame} style={{ padding }}>
      {children}
    </section>
  );
}
