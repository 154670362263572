import React, { useContext } from 'react';
import TypesContext from '../../contexts/Types';
import Icon from '../Icon';
import Label from '../Label';
import styles from './DeviceCard.module.scss';
export default function DeviceCard({ device, activation_status = false }) {
  const types = useContext(TypesContext);
  const type = types.devices.find(t => t.id === device.devicetype_id);

  return (
    <div className={styles.wrap}>
      <Label text="Serial number" theme="light">
        <h3 className={styles.font}>{device.serial_number}</h3>
      </Label>
      <Label text="Version" theme="light">
        <p className={styles.type}>{type.name}</p>
      </Label>
      {activation_status == true && (
        <Label text="Activated" theme="light">
          <div className={device.activation_time === undefined ? styles.icon : styles.iconOk}>
            <div className={styles.wrapSmall}>
              <Icon name={device.activation_time === undefined ? "error" : "check_circle"} size={14}>
                <p className={styles.typeStatus}>{device.activation_time === undefined ? "Not activated" : "Activated"}</p>
              </Icon>
            </div>
          </div>
        </Label>)}
    </div>
  );
}
