import React, { useState, useEffect } from 'react';
import { useFormState } from 'react-use-form-state';
import { differenceBy } from 'lodash';

import history from '../../helpers/history';
import validate from '../../helpers/validate';
import { get, post, destroy, put } from '../../api';
import Select from '../../components/Select';
import Button from '../../components/Button';
import Frame from '../../components/Frame';
import FrameFooter from '../../components/FrameFooter';
import FrameHeader from '../../components/FrameHeader';
import Label from '../../components/Label';
import LoadingFrame from '../../components/LoadingFrame';
import NoResults from '../../components/NoResults';
import RowItem from '../../components/RowItem';
import Space from '../../components/Space';
import TextInput from '../../components/TextInput';
import styles from './GroupDetail.module.scss';
import { useConfirm } from 'material-ui-confirm';
import InputRow from '../../components/InputRow';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
export default function GroupDetail({ match: { params } }) {
  const [formState, { text }] = useFormState();
  const valid = validate(formState, {
    required: ['name']
  });
  const GROUP_USERS = 0, GROUP_ASSETS = 1;
  const [displayContent, setDisplayContent] = useState(GROUP_ASSETS);
  const [loading, setLoading] = useState(true);
  const [userLoading, setUserLoading] = useState(0);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [group, setGroup] = useState(null);
  const [groupUsers, setGroupUsers] = useState([]);
  const [groupAssets, setGroupAssets] = useState([]);
  const [nonGroupAssets, setNonGroupAssets] = useState([]);
  const [nonGroupUsers, setNonGroupUsers] = useState([]);
  const [newPrimaryUser, setNewPrimaryUser] = useState(0);
  const confirm = useConfirm();
  const filter = createFilterOptions();

  useEffect(() => {
    fetchGroup();
    fetchAssets();
    fetchUsers();
    setLoading(false);
  }, []);

  useEffect(() => {
    if (group) {
      formState.setField('name', group.name);
    }
  }, [group]);
  async function fetchAssets() {
    setLoading(true);
    const [groupAssets, nonGroupAssets] = await Promise.all([
      get(`/groups/${params.groupId}/assets`),
      get(`/customers/${params.id}/assets`)
    ]);
    const uniq = differenceBy(nonGroupAssets, groupAssets, 'id');
    setGroupAssets(groupAssets);
    setNonGroupAssets(uniq);
  }

  async function fetchGroup() {
    setLoading(true);
    const [group] = await Promise.all([
      get(`/groups/${params.groupId}`),
    ]);
    group && formState.setField('name', group.name);
    group && setGroup(group);
  }

  async function fetchUsers() {
    setLoading(true);
    const [groupUsers, nonGroupUsers] = await Promise.all([
      get(`/groups/${params.groupId}/users`),
      get('/users', { customer_id: params.id, include_groups: 1 }),

    ]);

    const uniq = differenceBy(nonGroupUsers.results, groupUsers.results, 'id');
    setGroupUsers(groupUsers.results);
    setNonGroupUsers(uniq);
  }

  async function save() {
    setSaving(true);

    await put(`/groups/${params.groupId}`, {
      ...group,
      name: formState.values.name
    });

    setSaving(false);
  }

  function deleteGroupConfirmation() {
    confirm({
      description: "Are you sure you want to remove this group?",
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true }
    })
      .then(() => {
        deleteGroup();
      })
      .catch(() => { /* ... */ });
  }

  async function deleteGroup() {
    setDeleting(true);
    await destroy(`/groups/${params.groupId}`);
    history.push(`/customers/${params.id}/groups`);
  }

  async function addUserToGroup(userId) {
    await post('/relations', {
      from_id: params.groupId,
      from_type: 'GROUP',
      to_id: userId,
      to_type: 'USER',
      relation_type_group: 'COMMON'
    });
    fetchUsers();
    setLoading(false);
  }

  async function addAssetToGroup(assetId) {
    const groupId = params.groupId;
    var res = await put(`/assets/${assetId}/group/${groupId}`);
    fetchAssets();
    setLoading(false);
  }

  async function removeUserFromGroup(userId) {
    confirm({
      description: "Are you sure you want to remove this user from the group?",
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true }
    })
      .then(async () => {
        const relation = await get('/relations', {
          from_id: params.groupId,
          from_type: 'GROUP',
          to_id: userId,
          to_type: 'USER'
        });

        if (relation && relation.results && relation.results.length) {
          const toDelete = relation.results[0].id;
          await destroy(`/relations/${toDelete}`);
          fetchUsers();
          setLoading(false);
        }
      })
      .catch(() => { /* ... */ });
  }

  async function removeAssetFromGroup(assetId) {
    confirm({
      description: "Are you sure you want to remove this asset from the group?",
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true }
    })
      .then(async () => {
        var res = await destroy(`/assets/${assetId}/group/${Number(params.groupId)}`);
        fetchAssets();
        setLoading(false);
      })
      .catch(() => { /* ... */ });
  }

  async function makePrimaryContact(userId) {
    confirm({
      description: "Are you sure you want to select this user as a primary contact for this group?",
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true }
    })
      .then(async () => {
        const group = await post(`/groups/${params.groupId}/primary_contact/${userId}`);
        if (group) {
          setGroup(group);
          fetchUsers();
          setLoading(false);
        }
      })
      .catch(() => { /* ... */ });
  }

  function renderAssetCounter() {
    if (groupAssets != undefined && groupAssets != null) {
      return ("(" + groupAssets.length + ")")
    }
  }

  function renderUsersCounter() {
    if (groupUsers != undefined && groupUsers != null) {
      return ("(" + groupUsers.length + ")")
    }
  }

  function groupHandler(value, reason, detail) {
    var id = detail?.option?.id;
    if (reason == 'removeOption') {
      removeAssetFromGroup(id);
    }
    if (reason == 'selectOption') {
      addAssetToGroup(id);
    }
  }

  function userHandler(value, reason, detail) {
    var id = detail?.option?.id;
    if (reason == 'removeOption') {
      removeUserFromGroup(id);
    }
    if (reason == 'selectOption') {
      addUserToGroup(id);
    }
  }

  function renderDisplayType() {
    switch (displayContent) {
      case GROUP_USERS:
        return <>
          <FrameHeader title={"Users in this group " + renderUsersCounter()} />
          <Frame>
            <Autocomplete
              multiple
              id="tags-standard"
              filterSelectedOptions
              options={nonGroupUsers}
              getOptionLabel={(option) => option.first_name + " " + option.last_name}
              onChange={(event, value, reason, detail) => { userHandler(value, reason, detail) }}
              renderInput={(params) => (<>
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Enter name"
                />
              </>
              )}
              value={groupUsers}
              filterOptions={(options, params) => {
                try {
                  const filtered = filter(options, params);
                  return filtered;
                } catch (e) {
                  return new [];
                }
              }}
            />
            <Space size='m' />
            <Label text="Primary contact">
              <Select value={group?.primary_contact_id} onChange={e => makePrimaryContact(e.target.value)} disabled={groupUsers?.length == 0}>
                {groupUsers.map(user => {
                  return <option value={user.id}>{user.first_name + " " + user.last_name}</option>
                })}
              </Select>
            </Label>
          </Frame></>;

      case GROUP_ASSETS:
        return <>
          <FrameHeader title={"Assets in this group " + renderAssetCounter()} />
          <Frame>
            <Autocomplete
              multiple
              id="tags-standard"
              filterSelectedOptions
              options={nonGroupAssets}
              getOptionLabel={(option) => option.name}
              onChange={(event, value, reason, detail) => { groupHandler(value, reason, detail) }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Enter name"
                />
              )}
              value={groupAssets}
              filterOptions={(options, params) => {
                try {
                  const filtered = filter(options, params);
                  return filtered;
                } catch (e) {
                  return new [];
                }
              }}
            />
          </Frame>
        </>;
    }
  }
  function renderDisplayType() {
    switch (displayContent) {
      case GROUP_USERS:
        return <>
          <FrameHeader title={"Users in this group " + renderUsersCounter()} />
          <Frame>
            <Autocomplete
              multiple
              id="tags-standard"
              filterSelectedOptions
              options={nonGroupUsers}
              getOptionLabel={(option) => option.first_name + " " + option.last_name}
              onChange={(event, value, reason, detail) => { userHandler(value, reason, detail) }}
              renderInput={(params) => (<>
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Enter name"
                />
              </>
              )}
              value={groupUsers}
              filterOptions={(options, params) => {
                try {
                  const filtered = filter(options, params);
                  return filtered;
                } catch (e) {
                  alert('new');
                  return new [];
                }
              }}
            />
            <Space size='m' />
            <Label text="Primary contact">
              <Select value={group?.primary_contact_id} onChange={e => makePrimaryContact(e.target.value)} disabled={groupUsers?.length == 0}>
                {groupUsers.map(user => {
                  return <option value={user.id}>{user.first_name + " " + user.last_name}</option>
                })}
              </Select>
            </Label>
          </Frame></>;

      case GROUP_ASSETS:
        return <>
          <FrameHeader title={"Assets in this group " + renderAssetCounter()} />
          <Frame>
            <Autocomplete
              multiple
              id="tags-standard"
              filterSelectedOptions
              options={nonGroupAssets}
              getOptionLabel={(option) => option.name}
              onChange={(event, value, reason, detail) => { groupHandler(value, reason, detail) }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Enter name"
                />
              )}
              value={groupAssets}
              filterOptions={(options, params) => {
                try {
                  const filtered = filter(options, params);
                  return filtered;
                } catch (e) {
                  return new [];
                }
              }}
            />
          </Frame>
        </>;
    }
  }

  if (loading) return <LoadingFrame />;

  return (
    <>
      <Space size="l">
        <FrameHeader title="Info" />
        <Frame>
          <Label text="group name">
            <TextInput {...text('name')} required />
          </Label>
          <FrameFooter>
            <Button
              type="submit"
              theme="primary"
              disabled={!valid}
              isLoading={saving}
              onClick={save}
            >
              Save
            </Button>
          </FrameFooter>
        </Frame>
      </Space>
      <InputRow>
        <Button onClick={() => setDisplayContent(GROUP_ASSETS)}>Assets</Button>
        <Button onClick={() => setDisplayContent(GROUP_USERS)}>Users</Button>
      </InputRow>
      <Space>
        {renderDisplayType()}
      </Space>
      <div className={styles.deleteWrap}>
        <Button onClick={deleteGroupConfirmation} theme="danger" isLoading={deleting}>
          Delete group
        </Button>
      </div>
    </>
  );
}
