import React from 'react';

import Icon from '../../components/Icon';
import Center from '../../components/Center';
import Space from '../../components/Space';
import Button from '../../components/Button';

import styles from './Error.module.scss';
import history from '../../helpers/history';

export default function Error({ match: { params } }) {
  const response = window.localStorage.getItem(`error-${params.errorId}`);

  function text() {
    const { statusCode } = params;

    switch (statusCode) {
      case '401':
        return {
          title: 'Insufficent permissions',
          subtitle:
            "You don't have the necessary permissions to complete that request."
        };

      case '404':
        return {
          title: 'Resource not found',
          subtitle:
            "Either the resource doesn't exist or you don't have permission to access it."
        };
        case '500':
          return {
            title: 'Unable to establish connection',
            subtitle:
              "Sorry! Unable to establish connection with Sentinel Server. Try again later."
          };
        case '501':
          return {
            title: 'Unable to establish connection',
            subtitle:
              "Sorry! Unable to establish connection with Sentinel Server. Try again later."
          };
      default:
        return {
          title: 'Something went wrong.',
          subtitle: 'We have logged the issue and are investigating.'
        };
    }
  }
 
  function back() {
    window.localStorage.removeItem(`error-${params.errorId}`);
    history.replace('/');
  }

  function logOut() {
    /** Last-ditch effort, log out */
    window.localStorage.removeItem('refreshToken');
    window.localStorage.removeItem('bearerToken');

    history.replace('/log-in');
  }

  const parsedMessageError = () => {
    try {
      var myObject = JSON.parse(response);
      if (myObject != null && myObject != undefined) {
        return myObject.responseStatus.message;
      }
    } catch (ex) { }
  }
  return (
    <Center>
      <div className={styles.text}>
        <Space size="xs">
          <div className={styles.icon}>
            <Icon name="warning" size={52} />
          </div>
        </Space>

        <Space size="xs">
          <h1>{text().title}</h1>
        </Space>

        <Space size="m">
          <p>{text().subtitle}</p>
        </Space>

        {params.errorId && response && (
          <Space size="m">
            <p>Response:</p>
            <pre className={styles.response}>{parsedMessageError()}</pre>
          </Space>
        )}

        <Space size="xs">
          <div className={styles.buttons}>
            <Button onClick={back}>Back</Button>
            <Button onClick={logOut} theme="danger">
              Log out
            </Button>
          </div>
        </Space>
      </div>
    </Center>
  );
}
