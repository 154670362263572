import React from 'react';
import styles from './ReleaseInfo.module.scss';

export default function ReleaseInfo() {
  return (
    <div className={styles.release}>
      Release: <code>{process.env.REACT_APP_GIT_SHA}</code>
    </div>
  );
}
