import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { post, put } from '../../api';
import UserModel from '../../models/user';
import { TENANT_ADMIN } from '../../constants/roles';

import UserInfoForm from '../../components/UserInfoForm';
import FrameHeader from '../../components/FrameHeader';

// import styles from './NewTenantAdmin.module.scss';

export default function NewTenantAdmin({ match: { params } }) {
  const [saving, setSaving] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const createAdmin = async admin => {
    setSaving(true);
    const res = await post('/users', {
      ...admin,
      tenant_id: params.id,
      authority: TENANT_ADMIN
    });

    await put(`/users/${res.id}/permissions`, { permissions: ['ADMIN'] });

    await post('/users/sendActivationMail', {
      email: res.email
    });

    setRedirect(`/users/${res.id}`);
  };

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <>
      <FrameHeader title="New Admin" />
      <UserInfoForm
        state={UserModel}
        saving={saving}
        onSave={createAdmin}
        isNew
      />
    </>
  );
}
