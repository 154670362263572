import React from 'react';

export default function Icon({ children, name, size = 20, color = false, marginTop = false }) {

  return (<>
    <i
      className={`material-icons`}
      style={{ fontSize: size, color: color, marginTop: marginTop }}
      aria-hidden="true"
    >
      {name}
    </i>{children}
  </>
  );
}
