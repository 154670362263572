import React, { useEffect, useState } from 'react';
import { get } from '../../api';

import Frame from '../../components/Frame';
import LoadingFrame from '../../components/LoadingFrame';
import RowItem from '../../components/RowItem';
import Label from '../../components/Label';
import InputRow from '../../components/InputRow';
import FrameHeader from '../../components/FrameHeader';
export default function AdminPortalPendingCustomers() {
  const [loading, setLoading] = useState(true);
  const [pending, setPending] = useState([]);
  useEffect(() => {
    async function getPendingCustomers() {
      const res = await get(`/customers/pending`);
      res && setPending(res.results);
      setLoading(false);
    }
    getPendingCustomers();
  }, []);
  return (loading ? <LoadingFrame/> :
    <>
      <FrameHeader title="Pending customers" />
    <Frame>
      {pending && pending.length > 0 ? (
        <>
          {pending.map(customer =>
            <>
              <RowItem
                wideActionPanel={true}
                key={customer.id}
                title={`${customer.company} - ${customer.name} ${customer.lastname}`}
                subtitle={`${customer.email} - ${customer.shipping_address},${customer.shipping_city},${customer.shipping_zip},${customer.shipping_state},${customer.shipping_country} - ${customer.phone}`}
                right={<InputRow>
                  <Label text="Distrib">{customer.distributor}</Label>
                  <Label text="Tier">${customer.tier}</Label>
                </InputRow>}
                to={`/pendingcustomer/${customer.id}`}
              />
            </>
          )}
        </>
      ) : ("No pending customers")}
    </Frame>
    </>
  );
}
