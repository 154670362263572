import React, { useContext,useState } from 'react';
import { Link } from 'react-router-dom';
import { CUSTOMER_USER, SYS_ADMIN, TENANT_ADMIN } from '../../constants/roles';
import { isAuthorized, hasPermission } from '../../helpers/authorization';
import { post ,destroy} from '../../api';
import { humanUserRole } from '../../helpers/user';
import { AuthContext } from '../../contexts/Auth';
import Avatar from '../../components/Avatar';
import Badge from '../../components/Badge';
import Button from '../../components/Button';
import CollectionListing from '../../components/CollectionListing';
import FrameHeader from '../../components/FrameHeader';
import RowItemActions from '../../components/RowItemActions';
import { setRef } from '@material-ui/core';
import Popover from '../../components/Popover';
import styles from './CustomerUsers.module.scss';
import { useConfirm } from 'material-ui-confirm';

export default function CustomerUsers({ match: { params } }) {
  const { state } = useContext(AuthContext);
  const [refresh,setRefresh] = useState(false);
  const confirm = useConfirm();
  const displayOptions = (u) => {

    var isAdmin = isAuthorized(state.user.roles, [SYS_ADMIN, CUSTOMER_USER, TENANT_ADMIN]);
    var isTeamLeader = hasPermission(state.user.perms, 'ADMIN');
    var selfEdit = state.user.user_id == u.id;

    if (selfEdit == false) {
      if (isAdmin == true || isTeamLeader == true) {
        return (
          <>
            <Popover
              text="Action"
              align="right"
              width={200}
            > <div className={styles.wrap}>
                {!u.activated &&
                  (<Button theme="primary" onClick={
                    (e) => {
                      confirm({ 
                        description: `Are you sure you want to resend activation email to ${u.first_name} ${u.last_name}?`, 
                        title: null, 
                        dialogProps: { fullScreen: false }, 
                        cancellationButtonProps: { color: "error", disableRipple: true },
                        confirmationButtonProps: { color: "primary", disableRipple: true }
                       })
                        .then(() => {
                          _sendActivationLink(u.email);
                        })
                        .catch(() => { /* ... */ });
                    }
                  }>Resend Invite
                  </Button>)}
                <>
                  {!u.user_disabled &&
                    <Button theme={'secondary'} onClick={(e) => {
                      confirm({
                        description: `Are you sure you want to disable user ${u.first_name} ${u.last_name}?`,
                        title: null,
                        dialogProps: { fullScreen: false },
                        cancellationButtonProps: { color: "error", disableRipple: true },
                        confirmationButtonProps: { color: "primary", disableRipple: true }
                      })
                        .then(() => {
                          _disableUser(u.email)
                        })
                        .catch(() => { /* ... */ });
                    }
                    }>Disable</Button>
                  }
                  {u.user_disabled &&
                    <Button theme={'secondary'} onClick={(e) => {
                      confirm({
                        description: `Are you sure you want to enable user ${u.first_name} ${u.last_name}?`,
                        title: null,
                        dialogProps: { fullScreen: false },
                        cancellationButtonProps: { color: "error", disableRipple: true },
                        confirmationButtonProps: { color: "primary", disableRipple: true }
                      })
                        .then(() => {
                          _enableUser(u.email)
                        })
                        .catch(() => { /* ... */ });
                    }
                    }>Enable</Button>
                  }
                  <Button theme="danger" onClick={
                    (e) => {
                      confirm({
                        description: `Are you sure you want to delete user ${u.first_name} ${u.last_name}?`,
                        title: null,
                        dialogProps: { fullScreen: false },
                        cancellationButtonProps: { color: "error", disableRipple: true },
                        confirmationButtonProps: { color: "primary", disableRipple: true }
                      })
                        .then(() => {
                          _deleteUser(u.id)
                        })
                        .catch(() => { /* ... */ });
                    }
                  }>Delete</Button>
                </>
              </div>
            </Popover>
          </>);
      } else {
        return "";
      }
    } else {
      return "";
    }

  }
  async function _sendActivationLink(userEmail) {
    const res = await Promise.all([
      post(`/users/sendActivationMail`, {
        email: userEmail,
      })
    ]);
  }
  async function _deleteUser(userId) {
    setRefresh(true);
    const res = await Promise.all([
      destroy(`/users/${userId}`)
    ]);
    setRefresh(false);
  }
  async function _disableUser(userEmail) {
    setRefresh(true);
    const res = await Promise.all([
      post(`/users/disable`, {
        email: userEmail,
      })
    ]);
    setRefresh(false);
  }
  async function _enableUser(userEmail) {
    setRefresh(true);
    const res = await Promise.all([
      post(`/users/enable`, {
        email: userEmail,
      })
    ]);
    setRefresh(false);
  }
  function generateTitle(u) {
    var name = `${u.first_name} ${u.last_name}`;
    if (u.user_disabled === true) {
      name += " - Disabled";
    }
    return name;
  }
  const userRowItem = u => (
    <RowItemActions
      //wideActionPanel={true}
      key={u.id}
      left={<Avatar name={`${u.first_name} ${u.last_name}`} theme={u.user_disabled ? "pending" : "default"} />}
      right={displayOptions(u)}
      title={generateTitle(u)}
      subtitle={u.email}
      to={`/users/${u.id}`}
    />
  );

  return (
    <>
      <FrameHeader title="Users">
        <Link to={`/customers/${params.id}/users/new`}>
          <Button theme="primary" icon="add">
            New user
          </Button>
        </Link>
      </FrameHeader>

      {!refresh && <CollectionListing
        collection="users"
        queryKey="email"
        params={{ customer_id: params.id}}
        item={userRowItem}
        sortOptions={
          <>
            <option value="first_name">First name</option>
            <option value="last_name">Last name</option>
            <option value="email">Email</option>
          </>
        }
      />
}
    </>
  );
}
