export const setMarkers = markers => dispatch => {
  dispatch({ type: 'SET_MARKERS', payload: { markers } });
};

export const removeMarker = id => dispatch => {
  dispatch({ type: 'REMOVE_MARKER', payload: { id } });
};

export const removeAllMarkers = () => dispatch => {
  dispatch({ type: 'REMOVE_ALL_MARKERS', });
};
export const toggleMap = () => dispatch => {
  dispatch({ type: 'TOGGLE_MAP' });
};

export const resetMapState = () => dispatch => {
  dispatch({ type: 'RESET_MAP_STATE' });
};

export const updateBounds = bounds => dispatch => {
  dispatch({ type: 'UPDATE_BOUNDS', payload: { bounds } });
};

export const isolateMarker = coords => dispatch => {
  dispatch({ type: 'ISOLATE_MARKER', payload: { coords } });
};
export const centerSmallMap = () => dispatch => {
  dispatch({ type: 'CENTER_SMALL_MAP' });
};
export const showAllMarkers = () => dispatch => {
  dispatch({ type: 'SHOW_ALL_MARKERS' });
};

export const updateAllMarkersQuiet = () => dispatch => {
  dispatch({ type: 'UPDATE_ALL_MARKERS_QUIET' });
};

export const displayGPSRecalibrateButton = () => dispatch => {
  dispatch({ type: 'DISPLAY_GPS_RECALIBRATE' });
};
export const disableMap = () => dispatch => {
  dispatch({ type: 'DISABLE_MAP' });
};