import React, { useState } from "react";
import { put } from "../../api";
import Button from "../Button";
import Space from "../Space";
import Modal from "../Modal";
import OrientationOptions from "../OrientationOptions";
import { getXYZ} from "../../helpers/orientation";
import Label from "../../components/Label";
import SliderCustom from '../../components/SliderCustom';
export default function InstallOnAssetStep2({ asset, back, next, customFooter=false}) {
  const [selected, setSelected] = useState("POSITION_FLAT");
  const [sensitivity, setSensitivity] = useState(2);
  
  async function saveAndNext() {
    const thresholds = getXYZ(selected, sensitivity);
    if (customFooter == false) {
      await put(`/assets/${asset.id}`, {
        ...asset,
        ...thresholds
      });
    } else {
      customFooter(getXYZ(selected, sensitivity));
    }
    next();
  }

  function _renderFooter() {
    return (
      <>
        <Button onClick={back}>Back</Button>
        <Button theme="primary" onClick={saveAndNext}>
          Next
        </Button>
      </>
    );
  }
  function _renderFooterExternal() {
    return (
      <>
        <Button onClick={back}>Back</Button>

        <Button theme="primary" onClick={() => customFooter(getXYZ(selected, sensitivity))}>
          Next
        </Button>
      </>
    );
  }
  return (
    <Modal footer={customFooter ? _renderFooterExternal(): _renderFooter()}>
      <Space size="m">
        <h2>Step 3: Install the device</h2>
      </Space>

      <Space size="m">
        <p>
          Next, install the device on the asset according to the Mounting
          Options provided on the Sentinel Web Site.
        </p>
      </Space>

      <Space size="l">
        <OrientationOptions selected={selected} setSelected={setSelected} />
      </Space>
      <Label text="Sensitivity">

      <SliderCustom
              inputValue={sensitivity}
              onChangeAction={(e, v) => { setSensitivity(v) }}
            />
       
      </Label>
    </Modal>
  );
}
