import React from 'react';
import { useMediaQuery } from 'react-responsive'
import Button from '../Button';
import styles from './MapToggleButton.module.scss';

export default function MapToggleButton({text=true, ...props}) {

  const isNotMobile = useMediaQuery({ query: 'not screen and (max-width: 600px)' })
  return (
    <div className={styles.wrap}>
      <Button icon="public" {...props}>
{ (text || isNotMobile) && "Map" }
      </Button> 
    </div> 
  );
}
