import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { post } from '../../api';
import FrameHeader from '../../components/FrameHeader';
import FrameFooter from '../../components/FrameFooter';
import Frame from '../../components/Frame';
import Button from '../../components/Button';
import TextInput from '../../components/TextInput';
import Label from '../../components/Label';

export default function CustomerSitesNew({ match: { params } }) {
  const [name, setName] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newSite, setNewSite] = useState('');

  const createNewSite = async e => {
    e.preventDefault();
    setLoading(true);
    await post(`/site/new`, { customer_id: params.id, name: newSite });
    setNewSite('');
    setLoading(false);
    setRedirect(`/customer/sites`)
  }

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <>
      <FrameHeader title="New site" />
      <form onSubmit={createNewSite}>
        <Frame>
          <Label text="Site name">
            <TextInput
              type="text"
              value={newSite}
              onChange={e => setNewSite(e.target.value)}
            />
          </Label>

          <FrameFooter>
            <Button
              type="submit"
              theme="primary"
              isLoading={loading}
              disabled={!newSite || newSite.length < 2}
            >
              Create
            </Button>
          </FrameFooter>
        </Frame>
      </form>
    </>
  );
}