import React from 'react';
import styles from './NoResults.module.scss';

export default function NoResults({ message = 'No results found' }) {
  return (
    <div className={styles.wrap}>
      <h3>{message}</h3>
    </div>
  );
}
