import React, { useEffect, useState, useContext } from 'react';
import { Link, Route } from 'react-router-dom';

import {
  fetchDevice,
  fetchRelation,
  fetchActivity,
  removeRelation,
  updateActivityFilters,
  updateActivityPage
} from '../../actions/Device';

import {
  isolateMarker,
  toggleMap,
  showAllMarkers,
  setMarkers,
  removeMarker
} from '../../actions/Map';

import DeviceContext from '../../contexts/Device';
import MapContext from '../../contexts/Map';
import TypesContext from '../../contexts/Types';
import useThunk from '../../hooks/thunk';
import { DeviceReducer, initialDeviceState } from '../../reducers/Device';
import { INACTIVE } from '../../constants/statuses';

import InstallDevice from '../InstallDevice';
import DeviceNote from '../DeviceNote';
import { useMediaQuery } from 'react-responsive'
import AssociatedAsset from '../../components/AssociatedAsset';
import Breadcrumb from '../../components/Breadcrumb';
import Breadcrumbs from '../../components/Breadcrumbs';
import Button from '../../components/Button';
import DeviceActivity from '../../components/DeviceActivity';
import DeviceStatus from '../../components/DeviceStatus';
import Frame from '../../components/Frame';
import FrameHeader from '../../components/FrameHeader';
import Image from '../../components/Image';
import Label from '../../components/Label';
import LoadingFrame from '../../components/LoadingFrame';
import MapToggleButton from '../../components/MapToggleButton';
import PageHeader from '../../components/PageHeader';
import PageWrap from '../../components/PageWrap';
import Space from '../../components/Space';

import styles from './DeviceDetail.module.scss';
export default function DeviceDetail({ match: { params } }) {
  const [state, dispatch] = useThunk(DeviceReducer, initialDeviceState);

  const types = useContext(TypesContext);
  const [, mapDispatch] = useContext(MapContext);

  const [type, setType] = useState({});
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    dispatch(fetchDevice(params.id));
  }, []);

  useEffect(() => {
    const { filters, page } = state.activity;
    dispatch(fetchActivity(params.id, filters, page));
  }, [state.relation.id, state.activity.filters, state.activity.page]);

  useEffect(() => {
    /** Set the device type */
    const typeId = state.device && state.device.devicetype_id;
    const type = types.devices.find(t => t.id === typeId);
    setType(type);

    /** If active, center the map on this device */
    if (state.device.id && state.asset.id) {
      const { longitude, latitude } = state.device;
      const marker = [{ ...state.device, assets: [state.asset] }];

      if (longitude && latitude) {
        mapDispatch(setMarkers(marker));
        mapDispatch(isolateMarker([longitude, latitude]));
      }
    } else {
      mapDispatch(removeMarker(state.device.id));
    }

    /** False-out the initial load so the store's loading state can be used */
    setInitialLoad(false);
  }, [state.device, state.asset]);

  useEffect(() => {
    /** Get the relation, if exists */
    state.asset.id && dispatch(fetchRelation(state.asset.id, state.device.id));
  }, [state.asset.id]);

  function _renderContent() {
    return (
      <>
        <Space size="m">
          <FrameHeader title="Device info" />
          <Frame padding={0}>
            {state.asset.id && !!state.installPhoto.id && (
              <div className={styles.bannerImage}>
                <Image url={state.installPhoto.url} />
              </div>
            )}
            <div className={styles.mainInfo}>
              <Label text="Serial number" theme="light">
                <h2>{state.device.serial_number}</h2>
              </Label>
              <Label text="Version" theme="light">
                {type && type.name}
              </Label>
            </div>

            <div className={styles.deviceStatus}>
              <Label text="Status" theme="light">
                <DeviceStatus
                  status={state.device.active ? state.device.status : INACTIVE}
                  signal={state.device.signal}
                  battery={state.device.battery}
                  temp={state.device.temperature}
                  firmware={state.device.firmware}
                  updated={
                    state.device.active && state.device.status_updated_time
                  }
                />
              </Label>
            </div>
          </Frame>
        </Space>

        <Space size="m">
          <FrameHeader title="Asset" />
          <AssociatedAsset
            deviceId={state.device.id}
            asset={state.asset}
            relation={state.relation}
            onRemove={relationId => {
              dispatch(removeRelation(state.device.id, relationId));
              mapDispatch(showAllMarkers());
            }}
          />
        </Space>

        <Space size="m">
          <FrameHeader title="Activity" />
          <DeviceActivity
            perspective="device"
            events={state.activity.events}
            loading={state.activity.loading}
            page={state.activity.page}
            total={state.activity.total}
            filters={state.activity.filters}
            setFilters={filters => dispatch(updateActivityFilters(filters))}
            setCurrentPage={page => dispatch(updateActivityPage(page))}
          />
        </Space>
      </>
    );
  }
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })

  return (
    <DeviceContext.Provider value={[state, dispatch]}>
      <PageHeader
        right={
          <>
            <MapToggleButton text={false} onClick={() => mapDispatch(toggleMap())} />
            <Link to={`/devices/${params.id}/note`}>
              <Button icon="add" theme="primary">
                { !isMobile && "New " }Note
              </Button>
            </Link>
          </>
        }
      >
        <Breadcrumbs>
          <Breadcrumb title="Devices" to="/devices" />
          <Breadcrumb title={state.device.name} />
        </Breadcrumbs>
      </PageHeader>

      <PageWrap>
        {initialLoad || state.loading > 0 ? <LoadingFrame /> : _renderContent()}
        {!initialLoad && (
          <>
            <Route path={`/devices/${params.id}/note`} component={DeviceNote} />
          </>
        )}
      </PageWrap>
    </DeviceContext.Provider>
  );
}
