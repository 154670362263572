import React, { useEffect, useState, useContext } from "react";
import { get,post } from "../../api";
import { AuthContext } from "../../contexts/Auth";
import TypesContext from "../../contexts/Types";
import history from '../../helpers/history';
import Modal from "../../components/Modal";
import Frame from "../../components/Frame";
import FrameHeader from "../../components/FrameHeader";
import Space from "../../components/Space";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import Select from "../../components/Select";
import moment from "moment";
import queryString from "query-string";
import Label from "../../components/Label";
import InputRow from "../../components/InputRow";
import LoadingFrame from "../../components/LoadingFrame";
import { useConfirm } from 'material-ui-confirm';
export default function LicenseAdd({ match: { params }, location: { search } }) {


  const { state: currentUser } = useContext(AuthContext);
  const queryParams = queryString.parse(search);
 
  const [loading, setLoading] = useState(true);
  const [license, setLicense] = useState({
    device_id:0,
    subscription_id:params.subscriptionId,
    customer_id:params.id,
    additional_info:""
  });
  function validated(){ return !(license.device_id==0 || license.subscription_id==0);}
  const [devicesWithNoLicenses, setDevicesWithNoLicenses] = useState([]);


  useEffect(() => {
    async function getDevicesWithNoLicense() {
      const res = await get(`/customers/${params.id}/devices/`, { no_license: true });
      res && setDevicesWithNoLicenses(res);
      setLoading(false);
    }
    getDevicesWithNoLicense();
  }, []);


async function createLicense(){

  const res = await post(`/subscriptions/${license.subscription_id}/licenses`,
    {
      device_id: license.device_id,
      customer_id: license.customer_id,
      additional_info:license.additional_info
    }
  );
  res && history.push(`/customers/${params.id}/subscriptions/`);
}

  function _renderFooter() {
    return (
      <>
        <Button theme="primary"
          onClick={() => history.push(`/customers/${params.id}/subscriptions/`)}>
          Cancel
        </Button>
        <Button 
        disabled ={!validated()}
        theme="secondary"
          onClick={() => createLicense()}
          >
          Save
        </Button>
      </>
    );
  }


  return (

    <Modal footer={_renderFooter()} >
      {loading ? <LoadingFrame /> : (
        license && (<>
          <FrameHeader title="License details" />
          <Frame>
{license.device_id}
x
{license.subscription_id}
x
{license.customer_id}
            <InputRow>
              <Label text="Device ID" theme="light">
             
                {devicesWithNoLicenses ? (<Select 
                  value={license.device_id}
                  onChange={e => setLicense({...license,device_id:e.target.value})}>
                  <option value={0}>Select new device</option>
                  {devicesWithNoLicenses.map(a => (
                    <option key={a.id} value={a.id}>
                      {a.serial_number}
                    </option>
                  ))}
                </Select>) : ("No Devices")}

              </Label>

            
            </InputRow>

            <InputRow>
              <Label text="Additional Info" theme="light">
                <TextInput
                  value={license.additional_info}
                  placeholder={"Enter additional info"}
                  onChange={e => {

                    setLicense({ ...license, additional_info: e.target.value })
                  }}
                />

              </Label>
             
            
            </InputRow>
          </Frame>

        </>)

      )}
    </Modal>
  );

}
