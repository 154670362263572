import React from 'react';
import styles from './Logo.module.scss';
import Img from '../../images/sentinel.png';

export default function Logo({ size }) {
  return (
    <img
      className={styles.img}
      src={Img}
      alt="Traffix Devices – Sentinel"
      width={size}
    />
  );
}
