import React from 'react';
import styles from './FrameHeader.module.scss';

export default function CollectionHeader({ children, title, inlineClass }) {

  const classes = [styles.header, inlineClass ? styles[`${inlineClass}`] : ''].join(' ');

  return (
    <div className={classes}>
      <span className={styles.title}>{title}</span>
      {children}
    </div>
  );
}
