import React, { useContext } from "react";
import { Redirect } from "react-router-dom";

import { AuthContext } from "../../contexts/Auth";
import { addIntent } from "../../actions/Auth";
import history from "../../helpers/history";

export default function ProtectedRoute({ component: Component, ...rest }) {
  const { state, dispatch } = useContext(AuthContext);

  function _captureIntentAndRedirect() {
    if (history.location.search) {
      if (history.location.search === "?qr=true") {
        window.sessionStorage.setItem("bbMode", 'true');
        window.sessionStorage.setItem("qrRedirect", 'true');
      }
      var intent = history.location.pathname + history.location.search
    }
    else {
      var intent = history.location.pathname;
    }
    const whitelist = ["/account", "/", ""];
    if (!whitelist.includes(intent)) dispatch(addIntent({ url: intent }));

    return <Redirect from="" to="/log-in" noThrow />;
  }

  return state.isAuthenticated ? (
    <Component {...rest} />
  ) : (
    _captureIntentAndRedirect()
  );
}
