import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { get } from '../../api';
import { useDebounce } from '../../hooks/inputs';
import TypesContext from '../../contexts/Types';
import Frame from '../../components/Frame';
import LoadingFrame from '../../components/LoadingFrame';
import NoResults from '../../components/NoResults';
import QueryBar from '../../components/QueryBar';
import RowItem from '../../components/RowItem';
import StatusIndicator from '../../components/StatusIndicator';
import TextInput from '../../components/TextInput';
import { INACTIVE } from '../../constants/statuses';

// import styles from './InactiveAssets.module.scss';

export default function InactiveAssets({ nowrap, mobile = false }) {
  const types = useContext(TypesContext);
  const [query, setQuery] = useState('');
  const [sortBy, setSortBy] = useState('name');
  const [loading, setLoading] = useState(true);
  const [assets, setAssets] = useState([]);
  const debouncedQuery = useDebounce(query, 350);
  useEffect(() => {
    async function getAssets(first) {
      first && setLoading(true);
      const res = await get('/assets', {
        active: 'false',
        orderBy: sortBy,
        nameContains: debouncedQuery
      });

      setAssets(res.results);
      setLoading(false);
    }

    getAssets(true);

    /** Re-check inactive devices every 30 seconds */
    const timer = setInterval(() => {
      getAssets(false);
    }, 1000 * 30);

    return () => {
      clearInterval(timer);
    };
  }, [debouncedQuery, sortBy]);

  function _renderAssets() {
    return assets.length ? (
      assets.map(a => {
        const type = types.assets.find(t => t.id === a.assettype_id);
        const fullDateTime = moment
          .utc(a.created_time)
          .format('dddd, MMMM Do YYYY, h:mm:ss a');
        const timeAgo = moment.utc(a.created_time).fromNow();

        return (
          <RowItem
            key={a.id}
            mobile={mobile}
            title={a.name}
            subtitle={type.name}
            to={`/assets/${a.id}`}
            left={<StatusIndicator status={INACTIVE} />}
            right={
              a.created_time && (
                <span title={fullDateTime}>{`Created ${timeAgo}`}</span>
              )
            }
          />
        );
      })
    ) : (
        <NoResults message="No inactive assets found" />
      );
  }

  return (
    <Frame padding="0">
      <QueryBar button={false}>
        <TextInput
          value={query}
          placeholder="Search by name"
          icon="search"
          loading={!!assets.length && loading}
          onChange={e => {
            setQuery(e.target.value);
          }}
        />
      </QueryBar>

      {loading && !assets.length ? <LoadingFrame /> : _renderAssets()}
    </Frame>
  );
}
