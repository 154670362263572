import React, { useContext, useEffect, useState } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { get } from '../../api';
import MapContext from '../../contexts/Map';
import { AuthContext } from '../../contexts/Auth';
import { CUSTOMER_USER } from '../../constants/roles';
import { isAuthorized, hasPermission } from '../../helpers/authorization';
import { toggleMap, showAllMarkers,setMarkers } from '../../actions/Map';
import {
  IMPACT,
  SIGNALSTRENGTH_LOW,
  SIGNALSTRENGTH_CRITICAL,
  TEMPERATURE_LOW,
  TEMPERATURE_HIGH,
  BATTERY_LOW,
  BATTERY_CRITICAL,
  COMMUNICATION_ERROR,
  OK,
  INACTIVE,
  WORK_IN_PROGRESS,
  READY,
  ORIENTATION_CHANGE,
  CONNECTIVITY_ISSUE
} from '../../constants/statuses';
import ActiveAssets from '../ActiveAssets';
import InactiveAssets from '../InactiveAssets';
import NewAssetWizard from '../NewAssetWizard';
import NewAsset from '../NewAsset';
import Breadcrumb from '../../components/Breadcrumb';
import Breadcrumbs from '../../components/Breadcrumbs';
import Button from '../../components/Button';
import DataValue from '../../components/DataValue';
import FrameHeader from '../../components/FrameHeader';
import LoadingFrame from '../../components/LoadingFrame';
import MapToggleButton from '../../components/MapToggleButton';
import Overview from '../../components/Overview';
import PageHeader from '../../components/PageHeader';
import PageWrap from '../../components/PageWrap';
import Space from '../../components/Space';
import StatusIndicator from '../../components/StatusIndicator';
import SubNavigation from '../../components/SubNavigation';
import {
  TOOLTIP_TOTAL_ASSETS,
  TOOLTIP_ACTIVE_ASSETS,
  TOOLTIP_INACTIVE_ASSETS,
  TOOLTIP_READY_ASSETS,
  TOOLTIP_IMPACT_DETECTED,
  TOOLTIP_WORK_IN_PROGRESS,
  TOOLTIP_CRITICAL_WARNINGS,
  TOOLTIP_REGULAR_WARNINGS,
} from '../../helpers/tooltips.js';
import Modal from '../../components/Modal';
import { useMediaQuery } from 'react-responsive';
import { useConfirm } from 'material-ui-confirm';

export default function Assets({ history }) {
  const { state } = useContext(AuthContext);
  const [, dispatch] = useContext(MapContext);

  const [counts, setCounts] = useState({
    active: 0,
    inactive: 0,
    impacted: 0,
    warning: 0,
    critical: 0,
    work_in_progress: 0,
    ready: 0,
  });
  const [loading, setLoading] = useState(true);
  const [simpleMode, setSimpleMode] = useState(window.sessionStorage.getItem('bbMode') === 'true');
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
  const confirm = useConfirm();

  useEffect(() => {
    async function fetchActiveDevices() {
      const devices = await get('/devices', {
        active: 'true',
        include_assets: '1'
      });

      if (devices && devices.results) {
        dispatch(setMarkers(devices.results));
        dispatch(showAllMarkers());/**/
      }
    }
    async function fetchAssetCounts() {
      // prettier-ignore
      const [active, inactive, impacted, critical,warning, work_in_progress, ready] = await Promise.all([
        get('/assets', { active: 'true', include: 'COUNT(*)', take: '1' }),
        get('/assets', { active: 'false', include: 'COUNT(*)', take: '1' }),
        get('/devices', { statuses: `${IMPACT}`, active: 'true', include: 'COUNT(*)', take: '1' }),
        get('/devices', { statuses: `${SIGNALSTRENGTH_CRITICAL},${BATTERY_CRITICAL},${TEMPERATURE_HIGH},${COMMUNICATION_ERROR},${CONNECTIVITY_ISSUE}`, active: 'true', include: 'COUNT(*)', take: '1' }),
        get('/devices', { statuses: `${SIGNALSTRENGTH_LOW},${BATTERY_LOW},${TEMPERATURE_LOW},${ORIENTATION_CHANGE}`, active: 'true', include: 'COUNT(*)' }),
        get('/devices', { statuses: `${WORK_IN_PROGRESS}`, active: 'true', include: 'COUNT(*)', take: '1' }),
        get('/devices', { statuses: `${READY}`, active: 'true', include: 'COUNT(*)', take: '1' }),
        //get(`/users/${state.user.user_id}/groups`),
      ]);
      //Counts
      setCounts({
        active: active.total,
        inactive: inactive.total,
        impacted: impacted.total,
        critical: critical.total,
        warning: warning.total,
        work_in_progress: work_in_progress.total,
        ready: ready.total,
      });

      setLoading(false);
    }

    fetchAssetCounts();
    fetchActiveDevices();
  }, []);

  const pageHeaderActions = () => (
    <>
     {(!simpleMode && isMobile)? (  <Button icon="directions_car"  onClick={() => { window.sessionStorage.setItem("bbMode", 'true'); setSimpleMode(true)}}></Button>) :
        (<MapToggleButton onClick={() => {dispatch(toggleMap())} }/>)
      }
      {isAuthorized(state.user.roles, [CUSTOMER_USER]) &&
        hasPermission(state.user.perms, 'ASSET_C') && (
          <Link to="/assets/new">
            <Button icon="add" theme="primary">
              New asset
            </Button>
          </Link>
        )}
    </>
  );

  const subNavigationItems = [
    { to: '/assets', name: `Active assets (${counts.active})` },
    { to: '/assets/inactive', name: `Inactive assets (${counts.inactive})` }
  ];

  function disableSimpleMode() {
    confirm({ 
      description: 'Are you sure you want to exit to Desktop Dashboard?', 
      title: null, 
      dialogProps: { fullScreen: false }, 
      cancellationButtonProps: {color: "error", disableRipple: true},
      confirmationButtonProps: {color: "primary", disableRipple: true}
     })
      .then(() => {
        setSimpleMode(false);
        window.sessionStorage.setItem("bbMode", 'false');
      })
      .catch(() => { /* ... */ });
  };

  function _renderHeader() {
    return (
 
      <div style={{marginLeft:'auto', width: 'unset'}}><MapToggleButton onClick={() => dispatch(toggleMap())} /></div>
    );
  }

  function _renderFooter() {
    return (
      <Button theme="secondary"
      onClick={() => disableSimpleMode()}>
        Exit to Desktop Dashboard
      </Button>
    );
  }    function _renderContent() {
    return (
      (simpleMode && isMobile)? 
        (<Modal simpleMode simpleModeAssetsList footer={_renderFooter()} header={_renderHeader()}>
          <ActiveAssets mobile={true} />
        </Modal>)
      : 
      (<><PageWrap>
        <Space size="l">
          <FrameHeader title="Overview" />
          <Overview>
            <DataValue
              mobile={true}
              tooltip={TOOLTIP_TOTAL_ASSETS}
              label="Total assets">
              {counts.active + counts.inactive}
            </DataValue>
            <DataValue
              mobile={true}
              tooltip={TOOLTIP_ACTIVE_ASSETS}
              label="Active assets">
              <StatusIndicator status={OK} /> {counts.active}
            </DataValue>
            <DataValue
              mobile={true}
              tooltip={TOOLTIP_INACTIVE_ASSETS}
              label="Inactive assets">
              <StatusIndicator status={INACTIVE} /> {counts.inactive}
            </DataValue>
            <DataValue
              mobile={true}
              tooltip={TOOLTIP_READY_ASSETS}
              label="Ready">
              <StatusIndicator status={READY} /> {counts.ready}
            </DataValue>
            <DataValue
              mobile={true}
              tooltip={TOOLTIP_IMPACT_DETECTED}
              label="Impact detected">
              <StatusIndicator status={IMPACT} /> {counts.impacted}
            </DataValue>
            <DataValue
              mobile={true}
              tooltip={TOOLTIP_WORK_IN_PROGRESS}
              label="Work in progress">
              <StatusIndicator status={WORK_IN_PROGRESS} /> {counts.work_in_progress}
            </DataValue>
            <DataValue
              mobile={true}
              tooltip={TOOLTIP_CRITICAL_WARNINGS}
              label="Critical warnings">
              <StatusIndicator status={COMMUNICATION_ERROR} /> {counts.critical}
            </DataValue>
            <DataValue
              mobile={true}
              tooltip={TOOLTIP_REGULAR_WARNINGS}
              label="Warnings">
              <StatusIndicator status={BATTERY_LOW} /> {counts.warning}
            </DataValue>
          </Overview>
        </Space>
        <SubNavigation history={history} items={subNavigationItems} />
        <Switch>
          <Route path="/assets/inactive" exact render={() => <InactiveAssets mobile={true} />} />
          <Route path="/assets" exact render={() => <ActiveAssets mobile={true} />} />
        </Switch>
        <Route path="/assets/new" component={NewAssetWizard} exact />
      </PageWrap>
      </>)
    );
  }

  return (
    (simpleMode && isMobile)? 
    (loading ? <LoadingFrame /> : _renderContent()):
    (<>
      <PageHeader right={pageHeaderActions()}>
        <Breadcrumbs>
          <Breadcrumb title="Assets" />
        </Breadcrumbs>
      </PageHeader>
      {loading ? <LoadingFrame /> : _renderContent()}
    </>)
  );
}
