import { LICENSE_UPDATED, RESUMED_CHECK_IN } from "../constants/events";
import {
  OK,
  SIGNALSTRENGTH_LOW,
  SIGNALSTRENGTH_CRITICAL,
  TEMPERATURE_LOW,
  TEMPERATURE_HIGH,
  BATTERY_LOW,
  BATTERY_CRITICAL,
  COMMUNICATION_ERROR,
  IMPACT,
  DEVICE_REPAIRED,
  FALSE_ALERT,
  MAINTENANCE,
  WORK_COMPLETE,
  WORK_IN_PROGRESS,
  READY,
  ORIENTATION_CHANGE,
  CONNECTIVITY_ISSUE,
  INACTIVE_EXPIRED_LICENSE,
  LICENSE_EXPIRED,
  ACTIVE_DEVICE_NO_ASSET,
} from "../constants/statuses";

const green = ["#03b106", "#018b04"];
const gray = ["#777777", "#777777"];
const yellow = ["#ffb316", "#c88b12"];
const orange = ["#ff7b00", "#c86000"];
const red = ["#ee0e0e", "#ba0808"];
const ready = ["#03b106", "#018b04"];
export default function getStatus(status) {
  switch (status) {
    case OK:
      return {
        name: "OK",
        icon: "",
        pin: "ok",
        colors: green,
      };

    case SIGNALSTRENGTH_LOW:
      return {
        name: "Low signal strength",
        icon: "flag",
        pin: "warning",
        colors: yellow,
      };

    case SIGNALSTRENGTH_CRITICAL:
      return {
        name: "Critically low signal strength",
        icon: "warning",
        pin: "critical",
        colors: orange,
      };

    case TEMPERATURE_LOW:
      return {
        name: "Device temperature is low",
        icon: "flag",
        pin: "warning",
        colors: yellow,
      };

    case TEMPERATURE_HIGH:
      return {
        name: "Device temperature is high",
        icon: "warning",
        pin: "critical",
        colors: yellow,
      };

    case BATTERY_LOW:
      return {
        name: "Low battery",
        icon: "flag",
        pin: "warning",
        colors: yellow,
      };

    case BATTERY_CRITICAL:
      return {
        name: "Critically low battery",
        icon: "warning",
        pin: "critical",
        colors: orange,
      };

    case COMMUNICATION_ERROR:
      return {
        name: "Missed check-in",
        icon: "warning",
        pin: "critical",
        colors: orange,
      };

    case IMPACT:
      return {
        name: "Impact detected",
        icon: "error_outline",
        pin: "impact",
        colors: red,
      };

    case DEVICE_REPAIRED:
      return {
        name: "Under repair",
        icon: "refresh",
        pin: "impact",
        colors: gray,
      };
    case FALSE_ALERT:
      return {
        name: "False alert",
        icon: "error",
        pin: "impact",
        colors: red,
      };
    case MAINTENANCE:
      return {
        name: "Maintenance",
        icon: "warning",
        pin: "impact",
        colors: red,
      };
    case WORK_IN_PROGRESS:
      return {
        name: "Work in progress",
        icon: "build",
        pin: "in_progress",
        colors: gray,
      };
    case WORK_COMPLETE:
      return {
        name: "Repair complete",
        icon: "done",
        pin: "ok",
        colors: green,
      };
    case READY:
      return {
        name: "Ready",
        icon: "schedule",
        pin: "schedule",
        colors: ready,
      };
    case false:
      return {
        name: "Inactive",
        icon: "warning",
        pin: "ok",
        colors: gray,
      };

    case ORIENTATION_CHANGE:
      return {
        name: "Orientation change",
        icon: "warning",
        pin: "warning",
        colors: yellow,
      };
    case CONNECTIVITY_ISSUE:
      return {
        name: "Low signal strength",
        icon: "warning",
        pin: "critical",
        colors: orange,
      };

    case LICENSE_EXPIRED:
      return {
        name: "Expired subscription",
        icon: "warning",
        pin: "expired",
        colors: gray,
      };

    case INACTIVE_EXPIRED_LICENSE:
      return {
        name: "Expired subscription",
        icon: "warning",
        pin: "expired",
        colors: gray,
      };

    case ACTIVE_DEVICE_NO_ASSET:
      return {
        name: "Active device with no group",
        icon: "info",
        pin: "no_group",
        colors: ready,
      };

    case RESUMED_CHECK_IN:
      return {
        name: "Resumed check-in",
        icon: "info",
        pin: "no_group",
        colors: ready,
      };

    case LICENSE_UPDATED:
      return {
        name: "License updated",
        icon: "info",
        pin: "no_group",
        colors: ready,
      };
    default:
      return {
        name: "Inactive",
        icon: "",
        pin: "ok",
        colors: gray,
      };
  }
}
