import React, { useState } from 'react';

import Button from '../Button';
import TextInput from '../TextInput';

import styles from './DeleteConfirmation.module.scss';
import Label from '../Label';

export default function DeleteConfirmation({
  challenge,
  name,
  onDelete = () => {}
}) {
  const [value, setValue] = useState('');
  const downcased = name && name.toLowerCase();

  const sendDelete = e => {
    e.preventDefault();
    onDelete();
  };

  return (
    <form onSubmit={sendDelete}>
      <Label
        text={`Enter the ${downcased} name ("${challenge}") to enable the delete button.`}
      />

      <div className={styles.wrap}>
        <div className={styles.input}>
          <TextInput value={value} onChange={e => setValue(e.target.value)} />
        </div>
        <Button disabled={challenge !== value} theme="danger" type="submit">
          Delete {downcased}
        </Button>
      </div>
    </form>
  );
}
