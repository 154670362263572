import React, { useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthContext } from "../../contexts/Auth";
import { post } from '../../api';
import CustomerContext from '../../contexts/Customer';
import PageWrap from '../../components/PageWrap';
import FrameHeader from '../../components/FrameHeader';
import FrameFooter from '../../components/FrameFooter';
import Frame from '../../components/Frame';
import Button from '../../components/Button';
import TextInput from '../../components/TextInput';
import Label from '../../components/Label';
import { useConfirm } from 'material-ui-confirm';

export default function NewUserGroup({ match: { params } }) {
  const { customer } = useContext(CustomerContext);
  const { state } = useContext(AuthContext);
  const [name, setName] = useState('');
  const [saving, setSaving] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const confirm = useConfirm();

  const createGroup = async e => {
    e.preventDefault();
    setSaving(true);

    const res = await post('/groups', {
      name,
      tenant_id: customer ? customer.tenant_id : state.user.tenant_id,
      customer_id: customer ? customer.customer_id : state.user.customer_id
    });
    res && res.id == -1 ? (confirm({
      description: "A group with this name already exists.",
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { style: { 'display': 'none' } },
      confirmationButtonProps: { color: "primary", disableRipple: true }
    })
      .then(() => {
        setSaving(false);
      })
      .catch(() => { })) : setRedirect(customer ? `/customers/${params.id}/groups` : `/groups`);
  };

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <>
      <PageWrap>
        <FrameHeader title="New group" />
        <form onSubmit={createGroup}>
          <Frame>
            <Label text="Group name">
              <TextInput
                type="text"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </Label>

            <FrameFooter>
              <Button
                type="submit"
                theme="primary"
                isLoading={saving}
                disabled={!name || saving}
              >
                Create
              </Button>
            </FrameFooter>
          </Frame>
        </form>
      </PageWrap>
    </>
  );
}
