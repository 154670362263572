import repair_model from './repair_time.js'

export default {
    body: "",
    damage: "",
    service_request: "",
    work_order:"",
    cost:"",
    repair_complete_time:"",
    severity:"",
  };
  