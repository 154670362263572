import React, { useEffect, useState, useContext } from "react";
import { get,put } from "../../api";
import { AuthContext } from "../../contexts/Auth";

import history from '../../helpers/history';
import Modal from "../../components/Modal";
import Frame from "../../components/Frame";
import FrameHeader from "../../components/FrameHeader";
import Space from "../../components/Space";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import Select from "../../components/Select";
import moment from "moment";
import queryString from "query-string";
import Label from "../../components/Label";
import InputRow from "../../components/InputRow";
import LoadingFrame from "../../components/LoadingFrame";
import { useConfirm } from 'material-ui-confirm';
export default function LicenseDetail({ match: { params }, location: { search } }) {


  const { state: currentUser } = useContext(AuthContext);
  const queryParams = queryString.parse(search);
  const [loading, setLoading] = useState(true);
  const [license, setLicense] = useState({});
  const [transferLicense, setTransferLicense] = useState(false);
  const [transferedDeviceId, setTransferedDeviceId] = useState(-1);
  const [devicesWithNoLicenses, setDevicesWithNoLicenses] = useState([]);


  useEffect(() => {

    async function getLicense() {
      const paramsx = params;
      const res = await get(`/subscriptions/${params.subscriptionId}/license/${params.licenseId}`);
      res && setLicense(res);
      setLoading(false);
    }
    async function getDevicesWithNoLicense() {
      const paramsx = params;
      const res = await get(`/customers/${params.id}/devices/`, { no_license: true });
      res && setDevicesWithNoLicenses(res);
      setLoading(false);
    }
    getLicense();
    getDevicesWithNoLicense();
  }, []);
  async function updateLicense() {

   
    const res = await put(`/subscriptions/${params.subscriptionId}/licenses/${params.licenseId}`,license);
   
    setLoading(false);
  }
  function _renderFooter() {
    return (
      <>
        <Button theme="primary"
          onClick={() => history.push(`/customers/${params.id}/subscriptions/`)}>
          Cancel
        </Button>
        <Button theme="secondary"
          onClick={() => updateLicense()}>
          Save
        </Button>
      </>

    );
  }


  return (

    <Modal footer={_renderFooter()} >
      {loading ? <LoadingFrame /> : (
        license && (<>
          <FrameHeader title="License details" />
          <Frame>

            <InputRow>
              <Label text="Device ID" theme="light">
                <>
                  {license.device_id}

                </>
              </Label>
              <Label text="Available Devices" theme="light">

                {devicesWithNoLicenses ? (<Select disabled={transferLicense}
                  value={transferedDeviceId}
                  onChange={e => setTransferedDeviceId(e.target.value)}>
                  <option value={-1}>Select new device</option>
                  {devicesWithNoLicenses.map(a => (
                    <option key={a.id} value={a.id}>
                      {a.serial_number}
                    </option>
                  ))}
                </Select>) : ("No Devices")}

              </Label>

              <Label text="Active" theme="light">
                <Select
                  onChange={e => setLicense({ ...license, active: e.target.value })}
                  value={license.active}
                >
                  <option value={false}>Inactive</option>
                  <option value={true}>Active</option>
                </Select>

              </Label>
            </InputRow>

            <InputRow>
              <Label text="Additional Info" theme="light">
                <TextInput
                  value={license.additional_info}
                  placeholder={"Enter additional info"}
                  onChange={e => {

                    setLicense({ ...license, additional_info: e.target.value })
                  }}
                />

              </Label>
              <Label text="Start Date" theme="light">

                {moment.utc(license.start_date).format('dddd, MMMM Do YYYY')}
              </Label>
              <Label text="Expiry Date" theme="light">
                {moment.utc(license.expiry_date).format('dddd, MMMM Do YYYY')}
              </Label>
            </InputRow>
          </Frame>

        </>)

      )}
    </Modal>
  );

}
