export default {
  additional_info: '',
  authority: '',
  created_time: '',
  customer_id: '',
  email: '',
  phone: '',
  first_name: '',
  id: '',
  last_name: '',
  search_text: '',
  tenant_id: '',
  disable_notification: false,
  disable_notification_txt: false
};
