import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import { post, upload } from '../../api';
import DeviceContext from '../../contexts/Device';
import { DEVICE_NOTE } from '../../constants/events';
import { fetchActivity } from '../../actions/Device';
import history from '../../helpers/history';

import Button from '../../components/Button';
import DeviceCard from '../../components/DeviceCard';
import FileUploader from '../../components/FileUploader';
import FileUploadProgress from '../../components/FileUploadProgress';
import Label from '../../components/Label';
import LoadingFrame from '../../components/LoadingFrame';
import Modal from '../../components/Modal';
import Space from '../../components/Space';
import TextArea from '../../components/TextArea';

// import styles from './DeviceNote.module.scss';

export default function DeviceNote() {
  const [state, dispatch] = useContext(DeviceContext);

  const [saving, setSaving] = useState(false);
  const [body, setBody] = useState('');
  const [attachments, setAttachments] = useState([]);

  async function _saveNote() {
    setSaving(true);

    const event = await post(`/events`, {
      type: DEVICE_NOTE,
      ...(state.asset.id && { asset_id: state.asset.id }),
      device_id: state.device.id,
      body
    });

    let attachmentReqs = [];

    attachments.forEach(attachment => {
      const formData = new FormData();

      formData.set('entity_type', 'EVENT');
      formData.set('entity_id', event.id);
      formData.set('type', 'IMAGE');
      formData.append('file', attachment);

      const res = upload(formData);
      attachmentReqs.push(res);
    });

    await Promise.all(attachmentReqs);

    dispatch(fetchActivity(state.device.id, state.activity.filters, 1));
    history.push(`/devices/${state.device.id}`);
  }

  function _removeFile(i) {
    const newAttachments = attachments
      .slice(0, i)
      .concat(attachments.slice(i + 1, attachments.length));

    setAttachments(newAttachments);
  }

  function _renderFooter() {
    return (
      <>
        <Link to={`/devices/${state.device.id}`}>
          <Button>Cancel</Button>
        </Link>
        <Button theme="primary" onClick={_saveNote} isLoading={saving}>
          Save
        </Button>
      </>
    );
  }
  function saveFile(attachment) {
    if (attachment != undefined) {
      const isArray = Array.isArray(attachment);
      if (isArray) {
        var firstElement = attachment[0];
        if (firstElement !== undefined && firstElement.name !== undefined) {
          setAttachments([...attachments, ...attachment])
        } else {
          setAttachments([...attachments, ...firstElement])
        }
      } else {
        var newArray = [attachment]
        setAttachments([...attachments, ...newArray])
      }
    } 
  }
  return (
    <Modal footer={_renderFooter()}>
      {!state.device.id ? (
        <LoadingFrame />
      ) : (
        <>
          <Space size="m">
            <h2>Add Note</h2>
          </Space>

          <Space size="m">
            <Label text="Device" />
            <DeviceCard device={state.device} />
          </Space>

          <Space size="m">
            <Label text="Note">
              <TextArea
                placeholder="Enter your note here"
                value={body}
                onChange={e => setBody(e.target.value)}
              />
            </Label>
          </Space>

          <Space size="m">
            <Label text="Attachments" />
            <Space size="m">
              <FileUploader
                onSelect={files => saveFile([files])}
              />
            </Space>

            <FileUploadProgress
              files={attachments}
              uploading={saving}
              onRemove={i => _removeFile(i)}
            />
          </Space>
        </>
      )}
    </Modal>
  );
}
