import React from 'react';
import { NavLink } from 'react-router-dom';
import { SYS_ADMIN, TENANT_ADMIN, CUSTOMER_USER } from '../../constants/roles';
import { isAuthorized, hasPermission } from '../../helpers/authorization';
import LogoIcon from '../LogoIcon';
import NavItem from '../NavItem';
import { useMediaQuery } from 'react-responsive'
import styles from './Navigation.module.scss';

function AssetPageNavigation(user, notification) {
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' })
  //const isTablet = useMediaQuery({ query: '(device-width: 1024px), (device-width: 834px), (device-width: 768px), (device-width: 820px), (device-width: 810px), (device-width: 768px), (device-width: 1280px), (device-width: 1366px), (device-width: 1440px)' })
  var assets_link = isDesktop === true ? "/assets_dashboard" : "/assets";
  return (isAuthorized(user.roles, [SYS_ADMIN, TENANT_ADMIN, CUSTOMER_USER]) && (
    <NavLink className={styles.navItemLink} to={assets_link}>
      <NavItem icon="map" title="Assets" notification={notification} />
    </NavLink>
  ));
}

export default function Navigation({ user, alarmCount }) {
  //const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' })
  //const isTablet = useMediaQuery({ query: '(min-width: 726px)' })
  return (
    <nav className={styles.nav}>
      <NavLink className={styles.logoLink} to="/" exact>
        <LogoIcon size={32} />
      </NavLink>
      {isAuthorized(user.roles, [SYS_ADMIN, TENANT_ADMIN, CUSTOMER_USER]) && (
        <NavLink className={styles.navItemLink} to="/map">
          <NavItem icon="public" title="Map" />
        </NavLink>
      )}

      {AssetPageNavigation(user, alarmCount)}

      {isAuthorized(user.roles, [SYS_ADMIN, TENANT_ADMIN, CUSTOMER_USER]) && (
        <NavLink className={styles.navItemLink} to="/devices">
          <NavItem icon="speaker_phone" title="Devices" />
        </NavLink>
      )}

      <div className={styles.break} />

      {isAuthorized(user.roles, [SYS_ADMIN, TENANT_ADMIN, CUSTOMER_USER]) &&
        (hasPermission(user.perms, 'ADMIN') || hasPermission(user.perms, 'ASSET_U')) && (
          <NavLink className={styles.navItemLink} to="/reports">
            <NavItem icon="receipt" title="Reports" />
          </NavLink>
        )}

      {isAuthorized(user.roles, [CUSTOMER_USER]) && (
        <NavLink className={styles.navItemLink} to={"/groups"}>
          <NavItem icon="groups" title="Groups" />
        </NavLink>
      )}

      {isAuthorized(user.roles, [SYS_ADMIN]) && (
        <NavLink className={styles.navItemLink} to="/tenants">
          <NavItem icon="business" title="Tenants" />
        </NavLink>
      )}

      {isAuthorized(user.roles, [SYS_ADMIN, TENANT_ADMIN]) && (
        <NavLink className={styles.navItemLink} to="/customers">
          <NavItem icon="work" title="Customers" />
        </NavLink>
      )}

      {isAuthorized(user.roles, [CUSTOMER_USER]) &&
        hasPermission(user.perms, 'ADMIN') && (
          <NavLink className={styles.navItemLink} to={`/customer`}>
            <NavItem icon="work" title="Customer" />
          </NavLink>
        )}

      {isAuthorized(user.roles, [SYS_ADMIN, TENANT_ADMIN]) && (
        <NavLink className={styles.navItemLink} to="/users">
          <NavItem icon="group" title="Users" />
        </NavLink>
      )}
      {isAuthorized(user.roles, [TENANT_ADMIN]) && (
        <NavLink className={styles.navItemLink} to="/admin_portal">
          <NavItem icon="settings" title="Admin" />
        </NavLink>
      )}
      <NavLink className={styles.accountLink} to="/help">
        <NavItem icon="help" title="Help" />
      </NavLink>
      <NavLink className={styles.navItemLink} to="/account">
        <NavItem icon="person" title="Account" />
      </NavLink>
    </nav>
  );
}
