import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { get, post } from "../../api";
import { AuthContext } from "../../contexts/Auth";
import history from "../../helpers/history";
import Frame from "../../components/Frame";
import FrameHeader from "../../components/FrameHeader";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import Label from "../../components/Label";
import InputRow from "../../components/InputRow";
import LoadingFrame from "../../components/LoadingFrame";
import { useConfirm } from "material-ui-confirm";
import Modal from "../../components/Modal";
import CustomerContext from "../../contexts/Customer";
import CustomerInfoForm from "../../components/CustomerInfoForm";
import Space from "../../components/Space";
import Icon from "../../components/Icon";
import styles from "./NewSubscription.module.scss";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { useMediaQuery } from "react-responsive";
import { Spa } from "@material-ui/icons";
import PaymentProfile from "../../components/PaymentProfile/PaymentProfile";
export default function NewSubscription({
  match: { params },
  location: { search },
}) {
  const isMobile = useMediaQuery({ query: "(max-width: 650px)" });
  const confirm = useConfirm();
  const { state: currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const { customer, setCustomer } = useContext(CustomerContext);
  const [customer_modified, setCustomerModified] = useState(null);
  const [paymentInProgress, setPaymentInProgress] = useState(false);
  const [generatingCheckout, setGeneratingCheckout] = useState(false);
  const [checkoutResult, setCheckoutResult] = useState(null);

  const [cartId, setCarId] = useState("");
  const [expiredDevices, setExpiredDevices] = useState(0);
  const [error, setError] = useState(null);
  const [subscriptions, setSubscriptions] = useState({
    fiveYear: { quantity: 0 },
    threeYear: { quantity: 0 },
    oneYear: { quantity: 0 },
  });

  const [cart, setCart] = useState([]);
  const INFO_STEP = 0;
  const CHECKOUT_STEP = 1;
  const PAYMENT_SUCCESS = 2;
  const [step, setStep] = useState(INFO_STEP);
  const [orderNumber, setOrderNumber] = useState(null);
  const [paymentProfile, setPaymentProfile] = useState(null);
  const retrieveCustomerExpiredDevices = async () => {
    const devices = await get(`/customers/subscriptions/expired`);
    if (devices) {
      setExpiredDevices(devices);
    }
    const customerPaymentProfile = await Promise.all([
      get(`/customers/paymentprofile`),
    ]);
    customerPaymentProfile &&
      customerPaymentProfile[0] &&
      customerPaymentProfile[0].length > 0 &&
      setPaymentProfile(customerPaymentProfile[0]);
  };
  useEffect(() => {
    retrieveCustomerExpiredDevices();
  }, []);

  const handleAddToCart = (option) => {
    let price = 240;
    switch (option) {
      case "oneYear":
        break;
      case "threeYear":
        price = 648;
        break;
      case "fiveYear":
        price = 900;
        break;
    }

    setCart((currentCart) => {
      const existingItemIndex = currentCart.findIndex(
        (item) => item.option === option
      );

      if (existingItemIndex >= 0) {
        const updatedCart = [...currentCart];
        updatedCart[existingItemIndex] = {
          ...updatedCart[existingItemIndex],
          quantity: updatedCart[existingItemIndex].quantity + 1,
        };
        return updatedCart;
      } else {
        const newItem = {
          option: option,
          quantity: 1,
          price: price,
        };
        return [...currentCart, newItem];
      }
    });

    setSubscriptions((currentSubscriptions) => ({
      ...currentSubscriptions,
      [option]: { ...currentSubscriptions[option], quantity: 0 },
    }));
  };

  function validated() {
    switch (step) {
      case INFO_STEP:
        return !cart.length == 0;
        break;

      case CHECKOUT_STEP:
        return paymentProfile != null && !generatingCheckout;
        break;

      case PAYMENT_SUCCESS:
        return true;
    }
  }

  async function geerateCheckout() {
    setLoading(true);
    try {
      setGeneratingCheckout(true);
      const res = await post("/checkout/generate", {
        customer: customer_modified || customer,
        cart,
      });
      if (res) {
        setCheckoutResult(JSON.parse(res?.json?.content));
        res.cartId && setCarId(res.cartId);
        setGeneratingCheckout(false);
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }

  async function placeOrder() {
    try {
      const res = await post("/checkout/placeorder", {
        cartId: cartId,
        customer_id: customer.id,
        cart,
      });
      if (res) {
        if (res.success) {
          setStep(PAYMENT_SUCCESS);
          setOrderNumber(res.content);
        }
        if (res.error) {
          setError(res.content);
          confirm({
            description: res.content,
            title: "Unable to place order",
            dialogProps: { fullScreen: false },
            cancellationButtonProps: { style: { display: "none" } },
            confirmationButtonProps: { color: "primary", disableRipple: true },
          })
            .then(() => {
              geerateCheckout();
            })
            .catch(() => {
              /* ... */
            });
        }
      }
      setPaymentInProgress(false);
    } catch (e) {
      console.log(e);
    }
  }

  const handleRemoveFromCart = (index) => {
    confirm({
      description:
        "Are you sure you want to remove this item from the shopping cart?",
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true },
    })
      .then(() => {
        setCart((currentCart) => currentCart.filter((_, i) => i !== index));
      })
      .catch(() => {});
  };

  function displayCartItems() {
    const handleQuantityChange = (index, newQuantity) => {
      if (newQuantity <= 0) return;
      const updatedCart = cart.map((item, idx) =>
        idx === index ? { ...item, quantity: newQuantity } : item
      );
      setCart(updatedCart);
    };

    if (cart?.length > 0) {
      return (
        <>
          <div className={styles.bottomsticky}>
            <div className={styles.cartFrame}>
              {cart.map((item, index) =>
                isMobile ? (
                  <div key={index} className={styles.quantitycontrolMobile}>
                    <div className={styles.minwidth}>
                      {decodeOptionShort(item.option)}
                    </div>
                    <div className={styles.totalcart}>
                      <div className={styles.quantitycontrol}>
                        <Button
                          size="s"
                          icon="keyboard_arrow_left"
                          theme="light"
                          onClick={() =>
                            handleQuantityChange(index, item.quantity - 1)
                          }
                        />
                        <span className={styles.span}>
                          <p> {item.quantity}</p>
                        </span>
                        <Button
                          size="s"
                          theme="light"
                          icon="keyboard_arrow_right"
                          onClick={() =>
                            handleQuantityChange(index, item.quantity + 1)
                          }
                        />
                      </div>
                    </div>
                    <Button
                      size="s"
                      icon="delete_forever"
                      theme="danger"
                      onClick={() => handleRemoveFromCart(index)}
                    />
                  </div>
                ) : (
                  <div key={index} className={styles.cartItem}>
                    {decodeOptionShort(item.option)}
                    <div className={styles.totalcart}>
                      <div className={styles.quantitycontrol}>
                        <Button
                          size="s"
                          icon="keyboard_arrow_left"
                          theme="light"
                          onClick={() =>
                            handleQuantityChange(index, item.quantity - 1)
                          }
                        />
                        <span className={styles.span}>
                          <p> {item.quantity}</p>
                        </span>
                        <Button
                          size="s"
                          theme="light"
                          icon="keyboard_arrow_right"
                          onClick={() =>
                            handleQuantityChange(index, item.quantity + 1)
                          }
                        />
                      </div>
                    </div>
                    <span className={styles.span}>
                      <b>${item.price * item.quantity}.00</b>
                    </span>

                    <Button
                      size="s"
                      icon="delete_forever"
                      theme="danger"
                      onClick={() => handleRemoveFromCart(index)}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        </>
      );
    } else {
      return "Cart empty";
    }
  }

  const OrderSummary = ({ data }) => {
    const { grand_total, subtotal, tax_amount, items, total_segments } = data;
    if (!data) return null;
    return (
      <div>
        <div>
          <span style={{ display: "block", paddingBottom: 14 }}>Items</span>
          <ul>
            {items?.map((item) => (
              <li key={item.item_id}>
                {item.qty} x {item.name} - ${item.price}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <span style={{ display: "block", paddingTop: 14 }}>
            <p>
              <b>Order Total: ${grand_total}</b>
            </p>{" "}
          </span>
        </div>
      </div>
    );
  };

  function subOption(label, color, onAddToCart, price) {
    return (
      <div className={styles.label}>
        <div className={styles.img}>
          <div>
            <Icon
              color={color}
              name={"calendar_month"}
              size={isMobile ? 32 : 56}
            />
          </div>
        </div>
        <div className={styles.ceter}>
          <div className={styles.flexRow}>
            <div className={styles.text}>{label}</div>
          </div>
          <div className={styles.flexRow}>
            <div className={styles.sub_text}></div>
          </div>

          <div>
            <div className={styles.flexRow}>
              <div className={styles.center}>
                <p style={{ color: color }}>
                  <b>${price}</b>
                </p>
              </div>
            </div>
            <Space size="xs" />
            <div className={styles.wrap}>
              <Button
                size={isMobile ? "mobile" : "s"}
                theme={"primary"}
                onClick={onAddToCart}
              >
                Add to cart
              </Button>
            </div>
            <Space size="xs" />
          </div>
        </div>
      </div>
    );
  }

  function stepBack() {
    switch (step) {
      case INFO_STEP:
        confirm({
          description: "Are you sure you want to cancel?",
          title: null,
          dialogProps: { fullScreen: false },
          cancellationButtonProps: { color: "error", disableRipple: true },
          confirmationButtonProps: { color: "primary", disableRipple: true },
        })
          .then(() => {
            window.location.replace("/customer/subscriptions");
          })
          .catch(() => {
            /* ... */
          });

        break;
      case CHECKOUT_STEP:
        setCheckoutResult(null);
        setStep(step - 1);
        break;
    }
  }

  const NextStep = () => {
    switch (step) {
      case INFO_STEP:
        geerateCheckout();
        setStep(CHECKOUT_STEP);
        break;
      case CHECKOUT_STEP:
        setPaymentInProgress(true);
        placeOrder();

        break;

      case PAYMENT_SUCCESS:
        window.location.replace("/customer/subscriptions");
        break;
    }
  };

  const returnIconWrapper = (color, text) => {
    return (
      <div className={styles.flexContainer}>
        <div className={styles.iconContainer}>
          <Icon color={color} name={"calendar_month"} size={36} />
        </div>
        <div className={styles.textContainer}>{text}</div>
      </div>
    );
  };

  const decodeOptionShort = (option) => {
    if (!isMobile) {
      switch (option) {
        case "oneYear":
          return returnIconWrapper("#ee0e0e", "1 Year Subscription");
        case "threeYear":
          return returnIconWrapper("#f26925", "3 Year Subscription");
        case "fiveYear":
          return returnIconWrapper("#05b008", "5 Year Subscription");
      }
    } else {
      switch (option) {
        case "oneYear":
          return "1 Year";
        case "threeYear":
          return "#f26925", "3 Year";
        case "fiveYear":
          return "#05b008", "5 Year";
      }
    }
  };

  function renderContent() {
    switch (step) {
      case INFO_STEP:
        return (
          <>
            <FrameHeader title="Subscription details" />
            <Frame>
              <div className={styles.center}>
                <InputRow>
                  <div className={styles.wrap2}>
                    {subOption(
                      "5 Year Subscription",

                      "#05b008",
                      () => handleAddToCart("fiveYear"),
                      900
                    )}
                    {subOption(
                      "3 Year Subscription",

                      "#f26925",
                      () => handleAddToCart("threeYear"),
                      648
                    )}
                    {subOption(
                      "1 Year Subscription",

                      "#ee0e0e",
                      () => handleAddToCart("oneYear"),
                      240
                    )}
                  </div>
                </InputRow>
              </div>
            </Frame>
            {cart.length > 0 && displayCartItems()}
          </>
        );

      case CHECKOUT_STEP:
        return (
          <>
            <FrameHeader title="Payment Method" />
            <Frame>{paymentProfile != null ? <PaymentProfile /> : ""}</Frame>
            <Space size="xs" />
            <FrameHeader title="Order summary" />

            {generatingCheckout ? (
              <LoadingFrame />
            ) : (
              <>
                <Frame>
                  {checkoutResult && <OrderSummary data={checkoutResult} />}
                </Frame>
              </>
            )}
          </>
        );

      case PAYMENT_SUCCESS:
        return (
          <div className={styles.parent}>
            <div className={styles.child}>
              <Frame simple={true}>
                <Space>
                  <div className={styles.wrap}>
                    <div className={styles.icon}>
                      <Icon name="check_circle" size={isMobile ? 36 : 56} />
                    </div>
                    <div className={styles.wrapvert}>
                      <h1>Thank you for your purchase</h1>
                    </div>
                    <p className={styles.bodyLeft}>
                      Your order number is: <b>000000{orderNumber}</b>. An Order
                      Confirmation email has been sent to you.
                    </p>
                  </div>
                </Space>
              </Frame>
              <Space />
              <Frame>
                <div className={styles.wrap}>
                  {displaySubscriptionWizardOptions()}
                </div>
              </Frame>
            </div>
          </div>
        );
    }
  }

  const displaySubscriptionWizardOptions = () => {
    return expiredDevices > 0 ? (
      <>
        <p className={styles.bodyLeft}>
          There{" "}
          {expiredDevices == 1 ? (
            <>
              is <b>{expiredDevices}</b> expired device.
            </>
          ) : (
            <>
              are <b>{expiredDevices}</b> expired devices.
            </>
          )}
        </p>
        <Space />
        <div className={styles.wrap}>
          Open the subscription wizard to manage subscriptions.
          <Space />
          <Button
            theme="primary"
            icon="auto_fix_normal"
            noHeight={true}
            onClick={() =>
              window.location.replace("/customer/subscriptions?assign=true")
            }
          >
            Subscription wizard
          </Button>
        </div>
      </>
    ) : (
      <div className={styles.wrap}>
        <p>
          There are no devices that are expired or expiring. Press <b>Finish</b>{" "}
          to continue.
        </p>
      </div>
    );
  };

  function _renderFooter() {
    return (
      <>
        {step !== PAYMENT_SUCCESS && (
          <Button
            disabled={loading || paymentInProgress}
            onClick={() => stepBack()}
          >
            {step == 0 ? "Cancel" : "Back"}
          </Button>
        )}
        <Button
          theme="primary"
          onClick={NextStep}
          isLoading={loading || paymentInProgress}
          disabled={!validated()}
        >
          {step == INFO_STEP && "Checkout"}
          {step == CHECKOUT_STEP && "Place Order"}
          {step == PAYMENT_SUCCESS && "Finish"}
        </Button>
      </>
    );
  }

  return (
    <Modal isLoading={paymentInProgress} footer={_renderFooter()}>
      {renderContent()}
    </Modal>
  );
}
