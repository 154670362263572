import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { destroy, get, put, upload } from '../../api';
import Button from '../../components/Button';
import InputRow from '../../components/InputRow';
import Label from '../../components/Label';
import LoadingFrame from '../../components/LoadingFrame';
import Modal from '../../components/Modal';
import Select from '../../components/Select';
import Space from '../../components/Space';
import TextInput from '../../components/TextInput';
import InspectionModel from '../../models/inspection.js';
import { DefaultEditor } from 'react-simple-wysiwyg';
import { isAuthorized, hasPermission } from "../../helpers/authorization";
import { AuthContext } from "../../contexts/Auth";
import { CUSTOMER_USER, SYS_ADMIN, TENANT_ADMIN } from '../../constants/roles';
import FileUploader from '../../components/FileUploader';
import FileUploadProgress from '../../components/FileUploadProgress';
import { DateUtils } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import moment, { locale } from 'moment';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import styles from './AssetInspectionEdit.module.scss';
import { MINOR_REPAIR, MINOR_WITH_NO_REPAIR, MAJOR } from '../../constants/statuses';
import { useConfirm } from 'material-ui-confirm';
export default function AssetInspectionEdit({ match: { params } }) {
  const { state } = useContext(AuthContext);
  const [inspection, setInspection] = useState(InspectionModel);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const confirm = useConfirm();
  useEffect(() => {
    async function fetchInspection() {
      const res = await get(`/inspections/${params.id}`);
      res && setInspection(res);
      setLoading(false);
    }
    fetchInspection();
  }, []);
  async function _saveInspection() {
    setSaving(true);
    const res = await put(`/inspections/${params.id}`, inspection);
    let attachmentReqs = [];

    attachments.forEach(attachment => {
      const formData = new FormData();
      formData.set('entity_type', 'INSPECTION');
      formData.set('entity_id', params.id);
      formData.set('type', 'IMAGE');
      formData.append('file', attachment);
      const res = upload(formData);
      attachmentReqs.push(res);
    });
    await Promise.all(attachmentReqs);

    window.location.replace(`/assets/${params.assetId}/`);
  }
  async function saveFile(attachment) {
    if (attachment != undefined) {
      const isArray = Array.isArray(attachment);
      if (isArray) {
        var firstElement = attachment[0];
        if (firstElement !== undefined && firstElement.name !== undefined) {
          setAttachments([...attachments, ...attachment])
        } else {
          setAttachments([...attachments, ...firstElement])
        }
      } else {
        var newArray = [attachment]
        setAttachments([...attachments, ...newArray])
      }
    } else {
      alert('File saving error!');
    }
  }
  async function _removeFile(i) {

    if (attachments != undefined) {
      const newAttachments = attachments
        .slice(0, i)
        .concat(attachments.slice(i + 1, attachments.length));

      setAttachments(newAttachments);
    } else {
      alert('delete error');
    }
  }

  function _removeConfirmation() {
    confirm({
      description: 'Are you sure you want to delete this inspection?',
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true }
    })
      .then(() => {
        _remove();
      })
      .catch(() => { /* ... */ });
  }

  async function _remove() {
    setSaving(true);
    const res = await destroy(`/inspections/${params.id}`);
    window.location.replace(`/assets/${params.assetId}/`);
  }
  //date

  function formatDate(date, format, locale) {
    return dateFnsFormat(date, format, { locale });
  }

  const FORMAT = 'MM/dd/yyyy';
  function DateChange(desireddate) {


    desireddate && setInspection({ ...inspection, repair_complete_time: desireddate.getMonth() + 1 + "/" + desireddate.getDate() + "/" + desireddate.getFullYear() });
  }
  function parseDate(str, format, locale) {
    const parsed = dateFnsParse(str, format, new Date(), { locale });
    if (DateUtils.isDate(parsed)) {
      return parsed;
    }
    return undefined;
  }

  function costValidate() {
    return (inspection.cost && !inspection.cost.match(/^\s{0,1}\d*[.,]{0,1}\d*[.,]{0,1}\d*$/));
  }

  function _renderFooter() {
    return (
      <>
        {isAuthorized(state.user.roles, [SYS_ADMIN, TENANT_ADMIN, CUSTOMER_USER]) && (hasPermission(state.user.perms, 'ADMIN') || hasPermission(state.user.perms, 'ASSET_C')) && <Button theme="danger" onClick={() => _removeConfirmation()} disabled={saving}>
          Delete
        </Button>}
        <Link to={`/assets/${params.assetId}`}>
          <Button>Cancel</Button>
        </Link>
        <Button theme="primary" onClick={() => _saveInspection()} isLoading={saving} disabled={(costValidate()) ? true : saving}>
          Save
        </Button>
      </>
    );
  }
  return (
    <Modal footer={_renderFooter()}>
      {loading ? (
        <LoadingFrame />
      ) : (
        <>
          <Space size="l">
            <h2>Edit inspection report</h2>
          </Space>
          <InputRow>
            <Label text="Is the asset damaged?">
              <Select onChange={e => setInspection({ ...inspection, damage: e.target.value })} value={inspection.damage}>
                <option value="DAMAGED">Yes</option>
                <option value="NONE">No</option>
              </Select>
            </Label>
          </InputRow>


          {inspection.damage === "DAMAGED" && (<>
            <Label text="Severity">
              <Select onChange={e => setInspection({ ...inspection, severity: e.target.value })} value={inspection.severity}>
                <option value={MINOR_WITH_NO_REPAIR}>1-Minor with no repair</option>
                <option value={MINOR_REPAIR}>2-Minor repair</option>
                <option value={MAJOR}>3-Major, Severe, Replacement</option>
              </Select>
            </Label>
            <Space />
          </>)}

          <InputRow>
            <Label text="Work order number">
              <TextInput
                keyboardType="numeric"
                type="number"
                spinHide={true}
                value={inspection.work_order}
                onChange={e => setInspection({ ...inspection, work_order: e.target.value })}
              />
            </Label>
            <Label text="Service request number">
              <TextInput
                keyboardType="numeric"
                type="number"
                spinHide={true}
                value={inspection.service_request}
                onChange={e => setInspection({ ...inspection, service_request: e.target.value })}
              />
            </Label>
          </InputRow>
          <InputRow>
            <Label text="Repair cost">
              <TextInput
                keyboardType="numeric"
                type="text"
                error={costValidate() === true && "Only a digits, comma and dot are allowed"}
                spinHide={true}
                value={inspection.cost}
                onChange={e => setInspection({ ...inspection, cost: e.target.value })}
              />
            </Label>
            <Label text="Repair completion date">
              <div className={styles.wizzardPickerWrapper}>
                <DayPickerInput
                  formatDate={formatDate}
                  format={FORMAT}
                  value={inspection.repair_complete_time ? moment(inspection.repair_complete_time).format('MMMM Do YYYY') : null}
                  parseDate={parseDate}
                  classNames={styles.input}
                  style={styles.input}
                  placeholder={`Click to select`}
                  onDayChange={DateChange}
                  autoComplete="off"
                />
              </div>
            </Label>
          </InputRow>
          <Space size="m">
            <Label text="Notes"> </Label>
            <div className={styles.wyswigOuterWrapper}>
              <DefaultEditor
                placeholder="Enter any additional info here"
                value={inspection.body}
                onChange={e => setInspection({ ...inspection, body: e.target.value })}
                className={styles.wyswigWrapper}
              />
            </div>
          </Space>
          <Space size="s">
            <p>Upload photos</p>
          </Space>
          <Space size="m">
            <Space size="m">
              <FileUploader
                onError={errMsg => alert(errMsg)}
                onSelect={files => saveFile([files])}
              />
            </Space>
            <FileUploadProgress
              //preview={true}
              smallPreview={true}
              files={attachments}
              uploading={saving}
              onRemove={i => _removeFile(i)}
            />
          </Space>
        </>
      )}
    </Modal>
  );
}
