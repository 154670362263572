import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { post } from '../../api';
import CustomerModel from '../../models/customer';

import CustomerInfoForm from '../../components/CustomerInfoForm';
import FrameHeader from '../../components/FrameHeader';

// import styles from './NewTenantCustomer.module.scss';

export default function NewTenantCustomer({ match: { params } }) {
  const [saving, setSaving] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const createCustomer = async customer => {
    setSaving(true);
    const res = await post('/customers', { ...customer, tenant_id: params.id });
    setRedirect(`/customers/${res.id}`);
  };

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <>
      <FrameHeader title="New customer" />
      <CustomerInfoForm
        state={CustomerModel}
        saving={saving}
        onSave={customer => createCustomer(customer)}
        isNew
      />
    </>
  );
}
