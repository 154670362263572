import React, { useState, useEffect } from 'react';
import { get } from '../../api/api';
import Frame from '../Frame';
import Label from '../Label/Label';
import LoadingFrame from '../LoadingFrame';
import Popover from '../Popover';
import QueryBar from '../QueryBar';
import RowItem from '../RowItem';
import Select from '../Select';
import TextInput from '../TextInput';
import styles from './CollectionListingManual.module.scss';
const defaultItem = i => <RowItem key={i.id} title={i.id} />;
export default function CollectionListingManual({
  collection,
  item = defaultItem,
  queryKey = 'name',
  placeholder,
  sortOptions = false,
}) {
  const [query, setQuery] = useState('');
  const [data, setData] = useState([]);
  const [sortBy, setSortBy] = useState('-created_time');
  const [loading, setLoading] = useState(false);
  async function getResults(query) {
    setLoading(true);
    const data = await get(`/${collection}`, {
      name: query,
      orderBy: sortBy,
    });
    data && setData(data);
    setLoading(false);
  }
  useEffect(() => {
  }, []);
  const showCollection = () => <>{data && data.results.map(entity => item(entity))}</>;

  function _renderContent() {
    if (loading && !data) {
      return <LoadingFrame />;
    } else {
      return data && data.results && data.results.length>0 && <div className={styles.empty}>{showCollection()}</div>;
    }
  }
  function showResults(e) {
    if (e.target.value.length > 0) {
      setQuery(e.target.value);
      getResults(e.target.value);
    }else{
      setQuery('');
      setData([]);
    }
  }
  return (
    <>
      <Frame padding="0">
        <QueryBar button={sortOptions} >
          <TextInput
            value={query}
            placeholder={placeholder}
            icon="search"
            loading={loading && data}
            onChange={e => {
              showResults(e);
            }}
          />
          {sortOptions && <Popover
            icon="filter_list"
            text="Sort and filter"
            align="right"
            width={300}
          >
            <Label text="Sort">
              <Select onChange={e => setSortBy(e.target.value)}>
                {sortOptions}
              </Select>
            </Label>
          </Popover>}
        </QueryBar>
        {_renderContent()}
      </Frame>
    </>
  );
}
