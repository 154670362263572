import React from 'react';
import { Link } from 'react-router-dom';

import Breadcrumb from '../../components/Breadcrumb';
import Button from '../../components/Button';
import CollectionListing from '../../components/CollectionListing';
import FrameHeader from '../../components/FrameHeader';
import RowItem from '../../components/RowItem';
import SingleColumnPage from '../../components/SingleColumnPage';

// import styles from './Tenants.module.scss';

export default function Tenants() {
  const breadcrumbs = () => <Breadcrumb title="Tenants" />;

  return (
    <SingleColumnPage breadcrumbs={breadcrumbs}>
      <FrameHeader title="Tenants">
        <Link to="/tenants/invite">
          <Button icon="add" theme="primary">
            New Tenant
          </Button>
        </Link>
      </FrameHeader>
      <CollectionListing
        collection="tenants"
        item={t => (
          <RowItem
            key={t.id}
            title={t.name}
            subtitle={`${t.city}, ${t.state}`}
            to={`/tenants/${t.id}`}
          />
        )}
      />
    </SingleColumnPage>
  );
}
