import React from 'react';

import Frame from '../Frame';

import styles from './Overview.module.scss';

export default function Overview({ children, mobile = false, noframe = false }) {

  const classes = mobile === true ? ([styles.mobileView, styles.wrap].join(' ')) : noframe ? styles.wrapclear : styles.wrap;

  return (noframe ? <div>
    <div className={classes}>{children}</div>
  </div> :
    <Frame padding={0}>
      <div className={classes}>{children}</div>
    </Frame>
  );
}
