import { useState, useEffect } from 'react';
import { headers, serialize } from '../helpers/api';
import { HOST } from '../constants/api';

const useFetch = (path, params = {}, fetchOptions = {}, dependantKeys = []) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async url => {
    try {
      setLoading(true);

      const response = await fetch(url, {
        headers: headers(),
        credentials: 'omit',
        ...fetchOptions
      });

      setData(await response.json());
      setLoading(false);
    } catch (e) {
      if (e.status < 200 || e.status >= 300) {
        window.localStorage.removeItem('refreshToken');
        window.localStorage.removeItem('bearerToken');
        window.location.replace('/log-in');
      }

      setError(e);
    }
  };

  useEffect(() => {
    fetchData(HOST + path + serialize(params));
  }, [...Object.values(params), ...dependantKeys]);

  return [data, loading, error];
};

export { useFetch };
