import React, { useState } from 'react';
import Frame from '../../components/Frame';
import {postExternal,post } from "../../api";
import Label from '../../components/Label';
import InputRow from '../../components/InputRow';
import FrameHeader from '../../components/FrameHeader';
import Space from '../../components/Space';
import TextInput from '../../components/TextInput';
import Button from "../../components/Button";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { useConfirm } from 'material-ui-confirm';
import { POSITION_FLAT,POSITION_VERTICAL,POSITION_HORIZONTAL,POSITION_UNKNOWN } from "../../helpers/orientation";
export default function AdminPortalFunctions() {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('HEARTBEAT');
  const [serial_number, setSerialNumber] = useState('999999999999999');
  const [orientation,setOrientation] = useState(POSITION_FLAT);
    const confirm = useConfirm();
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const [target,setTarget] = useState('production');
  async function sendRequest() {
    setLoading(true);
    var json = { "serial_number": serial_number, "imei": "999999999999999", "iccid": "999999999999999", "firmware": "1", "modem_firmware": "mfw_nrf9160_1.2.0", "mac": "0","orientation":orientation, "records": [{ "sequence_number": 16, "reason": value, "device_time": "datetoupdate", "fields": [{ "latitude": 37.274035, "longitude": -121.862684, "accelerometer_x": 0.000000, "accelerometer_y": 10.000000, "accelerometer_z": 0.000000, "temperature": 16.0, "signal": 40, "battery": 3500 }] }] }
   
    var endpoint = 'https://traffixsentinelfunction01.azurewebsites.net/api/SentinelTelemetryEndpoint';

    if(target=='staging'){
      endpoint = 'https://traffixsentinelfunction01.azurewebsites.net/api/TestingOnStaging ';
    }


    const response = fetch(endpoint, {
      method: 'POST',
      headers: {
        accept: 'application/json',
      },
      body:JSON.stringify(json)
    });

    setLoading(false);
    window.location.reload();
  }
async function activateSIM(){

  const resp = await post(`/device/${serial_number}/sim`);
 
  confirm({
    description: JSON.stringify(resp),
    title: null,
    dialogProps: { fullScreen: false },
    cancellationButtonProps: { style: {'display' : 'none'}},
    confirmationButtonProps: { color: "primary", disableRipple: true }
  })
    .then(() => {
    
    })
    .catch(() => { });
}

  return (
    <>
      <FrameHeader title="Functions" />
      <Frame padding={8}>
        <InputRow>
          <Space>
            <FormControl component="fieldset">
              <FormLabel component="legend">Type</FormLabel>
              <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                <FormControlLabel value="HEARTBEAT" control={<Radio />} label="HEARTBEAT" />
                <FormControlLabel value="MOVEMENT" control={<Radio />} label="MOVEMENT" />
              </RadioGroup>
            </FormControl>
            <FormControl component="fieldset">
              <FormLabel component="legend">Orientation</FormLabel>
              <RadioGroup aria-label="orientation" name="orientation" value={orientation} onChange={event => setOrientation(event.target.value)}>
                <FormControlLabel value={POSITION_FLAT} control={<Radio />} label={POSITION_FLAT} />
                <FormControlLabel value={POSITION_VERTICAL} control={<Radio />} label={POSITION_VERTICAL} />
                <FormControlLabel value={POSITION_HORIZONTAL} control={<Radio />} label={POSITION_HORIZONTAL} />
                <FormControlLabel value={POSITION_UNKNOWN} control={<Radio />} label={POSITION_UNKNOWN} />
              </RadioGroup>
            </FormControl>
          </Space>
          <Space>
            <Label text="Serial number">
              <TextInput value={serial_number} onChange={e => setSerialNumber(e.target.value)}></TextInput>
            </Label>
          </Space>
          <Space>
          <FormControl component="fieldset">
              <FormLabel component="legend">Target</FormLabel>
              <RadioGroup aria-label="staging" name="staging" value={target} onChange={e => setTarget(e.target.value)}>
                <FormControlLabel value='production' control={<Radio />} label="Production" />
                <FormControlLabel value='staging' control={<Radio />} label="Staging" />
             
              </RadioGroup>
            </FormControl>
            <Button onClick={() => sendRequest()} disabled={loading}>Send request</Button>
          </Space>
        </InputRow>
      </Frame>
      <Space/>
      <Frame>
       
      <Space>
            <Label text="Serial number to activate sim">
              <TextInput value={serial_number} onChange={e => setSerialNumber(e.target.value)}></TextInput>
            </Label>
          </Space>
          <Button onClick={() => activateSIM()} disabled={loading}>Activate SIM</Button>
      </Frame>
    </>
  );
}
