

export const low = 1.5;
export const low_medium =  1.3;
export const medium = 1.16;
export const medium_high = 1.05;
export const high = 0.9;


export const POSITION_FLAT ="POSITION_FLAT";
export const POSITION_VERTICAL ="POSITION_VERTICAL";
export const POSITION_HORIZONTAL ="POSITION_HORIZONTAL";
export const POSITION_UNKNOWN ="POSITION_UNKNOWN";


export function getXYZ(orientation,sensitivity) {
var desiredThreshold = medium;
  switch (sensitivity) {
    case 0:
      desiredThreshold = low;
      break;
    case 1:
      desiredThreshold = low_medium;
      break;
    case 2:
      desiredThreshold = medium;
      break;
    case 3:
      desiredThreshold = medium_high;
      break;
    case 4:
      desiredThreshold = high;
      break;
    default:
      break;
}

  switch (orientation) {
    case POSITION_FLAT:
      return {
        threshold_x: 0.57,
        threshold_y: 0.57,
        threshold_z: desiredThreshold,
        sensitivity:sensitivity,
        orientation:POSITION_FLAT
      };

    case POSITION_VERTICAL:
      return {
        threshold_x: desiredThreshold,
        threshold_y: 0.57,
        threshold_z: 0.57,
        sensitivity:sensitivity,
        orientation:POSITION_VERTICAL
      };

    case POSITION_HORIZONTAL:
      return {
        threshold_x: 0.57,
        threshold_y: desiredThreshold,
        threshold_z: 0.57,
        sensitivity:sensitivity,
        orientation:POSITION_HORIZONTAL
      };

    default:
      return {
        threshold_x: 0,
        threshold_y: 0,
        threshold_z: 0,
        sensitivity:2,
        orientation:POSITION_UNKNOWN
      };
  }
}

export function getOrientation(x, y, z, AssetOrientation) {

  if (AssetOrientation !== undefined) {
    return AssetOrientation;
  }


  x = parseFloat(x);
  y = parseFloat(y);
  z = parseFloat(z);

  if (x === 0.57 && y === 0.57) {// && z === 1.12) {
    return POSITION_FLAT;
  }

  if (/*x === 1.12 && */y === 0.57 && z === 0.57) {
    return POSITION_VERTICAL;
  }

  if (x === 0.57 /*&& y === 1.12*/ && z === 0.57) {
    return POSITION_HORIZONTAL;
  }

  return;
}
