import { get, post } from "../api";

export const authWithCredentials = ({ email, password }) => async dispatch => {
  dispatch({ type: "AUTH_WITH_CREDENTIALS_REQUEST" });

  const res = await post("/auth", {
    provider: "credentials",
    userName: email,
    password
  });

  if (res !==undefined &&  Object.keys(res.responseStatus).length === 0) {
    const { bearerToken, refreshToken } = res;
    const payload = sessionPayload(bearerToken, refreshToken);

    window.localStorage.setItem("refreshToken", refreshToken);
    window.localStorage.setItem("bearerToken", bearerToken);

    dispatch(authSuccess(payload));
  } else {
    const payload = {
      code: res && res.responseStatus.errorCode,
      message: res && res.responseStatus.message
    };
 
    dispatch(authError(payload));
  }
};
export async function requestAutoLogin(email) {
  const res = await post("/requestautologin", {
    email: email,
  });


  return res;
};
export async function checkAutoLogin(token) {
  const res = await get("/checkautologin", {
    token: token,
  });

  if (res !== undefined && res?.responseStatus !== undefined) {
    
      if (Object.keys(res?.responseStatus).length === 0) {
        const { bearerToken, refreshToken } = res;
        const payload = sessionPayload(bearerToken, refreshToken);

        window.localStorage.setItem("refreshToken", refreshToken);
        window.localStorage.setItem("bearerToken", bearerToken);
        window.location.replace("/");
        return true;
      }
   
  } else {
    
    window.location.replace("/log-in?loginLinkExpired=true");
    return true;
  }
 // return res;
};
export const authWithToken = refreshToken => async dispatch => {
  try {
    const res = await get("/access-token", { refreshToken });

    /** Handle expired token response by logging the user out. */
    if (
      res &&
      res.responseStatus &&
      res.responseStatus.errorCode === "TokenException"
    ) {
      throw res;
    }

    const bearerToken = res.accessToken;
    const payload = sessionPayload(bearerToken, refreshToken);

    dispatch(authSuccess(payload));
  } catch (e) {
    window.localStorage.removeItem("refreshToken");
    window.localStorage.removeItem("bearerToken");

    window.location.replace("/log-in");
  }
};

export const addIntent = payload => dispatch => {
  dispatch({ type: "ADD_INTENT", payload });
};

export const clearIntent = () => dispatch => {
  dispatch({ type: "CLEAR_INTENT" });
};

export const logOut = () => dispatch => {
  window.localStorage.removeItem("refreshToken");
  window.localStorage.removeItem("bearerToken");

  dispatch({ type: "LOG_OUT" });
};

const authSuccess = payload => {
  return { type: "AUTH_SUCCESS", payload };
};

const authError = payload => {
  return { type: "AUTH_ERROR", payload };
};

const sessionPayload = (bearerToken, refreshToken) => {
  const user = JSON.parse(atob(bearerToken.split(".")[1]));

  return {
    bearerToken,
    refreshToken,
    user
  };
};
