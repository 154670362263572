import React, { useEffect, useState,useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthorizedRoute from "../../components/AuthorizedRoute";
import { get } from '../../api';
import useThunk from '../../hooks/thunk';
import MapContext from '../../contexts/Map';
import TypesContext from '../../contexts/Types';
import { useWindowSize } from '../../hooks/window';
import { MapReducer, initialMapState } from '../../reducers/Map';
import { AuthContext } from "../../contexts/Auth";
import DeviceDetail from '../DeviceDetail';
import Assets from '../Assets';
import AssetDetail from '../AssetDetail';
import Map from '../../components/Map';
import LoadingFrame from '../../components/LoadingFrame';
import styles from './Manage.module.scss';
import { SYS_ADMIN, TENANT_ADMIN} from "../../constants/roles";
export default function Manage() {
  const { height } = useWindowSize();
  const [state, dispatch] = useThunk(MapReducer, initialMapState);
  const { state:user } = useContext(AuthContext);
  const [types, setTypes] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchTypes() {
      setLoading(true);
      const [assetTypes, deviceTypes] = await Promise.all([
        get('/assettypes'),
        get('/devicetypes')
      ]);
      assetTypes.results && setTypes({
        assets: assetTypes.results,
        devices: deviceTypes.results
      });
      setLoading(false);
    }
    fetchTypes();
  }, []);

  const classes = [
    styles.layout,
    state.isMapShowing ? styles['layout--map-showing'] : ''
  ].join(' ');

  return loading ? (
    <LoadingFrame />
  ) : (
    <TypesContext.Provider value={types}>
    
      <MapContext.Provider value={[state, dispatch]}>
        <div className={classes}>
          <section className={styles.content}>
            <Switch>
              <Redirect from="/" to="/map" exact nothrow />
              <AuthorizedRoute 
                  userRoles={user.user.roles}
                  allowedRoles={[SYS_ADMIN, TENANT_ADMIN]}
                  path="/sentineldevice/:id(\d+)"
                  component={DeviceDetail} />
            
              <Route path="/assets/:id(\d+)" component={AssetDetail} />
              <Route path="/assets" component={Assets} />
              
            </Switch>
          </section>
          <section className={styles.map} style={{ height: `${height}px` }}>
            <Map />
          </section>
        </div>
      </MapContext.Provider>
    </TypesContext.Provider>
  );
}
