import React, { useEffect, useState, useContext } from "react";
import { Route, Link } from "react-router-dom";
import moment from "moment";
import {
  fetchAsset,
  saveAsset,
  fetchRelation,
  fetchActivity,
  removeRelation,
  updateActivityFilters,
  updateActivityPage,
} from "../../actions/Asset";
import {
  isolateMarker,
  toggleMap,
  showAllMarkers,
  setMarkers,
  displayGPSRecalibrateButton,
  //setSingleMarker,
  removeMarker,
  centerSmallMap,
  removeAllMarkers,
  resetMapState,
  disableMap,
} from "../../actions/Map";
import CheckboxWithLabel from "../../components/CheckboxWithLabel";
import { destroy, get, upload, uploadQR, put, post } from "../../api";
import { isAuthorized, hasPermission } from "../../helpers/authorization";
import { AuthContext } from "../../contexts/Auth";
import history from "../../helpers/history";
import AssetContext from "../../contexts/Asset";
import MapContext from "../../contexts/Map";
import TypesContext from "../../contexts/Types";
import useThunk from "../../hooks/thunk";
import { AssetReducer, initialAssetState } from "../../reducers/Asset";
import {
  getOrientation,
  getXYZ,
  low,
  low_medium,
  medium,
  medium_high,
  high,
} from "../../helpers/orientation";
import InstallOnAsset from "../InstallOnAsset";
import AssetNote from "../AssetNote";
import AssetInspectionWizard from "../AssetInspectionWizard";
import AssetInspectionEdit from "../AssetInspectionEdit";
import AssetActivity from "../../components/AssetActivity";
import AssociatedDevice from "../../components/AssociatedDevice";
import Breadcrumb from "../../components/Breadcrumb";
import Breadcrumbs from "../../components/Breadcrumbs";
import Button from "../../components/Button";
import Frame from "../../components/Frame";
import FrameFooter from "../../components/FrameFooter";
import FrameHeader from "../../components/FrameHeader";
import QrCodeScanner from "../../components/QrCodeScanner";
import Label from "../../components/Label";
import LoadingFrame from "../../components/LoadingFrame";
import MapToggleButton from "../../components/MapToggleButton";
import { useMediaQuery } from "react-responsive";
import OrientationOptions from "../../components/OrientationOptions";
import PageHeader from "../../components/PageHeader";
import PageWrap from "../../components/PageWrap";
import Space from "../../components/Space";
import Image from "../../components/Image";
import styles from "./AssetDetail.module.scss";
import SliderCustom from "../../components/SliderCustom";
import {
  SENTINEL_TMA,
  SENTINEL_MOBILE_TRACKER,
} from "../../constants/sentinel_types.js";
import RepairStart from "../RepairStart";
import { fetchDevice, fetchDeviceOnly } from "../../actions/Device";
import AssetEventEdit from "../AssetEventEdit";
import AssetNoteEdit from "../AssetNoteEdit/AssetNoteEdit";
import AssetEdit from "../AssetEdit";
import Loading from "../../components/Loading";
import Modal from "../../components/Modal";
import queryString from "query-string";
import { useConfirm } from "material-ui-confirm";
import { TENANT_ADMIN } from "../../constants/roles";
import ImgFlat from "../../images/orientations/flat.png";
import ImgHorizontal from "../../images/orientations/horizontal.png";
import ImgVertical from "../../images/orientations/vertical.png";
import ImgUnknown from "../../images/orientations/unknown.png";
import {
  POSITION_FLAT,
  POSITION_VERTICAL,
  POSITION_HORIZONTAL,
  POSITION_UNKNOWN,
} from "../../helpers/orientation";
import {
  INACTIVE_EXPIRED_LICENSE,
  OK,
  WORK_IN_PROGRESS,
  IMPACT,
  READY,
} from "../../constants/statuses";
import { createTheme } from "baseui";
import { PinCode } from "baseui/pin-code";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { BaseProvider } from "baseui";
import {
  ERROR,
  NOT_AVAILABLE,
  DEVICE_ALREADY_ASSIGED,
  DEVICE_READY_TO_ASSIGN,
  DEVICE_SIM_NOT_ACTIVATED,
} from "../../constants/activation_statuses.js";
import Icon from "../../components/Icon";
import Select from "../../components/Select";
import TextInput from "../../components/TextInput";
import InputRow from "../../components/InputRow";
import Popover from "../../components/Popover";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
export default function AssetDetail({
  match: { params },
  location: { search },
}) {
  const queryParams = queryString.parse(search);
  const [currentDeviceStatus, setCurrentDeviceStatus] = useState({
    status: "",
    latitude: 0,
    longitude: 0,
  });
  const { state: currentUser } = useContext(AuthContext);
  const [state, dispatch] = useThunk(AssetReducer, initialAssetState);
  const [maintenanceState, setMaintenanceState] = useState(false);
  const types = useContext(TypesContext);
  const [, mapDispatch] = useContext(MapContext);
  const [initialLoad, setInitialLoad] = useState(true);
  const [assetForm, setAssetForm] = useState({});
  const [assetInitialForm, setAssetInitialForm] = useState({});
  const [relationGroups, setRelationGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const [assetStats, setAssetStats] = useState({});
  const [statsLoading, setStatsLoading] = useState(true);
  const [orientationLoading, setOrientationLoading] = useState(false);
  const [sentinelType, setSentinelType] = useState(1);
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const [simpleMode, setSimpleMode] = useState(
    queryParams.qr || window.sessionStorage.getItem("bbMode") === "true"
  );
  const confirm = useConfirm();
  const [noZIndex, setNoZIndex] = useState(false);
  const [resetMap, setResetMap] = useState(true);
  const [orientationChangeDetected, setOrientationChangeDetected] =
    useState(false);
  const [disabledControls, setDisabledControls] = useState(false);
  const [transferDevice, setTransferDevice] = useState(false);
  const [deviceSelectorType, setDeviceSelectorType] = useState(0);
  const [showQRScanning, setShowQRScanning] = useState(false);
  const [selectedDeviceForTrasfer, setSelectedDeviceForTransfer] = useState(0);
  const [loading, setLoading] = useState(true);
  const [dev, setDev] = useState({});
  const [notes, setNotes] = useState("");
  const [devicesWithNoLicenses, setDevicesWithNoLicenses] = useState([]);
  const [allDevices, setAllDevices] = useState([]);
  const [checkingDevice, setCheckingDevice] = useState(false);
  const [values, setValues] = useState(["", "", "", ""]);
  const [checkingPin, setCheckingPin] = useState(false);
  const [returnMessage, setReturnMessage] = useState(null);
  const [pinEnabled, setPinEnabled] = useState(false);
  const [serialEnabled, setSerialEnabled] = useState(true);
  const [newDeviceSerial, setNewDeviceSerial] = useState(null);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [backOptions, setBackOptions] = useState(true);
  const [checkingQR, setCheckingQR] = useState(false);
  const [resultQR, setResultQR] = useState(null);

  const engine = new Styletron();
  const primitives = {
    inputFillActive: "#a9a9a9",
    inputFill: "#d9d9d9",
  };
  const overrides = {
    colors: {
      inputFillActive: primitives.inputFillActive,
      inputFill: primitives.inputFill,
    },
  };
  const pinTheme = createTheme(primitives, overrides);
  const [selectionMode, setSelectionMode] = useState("");

  useEffect(() => {
    if (queryParams.qr) {
      window.sessionStorage.setItem("bbMode", "true");
      window.sessionStorage.setItem("qrRedirect", "true");
    }

    async function getAvailableDevices() {
      if (isAuthorized(currentUser.user.roles, [TENANT_ADMIN])) {
        const devices = await get(
          `/customers/${currentUser.user.tenant_id}/devices`,
          {
            no_license: true,
            active: false,
            auto_renew: true,
          }
        );
        devices && setDevicesWithNoLicenses(devices);
      } else {
        const devices = await get(
          `/customers/${currentUser.user.customer_id}/devices`,
          {
            no_license: true,
            active: false,
            auto_renew: true,
          }
        );
        devices && setDevicesWithNoLicenses(devices);
      }

      const all_devices = await get(`/devices`, {});
      all_devices && all_devices.results && setAllDevices(all_devices.results);
      setLoading(false);
    }
    async function getAssetStats() {
      const res = await get(`/assets/${params.id}/stats`);
      res && setAssetStats(res);
      setStatsLoading(false);
    }
    dispatch(fetchAsset(params.id));
    getAssetStats();
    getAvailableDevices();
  }, [params.id]);

  useEffect(() => {
    const { filters, page } = state.activity;
    dispatch(fetchActivity(params.id, filters, page));
    async function getAssetOrientationAlarms() {
      const alarms = await get("/alarms", {
        entity_type: "DEVICE",
        entity_id: state.device.id,
        reference_id: state.asset.id,
        status: "ACTIVE_UNACK",
        type: "ORIENTATION_CHANGE",
      });

      alarms && alarms?.results && alarms?.results.length > 0
        ? state.device.status !== INACTIVE_EXPIRED_LICENSE &&
          setOrientationChangeDetected(true)
        : console.log("");
    }
    state.device.id && state.asset.id && getAssetOrientationAlarms();
    state.device && setDev(state.device);
  }, [
    state.relation.id,
    state.activity.filters,
    state.activity.page,
    state.asset,
    state.device,
  ]);

  function refreshActivity(refresh) {
    const { filters, page } = state.activity;
    dispatch(fetchActivity(params.id, filters, page));
    if (refresh == true) {
      dispatch(fetchAsset(params.id));
      fetchRelationGroups(params.id);
      dispatch(fetchDevice(state.device.id));
      const marker = [{ ...state.device, assets: [state.asset] }];
      mapDispatch(setMarkers(marker));
    }
    dispatch(updateActivityPage(page));
  }

  async function fetchRelationGroups(id) {
    if (id != undefined) {
      const gre = await get(`/assets/${id}/groups`);
      setRelationGroups(gre);
    }
  }

  function maintenanceEdited() {
    return assetForm.maintenance != assetInitialForm.maintenance;
  }

  function orientationOrSensitivityEdited() {
    return (
      assetForm.orientation != assetInitialForm.orientation ||
      assetForm.sensitivity != assetInitialForm.sensitivity
    );
  }
  useEffect(() => {
    async function fetchGroups() {
      if (state.asset.customer_id) {
        if (isAuthorized(currentUser.user.roles, [TENANT_ADMIN])) {
          const res = await get(`/customers/${state.asset.customer_id}/groups`);
          if (res && res.length) {
            setGroups(res);
          }
        } else {
          const res = await get(`/users/${currentUser.user.user_id}/groups`);
          if (res && res.results && res.results.length) {
            setGroups(res.results);
          }
        }
      }
    }

    /** Set the initial values in the asset form */
    setAssetForm({
      name: state.asset.name,
      type: state.asset.assettype_id,
      serial: state.asset.serial,
      threshold_x: state.asset.threshold_x,
      threshold_y: state.asset.threshold_y,
      threshold_z: state.asset.threshold_z,
      assettype_id: state.asset.assettype_id,
      //group_id: state.asset.group_id,
      //site_id: state.asset.site_id,
      maintenance: state.asset.maintenance,
      sensitivity: SensitivityRetrieval(),
      deployed: state.asset.deployed,
      orientation: state.asset.orientation,
    });

    /*Set initial form for enabling save button */
    setAssetInitialForm({
      maintenance: state.asset.maintenance,
      sensitivity: SensitivityRetrieval(),
      orientation: state.asset.orientation,
    });

    fetchRelationGroups(state.asset.id);
    //Set asset maintenance mode on/off
    setMaintenanceState(state.asset.maintenance);
    /** Get the groups for the asset's customer */
    fetchGroups();
    /** If active, center the map on this device */
    if (resetMap) {
      mapDispatch(resetMapState());
      mapDispatch(removeAllMarkers());
      setResetMap(false);
    }

    if (state.device.id && state.asset.id) {
      const { longitude, latitude, status } = state.device;

      setCurrentDeviceStatus({
        status: status,
        latitude: latitude,
        longitude: longitude,
      });

      status === INACTIVE_EXPIRED_LICENSE && setDisabledControls(true);

      status === INACTIVE_EXPIRED_LICENSE && mapDispatch(disableMap());

      const marker = [{ ...state.device, assets: [state.asset] }];
      setSentinelType(state.device.devicetype_id);
      mapDispatch(setMarkers(marker));
      mapDispatch(isolateMarker([longitude, latitude]));
      if (longitude && latitude) {
        hasPermission(currentUser.user.perms, "ASSET_U") &&
          mapDispatch(displayGPSRecalibrateButton());
      } else {
        if (status === OK || status === WORK_IN_PROGRESS || status === IMPACT) {
          var res = put(`/devices/${state.device.id}/gps/1`);
          if (res && !isMobile) {
            setNoZIndex(true);
          }
        }
      }
    } else {
      if (state.device.id) {
        mapDispatch(removeMarker(state.device.id));
      } else {
        mapDispatch(centerSmallMap());
      }
    }
    /** False-out the initial load so the store's loading state can be used */
    setInitialLoad(false);
  }, [state.device, state.asset.id]);

  useEffect(() => {
    /** Get the relation, if exists */
    state.device.id && dispatch(fetchRelation(state.asset.id, state.device.id));
    //state.device.status!==undefined && setCurrentDeviceStatus(state.device.status);
    const timer = setInterval(() => {
      state.device.id && getDeviceState();
    }, 1000 * 5);
    return () => {
      clearInterval(timer);
    };
  }, [state.device, currentDeviceStatus]);

  async function checkSerial(value) {
    setReturnMessage(null);
    if (value.length >= 15) {
      setCheckingDevice(true);
      setResultQR(null);
      setSelectedDeviceForTransfer(0);
      const deviceToTake = await post(`/device/exist`, {
        serial: value,
        all: true,
      });
      setReturnMessage(deviceToTake);
      if (deviceToTake != undefined) {
        switch (deviceToTake) {
          case ERROR:
            //setError(true);
            break;
          case NOT_AVAILABLE:
            // setError(true);
            break;
          case DEVICE_ALREADY_ASSIGED:
            confirm({
              description:
                "You have entered a device already in your account. Click swap to proceed to the next step.",
              title: null,
              dialogProps: { fullScreen: false },
              cancellationButtonProps: { style: { display: "none" } },
              confirmationButtonProps: {
                color: "primary",
                disableRipple: true,
              },
            })
              .then(() => {})
              .catch(() => {});

            var existingIdOnList = devicesWithNoLicenses.filter(
              (x) => x.serial_number == value
            );
            if (existingIdOnList !== undefined && existingIdOnList.length > 0) {
              setSelectedDeviceForTransfer(existingIdOnList[0].id);
            }
            // next(null, value);
            break;
          case DEVICE_READY_TO_ASSIGN:
            setSerialEnabled(false);
            setNewDeviceSerial(value);
            setPinEnabled(true); //disable field
            break;
          case DEVICE_SIM_NOT_ACTIVATED:
            setNewDeviceSerial(value);
            setSerialEnabled(false);
            setBackOptions(false); //disable back button
            setPinEnabled(true); //disable field
            break;

          default:
            break;
        }
      } else {
        alert("Sorry! Unable to retrieve data. Try again.");
      }
      setCheckingDevice(false);
    }
  }

  async function changeValues(input) {
    setReturnMessage(null);
    setValues(input);
    setError(false);
    setSuccess(false);
    if (
      input[0] !== "" &&
      input[1] !== "" &&
      input[2] !== "" &&
      input[3] !== ""
    ) {
      setCheckingPin(true);
      const device = await post(`/device/${newDeviceSerial}/pin/`, {
        activation_code: input.toString(),
        activate_device: true,
      });
      if (device && device.id !== 0) {
        setSelectedDeviceForTransfer(device.id);
        setSuccess(true);
        setBackOptions(false); //disable back button
        setPinEnabled(false); //disable field
        setCheckingDevice(true);
      } else {
        setValues([, , ,]);
        setError(true);
        setValues(["", "", "", ""]);
      }
      setCheckingPin(false);
    }
  }

  function renderMessageResponse() {
    switch (returnMessage) {
      case ERROR:
        return (
          <div className={styles.wrap}>
            <div className={styles.wrapvert}>
              <div className={styles.error}>
                <Icon name="error" size={22} />
              </div>
              <h1>Something went wrong</h1>
            </div>
            <p className={styles.bodyLeft}>
              Looks like something went wrong with the process.
              <br />
              Contact the Sentinel support team to troubleshoot this issue.
              <br />
              <br />
              Call 949-949-5663 or e-mail sentinelsupport@traffixdevices.com
            </p>
          </div>
        );
      case NOT_AVAILABLE:
        return (
          <div className={styles.wrap}>
            <div className={styles.wrapvert}>
              <div className={styles.error}>
                <Icon name="error" size={22} />
              </div>
              <h1>Device not found</h1>
            </div>
            <p className={styles.bodyLeft}>
              Looks like you typed in the wrong serial number.
            </p>
          </div>
        );
      case DEVICE_ALREADY_ASSIGED:
        return ""; //"DEVICE_ALREADY_ASSIGED";
      case DEVICE_READY_TO_ASSIGN:
        return ""; //"DEVICE_READY_TO_ASSIGN";
      case DEVICE_SIM_NOT_ACTIVATED:
        return "DEVICE_SIM_NOT_ACTIVATED";

      default:
        break;
    }
  }

  function renderWrongPin() {
    return (
      <div className={styles.wrap}>
        <div className={styles.wrapvert}>
          <div className={styles.error}>
            <Icon name="error" size={22} />
          </div>
          <h1>PIN incorrect</h1>
        </div>
        <p className={styles.bodyLeft}>
          Looks like you entered an incorrect PIN code.
        </p>
      </div>
    );
  }

  function renderSuccess() {
    return (
      <div className={styles.wrap}>
        <div className={styles.wrapvert}>
          <div className={styles.icon}>
            <Icon name="check_circle" size={22} />
          </div>
          <h1>PIN correct</h1>
        </div>
        <p className={styles.bodyLeft}>
          PIN matches our records. Click swap to proceed.
        </p>
      </div>
    );
  }

  async function updateLicense() {
    confirm({
      description:
        "Are you sure you want to swap the current device to the one selected?",
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true },
    })
      .then(() => {
        const transfer = post(`/license/transfer`, {
          device_id: dev.id,
          target_device_id: selectedDeviceForTrasfer,
          asset_id: state.asset.id,
          additional_info: notes,
        });
        transfer && setLoading(false);
        transfer && window.location.reload(); //replace(`/assets/${state.asset.id}`)
      })
      .catch((e) => {});
  }

  async function getDeviceState() {
    var refreshedDevice = undefined;
    if (state.device !== undefined) {
      refreshedDevice = await get(`/devices/${state.device.id}`);
      if (refreshedDevice !== undefined && state.device !== undefined) {
        if (
          refreshedDevice.status !== currentDeviceStatus.status ||
          refreshedDevice.latitude !== currentDeviceStatus.latitude ||
          refreshedDevice.longitude !== currentDeviceStatus.longitude
        ) {
          dispatch(fetchDeviceOnly(state.device.id));
          setCurrentDeviceStatus({
            status: refreshedDevice.status,
            latitude: refreshedDevice.latitude,
            longitude: refreshedDevice.longitude,
          });

          const marker = [{ ...refreshedDevice, assets: [state.asset] }];
          mapDispatch(setMarkers(marker));
        }
      }
    }
  }

  function _deleteAssetConfirmation() {
    confirm({
      description: "Are you sure you want to delete this asset?",
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true },
    })
      .then(() => {
        _deleteAsset();
      })
      .catch(() => {
        /* ... */
      });
  }

  async function _deleteAsset() {
    await destroy(`/assets/${params.id}`);
    history.push("/assets_dashboard");
  }

  function changeMaintenanceState() {
    setAssetForm({ ...assetForm, maintenance: !maintenanceState });
    setMaintenanceState(!maintenanceState);
  }

  function simpleModeChangeMaintenanceStateConfirmation() {
    confirm({
      description: `Are you sure you want to ${
        maintenanceState ? "enable" : "disable"
      } impact notifications?`,
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true },
    })
      .then(() => {
        simpleModeChangeMaintenanceState();
      })
      .catch(() => {
        /* ... */
      });
  }

  function simpleModeChangeMaintenanceState() {
    setAssetForm({ ...assetForm, maintenance: !maintenanceState });
    setMaintenanceState(!maintenanceState);
    async function simpleModeChangeMaintenanceStateCall() {
      await put(`/assets/${state.asset.id}/maintenance`, {
        enable: !maintenanceState,
      });
    }
    simpleModeChangeMaintenanceStateCall();
  }

  function clearAndClose() {
    setResultQR(null);
    setCheckingQR(false);
    setCheckingPin(false);
    setPinEnabled(false);
    setSerialEnabled(true);
    setCheckingDevice(false);
    setSelectedDeviceForTransfer(0);
    setTransferDevice(false);
    setSelectionMode("");
  }

  const _renderTransferLicenseFooter = () => {
    return (
      <>
        <Button theme="secondary" onClick={() => clearAndClose()}>
          Cancel
        </Button>
        <Button
          theme="primary"
          disabled={!selectedDeviceForTrasfer}
          onClick={() => updateLicense()}
        >
          Swap
        </Button>
      </>
    );
  };

  function selectDeviceSelectorMode(type) {
    setDeviceSelectorType(type);
    setTransferDevice(true);
  }

  function qrCheckResult(response) {
    setCheckingQR(true);
    setSelectedDeviceForTransfer(0);
    if (response != undefined) {
      setResultQR(response);
      switch (response.result) {
        case 1:
          setSelectedDeviceForTransfer(response.device_id);
          break;
      }
    }
    setCheckingQR(false);
  }

  function displayQRResult() {
    let text = "";
    if (resultQR) {
      switch (resultQR.result) {
        case -2:
          text =
            "Try scanning again.  If the problem persists, click on Select and enter the serial number and PIN.";
          break;
        case -1:
          text =
            "Try scanning again.  If the problem persists, click on Select and enter the serial number and PIN.";
          break;
        case 0:
          text =
            "Try scanning again.  If the problem persists, click on Select and enter the serial number and PIN.";
          break;
        case 1:
          text = "Device found. Click transfer to proceed to the next step.";
          break;
      }
    }

    return (
      <>
        <Space size="m" />
        <div className={styles.wrap}>
          {resultQR.result === 1 ? (
            <>
              <div className={styles.icon}>
                <Icon name="check_circle" size={64} />
              </div>
              <h1>QR code scanned properly</h1>
              <p className={styles.body}>{text}</p>
            </>
          ) : (
            <>
              <div className={styles.error}>
                <Icon name="error" size={64} />
              </div>
              <h1>Unable to read the QR code</h1>
              <p className={styles.body}>{text}</p>
            </>
          )}
        </div>
      </>
    );
  }

  function stepOption(label, initialValue) {
    const active = selectionMode === initialValue;
    const classes = [styles.label, active && styles.labelActive].join(" ");

    return (
      <label className={classes}>
        <div className={styles.img}>
          <div className={styles[initialValue]}>
            <Icon name={initialValue === "qr" ? "qr_code" : "list"} size={64} />
          </div>
        </div>
        <div className={styles.flexContainer}>
          <div className={styles.flexRow}>
            <div className={styles.text}>{active ? <b>{label}</b> : label}</div>
          </div>
        </div>
        <input
          type="radio"
          name="damage"
          value={initialValue}
          onChange={(e) => setSelectionMode(initialValue)}
          checked={active}
        />
      </label>
    );
  }

  function transferDeviceModal(type) {
    var filteredDevicesList = [];
    switch (type) {
      case 0:
        filteredDevicesList = devicesWithNoLicenses;
        break;
      case 1:
        filteredDevicesList = allDevices;
        break;
    }

    function selectFromList(value) {
      setResultQR(null);
      setSelectedDeviceForTransfer(value);
    }

    function renderOptions() {
      switch (selectionMode) {
        case "qr":
          return (
            <>
              <Space size="xs">
                <div className={styles.lineHeight}>
                  Scan the QR code on the new device.
                </div>
              </Space>

              <QrCodeScanner
                setCheckingQR={setCheckingQR}
                onResult={(result) => qrCheckResult(result)}
              />

              <Space size="s">
                {checkingQR ? <LoadingFrame /> : resultQR && displayQRResult()}
              </Space>
            </>
          );
        case "manual":
          return (
            <Space size="xxs">
              <div className={styles.deviceWrap}>
                {filteredDevicesList && filteredDevicesList.length > 0 ? (
                  <>
                    <Space size="xxs">
                      <Label text="Serial number">
                        <Select
                          disabled={loading}
                          value={selectedDeviceForTrasfer}
                          onChange={(e) => selectFromList(e.target.value)}
                        >
                          <option value={-1}>Choose a device...</option>
                          {filteredDevicesList.map((d) => (
                            <option key={d.id} value={d.id}>
                              {d.serial_number}
                            </option>
                          ))}
                        </Select>
                      </Label>
                    </Space>

                    <Space size="xs" />
                    <Space size="xs">
                      <div className={styles.bannerImage}>
                        <Image
                          url={"/img/SentinelPinLocation.png"}
                          token={false}
                          zoom={true}
                          max={false}
                        />
                      </div>
                    </Space>
                    <Space size="xxs">
                      <InputRow>
                        <Label text="Or add a new device">
                          <TextInput
                            keyboardType="numeric"
                            serial_width={true}
                            type="number"
                            spinHide={true}
                            maxLength={15}
                            disabled={checkingDevice || !serialEnabled}
                            placeholder="Enter serial number"
                            onChange={(e) => checkSerial(e.target.value)}
                          ></TextInput>
                        </Label>

                        <Label text="PIN">
                          {pinEnabled ? (
                            <StyletronProvider value={engine}>
                              <BaseProvider theme={pinTheme}>
                                <PinCode
                                  values={values}
                                  onChange={({ values }) =>
                                    changeValues(values)
                                  }
                                  disabled={checkingPin || !pinEnabled}
                                  clearOnEscape
                                  placeholder=""
                                  autoFocus={true}
                                />
                              </BaseProvider>
                            </StyletronProvider>
                          ) : (
                            <StyletronProvider value={engine}>
                              <PinCode disabled placeholder="" />
                            </StyletronProvider>
                          )}
                        </Label>
                      </InputRow>
                      <InputRow>
                        <>
                          {returnMessage != null && (
                            <div>{renderMessageResponse()}</div>
                          )}
                          {error && <div>{renderWrongPin()}</div>}
                          {success && <div>{renderSuccess()}</div>}
                        </>
                      </InputRow>
                    </Space>
                  </>
                ) : (
                  <>
                    <Space size="s">
                      <div className={styles.lineHeight}>
                        No devices available. To add a new device, enter the
                        serial number and the 4-digit PIN code that is located
                        on the Sentinel device.
                      </div>
                    </Space>
                    <Space size="s">
                      <div className={styles.bannerImage}>
                        <Image
                          url={"/img/SentinelPinLocation.png"}
                          token={false}
                          zoom={true}
                          max={false}
                        />
                      </div>
                    </Space>
                    <Space size="xxs">
                      <InputRow>
                        <Label text="Serial number">
                          <TextInput
                            keyboardType="numeric"
                            serial_width={true}
                            type="number"
                            spinHide={true}
                            maxLength={15}
                            disabled={checkingDevice || !serialEnabled}
                            placeholder="Enter serial number"
                            onChange={(e) => checkSerial(e.target.value)}
                          ></TextInput>
                        </Label>
                        <Label text="PIN">
                          {pinEnabled ? (
                            <StyletronProvider value={engine}>
                              <BaseProvider theme={pinTheme}>
                                <PinCode
                                  values={values}
                                  onChange={({ values }) =>
                                    changeValues(values)
                                  }
                                  disabled={checkingPin || !pinEnabled}
                                  clearOnEscape
                                  placeholder=""
                                  autoFocus={true}
                                />
                              </BaseProvider>
                            </StyletronProvider>
                          ) : (
                            <StyletronProvider value={engine}>
                              <PinCode disabled placeholder="" />
                            </StyletronProvider>
                          )}
                        </Label>
                      </InputRow>
                      <InputRow>
                        <>
                          {returnMessage != null && (
                            <div>{renderMessageResponse()}</div>
                          )}
                          {error && <div>{renderWrongPin()}</div>}
                          {success && <div>{renderSuccess()}</div>}
                        </>
                      </InputRow>
                    </Space>
                  </>
                )}
              </div>
            </Space>
          );

        default:
          return;
      }
    }

    return loading ? (
      <LoadingFrame />
    ) : (
      <Modal footer={_renderTransferLicenseFooter()}>
        <Space size="xs">
          <h2>{displayTransferTextHeadier(type)}</h2>
        </Space>
        <Space size="xs">
          <p> {displayTransferText(type)}</p>
        </Space>
        <div className={styles.center}>
          <InputRow>
            <div className={styles.wrap2}>
              {stepOption("Scan QR", "qr")}
              {stepOption("Select", "manual")}
            </div>
          </InputRow>
        </div>

        {renderOptions()}
      </Modal>
    );
  }

  const SensitivityRetrieval = () => {
    var finalSensitivity = 2; //default
    if (state.asset.threshold_x == state.asset.threshold_y) {
      finalSensitivity = state.asset.threshold_z;
    }
    if (state.asset.threshold_x == state.asset.threshold_z) {
      finalSensitivity = state.asset.threshold_y;
    }
    if (state.asset.threshold_y == state.asset.threshold_z) {
      finalSensitivity = state.asset.threshold_x;
    }
    switch (finalSensitivity) {
      case low:
        return 0;
        break;
      case low_medium:
        return 1;
        break;
      case medium:
        return 2;
        break;
      case medium_high:
        return 3;
        break;
      case high:
        return 4;
        break;
      default:
        return medium;
    }
  };

  const displayTransferText = (type) => {
    switch (type) {
      case 0:
        return "Select the device you want the subscription transferred to.";
      case 1:
        return "Select or Scan the new device that will replace the current one.";
      case 2:
        return "?";
    }
  };

  const displayTransferTextHeadier = (type) => {
    switch (type) {
      case 0:
        return "Swap subscription";
      case 1:
        return "Swap devices";
      case 2:
        return "?";
    }
  };

  function _renderContent() {
    const x = assetForm.threshold_x;
    const y = assetForm.threshold_y;
    const z = assetForm.threshold_z;
    const AssetOrientation = assetForm.orientation;
    function disableSimpleMode() {
      confirm({
        description: "Are you sure you want to exit to Desktop Dashboard?",
        title: null,
        dialogProps: { fullScreen: false },
        cancellationButtonProps: { color: "error", disableRipple: true },
        confirmationButtonProps: { color: "primary", disableRipple: true },
      })
        .then(() => {
          setSimpleMode(false);
          window.sessionStorage.setItem("bbMode", "false");
        })
        .catch(() => {
          /* ... */
        });
    }

    const goToInspection = () => {
      let path = `/assets/${params.id}/inspection`;
      history.push(path);
    };

    function _sendGpsRecalibrationRequest(id) {
      var res = put(`/devices/${id}/gps/1`);
      if (res) {
        confirm({
          description: "GPS will be recalibrated within 24 hours",
          title: null,
          dialogProps: { fullScreen: false },
          cancellationButtonProps: { color: "error", disabled: true },
          confirmationButtonProps: { color: "primary", disableRipple: true },
        })
          .then(() => {})
          .catch(() => {
            /* ... */
          });
      }
    }

    function _renderFooter() {
      return (
        <Button theme="secondary" onClick={() => disableSimpleMode()}>
          Exit to Desktop Dashboard
        </Button>
      );
    }

    async function orientationAction(type) {
      setOrientationLoading(true);
      var request = {
        id: state.asset.id,
        deviceId: state.device.id,
        orientation: state.device.orientation,
        reject: false,
      };

      if (type === 0) {
        request.reject = true;
      }
      var res = await post(`/assets/${state.asset.id}/orientation`, request);
      if (type === 1) {
        dispatch(fetchAsset(params.id));
      }
      setOrientationLoading(false);
      setOrientationChangeDetected(false);
    }

    function displayOrientation(orientation) {
      switch (orientation) {
        case POSITION_FLAT:
          return (
            <label className={styles.label}>
              <div className={styles.img}>
                <img src={ImgFlat} />
              </div>
              <div className={styles.textDesc}>Mounted flat</div>
            </label>
          );

        case POSITION_HORIZONTAL:
          return (
            <label className={styles.label}>
              <div className={styles.img}>
                <img src={ImgHorizontal} />
              </div>
              <div className={styles.textDesc}>Mounted horizontally</div>
            </label>
          );

        case POSITION_VERTICAL:
          return (
            <label className={styles.label}>
              <div className={styles.img}>
                <img src={ImgVertical} />
              </div>
              <div className={styles.textDesc}>Mounted vertically</div>
            </label>
          );
        case POSITION_UNKNOWN:
          return (
            <label className={styles.label}>
              <div className={styles.img}>
                <img src={ImgUnknown} />
              </div>
              <div className={styles.textDesc}>Unknown orientation</div>
            </label>
          );
      }
    }

    function displayOrientationWarning(device, asset) {
      return (
        <Modal
          footer={
            <>
              <Button
                onClick={() => orientationAction(0)}
                disabled={orientationLoading}
              >
                Reject
              </Button>
              <Button
                theme="primary"
                onClick={() => orientationAction(1)}
                disabled={orientationLoading}
              >
                Accept
              </Button>
            </>
          }
        >
          <>
            {orientationLoading ? (
              <LoadingFrame />
            ) : (
              <>
                <Space size="l">
                  <h2>Detected orientation change</h2>
                </Space>

                <div className={styles.wrapModal}>
                  <div className={styles.div}>
                    <p className={styles.textModal}>
                      The orientation of the Sentinel is different than the
                      orientation initially chosen on the dashboard.
                    </p>
                    <Space />
                    <div className={styles.break} />
                    <p className={styles.textModal}>Detected Orientation</p>
                    <Space />
                    {displayOrientation(device.orientation)}
                  </div>
                  <Space />
                  <div className={styles.div}>
                    <p className={styles.textModal}>Current Orientation</p>

                    <Space />
                    {displayOrientation(asset.orientation)}
                  </div>

                  <div className={styles.div}>
                    <div className={styles.break} />
                    <Space />
                    <Space />
                    <p className={styles.textModal}>
                      <b>
                        <h2>
                          Click the accept button to change the orientation on
                          the dashboard. To keep the current orientation click
                          the reject button.
                        </h2>
                      </b>
                    </p>
                  </div>
                </div>
              </>
            )}
          </>
        </Modal>
      );
    }

    function _renderHeader() {
      return (
        <div style={{ display: "flex" }}>
          <Button
            theme="secondary"
            icon="arrow_back_ios"
            onClick={() => history.push(`/assets`)}
          >
            Back to assets list
          </Button>
          <MapToggleButton onClick={() => mapDispatch(toggleMap())} />
        </div>
      );
    }

    if (isMobile && simpleMode)
      return (
        <Modal simpleMode footer={_renderFooter()} header={_renderHeader()}>
          <span className={styles.simpleModeContainer}>
            <div
              className={[
                styles.value,
                hasPermission(currentUser.user.perms, "ASSET_U")
                  ? styles.admin
                  : null,
              ].join(" ")}
            >
              {assetForm.name}
            </div>
            {transferDevice && transferDeviceModal(deviceSelectorType)}
            {/*swapDevice && swapDeviceModal()*/}
            {hasPermission(currentUser.user.perms, "INSPECTION_C") && (
              <Button
                onClick={goToInspection}
                disabled={state.device.status === INACTIVE_EXPIRED_LICENSE}
                icon="taxi_alert"
              >
                New report
              </Button>
            )}

            {state.asset && (
              <Button
                onClick={() => simpleModeChangeMaintenanceStateConfirmation()}
                icon={maintenanceState ? "notifications" : "notifications_off"}
                isLoading={state.saving}
                disabled={
                  state.saving ||
                  !hasPermission(currentUser.user.perms, "ASSET_U") ||
                  state.device.status === INACTIVE_EXPIRED_LICENSE
                }
              >
                {maintenanceState ? "Enable" : "Disable"} impact
                <br />
                notifications
              </Button>
            )}

            {state.device && (
              <Button
                icon="room"
                disabled={state.device.status === INACTIVE_EXPIRED_LICENSE}
                onClick={() => _sendGpsRecalibrationRequest(state.device.id)}
              >
                Recalibrate GPS
              </Button>
            )}

            {/*state.device && <Button
            icon="move_up"
            onClick={() => selectDeviceSelectorMode(0)}>
            Transfer subscription
          </Button>*/}

            {state.device && (
              <Button icon="cached" onClick={() => selectDeviceSelectorMode(1)}>
                Swap devices
              </Button>
            )}
          </span>
        </Modal>
      );
    else
      return (
        <div
          className={
            disabledControls && !transferDevice && styles.disabledPointer
          }
        >
          <div
            className={disabledControls && !transferDevice && styles.disabled}
          >
            {state.device.id && (
              <Space size="m">
                <FrameHeader title="Installation photo"></FrameHeader>
                <Frame padding={0}>
                  <div className={styles.bannerImage}>
                    {state.installPhoto.id && (
                      <Image url={state.installPhoto.url} zoom={true} />
                    )}
                    {!state.installPhoto.id && (
                      <Image url="/img/photo_placeholder.jpg" zoom={true} />
                    )}
                  </div>
                </Frame>
              </Space>
            )}

            {transferDevice && transferDeviceModal(deviceSelectorType)}
            <Space size="m">
              <FrameHeader title="Info">
                <div className={styles.totalImpactsNumber}>
                  Total number of impacts:{" "}
                  {statsLoading ? (
                    <Loading />
                  ) : (
                    assetStats && assetStats.impact_count
                  )}
                </div>
                <></>
              </FrameHeader>
              <Frame padding={0}>
                {hasPermission(currentUser.user.perms, "ASSET_U") ? (
                  <div className={styles.editButton}>
                    <Link to={`/assets/${params.id}/edit`}>
                      <Button theme="primary">Edit</Button>
                    </Link>
                  </div>
                ) : null}
                <>
                  <div className={styles.detailsHead}>
                    <Label text="Name" theme="light">
                      <div
                        className={[
                          styles.value,
                          hasPermission(currentUser.user.perms, "ASSET_U")
                            ? styles.admin
                            : null,
                        ].join(" ")}
                      >
                        {assetForm.name}
                      </div>
                    </Label>
                    <Label text="Type" theme="light">
                      <div className={styles.value}>
                        {types.assets &&
                          types.assets.find(
                            (x) => x.id == state.asset.assettype_id
                          ).name}
                      </div>
                    </Label>
                  </div>
                  <div className={styles.details}>
                    <Label text="Groups" theme="light">
                      <Autocomplete
                        multiple
                        id="tags-standard"
                        readOnly
                        options={relationGroups}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" />
                        )}
                        value={relationGroups}
                      />
                    </Label>
                    <Label text="Last impact date" theme="light">
                      <div className={styles.value}>
                        {statsLoading ? (
                          <Loading />
                        ) : assetStats && assetStats.last_impact ? (
                          moment(assetStats.last_impact).format("MMM Do YYYY")
                        ) : (
                          "-"
                        )}
                      </div>
                    </Label>
                  </div>
                </>
              </Frame>
            </Space>

            <Space size="m">
              <FrameHeader title="Impact notifications"></FrameHeader>
              <Frame padding={0}>
                <div className={styles.notificationsControlWrapper}>
                  <div className={styles._w70}>
                    <CheckboxWithLabel
                      label={"Disable notifications"}
                      checked={maintenanceState}
                      onChange={() => changeMaintenanceState()}
                    />
                  </div>
                  <div>
                    {hasPermission(currentUser.user.perms, "ASSET_U") && (
                      <Button
                        theme="primary"
                        onClick={() => dispatch(saveAsset(assetForm))}
                        isLoading={state.saving}
                        disabled={state.saving || !maintenanceEdited()}
                      >
                        Save
                      </Button>
                    )}
                  </div>
                </div>
              </Frame>
            </Space>

            {state.device.id &&
              state.asset.id &&
              state.device.devicetype_id != SENTINEL_MOBILE_TRACKER &&
              state.device.devicetype_id != SENTINEL_TMA && (
                <Space size="m">
                  <Space size="m" />
                  <FrameHeader title="Orientation and sensitivity" />
                  <Frame>
                    <Space size="m">
                      <OrientationOptions
                        label={false}
                        selected={getOrientation(x, y, z, AssetOrientation)}
                        setSelected={(v) =>
                          hasPermission(currentUser.user.perms, "ASSET_U") &&
                          setAssetForm({
                            ...assetForm,
                            ...getXYZ(v, assetForm.sensitivity),
                          })
                        }
                      />
                    </Space>
                    <br />
                    <br />
                    <Space size="m">
                      <div className={styles.break} />
                    </Space>
                    <SliderCustom
                      inputValue={assetForm.sensitivity}
                      onChangeAction={(e, v) => {
                        hasPermission(currentUser.user.perms, "ASSET_U") &&
                          setAssetForm({
                            ...assetForm,
                            ...getXYZ(
                              getOrientation(x, y, z, AssetOrientation),
                              v
                            ),
                          });
                      }}
                    />
                    {hasPermission(currentUser.user.perms, "ASSET_U") && (
                      <FrameFooter>
                        <Button
                          theme="primary"
                          onClick={() => dispatch(saveAsset(assetForm))}
                          isLoading={state.saving}
                          disabled={
                            state.saving || !orientationOrSensitivityEdited()
                          }
                        >
                          Save
                        </Button>
                      </FrameFooter>
                    )}
                  </Frame>
                </Space>
              )}

            {orientationChangeDetected &&
              displayOrientationWarning(state.device, state.asset)}
            <Space size="m">
              <FrameHeader title="Device" />
              <AssociatedDevice
                link={false}
                assetId={state.asset.id}
                device={state.device}
                device_type={state.device.devicetype_id}
                deployed={assetForm.deployed}
                relation={state.relation}
                onRemove={
                  (hasPermission(currentUser.user.perms, "ADMIN") ||
                    hasPermission(currentUser.user.perms, "ASSET_U")) &&
                  ((relationId) => {
                    dispatch(removeRelation(state.asset.id, relationId));
                    mapDispatch(showAllMarkers());
                  })
                }
                /*onLicenseTransfer={(hasPermission(currentUser.user.perms, 'ADMIN') || hasPermission(currentUser.user.perms, 'ASSET_U')) && (deviceId => {
                selectDeviceSelectorMode(0);
              })}*/
                onDeviceSwap={
                  (hasPermission(currentUser.user.perms, "ADMIN") ||
                    hasPermission(currentUser.user.perms, "ASSET_U")) &&
                  ((deviceId) => {
                    selectDeviceSelectorMode(1);
                  })
                }
              />
            </Space>
            <Space size="l">
              <FrameHeader title="Activity" />
              <AssetActivity
                events={state.activity.events}
                loading={state.activity.loading}
                page={state.activity.page}
                perpage={500}
                total={state.activity.total}
                filters={state.activity.filters}
                setFilters={(filters) =>
                  dispatch(updateActivityFilters(filters))
                }
                setCurrentPage={(page) => dispatch(updateActivityPage(page))}
                onRefresh={(refresh) => refreshActivity(refresh)}
              />
            </Space>

            {hasPermission(currentUser.user.perms, "ASSET_D") && (
              <div className={styles.delete}>
                <Button
                  theme="danger"
                  icon="delete_forever"
                  onClick={_deleteAssetConfirmation}
                >
                  Delete asset
                </Button>
              </div>
            )}
          </div>
        </div>
      );
  }

  const assetsDashboardMediaQuery = useMediaQuery({
    query: "(min-width: 1500px)",
  });
  return (
    <AssetContext.Provider value={[state, dispatch]}>
      {(!simpleMode || !isMobile) && (
        <PageHeader
          noZIndex={noZIndex ? true : false}
          right={
            <>
              <MapToggleButton
                text={false}
                onClick={() => mapDispatch(toggleMap())}
              />
              {hasPermission(currentUser.user.perms, "INSPECTION_C") &&
                state.asset.id &&
                (disabledControls ? (
                  <>
                    <Popover
                      //title={"Action"}
                      icon="warning"
                      align="left"
                      alt="Click to swap the subscription or buy new one"
                      theme={"danger"}
                      width={250}
                    >
                      <>
                        <Link to={`/customer/subscriptions/new`}>
                          {" "}
                          <Button theme="danger" icon="add" fixedWidth={210}>
                            Buy subscription
                          </Button>
                        </Link>
                        <Space size="xs" />{" "}
                        <Button
                          icon="refresh"
                          fixedWidth={210}
                          onClick={() => selectDeviceSelectorMode(0)}
                        >
                          Swap subscription
                        </Button>
                      </>
                    </Popover>
                  </>
                ) : (
                  <Link to={`/assets/${params.id}/inspection`}>
                    {" "}
                    <Button theme="primary" icon="add">
                      New{!isMobile && " report"}
                    </Button>
                  </Link>
                ))}
            </>
          }
        >
          <Breadcrumbs>
            <Breadcrumb
              title="Assets"
              to={assetsDashboardMediaQuery ? "/assets_dashboard" : "/assets"}
            />
            <Breadcrumb
              title={state.asset && state.asset.name}
              tooltip={true}
            />
            {disabledControls && (
              <Breadcrumb
                warning={disabledControls}
                title={"Expired"}
                to="/customer/subscriptions/new"
              />
            )}
          </Breadcrumbs>
        </PageHeader>
      )}
      <PageWrap>
        {initialLoad || state.loading > 0 ? <LoadingFrame /> : _renderContent()}
        {!initialLoad && (
          <>
            <Route
              path={`/assets/${params.id}/install`}
              exact
              component={InstallOnAsset}
            />
            <Route path={`/assets/${params.id}/note`} component={AssetNote} />
            <Route
              path={`/assets/:assetId/inspection/:id/edit`}
              component={AssetInspectionEdit}
            />
            <Route
              path={`/assets/:assetId/event/:id/edit`}
              component={AssetEventEdit}
            />
            <Route path={`/assets/:assetId/edit`} component={AssetEdit} />
            <Route
              path={`/assets/:assetId/note/:id/edit`}
              component={AssetNoteEdit}
            />
            <Route
              path={`/assets/:assetId/alarmaction/:id/`}
              component={RepairStart}
            />
            <Route
              path={`/assets/:assetId/start/:id/`}
              component={RepairStart}
            />
            <Route
              path={`/assets/${params.id}/inspection`}
              exact
              component={AssetInspectionWizard}
            />
          </>
        )}
      </PageWrap>
    </AssetContext.Provider>
  );
}
