import React, { useEffect, useState } from 'react';
import { get } from '../../api';
import moment from 'moment';
import Frame from '../../components/Frame';
import LoadingFrame from '../../components/LoadingFrame';
import RowItem from '../../components/RowItem';
import Label from '../../components/Label';
import InputRow from '../../components/InputRow';
import FrameHeader from '../../components/FrameHeader';
export default function AdminPortalQueue() {
  const [loading, setLoading] = useState(true);
  const [queues, setQueues] = useState([]);
  useEffect(() => {
    async function getQueue() {
      const res = await get(`/queue/list`);
      res && setQueues(res.results);
      setLoading(false);
    }
    getQueue();
  }, []);
  return (loading ? <LoadingFrame /> :
    <>
      <FrameHeader title="Queues" />
      <Frame>
        {queues && queues.length > 0 ? (
          <>
            {queues.map(queue =>
              <>
                <InputRow>
                  <Label text="Alarm id" theme='light'>{queue.alarm_id}</Label>
                  <Label text="Device id" theme='light'>{queue.device_id}</Label> 
                  <Label text="Status" theme='light'>{queue.status}</Label>
                  <Label text="Created" theme='light'>{moment(queue.created_time).format('MMMM Do YYYY [at] h:mma')}</Label> 
                  <Label text="Processed" theme='light'>{moment(queue.processed_date).format('MMMM Do YYYY [at] h:mma')}</Label>
                </InputRow>
              </>
            )}
          </>
        ) : ("No pending items")}
      </Frame>
    </>
  );
}
