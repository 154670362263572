import React from 'react';

import LogoIcon from '../LogoIcon';

import styles from './TenantAvatar.module.scss';

export default function TenantAvatar() {
  return (
    <div className={styles.wrap}>
      <LogoIcon size={20} />
    </div>
  );
}
