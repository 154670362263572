import React, { useState, useEffect } from 'react';
import { setMarkers, showAllMarkers } from '../../actions/Map';
import { MapReducer, initialMapState } from '../../reducers/Map';
import { get } from '../../api';
import useThunk from '../../hooks/thunk';
import MapContext from '../../contexts/Map';
import LoadingFrame from '../../components/LoadingFrame';
import Map from '../../components/Map';
var previousDevices = [];
export default function MapDisplay() {
  const [state, dispatch] = useThunk(MapReducer, initialMapState);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchActiveDevicesChange(resetView) {
      const devices = await get('/devices', {
        active: 'true',
        include_assets: '1'
      });
      if (devices && devices.results) {
        if (detectedDeviceStatusChange(devices.results) === true) {
          dispatch(setMarkers(devices.results));
          resetView && dispatch(showAllMarkers());
          previousDevices = [];
          Array.prototype.push.apply(previousDevices, devices.results)
        }
      }
    }

    async function fetchActiveDevices() {
      const devices = await get('/devices', {
        active: 'true',
        include_assets: '1'
      });

      if (devices && devices.results) {
        devices.results && Array.prototype.push.apply(previousDevices, devices.results)
        dispatch(setMarkers(devices.results));
        dispatch(showAllMarkers());
        if (devices.results.length > 0) {
          if (devices.results.length === 1) {
            if (devices.results[0].latitude === 0 || devices.results[0].longitude === 0) {
              //setEmpty(true);
            }
          }
        } else {
          //setEmpty(true);
        }
      } else {
        //setEmpty(true);
      }
    }
    fetchActiveDevices();
    setLoading(false);
    const timer = setInterval(() => {
      fetchActiveDevicesChange(false);
    }, 1000 * 45);

    return () => {
      clearInterval(timer);
    };
  }, []);

  function detectedDeviceStatusChange(devices) {
    var output = false;
    devices.map(device => {

      var dev = retrieveDevice(device.id);
      if (dev !== undefined) {
        if (dev.status !== device.status) {
          output = true;
        }
        if (dev.latitude !== device.latitude || dev.longitude !== device.longitude) {
          output = true;
        }
      }
    });

    return output;
  }

  function retrieveDevice(deviceId) {
    return (previousDevices.find(x => x.id === deviceId));
  }

  return loading ? (
    <LoadingFrame />
  ) : (<MapContext.Provider value={[state, dispatch]}>
    <Map customBackButton={true} />
  </MapContext.Provider>
  );
}
